import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, FormControl, Form } from 'react-bootstrap';

function CustomersHeader(props) {
  const [text, setText] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault(); // prevent page reload
    props.onSearch(text);
  };

  return (
    <Navbar className='vitus-app-header' expand='lg'>
      <Navbar.Brand>{props.title}</Navbar.Brand>
      <Navbar.Toggle aria-controls='app-header-collapsible' />
      <Navbar.Collapse id='app-header-collapsible'>
        <Nav className='mr-auto'></Nav>
        <Form inline onSubmit={handleSubmit}>
          <FormControl
            type='search'
            placeholder='Search'
            className='mr-sm-2 ml-2'
            style={{ maxWidth: 350 }}
            onChange={({ target: { value } }) => setText(value)}
          />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

CustomersHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CustomersHeader;
