import {
  getAllPracticeTransactions,
  getAllPracticeTransactionsByPracticeId,
  getTransactionsByDateRange,
} from '../../api/transactionApi';
import * as spreedlyPublicApi from 'utils/api/spreedlyPublicApi';
import { StoreActions } from '.';
import moment from 'moment';

/**
 * PRIVATE: store to redux after response success
 * @param {object} transactions
 */
function getPracticeTransactionsSuccess() {
  return {
    type: StoreActions.GET_PRACTICE_TRANSACTIONS_SUCCESS,
  };
}

/**
 * PRIVATE: store to redux after response success
 * @param {object} transactions
 */
function getPracticeTransactionsStarted() {
  return {
    type: StoreActions.GET_PRACTICE_TRANSACTIONS_STARTED,
  };
}

/**
 * PRIVATE: store to redux after response success
 * @param {object} transactions
 */
function getPracticeTransactionsPageSuccess(transactions) {
  return {
    type: StoreActions.GET_PRACTICE_TRANSACTIONS_PAGE_SUCCESS,
    transactions,
  };
}

export function updateTransaction(transaction) {
  return function (dispatch) {
    dispatch({
      type: StoreActions.UPDATE_TRANSACTION,
      transaction,
    });
  };
}

/**
 * Get list of transactions in practice page
 */
export function getPracticeTransactions() {
  return function (dispatch) {
    dispatch(getPracticeTransactionsStarted());
    const getPageCallback = (response) =>
      dispatch(getPracticeTransactionsPageSuccess(response.Items));
    return getAllPracticeTransactions(getPageCallback)
      .then(() => dispatch(getPracticeTransactionsSuccess()))
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Get list of transactions in practice page
 */
export function getPracticeTransactionsByPracticeId(practiceId) {
  return function (dispatch) {
    dispatch(getPracticeTransactionsStarted());
    const getPageCallback = (response) =>
      dispatch(getPracticeTransactionsPageSuccess(response.Items));
    return getAllPracticeTransactionsByPracticeId(practiceId, getPageCallback)
      .then(() => dispatch(getPracticeTransactionsSuccess()))
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Get list of transactions for a single day
 */
export function getDailyTransactions(date = moment()) {
  return function (dispatch) {
    const startDate = moment(date).startOf('day').valueOf();
    const endDate = moment(date).endOf('day').valueOf();
    return getTransactionsByDateRange(startDate, endDate, (response) =>
      dispatch({
        type: StoreActions.GET_DAILY_TRANSACTIONS,
        transactions: response.Items.sort((a, b) => {
          return b.time - a.time;
        }),
      })
    ).catch((err) => {
      throw err;
    });
  };
}

/**
 * Get Spreedly transaction's info
 */
export function getSpreedlyTransactionInfo(id) {
  return function (dispatch) {
    return spreedlyPublicApi
      .getSpreedlyTransaction(id)
      .then((response) => {
        dispatch({
          type: StoreActions.GET_SPREEDLY_TRANSACTION,
          payload: response,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Get Spreedly draft transaction's info
 */
export function getDraftSpreedlyTransaction(id) {
  return function (dispatch) {
    return spreedlyPublicApi
      .getDraftSpreedlyTransaction(id)
      .then((response) => {
        dispatch({
          type: StoreActions.GET_SPREEDLY_TRANSACTION,
          payload: response,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Send Spreedly payment token
 */
export function postSpreedlyPaymentInfo(
  transactionId,
  token,
  retain_on_success = false
) {
  return function (dispatch) {
    return spreedlyPublicApi
      .postSpreedlyPaymentToken(transactionId, token, retain_on_success)
      .then((response) => {
        dispatch({
          type: StoreActions.POST_SPREEDLY_PAYMENT_INFO,
          payload: response,
        });
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };
}
