import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import styles from './PrivacyPolicyModal.module.scss';

const PrivacyPolicyModal = ({ show = false, onClose = null, link = '' }) => {
  return (
    <Modal
      className={styles.modal}
      show={show}
      onHide={onClose}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='text-center'>Privacy Policy</Modal.Header>
      <Modal.Body>
        <iframe
          title='privacy-policy-frame'
          style={{ width: '100%', height: '100%', overflow: 'scroll' }}
          src={link}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={() => onClose()}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyPolicyModal;
