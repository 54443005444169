import React from 'react';
import GitInfo from 'react-git-info/macro';
import Env from 'Env';

const gitInfo = GitInfo();
const AppVersion = () => {
  return (
    <div id='AppVersion' className='appversion'>
      <span>
        {Env.environment}
        <br />
        {gitInfo.branch}
        <br />
        {gitInfo.commit.date}
      </span>
    </div>
  );
};

export default AppVersion;
