import { StoreActions } from './../actions';
import initialState from './initialState';

export const currentUser = (state = initialState.authReducer, action) => {
  switch (action.type) {
    case StoreActions.LOGIN_SUCCESS:
      return action.userCredentials;
    case StoreActions.PASSWORD_RESET_SUCCESS:
      return action.userCredentials;
    case StoreActions.LOGOUT:
      return initialState.authReducer;
    default:
      return state;
  }
};

export const challengeReducer = (
  state = initialState.authChallengeReducer,
  action
) => {
  switch (action.type) {
    case StoreActions.NEW_PASSWORD_REQUIRED:
      return action.challengeObj;
    case StoreActions.PASSWORD_RESET_SUCCESS:
      return initialState.authChallengeReducer;
    case StoreActions.LOGOUT:
      return initialState.authChallengeReducer;
    default:
      return state;
  }
};

export const forgotPasswordRequest = (
  state = initialState.authForgotPasswordReducer,
  action
) => {
  switch (action.type) {
    case StoreActions.FORGOT_PASSWORD_REQUEST:
      return action.requestObj;
    case StoreActions.FORGOT_PASSWORD_RESET:
      return action.requestObj;
    case StoreActions.RESET_PASSWORD_SUCCESS:
      return initialState.authForgotPasswordReducer;
    case StoreActions.LOGOUT:
      return initialState.authForgotPasswordReducer;
    default:
      return state;
  }
};

export const passwordPolicyReducer = (
  state = initialState.passwordPolicy,
  action
) => {
  switch (action.type) {
    case StoreActions.PASSWORD_POLICY:
      return action.PasswordPolicy;
    default:
      return state;
  }
};
export default currentUser;
