import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoDataToDisplay from '../NoDataToDisplayList';
import PropTypes from 'prop-types';

const ItemsTable = ({
  keyField = 'id',
  columns = [],
  data = [],
  noDataMessage = 'No items to display',
  title = '',
  paginationOptions = null,
  defaultSorted = null,
  sortedByMostRecent = true,
  paginationEnabled = false,
  onRefresh = null,
  ...props
}) => {
  const defaultPaginationOptions = {
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    // TODO: check how to give keys to this icons
    // prePageText: <i className='fas fa-angle-left'></i>,
    // firstPageText: <i className='fas fa-angle-double-left'></i>,
    // nextPageText: <i className='fas fa-angle-right'></i>,
    // lastPageText: <i className='fas fa-angle-double-right'></i>,
    prePageText: '<',
    firstPageText: '<<',
    nextPageText: '>',
    lastPageText: '>>',
    paginationSize: 5,
    alwaysShowAllBtns: true,
  };

  function enablePagination() {
    if (paginationEnabled || paginationOptions) {
      if (paginationOptions) {
        return paginationFactory(paginationOptions);
      }

      return paginationFactory(defaultPaginationOptions);
    }

    return null;
  }

  function enableSortingByMostRecent() {
    if (sortedByMostRecent) {
      return [{ dataField: 'time', order: 'desc' }];
    }

    return null;
  }

  return (
    <div className={'table-responsive-md items-table mt-3 pl-4 pr-4'}>
      {title && (
        <div className='d-flex flex-wrap align-items-baseline pt-3 pb-2'>
          {title && <h1 className='mr-2'>{title}</h1>}
        </div>
      )}
      {data.length > 0 ? (
        <BootstrapTable
          keyField={keyField}
          data={data}
          columns={columns}
          bordered={false}
          classes='vitus-table'
          pagination={enablePagination()}
          defaultSorted={
            defaultSorted ? defaultSorted : enableSortingByMostRecent()
          }
          noDataIndication={noDataMessage}
          {...props}
        />
      ) : (
        <NoDataToDisplay message={noDataMessage} />
      )}
    </div>
  );
};

ItemsTable.propTypes = {
  keyField: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  noDataMessage: PropTypes.string,
  title: PropTypes.string,
  paginationOptions: PropTypes.object,
  sortedByMostRecent: PropTypes.bool,
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  paginationEnabled: PropTypes.bool,
  onRefresh: PropTypes.func,
  showLastUpdated: PropTypes.bool,
};

export default ItemsTable;
