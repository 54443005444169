import { cloneDeep } from 'lodash';
import moment from 'moment';

export function sortPaymentLinkLogChronologically(log) {
  const sortedLog = cloneDeep(log);
  sortedLog.sort((l1, l2) => {
    let difference = 0;
    if (l1.type === '2Way.Event' && l2.type === '2Way.Event') {
      //sort 2Way events by their own timestamp
      //if a 2Way timestamp is missing treat as generic entries
      if (l1.eventTime && l2.eventTime) {
        difference = +moment(l1.eventTime) - +moment(l2.eventTime);
        if (!difference) {
          //if timestamps are equal assume the following order
          //as of 9/22/2020 we're using eventbridge timestamps which are in "seconds"
          //so they're equal often
          const twowayStatusOrder = [
            'invalid',
            'error',
            'pending',
            'sent',
            'delivered',
            'undelivered',
            'failed',
          ];
          const l1StatusOrder = twowayStatusOrder.indexOf(l1.twowayStatus);
          const l2StatusOrder = twowayStatusOrder.indexOf(l2.twowayStatus);
          difference = l1StatusOrder - l2StatusOrder;
        }
      }
    }

    if (!difference) {
      //eventTime is in seconds, created in milliseconds.
      //we don't know where in the second the eventTime occurred so assume it
      //happened near the end by adding 999ms
      const l1Time = l1.eventTime ? +moment(l1.eventTime) + 999 : l1.created;
      const l2Time = l2.eventTime ? +moment(l2.eventTime) + 999 : l2.created;
      difference = l1Time - l2Time;
    }
    return difference;
  });
  return sortedLog;
}

export default function mapPaymentLinkLogToModel(paymentLinkLog) {
  if (paymentLinkLog == null) {
    return null;
  }
  const unparsedLogEntries = [];
  const messageHistory = {
    unparsedLogEntries,
    hasFromPhone: false,
    fromPhone: null,
  };

  const sortedLog = sortPaymentLinkLogChronologically(paymentLinkLog.messages);

  const messages = {};
  //reconstruct the message model by replaying the events from the log
  sortedLog.forEach((l) => {
    if (!l.id) {
      unparsedLogEntries.push(l);
    } else {
      try {
        if (!messages[l.id]) {
          messages[l.id] = { id: l.id, logEntries: [] };
        }
        let thisMessageModel = messages[l.id];
        thisMessageModel.logEntries.push(l);
        switch (l.type) {
          case 'T2P.EmailReceiptRequested':
          case 'T2P.TextReceiptRequested':
          case 'T2P.NoReceiptRequested':
          case 'T2P.SendingLink':
          case 'T2P.TextRefundRequested':
          case 'T2P.SendingReceipt': //obsolete
          case 'SMS.Message': //obsolete
            thisMessageModel.content = l.content;
            thisMessageModel.time = l.created;
            thisMessageModel.to = l.to;
            thisMessageModel.status = l.twowayStatus ?? l.twoWayStatus;
            break;
          case 'E2P.SendingLink':
            thisMessageModel.time = l.created;
            thisMessageModel.to = l.to;
            thisMessageModel.status =
              l.sendGridEventStatus ?? l.sendGridEventStatus;
            break;
          case '2Way.Event':
            thisMessageModel.status = l.twowayStatus ?? l.twoWayStatus;
            thisMessageModel.statusTime = l.eventTime;
            thisMessageModel.statusMessage = l.message;
            if (l.from) {
              thisMessageModel.fromPhone = l.from;
              if (!messageHistory.hasFromPhone) {
                messageHistory.fromPhone = l.from;
                messageHistory.hasFromPhone = true;
              }
            }
            break;
          case 'SendGrid.Event':
            thisMessageModel.status = l.sendGridEventStatus;
            thisMessageModel.statusTime = l.created;
            thisMessageModel.statusMessage = l.message;
            if (l.sendGridReason)
              thisMessageModel.sendGridReason = l.sendGridReason;
            break;
          default:
        }
      } catch {
        unparsedLogEntries.push(l);
      }
    }
  });

  messageHistory.messages = Object.values(messages);

  messageHistory.failures = paymentLinkLog.failures;

  return messageHistory;
}
