import React, { Fragment } from 'react';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import Currency from 'utils/helpers/currency';
import DateTime from 'utils/helpers/DateTime';

const popoverStyle = {
  maxHeight: 400,
  overflowY: 'auto',
  overflowX: 'hidden',
};

const TransactionVoidHistory = ({ transaction }) => {
  const voidObj = transaction.void;
  const amount = transaction.amount;

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Title as='h3' className='text-center'>
        Void History
      </Popover.Title>
      <Popover.Content>
        <div style={popoverStyle}>
          <Fragment>
            <div className='row' style={{ minWidth: 265 }}>
              <div className='col-4'>
                <b>Date</b>
              </div>
              <div className='col text-left'>
                <DateTime
                  datetime={DateTime.normalizeEpoch(voidObj.created)}
                  format='MM/DD/YYYY - h:mm a'
                />
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-4'>
                <b>Amount</b>
              </div>
              <div className='col'>
                <Currency amount={amount} />
              </div>
            </div>
            {voidObj.reason && (
              <div className='row mt-1'>
                <div className='col-4'>
                  <b>Reason</b>
                </div>
                <div className='col'>
                  <p>{voidObj.reason}</p>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger='focus' placement='right' overlay={popover}>
      <Button variant='outline-secondary'>
        VIEW VOID
        <i className='ml-2 fas fa-eye' aria-hidden='true'></i>
      </Button>
    </OverlayTrigger>
  );
};

export default TransactionVoidHistory;
