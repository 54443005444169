import { useState } from 'react';

export default function useToggle(initialVal = false) {
  const storedInitialVal = initialVal;

  const [state, setState] = useState(storedInitialVal);

  const toggle = (arg) => {
    if (typeof arg === 'function') {
      setState(arg);
    } else if (typeof arg === 'boolean') {
      setState(arg);
    } else {
      setState(!state);
    }
  };

  const reset = () => {
    setState(storedInitialVal);
  };

  return [state, toggle, reset, storedInitialVal];
}
