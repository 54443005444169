import { StoreActions } from './../store/actions';
import axios from 'axios';
import { API_AUTH_URL, handleResponse, handleError } from './../api';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
//import { store } from './../store/storeConfig';

const baseUrl = `${API_AUTH_URL}/login`;

const getItemFromLocalStorage = (item) => localStorage.getItem(item);

export const UserRoleMapping = {
  TEAM_ADMINISTRATOR: 'TeamAdministrator',
  PRACTICE_STAFF: 'PracticeStaff',
  PRACTICE_ADMINISTRATOR: 'PracticeAdministrator',
  SUPPORT_AGENT: 'SupportAgent',
};

function parseCredentials(AccessToken, IdToken) {
  let parsedCredentials = {
    ...jwtDecode(AccessToken),
    ...jwtDecode(IdToken),
  };
  parsedCredentials.roles = {
    tabletUser: false,
    practiceStaff: false,
    practiceAdmin: false,
    supportAgent: false,
    teamAdministrator: false,
    protectedAccount: false,
  };
  parsedCredentials.id = parsedCredentials['custom:UserId'];
  const userRole = parsedCredentials['custom:UserRole'];
  const splitRoles = (userRole ?? '').split(',');
  parsedCredentials.roles = {
    tabletUser: splitRoles.includes('tabletUser'),
    practiceStaff:
      splitRoles.includes('practiceStaff') ||
      userRole === UserRoleMapping.PRACTICE_STAFF,
    practiceAdmin:
      splitRoles.includes('practiceAdmin') ||
      userRole === UserRoleMapping.PRACTICE_ADMINISTRATOR,
    supportAgent:
      splitRoles.includes('supportAgent') ||
      userRole === UserRoleMapping.SUPPORT_AGENT,
    teamAdministrator:
      splitRoles.includes('teamAdministrator') ||
      userRole === UserRoleMapping.TEAM_ADMINISTRATOR,
    protectedAccount: splitRoles.includes('protectedAccount'),
  };
  return parsedCredentials;
}

export default class Auth {
  storeValues = {
    ACCESS_TOKEN: 'AccessToken',
    ID_TOKEN: 'IdToken',
    REFRESH_TOKEN: 'RefreshToken',
    //INSTALLMENTS_CONFIG: 'installmentsConfig'
  };

  constructor() {
    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.logout = this.logout.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.sendRecoverPasswordEmail = this.sendRecoverPasswordEmail.bind(this);
    this.recoverPassword = this.recoverPassword.bind(this);
  }

  getAccessToken = () => getItemFromLocalStorage(this.storeValues.ACCESS_TOKEN);

  getRefreshToken = () =>
    getItemFromLocalStorage(this.storeValues.REFRESH_TOKEN);

  getIdToken = () => getItemFromLocalStorage(this.storeValues.ID_TOKEN);

  getUserData = () =>
    parseCredentials(this.getAccessToken(), this.getIdToken());

  getPracticeId = () => {
    const data = this.getUserData();
    return data?.['custom:PracticeId'] ?? '';
  };

  login(username, password) {
    return axios
      .post(baseUrl, { username, password })
      .then(handleResponse)
      .catch(handleError);
  }

  handleAuthentication(credentials) {
    if (credentials.AuthenticationResult) {
      const {
        AccessToken,
        IdToken,
        RefreshToken = this.getRefreshToken(),
      } = credentials.AuthenticationResult;
      //const { installments_min_amount, globalInstallmentsPlan } = credentials;

      localStorage.setItem(this.storeValues.ACCESS_TOKEN, AccessToken);
      localStorage.setItem(this.storeValues.ID_TOKEN, IdToken);
      localStorage.setItem(this.storeValues.REFRESH_TOKEN, RefreshToken);
      //localStorage.setItem(this.storeValues.INSTALLMENTS_CONFIG, JSON.stringify({ installments_min_amount, globalInstallmentsPlan }))

      return parseCredentials(AccessToken, IdToken);
    } else {
      return credentials;
    }
  }

  isAuthenticated(verifyExpiration = false) {
    const AccessToken =
      localStorage.getItem(this.storeValues.ACCESS_TOKEN) || false;
    const IdToken = localStorage.getItem(this.storeValues.ID_TOKEN) || false;
    const RefreshToken =
      localStorage.getItem(this.storeValues.REFRESH_TOKEN) || false;
    //const installmentsConfig = localStorage.getItem(this.storeValues.INSTALLMENTS_CONFIG) ? JSON.parse(localStorage.getItem(this.storeValues.INSTALLMENTS_CONFIG)) : {};

    if (AccessToken && IdToken) {
      const parsedUser = parseCredentials(AccessToken, IdToken);

      /*store.dispatch({
                type: StoreActions.SET_CONFIG_FROM_LOCALSTORAGE,
                installmentsConfig: {
                    allowedPlans: installmentsConfig.globalInstallmentsPlan
                        ? (
                            typeof installmentsConfig.globalInstallmentsPlan === 'string'
                                ? installmentsConfig.globalInstallmentsPlan.split(',')
                                : installmentsConfig.globalInstallmentsPlan
                        )
                        : [],
                    defaultMinAmount: installmentsConfig.installments_min_amount ? installmentsConfig.installments_min_amount : 0
                }
            });*/

      const expiration = parsedUser.exp * 1000;

      if (Date.now() < expiration) {
        return parsedUser;
      } else {
        if (!verifyExpiration) {
          this.renewSession()
            .then((res) => {
              const payload = {};
              //                            const installmentsConfig = localStorage.getItem(this.storeValues.INSTALLMENTS_CONFIG) ? JSON.parse(localStorage.getItem(this.storeValues.INSTALLMENTS_CONFIG)) : {};

              payload.AuthenticationResult = {
                RefreshToken,
                ...res.AuthenticationResult,
              };
              //payload.installments_min_amount = installmentsConfig.installments_min_amount || 0;
              //payload.globalInstallmentsPlan = installmentsConfig.globalInstallmentsPlan ? installmentsConfig.globalInstallmentsPlan.split(',') : [];

              this.handleAuthentication(payload);
            })
            .catch(() => {
              this.logout().then(() => {
                toast.warn('Your session has expired.');
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              });
              return false;
            });
        }
        return verifyExpiration ? false : parsedUser;
      }
    }
    return false;
  }

  logout() {
    return new Promise((resolve) => {
      localStorage.removeItem(this.storeValues.ACCESS_TOKEN);
      localStorage.removeItem(this.storeValues.ID_TOKEN);
      localStorage.removeItem(this.storeValues.REFRESH_TOKEN);
      localStorage.removeItem('allowInstallments'); //obsolete key
      resolve(true);
    });
  }

  renewSession() {
    const RefreshToken = localStorage.getItem(this.storeValues.REFRESH_TOKEN);
    const payload = {
      refreshToken: RefreshToken,
    };

    return axios.post(baseUrl, payload).then(handleResponse).catch(handleError);
  }

  sendRecoverPasswordEmail(username) {
    return axios
      .post(`${API_AUTH_URL}/services/reset_password_request`, { username })
      .then(handleResponse)
      .catch(handleError);
  }

  recoverPassword(username, password, token, resetPassword = true) {
    const body = {
      username,
      password,
      resetPassword,
      token,
    };
    return axios
      .post(`${API_AUTH_URL}/services/reset_password`, body)
      .then(handleResponse)
      .catch((error) => {
        throw error;
      });
  }

  resetPassword(username, newPassword, sessionToken) {
    const body = {
      challengeName: StoreActions.NEW_PASSWORD_REQUIRED,
      username: username,
      password: newPassword,
      session: sessionToken,
    };

    return axios.post(baseUrl, body).then(handleResponse).catch(handleError);
  }

  passwordPolicy() {
    const body = {
      policy: true,
    };

    return axios.post(baseUrl, body).then(handleResponse).catch(handleError);
  }
}
