const initialState = {
  practiceReducer: [],
  selectedPractice: {},
  authReducer: null,
  authChallengeReducer: null,
  authForgotPasswordReducer: null,
  currentPath: '/',
  staff: [],
  selectedStaffMember: {},
  staffMemberToggleAddEdit: false,
  staffMemberToggleDeleteConfirmation: false,
  loggedPractice: {},
  transactions: {
    items: {},
    isGettingTransactions: false,
  },
  selectedTransaction: {},
  practiceDailyReport: [],
  practicePayoutsReport: [],
  transactionReport: {
    requests: [],
    transactions: [],
  },
  dailyTransactions: [],
  spreedlyTransactonInfo: {},
  spreedlyPaymentInfo: {},
  globalInstallmentsConfig: {
    allowedPlans: [],
    defaultMinAmount: null,
  },
  passwordPolicy: {
    PasswordPolicy: {},
    Message: '',
  },
};

export default initialState;
