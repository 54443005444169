import React, { Component } from 'react';
import { produce } from 'immer';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { StaffMember } from './../../../../models';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as staffMemberActions from './../../../../utils/store/actions/staffMemberActions';
import dataFilter from './../../../../utils/helpers/dataFilter';
import compareArrays from './../../../../utils/helpers/compareArrays';
import Loading from '../../../Common/Loading';
import StaffHeader from './StaffHeader';
import StaffList from './StaffList';
import StaffSave from './StaffSave';
import StaffDeleteConfirm from './StaffDeleteConfirm';
import ResetPasswordModal from './ResetPasswordModal';
import * as authActions from '../../../../utils/store/actions/authActions';

class Staff extends Component {
  state = {
    currentSaffType: 'users',
    staffMembers: [],
    selectedMemberForm: StaffMember,
    isLoading: false,
    isSaving: false,
    errors: {},
    search: '',
    showResetModal: false,
    selectedUserReset: {},
  };

  setCurrentStaffType = (type) => {
    this.setState({ currentSaffType: type });
  };

  filterStaff = (staff = [], staffType = 'users') => {
    let filteredStaff = staff.filter((user) => user.active);

    return staffType === 'users'
      ? filteredStaff.filter((user) => !user.role?.practiceStaff)
      : filteredStaff.filter((user) => user.role?.practiceStaff);
  };

  onCancelDeleteStaffMember = () => {
    const { unsetSelectedStaffMember, toggleDeleteStaffMemberAction } =
      this.props;
    this.setState({
      selectedMemberForm: StaffMember,
      errors: {},
    });
    unsetSelectedStaffMember();
    toggleDeleteStaffMemberAction(false);
  };

  onFilterChange = (event) => {
    let { value } = event.target;
    const filter = dataFilter(this.props.staffMembers, value);
    this.setState({ search: value });
    this.setState({ staffMembers: filter });
  };

  handleShow = () => {
    const { toggleAddEditStaffMemberAction } = this.props;
    toggleAddEditStaffMemberAction(true);
  };

  handleClose = () => {
    const { unsetSelectedStaffMember, toggleAddEditStaffMemberAction } =
      this.props;
    this.setState({
      selectedMemberForm: StaffMember,
      errors: {},
    });
    unsetSelectedStaffMember();
    toggleAddEditStaffMemberAction(false);
  };

  handleFormChange = (event) => {
    const { name, value, type } = event.target;
    let obj = { ...this.state.selectedMemberForm };
    if (type === 'checkbox') {
      obj.role = { ...obj.role, [name]: event.target.checked };
    } else {
      obj[name] = value;
    }
    this.setState({ selectedMemberForm: obj });
  };

  handleEdit = (id) => {
    const { staffMembers } = this.state;
    const selectedMember = staffMembers.filter((member) => member.id === id)[0];
    this.setState({ selectedMember });
  };

  formIsValid = (selectedMemberForm) => {
    const {
      id,
      name,
      lastName,
      email,
      password,
      password_confirm,
      role: { practiceAdmin },
      role: { tabletUser },
    } = selectedMemberForm;
    const type = this.state.currentSaffType;
    const errors = {};
    if (!name)
      errors.name = `${
        type === 'users' ? 'User' : 'Staff member'
      } name is required`;
    if (!lastName)
      errors.lastName = `${
        type === 'users' ? 'User' : 'Staff member'
      } last name is required`;
    if (this.state.currentSaffType === 'users') {
      if (!email) errors.email = 'User email is required';
      if (!id) {
        if (!password) errors.password = 'Password is required';
        if (!password_confirm)
          errors.password_confirm = 'Password confirmation is required';
        if (password !== password_confirm)
          errors.password_confirm = 'Passwords do not match';
      }
      if (!practiceAdmin && !tabletUser) {
        errors.permissions = 'At least one permission must be selected';
      }
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  sanitizeForm = () => {
    const { selectedMemberForm } = this.state;
    const sanitizedSelectedMemberForm = produce(
      selectedMemberForm,
      (selectedMemberFormDraft) => {
        selectedMemberFormDraft.name = selectedMemberForm.name?.trim();
        selectedMemberFormDraft.lastName = selectedMemberForm.lastName?.trim();
        selectedMemberFormDraft.email = selectedMemberForm.email?.trim();
        selectedMemberFormDraft.password = selectedMemberForm.password?.trim();
        selectedMemberFormDraft.password_confirm =
          selectedMemberForm.password_confirm?.trim();
      }
    );
    this.setState({ selectedMemberForm: sanitizedSelectedMemberForm });
    return sanitizedSelectedMemberForm;
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    const { saveStaffMember, match } = this.props;
    const { staffMembers, currentSaffType } = this.state;
    const sanitizedSelectedMemberForm = this.sanitizeForm();
    if (this.formIsValid(sanitizedSelectedMemberForm)) {
      let staffMemberToSave = { ...sanitizedSelectedMemberForm };
      const practiceId = match.params.practiceId;

      if (!staffMemberToSave.id) {
        // if there's no id it's an 'add'
        //find out if an inactive staff member already exists with the same name and lastname
        //if it does just reactivate them
        const foundInactiveMatch = staffMembers.find(
          (s) =>
            !s.active &&
            s.name === staffMemberToSave.name &&
            s.lastName === staffMemberToSave.lastName
        );
        if (foundInactiveMatch) {
          staffMemberToSave = { ...foundInactiveMatch };
          staffMemberToSave.active = true;
        }
      }

      this.setState({ isSaving: true });
      saveStaffMember(staffMemberToSave, currentSaffType, practiceId)
        .then(() => {
          toast.success(
            `${
              currentSaffType === 'users' ? 'User account' : 'Staff member'
            } saved`
          );
          this.setState({ isSaving: false });
          this.handleClose();
        })
        .catch((error) => {
          const errors = {};
          errors.message = error.userMessage;
          this.setState({ errors, isSaving: false });
        });
    }
  };

  onDeleteStaffMember = () => {
    const { deleteStaffMember, toggleDeleteStaffMemberAction, selectedMember } =
      this.props;
    const { currentSaffType } = this.state;
    this.setState({ isSaving: true });
    deleteStaffMember(selectedMember.id)
      .then(() => {
        toast.success(
          `${
            currentSaffType === 'users' ? 'User account' : 'Staff member'
          } removed`
        );
        this.setState({
          isSaving: false,
          selectedMemberForm: StaffMember,
          errors: {},
        });
        staffMemberActions.unsetSelectedStaffMember();
        toggleDeleteStaffMemberAction(false);
      })
      .catch((err) => {
        toast.error(
          `Unable to remove ${
            currentSaffType === 'users' ? 'user account' : 'staff member'
          }`
        );
        this.setState({ isSaving: false });
        toggleDeleteStaffMemberAction(false);
      });
  };

  sendRecoveryEmail = (email) => {
    return this.props.sendEmailForRecoveryOfPassword(email);
  };

  onResetPassword = (user) => {
    this.setState({ showResetModal: true, selectedUserReset: user });
  };

  onCloseResetModal = () => {
    this.setState({ showResetModal: false, selectedUserReset: {} });
  };

  componentDidMount() {
    const { fetchStaffMembers, staffMembers, match } = this.props;
    const { currentSaffType } = this.state;
    this.setState({ isLoading: true });
    fetchStaffMembers(match.params.practiceId)
      .then(() => {
        this.setState({ isLoading: false });
        this.setState({ staffMembers });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        this.setState({
          errors: {
            message: `Error loading ${
              currentSaffType === 'users' ? 'user' : 'staff'
            } list`,
          },
        });
        toast.error('Error Loading Data');
      });

    this.props.passwordPolicyWithValidationMessage();
  }

  componentDidUpdate(prevProps) {
    const { staffMembers, fetchStaffMembers, selectedMember, match } =
      this.props;
    const { currentSaffType } = this.state;
    // verify if list needs update
    if (
      staffMembers.length !== prevProps.staffMembers.length ||
      !compareArrays(staffMembers, prevProps.staffMembers)
    ) {
      fetchStaffMembers(match.params.practiceId)
        .then(() => {
          this.setState({ staffMembers });
        })
        .catch(() => {
          this.setState({
            errors: {
              message: `Error loading ${
                currentSaffType === 'users' ? 'user' : 'staff'
              } list`,
            },
          });
          toast.error('Error Loading Data');
        });
    }
    // verify if selected member changed
    if (prevProps?.selectedMember?.id !== selectedMember?.id) {
      this.setState({ selectedMemberForm: selectedMember });
    }
  }

  render() {
    const {
      currentSaffType,
      staffMembers,
      errors,
      isSaving,
      selectedMemberForm,
      search,
      showResetModal,
      selectedUserReset,
    } = this.state;

    const {
      toggleAddEditStaffMember,
      toggleDeleteStaffMemberConfirmation,
      selectedMember,
      passwordPolicies,
      currentUser,
    } = this.props;

    return (
      <div className='staff content' id='Staff'>
        <StaffHeader
          title='Users'
          handleShow={this.handleShow}
          onFilterChange={this.onFilterChange}
          search={search}
          setCurrentStaffType={this.setCurrentStaffType}
          staffType={currentSaffType}
          currentUser={currentUser}
        />
        {this.state.isLoading && <Loading />}
        {!this.state.isLoading && staffMembers && (
          <StaffList
            users={this.filterStaff(staffMembers, currentSaffType)}
            userType={currentSaffType}
            currentUser={currentUser}
            onResetPassword={this.onResetPassword}
          />
        )}
        <StaffSave
          show={toggleAddEditStaffMember}
          handleClose={this.handleClose}
          onSave={this.handleFormSubmit}
          onChange={this.handleFormChange}
          staffMember={selectedMemberForm}
          staffType={currentSaffType}
          errors={errors}
          isSaving={isSaving}
          passwordPolicies={passwordPolicies}
          currentUser={currentUser}
        />
        <StaffDeleteConfirm
          show={toggleDeleteStaffMemberConfirmation}
          onCancelDeleteStaffMember={this.onCancelDeleteStaffMember}
          staffMember={selectedMember}
          onDeleteStaffMember={this.onDeleteStaffMember}
          staffType={currentSaffType}
          onChange={this.handleFormChange}
          isSaving={isSaving}
        />
        <ResetPasswordModal
          show={showResetModal}
          onCloseResetModal={this.onCloseResetModal}
          user={selectedUserReset}
          sendRecoveryEmail={this.sendRecoveryEmail}
        />
      </div>
    );
  }
}

Staff.propTypes = {
  staffMembers: PropTypes.array.isRequired,
  selectedMember: PropTypes.object.isRequired,
  toggleAddEditStaffMember: PropTypes.bool.isRequired,
  toggleDeleteStaffMemberConfirmation: PropTypes.bool.isRequired,
  fetchStaffMembers: PropTypes.func.isRequired,
  saveStaffMember: PropTypes.func.isRequired,
  unsetSelectedStaffMember: PropTypes.func.isRequired,
  toggleAddEditStaffMemberAction: PropTypes.func.isRequired,
  toggleDeleteStaffMemberAction: PropTypes.func.isRequired,
  passwordPolicies: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  withRouter: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    staffMembers: state.staff,
    selectedMember:
      Object.keys(state.selectedStaffMember).length > 0
        ? state.selectedStaffMember
        : StaffMember,
    toggleAddEditStaffMember: state.toggleAddEditStaffMember,
    toggleDeleteStaffMemberConfirmation:
      state.toggleDeleteStaffMemberConfirmation,
    passwordPolicies: state.passwordPolicyReducer || { Message: 'empty' },
    currentUser: state.authReducer,
  };
}

const mapDispatchToProps = {
  ...staffMemberActions,
  ...authActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Staff));
