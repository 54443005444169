// Modules
import React, { useState, useReducer, useRef, useEffect } from 'react';

// CSS
import styles from './ExpirationDateInput.module.scss';

const names = {
  m1: 'monthOne',
  m2: 'monthTwo',
  y1: 'yearOne',
  y2: 'yearTwo',
};

const expDataInitialValue = {
  [names.m1]: '',
  [names.m2]: '',
  [names.y1]: '',
  [names.y2]: '',
  showMOne: true,
  showMTwo: true,
  showYOne: true,
  showYTwo: true,
};

function setFocusAndCaretPos(el) {
  el.focus();
  el.setSelectionRange(1, 2, 'forward');
}

function shiftFocusLeft(args) {
  const { type, refs } = args;

  switch (type) {
    case names.m2:
      return setFocusAndCaretPos(refs[names.m1]);
    case names.y1:
      return setFocusAndCaretPos(refs[names.m2]);
    case names.y2:
      return setFocusAndCaretPos(refs[names.y1]);
    default:
      return;
  }
}

function shiftFocusRight(args) {
  const { type, refs } = args;

  switch (type) {
    case names.m1:
      return setFocusAndCaretPos(refs[names.m2]);
    case names.m2:
      return setFocusAndCaretPos(refs[names.y1]);
    case names.y1:
      return setFocusAndCaretPos(refs[names.y2]);
    default:
      return;
  }
}

function getRegisteredKeys(key) {
  return {
    isDeleteKey: key === 'Delete' || key === 'Backspace',
    isRightArrow: key === 'ArrowRight',
    isLeftArrow: key === 'ArrowLeft',
  };
}

function changeFocusByKey(action) {
  const { type, payload } = action;
  const { value, refs, registeredKeys } = payload;

  const { isDeleteKey, isRightArrow, isLeftArrow } = registeredKeys;

  if (isDeleteKey && value === '') {
    shiftFocusLeft({ type, refs });
  } else if (isRightArrow) {
    shiftFocusRight({ type, refs });
  } else if (isLeftArrow) {
    shiftFocusLeft({ type, refs });
  }
}

function expDataReducer(state, action) {
  const { type, value } = action;

  switch (type) {
    case names.m1:
      return { ...state, [type]: value, showMOne: !state.showMOne };
    case names.m2:
      return { ...state, [type]: value, showMTwo: !state.showMTwo };
    case names.y1:
      return { ...state, [type]: value, showYOne: !state.showYOne };
    case names.y2:
      return { ...state, [type]: value, showYTwo: !state.showYTwo };
    default:
      return state;
  }
}

/**
 * ==================
 * Exported Component
 * ==================
 */
function ExpirationDateInput(props) {
  const { validInputFields } = props;

  const { month, year, expirationDate } = validInputFields;

  const isValid = month && year && expirationDate;

  const [expData, dispatchExpData] = useReducer(
    expDataReducer,
    expDataInitialValue
  );

  const [keyEvent, setKeyEvent] = useState(null);
  const [focus, setFocus] = useState(false);
  const [legendStyles, setLegendStyles] = useState('');
  const [inputStyles, setInputStyles] = useState('');

  const inputHasValue = Object.values(expData).some(
    (val) => typeof val === 'boolean' && !val
  );

  /**
   * These refs are necessary to change input element focus
   */
  const m1Ref = useRef(null);
  const m2Ref = useRef(null);
  const y1Ref = useRef(null);
  const y2Ref = useRef(null);

  const getRefs = () => ({
    [names.m1]: m1Ref.current,
    [names.m2]: m2Ref.current,
    [names.y1]: y1Ref.current,
    [names.y2]: y2Ref.current,
  });

  /**
   * Controlled components in React require both a value and onChange handler
   * But the synthetic event triggered by on the onChange handler doesn't contain
   * information about keyboard events
   *
   * Additionally the the onChange event is not triggered when the delete / backspace
   * key is pressed and there is nothing in the input field (because the input hasn't changed)
   *
   * Because of these edge cases we need to add a key event listener to each input
   * to handle the delete and arrow keys as well as passing keyboard event information
   * to the onChange handler
   */
  const handleKeyPress = (e) => {
    setKeyEvent(e.nativeEvent);

    const type = e.target.name;
    const value = e.target.value;
    const key = e?.key;

    const refs = getRefs();

    const registeredKeys = getRegisteredKeys(key);
    const { isDeleteKey, isRightArrow, isLeftArrow } = registeredKeys;

    if (isDeleteKey || isRightArrow || isLeftArrow)
      changeFocusByKey({
        type,
        payload: { value, refs, registeredKeys },
      });
  };

  const handleInput = (e) => {
    const type = e.target.name;
    const value = e.target.value;

    dispatchExpData({ type, value });

    const refs = getRefs();

    const key = keyEvent?.key;
    const registeredKeys = getRegisteredKeys(key);
    const { isDeleteKey, isRightArrow, isLeftArrow } = registeredKeys;

    if (isDeleteKey || isRightArrow || isLeftArrow) {
      return;
    } else {
      shiftFocusRight({ type, refs });
    }
  };

  /**
   * This could probably stand to be debounced or throttled in the future
   */
  const handleFocus = () => {
    const activeEl = document.activeElement;
    const refs = getRefs();
    const inputEls = Object.values(refs);
    const inputElHasFocus = inputEls.some((el) => el === activeEl);

    if (inputElHasFocus) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  };

  const handleOverlayClick = () => {
    m1Ref.current.focus();
  };

  useEffect(() => {
    const legendBaseStyles = [styles.legend, styles.dateLabel];
    const legendFocusStyles = [
      ...legendBaseStyles,
      focus || inputHasValue ? '' : 'invisible',
    ];

    const inputBaseStyles = [styles.wrapper, styles.dateInput];
    const inputFocusStyles = [
      ...inputBaseStyles,
      focus ? styles.wrapperFocus : '',
    ];
    const inputValidStyles = [
      ...inputFocusStyles,
      isValid ? '' : styles.invalid,
    ];

    setLegendStyles(legendFocusStyles.join(' '));
    setInputStyles(inputValidStyles.join(' '));
  }, [validInputFields, isValid, focus, inputHasValue]);

  return (
    <>
      <p className={legendStyles} style={isValid ? {} : { color: '#e85a5a' }}>
        {isValid ? 'Expiration date' : 'Expiration date is invalid'}
      </p>

      <div className={inputStyles}>
        <div
          className={!focus && !inputHasValue ? styles.overlay : 'd-none'}
          onClick={handleOverlayClick}
        >
          Expiration date
        </div>

        <label aria-label='expiration month'>
          <span className={styles.placeholderGroup}>
            <span className={expData.showMOne ? null : 'invisible'}>M</span>
            <span className={expData.showMTwo ? null : 'invisible'}>M</span>
          </span>

          <span className={styles.inputGroup}>
            <input
              ref={m1Ref}
              type='text'
              name={names.m1}
              value={expData.monthOne}
              onChange={handleInput}
              onKeyDown={handleKeyPress}
              onFocus={handleFocus}
              onBlur={handleFocus}
              maxLength='1'
              required
            />
            <input
              ref={m2Ref}
              type='text'
              name={names.m2}
              value={expData.monthTwo}
              onChange={handleInput}
              onKeyDown={handleKeyPress}
              onFocus={handleFocus}
              onBlur={handleFocus}
              maxLength='1'
              required
            />
          </span>
        </label>

        <span
          className={styles.slash}
          style={inputHasValue ? { color: '#000' } : null}
        >
          /
        </span>

        <label aria-label='expiration year'>
          <span className={styles.placeholderGroup}>
            <span className={expData.showYOne ? null : 'invisible'}>Y</span>
            <span className={expData.showYTwo ? null : 'invisible'}>Y</span>
          </span>

          <span className={styles.inputGroup}>
            <input
              ref={y1Ref}
              type='text'
              name={names.y1}
              value={expData.yearOne}
              onChange={handleInput}
              onKeyDown={handleKeyPress}
              onFocus={handleFocus}
              onBlur={handleFocus}
              maxLength='1'
              required
            />
            <input
              ref={y2Ref}
              type='text'
              name={names.y2}
              value={expData.yearTwo}
              onChange={handleInput}
              onKeyDown={handleKeyPress}
              onFocus={handleFocus}
              onBlur={handleFocus}
              maxLength='1'
              required
            />
          </span>
        </label>
      </div>
    </>
  );
}

export default ExpirationDateInput;
