import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Loading from '../../../Common/Loading';

import phoneNumberIsValidFormat from 'utils/helpers/phoneNumberValidate';
import { emailValidate } from 'utils/helpers/emailValidate';
import PaymentMethod from 'constants/PaymentMethod';

const fieldAttribute = Object.freeze({
  [PaymentMethod.EmailLink]: {
    label: 'Pet owner email address:',
    inputType: 'email',
    inputPlaceHolder: 'e.g.:email@address.com',
    inputDescription: 'Please enter an email address',
    inputErrorMessage:
      'The email you have entered is invalid. Please enter a new email address.',
    inputValidation: emailValidate,
  },
  [PaymentMethod.TextLink]: {
    label: 'Pet owner phone number:',
    inputType: 'tel',
    inputPlaceHolder: 'e.g.: 5551234567',
    inputDescription: 'Please enter a phone number.',
    inputErrorMessage:
      'The number you have entered is invalid. Please enter a new number.',
    inputValidation: phoneNumberIsValidFormat,
  },
});

function ResendPaymentRequestModal(props) {
  const { show, processing, toggleModal, resendRequest, paymentMethod } = props;
  const {
    label,
    inputType,
    inputPlaceHolder,
    inputDescription,
    inputErrorMessage,
    inputValidation,
  } = fieldAttribute[paymentMethod];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const isInputInvalid = !!errors[paymentMethod];

  const onSubmit = (formData) => {
    resendRequest(formData[paymentMethod]);
    reset();
  };

  const closeModalHandler = () => {
    toggleModal();
    reset();
  };

  return (
    <Modal
      id='ResendPaymentRequest'
      size='lg'
      show={show}
      onHide={closeModalHandler}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      {processing ? (
        <Modal.Body>
          <Loading message='Sending a new payment link...' />
        </Modal.Body>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className='text-center'>
            <h2>Resend Payment Request</h2>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                type={inputType}
                placeholder={inputPlaceHolder}
                isInvalid={isInputInvalid}
                {...register(paymentMethod, {
                  validate: inputValidation,
                })}
              />
              <Form.Text
                className={isInputInvalid ? 'text-danger' : 'text-muted'}
              >
                {isInputInvalid ? inputErrorMessage : inputDescription}
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={closeModalHandler}>
              CLOSE
            </Button>
            <Button
              className={isInputInvalid ? 'btn' : 'btn btn-primary'}
              variant={isInputInvalid ? 'light' : ''}
              onClick={handleSubmit(onSubmit)}
              type='submit'
              disabled={isInputInvalid}
            >
              RESEND REQUEST
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
}

ResendPaymentRequestModal.propTypes = {
  show: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  resendRequest: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
};

export default ResendPaymentRequestModal;
