// Environment Variables
import Env from 'Env';

import styles from './SpreedlyForm.module.scss';

const numberStyles = {
  margin: '0',
  'margin-top': '4px',
  padding: '0 2px',
  width: '100%',
  'font-size': '16px',
  border: 'none',
  outline: 'none',
  textDecoration: 'none',
};

const cvvStyles = {
  margin: '0',
  'margin-top': '4px',
  padding: '0 2px',
  width: '100%',
  height: '100%',
  'font-size': '16px',
  border: 'none',
  outline: 'none',
  textDecoration: 'none',
};

const getStyleString = (style) =>
  Object.entries(style)
    .map(([key, value]) => `${key}:${value}`)
    .join(';');

function getFieldEventValues(args) {
  const { name, inputProperties } = args;

  switch (name) {
    case 'number':
      return {
        parentEl: document.getElementById('spreedly-number'),
        name: 'spreedly_number',
        value: inputProperties?.numberLength ?? null,
        focusPlaceholder: '1234 1234 1234 1234',
        blurPlaceholder: 'Card number',
      };

    case 'cvv':
      return {
        parentEl: document.getElementById('spreedly-cvv'),
        name: 'spreedly_cvv',
        value: inputProperties?.cvvLength ?? null,
        focusPlaceholder: '123',
        blurPlaceholder: 'CVV',
      };

    default:
      return {
        parentEl: null,
        name: null,
        value: null,
        placeholder: null,
      };
  }
}

function handleFieldEvent(args) {
  const { type, name, inputProperties, handleFocus, handleInput } = args;

  const values = getFieldEventValues({ name, inputProperties });

  if (!values.parentEl)
    throw new Error(`Could not get parent element of spreedly ${name}`);

  switch (type) {
    case 'focus':
      values.parentEl.classList.add(styles.focusSpreedlyParent);
      window.Spreedly.setPlaceholder(name, values.focusPlaceholder);
      if (handleFocus)
        handleFocus({ type: 'focus', target: { name: values.name } });
      break;

    case 'blur':
      values.parentEl.classList.remove(styles.focusSpreedlyParent);
      window.Spreedly.setPlaceholder(name, values.blurPlaceholder);
      if (handleFocus)
        handleFocus({ type: 'blur', target: { name: values.name } });
      break;

    case 'input':
      if (handleInput)
        handleInput({
          target: {
            name: values.name,
            value: values.value,
          },
        });
      break;

    default:
      break;
  }
}

function initSpreedly(args) {
  const {
    handleSpreedlyValidation,
    handleSpreedlyError,
    handleTokenSuccess,
    handleInput,
    handleFocus,
  } = args;

  window.Spreedly.init(Env.spreedlyEnvironmentKey, {
    numberEl: 'spreedly-number',
    cvvEl: 'spreedly-cvv',
  });

  window.Spreedly.on('ready', () => {
    const submitButton = document.getElementById('submit-button');
    submitButton.disabled = false;

    window.Spreedly.setStyle('number', getStyleString(numberStyles));
    window.Spreedly.setStyle('cvv', getStyleString(cvvStyles));

    window.Spreedly.setPlaceholder('number', 'Card number');
    window.Spreedly.setPlaceholder('cvv', 'CVV');

    // Pretty format credit card text
    window.Spreedly.setFieldType('number', 'text');
    window.Spreedly.setNumberFormat('prettyFormat');

    window.Spreedly.on(
      'fieldEvent',
      function (name, type, activeEl, inputProperties) {
        handleFieldEvent({
          type,
          name,
          inputProperties,
          handleFocus,
          handleInput,
        });
      }
    );
  });

  if (handleSpreedlyValidation) {
    addSpreedlyValidationHandler(handleSpreedlyValidation);
  }

  if (handleSpreedlyError) {
    addSpreedlyErrorHandler(handleSpreedlyError);
  }

  if (handleTokenSuccess) {
    addSpreedlySuccessHandler(handleTokenSuccess);
  }
}

export function addSpreedlyValidationHandler(handler) {
  window.Spreedly.on('validation', function (inputProperties) {
    handler(inputProperties);
  });
}

export function addSpreedlyErrorHandler(handler) {
  window.Spreedly.on('errors', (errors) => {
    handler(errors);
  });
}

export function addSpreedlySuccessHandler(handler) {
  window.Spreedly.on('paymentMethod', (token, pmData) => {
    handler(token);
  });
}

function hasSpreedlyExpressScriptTag(source) {
  const spreedlyScript = document.querySelector(`script[src="${source}"]`);
  console.log(spreedlyScript);
  return !!spreedlyScript;
}

export function addSpreedlyJS(args) {
  const url = 'https://core.spreedly.com/iframe/iframe-v1.min.js';

  if (!hasSpreedlyExpressScriptTag(url)) {
    const script = document.createElement('script');

    script.async = true;
    script.SameSite = 'None';
    script.Secure = true;
    script.src = url;
    script.addEventListener('load', () => initSpreedly(args));

    document.body.appendChild(script);
  } else {
    window.Spreedly.removeHandlers();
    window.Spreedly.reload();
    initSpreedly(args);
  }
}
