import React from 'react';

// Utils
import { isPossiblePhoneNumber } from 'react-phone-number-input';

// Components
import { FormControl, InputGroup } from 'react-bootstrap';
import StateSelector from '../../Payment/CustomInputFields/StateSelector';
import Label from 'components/Common/Label';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';

// Styles
import styles from './CustomerModal.module.scss';

function CustomerInfo(props) {
  const { register, errors, control, customerToEdit } = props;

  return (
    <div>
      <div className={styles.customerNameContainer}>
        <Label text='Customer Name' hasError={errors.customerData?.full_name} />
        <FormControl
          placeholder='Customer Name'
          defaultValue={customerToEdit?.full_name}
          {...register('customerData.full_name', {
            required: true,
            pattern: { value: /^([a-zA-Z.]+\s?\b){2,}$/i },
          })}
          autoComplete='disabled'
        />
        <input
          {...register('customerData.first_name')}
          className={styles.hiddenInput}
        />
        <input
          {...register('customerData.last_name')}
          className={styles.hiddenInput}
        />
      </div>

      <div>
        <Label text='Phone Number' hasError={errors.customerData?.phone} />
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>+1</InputGroup.Text>
          </InputGroup.Prepend>
          <PhoneInput
            name='customerData.phone'
            country='US'
            placeholder='(xxx) xxx-xxxx'
            defaultValue={customerToEdit?.phone}
            className='form-control'
            control={control}
            rules={{
              required: true,
              validate: {
                validPhone: (v) => v?.length > 0 && isPossiblePhoneNumber(v),
              },
            }}
            autoComplete='disabled'
          />
        </InputGroup>
      </div>

      {/* hiding address-related fields until further notice */}
      <div hidden>
        <Label text='Address' hasError={errors.addressData?.address_1} />
        <div className={styles.spaceChildrenEvenly}>
          <FormControl
            placeholder='Address Line 1'
            defaultValue={customerToEdit?.addresses[0]?.address_1}
            {...register('addressData.address_1', { required: false })}
            autoComplete='disabled'
          />
          <FormControl
            placeholder='Address Line 2'
            defaultValue={customerToEdit?.addresses[0]?.address_2}
            {...register('addressData.address_2')}
            autoComplete='disabled'
          />
        </div>
      </div>

      {/* hiding address-related fields until further notice */}
      <div hidden>
        <Label text='City' hasError={errors.addressData?.city} />
        <FormControl
          placeholder='City'
          defaultValue={customerToEdit?.addresses[0]?.city}
          {...register('addressData.city', { required: false })}
          autoComplete='disabled'
        />
      </div>

      {/* hiding address-related fields until further notice */}
      <div className={styles.spaceSideBySide} hidden>
        <div>
          <Label text='State' hasError={errors.addressData?.state} />
          <StateSelector
            name='addressData.state'
            defaultValue={customerToEdit?.addresses[0]?.state}
            registerOptions={{ required: false }}
            register={register}
          />
        </div>

        <div>
          <Label text='Zip' hasError={errors.addressData?.zip} />
          <FormControl
            placeholder='Zip'
            defaultValue={customerToEdit?.addresses[0]?.zip}
            type='number'
            className={styles.disableArrows}
            {...register('addressData.zip', {
              required: false,
              minLength: 5,
              maxLength: 5,
            })}
            autoComplete='disabled'
          />
        </div>
      </div>
    </div>
  );
}

export default CustomerInfo;
