import React from 'react';
import PropTypes from 'prop-types';
import styleColors from 'assets/styles/_variables.scss';

const TransactionStatus = ({ status }) => {
  const getStatusStyle = (statusParam) => {
    switch (statusParam) {
      case 'Delivered':
      case 'Canceled':
      case 'Pending':
      case 'Uncaptured':
      case 'Expired':
      case 'Voided':
        return { color: styleColors.statusNormal };
      case 'Processed':
        return { color: styleColors.statusGreen };
      case 'Partially Refunded':
      case 'Refunded':
      case 'Error':
        return { color: styleColors.statusRed };
      default:
        return null;
    }
  };

  return <span style={getStatusStyle(status)}>{status ?? '-'}</span>;
};

TransactionStatus.propTypes = {
  status: PropTypes.string,
};

export default TransactionStatus;
