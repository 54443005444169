import React from 'react';
import NoDataToDisplay from './../../../Common/NoDataToDisplayList';
import { customersColumns } from './config/customers-config';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import styles from './Customers.module.scss';
import Loading from 'components/Common/Loading';

function CustomersList(props) {
  const { buttonFuncs, onNewCustomerModalShow } = props;

  const pagination = paginationFactory({
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: '<<',
    lastPageText: '>>',
    alwaysShowAllBtns: true,
  });

  return (
    <div className='customers-list'>
      {/* New customer button */}
      <div className='p-4'>
        <div className='d-flex flex-wrap justify-content-end'>
          <Button variant='secondary' onClick={onNewCustomerModalShow}>
            New Customer
          </Button>
        </div>
      </div>

      <div className={styles.table}>
        {!props.isLoading && props.customers.length === 0 ? (
          <NoDataToDisplay message='No Customers' />
        ) : (
          <BootstrapTable
            classes='vitus-table'
            keyField='customer_id'
            columns={customersColumns(buttonFuncs)}
            data={props.customers}
            noDataIndication={() => <Loading />}
            bordered={false}
            pagination={pagination}
            remote={{ pagination: true, filter: false, sort: false }}
            onTableChange={props.onTableChange}
          />
        )}
      </div>
    </div>
  );
}

export default CustomersList;
