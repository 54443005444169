import React, { useState, useEffect } from 'react';
import styles from '../PaymentRectangle/PaymentRectangle.module.scss';
import Loading from 'components/Common/Loading';
import { useTokenPay } from 'utils/store/TokenPayContext';
import PropTypes from 'prop-types';
import Currency from 'utils/helpers/currency';
import { Form } from 'react-bootstrap';

function PaymentRectangle(props) {
  const { tokenPay, initializeTokenPay } = useTokenPay();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaveCardSelected, setIsSaveCardSelected] = useState(false);

  const { payWithSavedCards } = props;

  // Initialize TokenPay only once
  useEffect(() => {
    initializeTokenPay(props.publicKey, props.isSaveCard);
  }, [initializeTokenPay, props.publicKey, props.isSaveCard]);

  function changeSaveCard() {
    setIsSaveCardSelected(!isSaveCardSelected);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      await tokenPay.current.createToken(
        function (result) {
          console.log(result.token);
          props.completePaymentAction(result.token, isSaveCardSelected);
        },
        function (result) {
          setIsSubmitting(false);
          console.log('error: ' + result?.errorMessage);
        }
      );
    } catch (err) {
      console.log('timeout');
    }
  }

  return (
    <div>
      {isSubmitting && <Loading></Loading>}
      <form
        id='paymentForm'
        style={{ display: isSubmitting ? 'none' : 'block' }}
      >
        <div id='card' className={styles.paymentForm}></div>
        {payWithSavedCards && (
          <Form.Group className={styles.checkboxContainer}>
            <input
              name='saveCard'
              type='checkbox'
              onChange={changeSaveCard}
              className={styles.checkbox}
            ></input>
            <label htmlFor='saveCard' className={styles.checkboxLabel}>
              Save Card For Future Purchases
              <span role='img' aria-label='lock'>
                🔒
              </span>
            </label>
          </Form.Group>
        )}
        <div className={styles.creditCardWrapper}>
          <img
            src='https://cdn.vitusvet.com/assets/payfabric-visa.png'
            className='creditCard'
            alt='Visa'
          />
          <img
            src='https://cdn.vitusvet.com/assets/payfabric-mastercard.png'
            className='creditCard'
            alt='MasterCard'
          />
          <img
            src='https://cdn.vitusvet.com/assets/payfabric-amex.png'
            className='creditCard'
            alt='Amex'
          />
          <img
            src='https://cdn.vitusvet.com/assets/payfabric-discover.png'
            className='creditCard'
            alt='Discover'
          />
        </div>
        <div id='errorMessage'></div>
        <div className={styles.toolbar}>
          <button
            className='btn'
            onClick={(e) => {
              e.preventDefault();
              props.backAction();
            }}
          >
            Back
          </button>
          <button
            disabled={isSubmitting}
            className={styles.btnSubmit + ' btn btn-secondary'}
            onClick={(e) => handleSubmit(e)}
          >
            Pay &nbsp;
            <Currency amount={props.amount}></Currency>
          </button>
        </div>
      </form>
    </div>
  );
}

PaymentRectangle.propTypes = {
  publicKey: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  completePaymentAction: PropTypes.func.isRequired,
  backAction: PropTypes.func.isRequired,
  isSaveCard: PropTypes.bool,
};

export default PaymentRectangle;
