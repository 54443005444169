import React from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';

const SidebarListItem = (props) => {
  let history = useHistory();

  const renderLink = () => {
    switch (props.flag) {
      case 'logout':
        return (
          <Link onClick={props.logoutFn} to='#'>
            <div className='row align-items-center'>
              <div className='col-2'>
                <i className={`fas ${props.icon}`} aria-hidden='true'></i>
              </div>
              <div className='col-10' style={{ paddingLeft: '0.8rem' }}>
                <span className='ml-0'>{props.name}</span>
              </div>
            </div>
          </Link>
        );
      case 'portal':
        return (
          <a
            href={`${process.env.REACT_APP_CORE_URL}/Practice/Dashboard`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='row align-items-center'>
              <div className='col-2'>
                <i className={`fas ${props.icon}`} aria-hidden='true'></i>
              </div>
              <div
                className='col-10'
                style={{
                  paddingLeft: '0.8rem',
                }}
              >
                <span className='ml-0'>{props.name}</span>
              </div>
            </div>
          </a>
        );
      default:
        return (
          <NavLink
            to={`${props.route}/${props.name}`}
            activeClassName='active'
            onClick={(e) => {
              e.preventDefault();
              props.toggleSidebar();
              history.push(`${props.route}/${props.name}`);
              props.onRouteClick(props.name);
            }}
          >
            <div className='row align-items-center'>
              <div className='col-2'>
                <i className={`fas ${props.icon}`} aria-hidden='true'></i>
              </div>
              <div
                className='col-10'
                style={{
                  paddingLeft: '0.8rem',
                  fontSize: props.displayAs.length > 13 ? '0.75rem' : 'inherit',
                }}
              >
                <span className='ml-0'>{props.displayAs}</span>
              </div>
            </div>
          </NavLink>
        );
    }
  };

  return <li className='sidebar-list-item'>{renderLink()}</li>;
};

export default SidebarListItem;
