import {
  handleResponse,
  handleError,
  axiosInstallmentsPublicInstance as axiosInstallments,
} from './index';

// Example input:
// {
//     "transactionId": "sr_testing123",
//     "installmentPlanNumber": "098765432123456789"
// }
export async function insertInstallmentPlanIntoTransaction(body) {
  if (
    typeof body !== 'object' ||
    !('transactionId' in body) ||
    !('installmentPlanNumber' in body)
  )
    throw new Error(
      'insertInstallmentPlanIntoTransaction function request body is not valid'
    );

  try {
    const res = await axiosInstallments.post('/insertExisting', body);
    return handleResponse(res);
  } catch (err) {
    return handleError(err);
  }
}
