export function passwordIsValid(pwd) {
  /**
   * length >= 8
   * At least one lowercase letter
   *
   * Future test cases may include numbers and special characters
   *      special: /[!@#$%^&*~]{1,}/.test(pwd),
   *      number: /\d{1,}/.test(pwd),
   */
  const testCases = {
    len: pwd.length >= 8,
    lower: /[a-z]{1,}/.test(pwd),
  };

  return Object.values(testCases).every((val) => val);
}
