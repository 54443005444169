const TransactionStatus = {
  draft: 'draft',
  succeeded: 'succeeded',
  inProgress: 'InProgress',
  pendingMerchantShipmentNotice: 'PendingMerchantShipmentNotice',
  cleared: 'Cleared',
  refunded: 'refunded',
  partiallyRefunded: 'partialRefund',
  linkSent: 'link sent',
  canceled: 'canceled',
  sendingLink: 'sending link',
  errorSendingLink: 'error sending link',
  uncaptured: 'uncaptured',
  expired: 'expired',
  voided: 'voided',
};

export default TransactionStatus;
