// Modules
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import Env from 'Env';
import { v4 as uuid } from 'uuid';

// API
import {
  createDraftTransaction,
  practiceProcessTransaction,
  createPayFabricFormUrl,
  paymentSuccess,
} from 'utils/api/payfabricApi';
import { upsertCustomerWithNewCreditCard } from 'utils/api/customerApi';

import { postTransactionReceipt } from '../../../../utils/api/transactionPublicApi';

// Google Analytics
import {
  payInFullBackGAEventHandler,
  payInFullNextGAEventHandler,
} from './googleAnalyticsHandlers';

// Hooks
import useStepHistory from 'utils/hooks/useStepHistory';

// Components
import { PayFabricHostedForm } from '@vitusvet/react-library';
import { Modal, Button } from 'react-bootstrap';
import Loading from 'components/Common/Loading';
import PaymentInfoStep from './PaymentInfoStep';
import PaymentResponseStep from './PaymentResponseStep';
import SelectCardStep from './CardSelect/SelectCardStep';

// Helpers
import { cents } from 'utils/helpers/currencyFormat';
import { getPhoneDigitsOnly } from 'utils/helpers/phoneNumberValidate';
import { capitalizeName, isFullName } from 'utils/helpers/names';

// Constants
import PaymentStates from 'constants/PaymentStates';

// CSS
import styles from './PayFabricModal.module.scss';

const FormSteps = Object.freeze({
  PAYMENT_INFO: 0,
  SELECT_CARD: 1,
  PAYFABRIC_FORM: 2,
  PAYMENT_RESPONSE: 3,
  PAYMENT_FAILED: 4,
});

export default function PayFabricModal(props) {
  const { showModal, setShowModal, triggerUpdate, payByTerminalEnabled } =
    props;

  const [selectedPetOwner, setSelectedPetOwner] = useState(null);
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);
  const [staffMember, setStaffMember] = useState('Choose staff member...');
  const [petOwnerName, setPetOwnerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [isFormLoading, setFormLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [formUrl, setFormUrl] = useState('');
  const [isPayFabricLoading, setIsPayFabricLoading] = useState(false);
  const [draftTransaction, setDraftTransaction] = useState({});
  const [{ currentStep }, { prevStep, setStep }] = useStepHistory();
  const [currentPaymentState, setCurrentPaymentState] = useState('');
  const [lastFourDigits, setLastFourDigits] = useState('XXXX');
  const [showReceiptOptions, setShowReceiptOptions] = useState(true);
  const [draftTransactionId, setDraftTransactionId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isTerminal, setIsTerminal] = useState(false);
  const [newCustomerId, setNewCustomerId] = useState('');

  const payWithSavedCardEnabled = useSelector(
    (s) => s.loggedPractice.payWithSavedCards
  );
  const practiceId = useSelector((s) => s.loggedPractice.id);

  // Fields used for validation
  const STAFF_MEMBER_DEFINITION = {
    field: 'staffMember',
    value: staffMember,
    validationMessage: 'Select a staff member',
  };
  const TOTAL_AMOUNT_DEFINITION = {
    field: 'totalAmount',
    value: totalAmount,
    validationMessage: 'Enter a non-zero amount',
  };
  const DESCRIPTION_DEFINITION = {
    field: 'description',
    value: description,
    validationMessage: 'Enter a description',
  };
  const PET_OWNER_NAME_DEFINITION = {
    field: 'petOwnerName',
    value: petOwnerName,
    validationMessage: "Enter a pet owner's full name",
  };
  const PHONE_NUMBER_DEFINITION = {
    field: 'phoneNumber',
    value: phoneNumber,
    validationMessage: 'Enter a valid phone number',
  };

  const step1Validation = [
    STAFF_MEMBER_DEFINITION,
    TOTAL_AMOUNT_DEFINITION,
    DESCRIPTION_DEFINITION,
    PET_OWNER_NAME_DEFINITION,
    PHONE_NUMBER_DEFINITION,
  ];

  const validatePayFabricStep = (stepValidation) => {
    const validationResult = validateStep(
      stepValidation,
      payWithSavedCardEnabled
    );

    setValidationErrors(validationResult);
    return Object.keys(validationResult).length === 0;
  };

  const getBaseTransactionDetails = () => {
    return {
      petOwnerName: capitalizeName(petOwnerName),
      petOwnerPhone: phoneNumber ? `+1${getPhoneDigitsOnly(phoneNumber)}` : '',
      invoiceId: description,
      amount: cents(totalAmount, { resultType: 'number' }),
      staffMemberId: staffMember,
    };
  };

  /**
   *
   * @param {boolean}  isTerminal
   */
  const submitPaymentInfo = async (_isTerminal) => {
    const stepIsValid = validatePayFabricStep(step1Validation);

    if (stepIsValid) {
      const newStep =
        selectedPetOwner !== null &&
        payWithSavedCardEnabled &&
        !_isTerminal &&
        currentStep !== FormSteps.SELECT_CARD
          ? FormSteps.SELECT_CARD
          : FormSteps.PAYFABRIC_FORM;
      setStep(newStep);

      if (newStep === FormSteps.PAYFABRIC_FORM) {
        setIsPayFabricLoading(true);
        setIsTerminal(_isTerminal);

        const draftTransactionDetails = getBaseTransactionDetails();

        if (payWithSavedCardEnabled && !_isTerminal) {
          if (selectedPetOwner) {
            draftTransactionDetails['customerId'] =
              selectedPetOwner.customer_id;
          } else {
            const randomCustomerId = uuid();
            setNewCustomerId(randomCustomerId);
            draftTransactionDetails['customerId'] = randomCustomerId;
          }
        }

        const draft = await createDraftTransaction(draftTransactionDetails);
        const url = await createPayFabricFormUrl({
          transactionId: draft.transactionId,
          isTerminalEntry: _isTerminal,
          isMobile: false,
        });

        setDraftTransactionId(draft.transactionId);
        setFormUrl(url);
        setDraftTransaction(draft);
      }
    }
  };

  const payWithPayFabricCard = async () => {
    try {
      setFormLoading(true);
      const creditCard =
        selectedPetOwner.payment_methods[selectedPaymentMethodIndex];
      const transactionDetails = getBaseTransactionDetails();

      Object.assign(transactionDetails, {
        creditCardId: creditCard.payment_method_token,
        customerId: selectedPetOwner?.customer_id,
      });

      const processedTransaction = await practiceProcessTransaction(
        transactionDetails
      );

      setDraftTransaction(processedTransaction);
      setLastFourDigits(creditCard.last_four);
      setCurrentPaymentState(PaymentStates.Successful);
      triggerUpdate();
    } catch (error) {
      console.error(error);
      setCurrentPaymentState(PaymentStates.Failed);
    } finally {
      setFormLoading(false);
    }

    setStep(FormSteps.PAYMENT_RESPONSE);
  };

  const payWithSelectedCard = async () => {
    payInFullNextGAEventHandler();

    selectedPaymentMethodIndex >= selectedPetOwner?.payment_methods?.length
      ? await submitPaymentInfo(false)
      : await payWithPayFabricCard();
  };

  const handleTransactionCompleted = async (msg) => {
    setIsPayFabricLoading(true);

    try {
      if (payWithSavedCardEnabled) {
        const customerId = selectedPetOwner?.customer_id || newCustomerId;

        await upsertCustomerWithNewCreditCard(
          selectedPetOwner,
          customerId,
          practiceId,
          phoneNumber
        );
      }

      const resp = await paymentSuccess(
        draftTransaction.transactionId,
        msg,
        isTerminal
      );
      const { last4 } = resp;
      setLastFourDigits(last4);

      setCurrentPaymentState(PaymentStates.Successful);
      triggerUpdate();

      setStep(FormSteps.PAYMENT_RESPONSE);
      setIsPayFabricLoading(false);
    } catch (error) {
      console.error(error);
      setStep(FormSteps.PAYMENT_FAILED);
    }
  };

  const handleTransactionFailed = async (msg) => {
    setIsPayFabricLoading(true);

    setCurrentPaymentState(PaymentStates.Failed);
    setErrorMessage(msg.errorMessage);

    setStep(FormSteps.PAYMENT_RESPONSE);
    setIsPayFabricLoading(false);

    if (Env.sentryEnabled) {
      // Log payment errors in Sentry
      Sentry.withScope(() => {
        Sentry.setContext('transactionRsp', msg);
        Sentry.captureMessage(`PayFabric Payment Failed: ${msg.ResponseMsg}`, {
          level: 'info',
        });
      });
    }
  };

  const tryAgain = async () => {
    try {
      setIsPayFabricLoading(true);

      if (
        selectedPaymentMethodIndex < selectedPetOwner?.payment_methods?.length
      ) {
        setStep(FormSteps.SELECT_CARD);
      } else {
        const url = await createPayFabricFormUrl({
          transactionId: draftTransactionId,
          isTerminalEntry: false,
          isMobile: false,
        });
        setFormUrl(url);
        setStep(FormSteps.PAYFABRIC_FORM);
      }
    } catch (err) {
      toast.error('Encountered an error reloading the payment form');
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      size='md'
      aria-labelledby={`pay-in-full-modal`}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {currentStep === FormSteps.PAYMENT_INFO && 'Payment Information'}
          {currentStep === FormSteps.SELECT_CARD && 'Pay with Saved Card'}
          {currentStep === FormSteps.PAYFABRIC_FORM && 'Pay by Card'}
          {currentStep === FormSteps.PAYMENT_RESPONSE &&
            currentPaymentState === PaymentStates.Failed &&
            `Payment Failed`}
          {currentStep === FormSteps.PAYMENT_RESPONSE &&
            currentPaymentState === PaymentStates.Successful &&
            `Payment Successful`}
          {currentStep === FormSteps.PAYMENT_RESPONSE &&
            currentPaymentState === PaymentStates.Completed &&
            `Payment Completed`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {currentStep === FormSteps.PAYMENT_INFO && !isPayFabricLoading && (
          <PaymentInfoStep
            staffMember={staffMember}
            setStaffMember={setStaffMember}
            petOwnerName={petOwnerName}
            setPetOwnerName={setPetOwnerName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            description={description}
            setDescription={setDescription}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
            isFormLoading={isFormLoading}
            setFormLoading={setFormLoading}
            validationErrors={validationErrors}
            selectedPetOwner={selectedPetOwner}
            setSelectedPetOwner={setSelectedPetOwner}
            enablePhoneNumber={true}
          />
        )}

        {currentStep === FormSteps.SELECT_CARD && (
          <>
            <SelectCardStep
              selectedPaymentMethodIndex={selectedPaymentMethodIndex}
              setSelectedPaymentMethodIndex={setSelectedPaymentMethodIndex}
              paymentMethods={selectedPetOwner?.payment_methods}
              totalAmount={totalAmount}
              isFormLoading={isFormLoading}
            />
          </>
        )}

        {currentStep === FormSteps.PAYFABRIC_FORM && (
          <div className={styles.payFabricHostedForm}>
            <PayFabricHostedForm
              hostedFormUrl={formUrl}
              handleFormLoaded={() => setIsPayFabricLoading(false)}
              handleCancel={prevStep}
              handleTransactionCompleted={handleTransactionCompleted}
              handleTransactionFailed={handleTransactionFailed}
            />

            {isPayFabricLoading && (
              <div className={styles.payFabricLoading}>
                <Loading />
              </div>
            )}
          </div>
        )}

        {currentStep === FormSteps.PAYMENT_RESPONSE && (
          <PaymentResponseStep
            totalAmount={totalAmount}
            isFormLoading={isFormLoading}
            currentPaymentState={currentPaymentState}
            errorMessage={errorMessage}
            setCurrentPaymentState={setCurrentPaymentState}
            lastFourDigits={lastFourDigits}
            postTransactionReceipt={postTransactionReceipt}
            showReceiptOptions={showReceiptOptions}
            setShowReceiptOptions={setShowReceiptOptions}
            processedTransactionId={{ current: draftTransaction.transactionId }} // this is how the data structure is set up in PaymentResponseStep
            autoReceiptPhoneNumber={selectedPetOwner ? phoneNumber : null}
          />
        )}

        {currentStep === FormSteps.PAYMENT_FAILED && (
          <div>
            <h2>Error</h2>
            <p>There was an error with the payment. Please contact support.</p>
            <p>VitusPay Transaction ID: {draftTransaction?.transactionId}</p>
          </div>
        )}
      </Modal.Body>

      {currentStep === FormSteps.PAYMENT_INFO && payByTerminalEnabled && (
        <Modal.Footer>
          <Button
            variant='light'
            onClick={() => {
              setShowModal();
            }}
            disabled={false}
          >
            Cancel
          </Button>

          <Button
            variant='secondary'
            onClick={() => {
              submitPaymentInfo(true);
            }}
            disabled={isFormLoading}
          >
            Terminal Entry
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              submitPaymentInfo(false);
            }}
            disabled={isFormLoading}
          >
            Manual Entry
          </Button>
        </Modal.Footer>
      )}

      {currentStep === FormSteps.PAYMENT_INFO && !payByTerminalEnabled && (
        <Modal.Footer>
          <Button
            variant='light'
            onClick={() => {
              setShowModal();
            }}
            disabled={false}
          >
            Cancel
          </Button>

          <Button
            variant='secondary'
            onClick={() => {
              submitPaymentInfo(false);
            }}
            disabled={isFormLoading}
          >
            Next
          </Button>
        </Modal.Footer>
      )}

      {currentStep === FormSteps.SELECT_CARD && (
        <Modal.Footer>
          <Button
            variant='light'
            onClick={() => {
              payInFullBackGAEventHandler(totalAmount);
              prevStep();
            }}
            disabled={false}
          >
            Back
          </Button>

          <Button
            variant='secondary'
            onClick={payWithSelectedCard}
            disabled={isFormLoading}
          >
            {selectedPaymentMethodIndex <
            selectedPetOwner?.payment_methods?.length
              ? 'Pay Now'
              : 'Next'}
          </Button>
        </Modal.Footer>
      )}

      {currentStep === FormSteps.PAYMENT_RESPONSE &&
        currentPaymentState === PaymentStates.Failed && (
          <Modal.Footer>
            <Button
              variant='light'
              onClick={() => {
                tryAgain();
              }}
              disabled={isFormLoading}
            >
              Try again
            </Button>
          </Modal.Footer>
        )}

      {currentStep === FormSteps.PAYMENT_RESPONSE && !showReceiptOptions && (
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={setShowModal}
            disabled={isFormLoading}
          >
            Finished
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

function validateStep(rules, payWithSavedCardEnabled) {
  const validationResult = rules.reduce((errors, vf) => {
    const error = !validateReducer(vf.field, vf.value);
    if (error) {
      errors[vf.field] = vf.validationMessage;
    }
    return errors;
  }, []);

  return validationResult;

  function validateReducer(field, value) {
    switch (field) {
      case 'totalAmount':
        return value > 0;
      case 'staffMember':
        return value?.length === 36;
      case 'petOwnerName':
        return value?.length > 0 && isFullName(value);
      case 'description':
        return value?.length > 0;
      case 'phoneNumber':
        if (!payWithSavedCardEnabled) return true;
        return value?.length > 0 && isPossiblePhoneNumber(value);
      default:
        return null;
    }
  }
}
