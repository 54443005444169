export function getPhoneDigitsOnly(number) {
  if (typeof number !== 'string') return null;
  const numberOnly = number.replace(/[\D]/g, '');
  const numberLength = numberOnly.length;
  return numberLength > 10
    ? numberOnly.substring(numberLength - 10)
    : numberOnly;
}

export default function phoneNumberIsValidFormat(number) {
  if (typeof number !== 'string') return false;

  const noSpecialChars = getPhoneDigitsOnly(number);

  if (noSpecialChars.length !== 10) return false;

  const stringIsAllDigits = /^[^\D]+$/.test(noSpecialChars);

  if (!stringIsAllDigits) return false;

  return true;
}
