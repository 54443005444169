import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const mostRecentTransactionFromWebSocket = createSlice({
  name: 'mostRecentTransactionFromWebSocket',
  initialState,
  reducers: {
    updateTransaction(state, action) {
      return action?.payload;
    },
  },
});

export const { updateTransaction } = mostRecentTransactionFromWebSocket.actions;
export default mostRecentTransactionFromWebSocket.reducer;
