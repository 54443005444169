import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorCard.module.scss';

const ErrorCard = (message) => {
  console.log('Message: ', message);
  return (
    <div className={`card ${styles.errorCard}`}>
      <div className={`jumbotron jumbotron-fluid ${styles.jumbotronHeader}`}>
        <div className={`container`}>
          <i className={`fa fa-exclamation-triangle ${styles.errorIcon}`}></i>
          <h1 className={`display-4 ${styles.header}`}>Error!</h1>
          <p className={`lead ${styles.errorMessage}`}>{message}</p>
        </div>
      </div>
    </div>
  );
};

ErrorCard.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorCard;
