import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import UserBehavior from 'behaviors/UserBehavior';

const StaffSave = ({
  show,
  handleClose,
  onSave,
  staffMember,
  staffType,
  errors,
  onChange,
  isSaving,
  passwordPolicies,
  currentUser,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  return (
    <Modal
      id='StaffSave'
      show={show}
      onHide={handleClose}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <form onSubmit={onSave}>
        <Modal.Header className='text-center'>
          {staffType === 'staff' && (
            <h2>
              {staffMember.id ? 'Edit Staff Member' : 'Add New Staff Member'}
            </h2>
          )}
          {staffType === 'users' && (
            <h2>
              {staffMember.id ? 'Edit User Account' : 'Add New User Account'}
            </h2>
          )}
        </Modal.Header>
        <Modal.Body>
          {errors.message && (
            <div className='alert alert-danger mt-1 mb-1'>{errors.message}</div>
          )}
          <div className='row'>
            <div className='form-group col-md-6'>
              <label htmlFor='firstName'>
                First Name
                <span className='vitus-detail-blue'> *</span>
              </label>
              <input
                id='firstName'
                className={`form-control${errors.name ? ' is-invalid' : ''}`}
                type='text'
                name='name'
                placeholder='Enter first name'
                value={staffMember.name}
                onChange={onChange}
              ></input>
              {errors.name && (
                <div className='invalid-feedback'>{errors.name}</div>
              )}
            </div>
            <div className='form-group col-md-6'>
              <label htmlFor='lastName'>
                Last Name
                <span className='vitus-detail-blue'> *</span>
              </label>
              <input
                id='lastName'
                className={`form-control${
                  errors.lastName ? ' is-invalid' : ''
                }`}
                type='text'
                name='lastName'
                placeholder='Enter last name'
                value={staffMember.lastName}
                onChange={onChange}
              ></input>
              {errors.lastName && (
                <div className='invalid-feedback'>{errors.lastName}</div>
              )}
            </div>
          </div>
          {staffType === 'users' && (
            <Fragment>
              <div className='form-group'>
                <label htmlFor='email'>
                  Email
                  <span className='vitus-detail-blue'> *</span>
                </label>
                <input
                  id='email'
                  className={`form-control${errors.email ? ' is-invalid' : ''}`}
                  type='email'
                  name='email'
                  placeholder='Enter email'
                  value={staffMember.email}
                  onChange={onChange}
                ></input>
                {errors.email && (
                  <div className='invalid-feedback'>{errors.email}</div>
                )}
              </div>
              {!staffMember.id && (
                <div className='form-group row'>
                  <div className='col-md-6'>
                    <label htmlFor='setPassword'>
                      Set User Password
                      <span className='vitus-detail-blue'> *</span>
                    </label>
                    <div className='input-group'>
                      <input
                        id='setPassword'
                        className={`form-control${
                          errors.password ? ' is-invalid' : ''
                        }`}
                        type={passwordVisible ? 'text' : 'password'}
                        name='password'
                        placeholder='Enter password'
                        value={staffMember.password}
                        onChange={onChange}
                      ></input>
                      <div className='input-group-append'>
                        <span
                          className='input-group-text'
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          style={{
                            cursor: 'pointer',
                            borderRadius: '0 0.25rem 0.25rem 0',
                          }}
                        >
                          <span className='sr-only'>toggle password view</span>
                          <i
                            className='fas fa-eye'
                            aria-hidden='true'
                            style={{
                              color: passwordVisible ? '#ff8f32' : '',
                            }}
                          ></i>
                        </span>
                      </div>
                      {passwordPolicies && (
                        <small
                          className='form-text'
                          style={{
                            color: '#FFF !important',
                            textAlign: 'left',
                          }}
                        >
                          {passwordPolicies.Message}
                        </small>
                      )}
                      {errors.password && (
                        <div className='invalid-feedback'>
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor='confirmPassword'>
                      Confirm Password
                      <span className='vitus-detail-blue'> *</span>
                    </label>
                    <div className='input-group'>
                      <input
                        id='confirmPassword'
                        className={`form-control${
                          errors.password_confirm ? ' is-invalid' : ''
                        }`}
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        name='password_confirm'
                        placeholder='Confirm password'
                        value={staffMember.password_confirm}
                        onChange={onChange}
                      ></input>
                      <div className='input-group-append'>
                        <span
                          className='input-group-text'
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                          style={{
                            cursor: 'pointer',
                            borderRadius: '0 0.25rem 0.25rem 0',
                          }}
                        >
                          <span className='sr-only'>toggle password view</span>
                          <i
                            className='fas fa-eye'
                            aria-hidden='true'
                            style={{
                              color: confirmPasswordVisible ? '#ff8f32' : '',
                            }}
                          ></i>
                        </span>
                      </div>
                      {errors.password_confirm && (
                        <div className='invalid-feedback'>
                          {errors.password_confirm}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className='form-group'>
                <p className='mb-0'>
                  Permissions
                  <span className='vitus-detail-blue'> *</span>
                </p>
                <div>
                  <input
                    id='tabletAccess'
                    className='mr-3'
                    style={{ width: 'auto' }}
                    type='checkbox'
                    name='tabletUser'
                    value={staffMember.role.tabletUser}
                    onChange={onChange}
                    checked={staffMember.role.tabletUser}
                    disabled={
                      UserBehavior.editModeFor(currentUser, staffMember) ===
                      UserBehavior.EditModes.NameAndEmail
                    }
                  ></input>
                  <label className='form-check-label' htmlFor='tabletAccess'>
                    Access to Tablet
                  </label>
                </div>
                <div>
                  <input
                    id='portalAccess'
                    className='mr-3'
                    style={{ width: 'auto' }}
                    type='checkbox'
                    name='practiceAdmin'
                    value={staffMember.role.practiceAdmin}
                    onChange={onChange}
                    checked={staffMember.role.practiceAdmin}
                    disabled={
                      UserBehavior.editModeFor(currentUser, staffMember) ===
                      UserBehavior.EditModes.NameAndEmail
                    }
                  ></input>
                  <label className='form-check-label' htmlFor='portalAccess'>
                    Access to Web Portal
                  </label>
                </div>
                {errors.permissions && (
                  <div
                    className='invalid-feedback'
                    style={{ display: 'block' }}
                  >
                    {errors.permissions}
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='light' onClick={() => handleClose()}>
            CANCEL
          </Button>
          <Button type='submit' variant='primary' disabled={isSaving}>
            {isSaving ? 'Saving...' : 'SAVE'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

StaffSave.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  staffMember: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default StaffSave;
