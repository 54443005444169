import React from 'react';
import PropTypes from 'prop-types';

const StripeFeedback = ({ message }) => {
  return (
    <div className='no-data' style={{ padding: '0 1.5rem' }}>
      <div className='vitus-vet-box text-center'>
        <h4>{message}</h4>
      </div>
    </div>
  );
};

StripeFeedback.propTypes = {
  message: PropTypes.string,
};

export default StripeFeedback;
