import { StoreActions } from '.';
import {
  readPractice,
  verifyPractice,
  getPractices,
} from './../../api/practiceApi';
import { configurePayment } from './../../api/merchantApi';

/*******************************
 * Private Functions
 *****************************/

/**
 * Set selected practice on search success
 * @param {*} practiceInfo
 */
function getLoggedPracticeInfo(practiceInfo = null) {
  return {
    type: StoreActions.GET_LOGGED_PRACTICE_INFO,
    practiceInfo,
  };
}

/**
 * Change logged practice status to 'Connected to stripe' (upon successful connection)
 */
function setPracticePaymentSuccess() {
  return {
    type: StoreActions.SET_PRACTICE_PAYMENT_SUCCESS,
  };
}

/**
 * Set practice status to active (upon) successful request
 */
function activatePracticeSuccess() {
  return {
    type: StoreActions.ACTIVATE_PRACTICE_SUCCESS,
  };
}

/**
 *
 * @param {*} practices
 */
function displayParcticeList(practices) {
  return {
    type: StoreActions.DISPLAY_PRACTICE_LIST_SUCCESS,
    practices,
  };
}

/*******************************
 * Esports
 *****************************/

/**
 * Set selected practice in Redux store
 * @param {*} id
 * @param {*} practiceList
 */
export function setSelectedPractice(id, practiceList) {
  const selectedPractice = practiceList.find((element) => element.id === id);
  return {
    type: StoreActions.SET_SELECTED_PRACTICE,
    selectedPractice,
  };
}

export function setSelectedPracticeByPractice(selectedPractice) {
  return {
    type: StoreActions.SET_SELECTED_PRACTICE,
    selectedPractice,
  };
}

/**
 * Reset initial state in Redux Store for selected practice
 */
export function unsetSelectedPractice() {
  return {
    type: StoreActions.UNSET_SELECTED_PRACTICE,
  };
}

/**
 * Edit selected practice in redux store
 * @param {*} selectedPractice
 * @param {*} propsToAlter
 */
export function alterSelectedPractice(selectedPractice, propsToAlter) {
  const alteredPractice = { ...selectedPractice, ...propsToAlter };
  return {
    type: StoreActions.ALTER_SELECTED_PRACTICE,
    alteredPractice,
  };
}

// Async Functions

/**
 * Read Practice from database by ID
 * @param {*} id
 */
export function getPracticeInfo(id) {
  return function (dispatch) {
    return readPractice(id)
      .then((res) => {
        dispatch(getLoggedPracticeInfo(res));
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Get practice by id and set to the selected practice
 * @param {*} id
 */
export function getPracticeInfoForAdmin(id) {
  return function (dispatch) {
    return readPractice(id)
      .then((res) => {
        dispatch(setSelectedPracticeByPractice(res));
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Connect to Stripe
 * @param {*} code
 * @param {*} userId
 */
export function setPracticePayment(code, userId) {
  return function (dispatch) {
    return configurePayment(code).then(() => {
      readPractice(userId)
        .then((practiceInfo) => {
          dispatch(getLoggedPracticeInfo(practiceInfo));
          dispatch(setPracticePaymentSuccess());
        })
        .catch((err) => {
          throw err;
        });
    });
  };
}

/**
 * Set practice status to active and store to DB
 * @param {*} practice
 */
export function activatePractice(practice) {
  return function (dispatch) {
    return verifyPractice(practice)
      .then(() => {
        dispatch(activatePracticeSuccess());
        getPractices()
          .then((practices) => {
            dispatch(
              displayParcticeList(
                practices.sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  else return 0;
                })
              )
            );
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };
}
