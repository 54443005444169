import React from 'react';
import PropTypes from 'prop-types';

const SortingHeader = (order = '', title = '') => {
  function renderSortIcon() {
    switch (order) {
      case 'asc':
        return <i className='fas fa-caret-up ml-1 mb-1'></i>;
      case 'desc':
        return <i className='fas fa-caret-down ml-1 mb-1'></i>;
      default:
        return <i className='fas fa-caret-right ml-1 mb-1'></i>;
    }
  }

  return (
    <div className='table-sorting-header'>
      <span className='table-sorting-header-title'>{title}</span>
      {renderSortIcon()}
    </div>
  );
};

SortingHeader.propTypes = {
  order: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SortingHeader;
