import React from 'react';
import PropTypes from 'prop-types';

const SelectField = ({
  label = '',
  placeholder = '',
  placeholderValue = '',
  iconClass = '',
  selectStyling = {},
  selectClasses = '',
  required = false,
  error = false,
  options = [],
  keyPrefix = '',
  name = '',
  defaultValue = '',
  ...props
}) => {
  const renderOptions = () => {
    if (options.length) {
      return options.map((item) => (
        <option
          key={`${label}-${keyPrefix || item.id || item.value}`}
          value={item.value}
        >
          {item.label ?? item.value}
        </option>
      ));
    } else if (props.children) {
      return props.children;
    }

    return '';
  };

  return (
    <div
      className={`
            vitus-select-field 
            ${error ? 'error' : ''}
        `}
    >
      {iconClass && <i className={`pr-2 ${iconClass}`}></i>}
      <div className='position-relative'>
        {label && (
          <label className='vitus-select-field-label'>
            {label} {required && '*'}
          </label>
        )}
        <select
          name={name}
          className={`form-control ${selectClasses}`}
          style={selectStyling}
          defaultValue={defaultValue}
          {...props}
        >
          {placeholder && (
            <option value={placeholderValue}>{placeholder}</option>
          )}
          {renderOptions()}
        </select>
      </div>
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholderValue: PropTypes.string,
  iconClass: PropTypes.string,
  selectStyling: PropTypes.object,
  selectClasses: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  options: PropTypes.array,
  keyPrefix: PropTypes.string,
};

export default SelectField;
