/**
 * Table Filter
 *
 * @param {object} data
 * @param {string} search
 */
const dataFilter = (data, search) => {
  // validate search term
  search = search ? search.trim().toLowerCase() : null;
  if (search) {
    return data.filter((item) => {
      // get object columns
      let cols = Object.keys(item);
      let found = false;
      // check search tearm against every value in column
      for (var i = 0; i < cols.length; i++) {
        let currentKey = cols[i];
        let currentValue = item[currentKey];
        currentValue =
          currentValue === null || currentValue === undefined
            ? ''
            : currentValue;
        try {
          // convert current col value to string
          currentValue =
            typeof currentValue === 'string'
              ? currentValue
              : currentValue.toString();
          currentValue = currentValue.trim().toLowerCase();
        } catch (e) {
          currentValue = '';
        } finally {
          // if match return row
          if (currentValue.indexOf(search) >= 0) {
            found = true;
          }
        }
      }
      return found;
    });
  }
  // return entire table
  return data;
};

export default dataFilter;
