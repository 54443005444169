import React, { useEffect } from 'react';
import Loading from 'components/Common/Loading';
import { currencyFormat } from 'utils/helpers/currencyFormat';
import { capitalizeName } from 'utils/helpers/names';
import { sendEmailGAEventHandler } from './googleAnalyticsHandlers';

// Third step - success/fail
const SendEmailStep = (props) => {
  let { success } = props;
  useEffect(() => {
    sendEmailGAEventHandler(success);
  }, [success]);

  return props.isFormLoading ? (
    <Loading></Loading>
  ) : (
    <div className='text-center'>
      {success && (
        <>
          <strong>Email to Pay Request Sent!</strong>
          <br />
          <p>
            <small>Total Amount</small>
            <br />
            {/* helper function works on cent amounts */}
            <strong>${currencyFormat(props.totalAmount * 100)}</strong>
          </p>
          <p>
            Please check the VitusPay Portal to confirm payment has been made!
          </p>
          <p>
            <small>Cardholder Name</small>
            <br />
            <strong>{capitalizeName(props.petOwnerName)}</strong>
            <br />
            <small>Email Address</small>
            <br />
            <strong>{props.emailAddress}</strong>
            <br />
            <small>Invoice ID</small>
            <br />
            <strong>{props.description}</strong>
          </p>
        </>
      )}
      {!success && (
        <p>
          <strong>Email to Pay Request Failed</strong>
          <br />
          <br />
          Please select <strong>Try Again</strong> to retry sending the payment
          request
        </p>
      )}
    </div>
  );
};

export default SendEmailStep;
