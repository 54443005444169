import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function ResetButton({ user }) {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='top'
      overlay={
        <Popover className='popover-basic'>
          <Popover.Content>Reset Password</Popover.Content>
        </Popover>
      }
    >
      <button
        className='btn btn-link'
        onClick={() => user.onResetPassword(user)}
      >
        <span className='sr-only'>Edit item</span>
        <span
          className='fa-passwd-reset fa-stack'
          style={{ fontSize: '0.70rem' }}
        >
          <i className='fa fa-undo fa-stack-2x'></i>
          <i className='fa fa-lock fa-stack-1x'></i>
        </span>
      </button>
    </OverlayTrigger>
  );
}

ResetButton.prototype = {
  user: PropTypes.object.isRequired,
};

export default ResetButton;
