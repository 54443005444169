import moment from 'moment';
import currencyFormat from './currencyFormat';
import DateTime from './DateTime';
import removeEmptyRefunds from './removeEmptyRefunds';
import map from 'utils/api/map';
import TransactionStatus from 'constants/TransactionStatus';
import PaymentMethod from 'constants/PaymentMethod';
import TransactionSource from 'constants/TransactionSource';

/**
 * Status Map
 */
export const transactionStatusMap = {
  draft: 'Draft',
  succeeded: 'Processed',
  InProgress: 'Processed',
  PendingMerchantShipmentNotice: 'Processed',
  Cleared: 'Refunded',
  refunded: 'Refunded',
  partialRefund: 'Partially Refunded',
  'link sent': 'Delivered',
  canceled: 'Canceled',
  'sending link': 'Pending',
  'error sending link': 'Error',
  uncaptured: 'Uncaptured',
  expired: 'Expired',
  voided: 'Voided',
};

/**
 * PRIVATE: Map and sort transactions
 * @param {Array<object>} transactions
 */
export function sortTransactionsByDateDsc(transactions) {
  return (
    transactions.Items
      // Sort by Date DESC
      .sort((a, b) => {
        a.time = a.time ? a.time : false;
        b.time = b.time ? b.time : false;
        return b.time - a.time;
      })
  );
}

export function mapTransaction(transaction) {
  let splititObjectJSON;

  if (
    !transaction.pet_parent_card_type &&
    transaction.splitItObject &&
    Object.keys(transaction.splitItObject).length !== 0
  ) {
    splititObjectJSON = JSON.parse(transaction.splitItObject);
  }

  transaction.isInstallments = transaction.source === TransactionSource.SplitIt;
  transaction.isMerchant = !transaction.isInstallments;
  transaction.isPaymentLink =
    transaction.charge.method === PaymentMethod.TextLink ||
    transaction.charge.method === PaymentMethod.EmailLink;
  transaction.hasInvoiceId =
    transaction.invoiceId &&
    (transaction.invoiceId ?? '').toLowerCase() !== 'no invoice';
  transaction.cleanInvoiceId = transaction.hasInvoiceId
    ? transaction.invoiceId
    : null;
  //set description
  transaction.cleanDescription = transaction.description;
  if (
    !transaction.cleanDescription ||
    transaction.cleanDescription.toLowerCase() === 'no description provided'
  ) {
    transaction.cleanDescription = transaction.cleanInvoiceId;
  }
  //set curencyFields
  transaction.amount = transaction.charge?.amount;
  transaction.fee_amount = transaction.charge?.applicationFeeAmount ?? 0;
  transaction.amountRefunded = transaction.charge?.refunded ?? 0;
  transaction.net = transaction.charge?.net;
  // Set payment method for transaction
  transaction.method = transaction.charge?.method;
  // Use sort key because it is guaranteed not to change
  transaction.time =
    transaction?.['sort']?.split('#')[0] ??
    DateTime.normalizeEpoch(transaction?.charge?.created);
  transaction.paymentLinkCompletedDate =
    getPaymentLinkCompletedDate(transaction);

  // Set card details
  transaction.pet_parent_card_digits = transaction.charge?.card?.last4;
  transaction.pet_parent_card_type = splititObjectJSON
    ? splititObjectJSON.ActiveCard?.CardBrand?.Code?.toLowerCase()
    : transaction.charge?.card?.brand;
  // Set staff member
  if (transaction.charge?.user) {
    transaction.staff_member =
      transaction.charge?.user?.fullName ?? transaction.charge?.user?.name;
  } else {
    transaction.staff_member = transaction.UserId;
  }

  // Set status label
  transaction.isRefund =
    transaction.amount === 0 && transaction.amountRefunded > 0;

  transaction.transaction_status =
    transactionStatusMap[transaction.status] || transaction.status;

  transaction.isInProgress =
    transaction.status === TransactionStatus.linkSent ||
    transaction.status === TransactionStatus.sendingLink;
  if (transaction.isInstallments) {
    transaction.type = 'Monthly Payments';
  } else if (transaction.isMerchant) {
    transaction.type = transaction.isRefund ? 'Refund' : 'Full';

    if (transaction.amountRefunded && transaction.amountRefunded > 0) {
      transaction.transaction_status = transactionStatusMap.partialRefund;
    }
    if (
      transaction.amount === transaction.amountRefunded ||
      transaction.isRefund
    ) {
      transaction.transaction_status = transactionStatusMap.refunded;
    }
  }

  //Set pet owner info
  transaction.pet_parent_email = splititObjectJSON
    ? splititObjectJSON.Consumer?.Email
    : transaction.petData?.owner?.contactEmail;
  transaction.hasPet_parent_email =
    transaction.pet_parent_email &&
    (transaction.pet_parent_email ?? '').toLowerCase() !== 'not specified';
  transaction.cleanPet_parent_email = transaction.hasPet_parent_email
    ? transaction.pet_parent_email
    : null;
  transaction.pet_parent_name = transaction.petData?.owner?.name;
  transaction.hasPet_parent_name =
    transaction.pet_parent_name &&
    (transaction.pet_parent_name ?? '').toLowerCase() !== 'not specified';
  transaction.cleanPet_parent_name = transaction.hasPet_parent_name
    ? transaction.pet_parent_name
    : null;
  transaction.pet_parent_phone = transaction.petData?.owner?.phone;
  transaction.hasPet_parent_phone =
    transaction.pet_parent_phone &&
    (transaction.pet_parent_phone ?? '').toLowerCase() !== 'not specified';
  transaction.cleanPet_parent_phone = transaction.hasPet_parent_phone
    ? transaction.pet_parent_phone
    : null;

  transaction.messageHistory = map.transaction.paymentLinkLog.toModel(
    transaction.paymentLinkLog
  );

  //remove empty refunds
  transaction.refunds = removeEmptyRefunds(transaction);

  // return mapped transaction object
  return transaction;
}

export function sortDailyReportData(report) {
  return report.sort((a, b) => {
    let aValue = a.date ? Date.now(a.date) : false;
    let bValue = b.date ? Date.now(b.date) : false;
    return bValue - aValue;
  });
}

export function parseTransactionsDataForExport(transactions = []) {
  return transactions
    .sort((a, b) => {
      return b.time ?? false - a.time ?? false;
    })
    .map((transaction) => ({
      DESCRIPTION: transaction.cleanInvoiceId ?? '',
      TRANSACTION: transaction.transactionId,
      'CREATED DATE': transaction.time
        ? moment.utc(transaction.time).local().format('LLL')
        : 'Invalid date',
      'CLIENT NAME': transaction.cleanPet_parent_name,
      STATUS: transaction.transaction_status,
      'STAFF MEMBER': transaction.staff_member,
      'TRANSACTION TYPE': transaction.type,
      'AMOUNT CHARGED': currencyFormat(transaction.amount),
      FEES: currencyFormat(transaction.fee_amount),
      'AMOUNT REFUNDED': currencyFormat(transaction.amountRefunded),
      NET: currencyFormat(transaction.net),
      EMAIL: transaction.cleanPet_parent_email ?? '',
      PHONE: transaction.cleanPet_parent_phone ?? '',
      'CARD HOLDER NAME': transaction.cleanPet_parent_name ?? '',
      'PAYMENT DATE': transaction.charge.created
        ? moment.utc(transaction.charge.created).local().format('LLL')
        : 'Invalid date',
      'CARD #': transaction.pet_parent_card_digits
        ? `** ${transaction.pet_parent_card_digits}`
        : '',
      'CARD TYPE': transaction.pet_parent_card_type,
    }));
}

export function parseDailyReportDataForExport(report = []) {
  return report.map((item) => ({
    DATE: item.date ? moment(item.date).format('MM/DD/YY') : '',
    'NUMBER OF TRANSACTIONS': parseFloat(item.count),
    'PAYMENT AMOUNT': currencyFormat(item.amount),
    FEES: currencyFormat(item.fees),
    'AMOUNT REFUNDED': currencyFormat(item.refunds),
    'NET TOTAL': currencyFormat(item.total),
  }));
}

export function parsePayoutsReportsForExport(report = []) {
  return report
    .sort((a, b) => b.arrival_date - a.arrival_date)
    .map((item) => ({
      ARRIVAL: moment(DateTime.normalizeEpoch(item.arrival_date)).format('LLL'),
      CREATED: moment
        .utc(DateTime.normalizeEpoch(item.created))
        .local()
        .format('LLL'),
      AMOUNT: currencyFormat(item.amount),
      DESCRIPTION: item.description,
    }));
}

function getPaymentLinkCompletedDate(transaction) {
  const { paymentLinkCompletedDate, transactionId } = transaction;

  if (!paymentLinkCompletedDate) return '';

  if (transactionId.startsWith('sp_'))
    return moment.utc(paymentLinkCompletedDate).local().toISOString();

  if (transactionId.startsWith('sr_'))
    return moment.utc(paymentLinkCompletedDate).local().toISOString();

  if (transactionId.startsWith('pf_')) return paymentLinkCompletedDate;

  return '';
}
