import React from 'react';
import Loading from 'components/Common/Loading';
import { currencyFormat } from 'utils/helpers/currencyFormat';
import { insertInstallmentPlan } from '../../../../utils/api/installmentApi';
import { paymentPlanFinishedGAEventHandler } from './googleAnalyticsHandlers';

const iframeStyles = {
  width: '770px',
  height: '600px',
  border: 0,
};

const PaymentPlanIframeStep = (props) => {
  const goBack = () => {
    props.setStepNumber(props.stepNumber - 1);
  };

  const getIframe = () => {
    const element = document.getElementById('paymentIframe');
    return element.contentWindow || element.contentDocument;
  };
  const getIframeUrl = () => {
    return getIframe().location.pathname;
  };

  const getQueryString = () => {
    return getIframe().location.search;
  };

  const onLoad = async function () {
    try {
      const url = getIframeUrl();
      if (url === '/canceled') {
        goBack();
      } else if (url === '/success') {
        paymentPlanFinishedGAEventHandler(props.totalAmount);

        const urlParams = new URLSearchParams(getQueryString());
        const planNumber = urlParams.get('InstallmentPlanNumber');

        props.setFormLoading(true);
        await insertInstallmentPlan(planNumber);
        props.setFormLoading(false);

        props.setSplitItResult('success');
        props.triggerUpdate();
      } else if (url === '/failed') {
        console.log('failed path called.');
        props.setSplitItResult('failed');
      } else {
        console.log('loaded but no path was found');
      }
    } catch (err) {
      //empty catch to handle error accessing contentWindow when the iframe src is splitIt
      console.log('Ignoring error');
    }
  };

  const successView = (
    <div className='text-center'>
      <strong>Payment plan successfully created!</strong>
      <p>
        <small>Total Amount</small>
        <br />
        {/* helper function works on cent amounts */}
        <strong>${currencyFormat(props.totalAmount * 100)}</strong>
      </p>
      <p>
        An email confirming the monthly payment plan has been sent from SplitIt
      </p>
    </div>
  );

  const failedView = (
    <div className='text-center'>
      <strong>Payment plan failed. Please try again.</strong>
    </div>
  );

  return props.isFormLoading ? (
    <Loading></Loading>
  ) : props.splitItResult === 'success' ? (
    successView
  ) : props.splitItResult === 'failed' ? (
    failedView
  ) : (
    <iframe
      id='paymentIframe'
      title='Payment Plan Frame'
      src={props.src}
      style={iframeStyles}
      onLoad={onLoad}
    />
  );
};

export default PaymentPlanIframeStep;
