import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Loading from 'components/Common/Loading';
import { currencyFormat } from 'utils/helpers/currencyFormat';
import StateSelector from './CustomInputFields/StateSelector';

const PaymentPlanStep = (props) => {
  return props.isFormLoading ? (
    <Loading></Loading>
  ) : (
    <Form>
      <p className='text-center'>
        Total Amount
        <br />
        {/* helper function works on cent amounts */}
        <strong>${currencyFormat(props.totalAmount * 100)}</strong>
      </p>
      <Form.Group controlId='cardholderName'>
        <Form.Label>Cardholder Name</Form.Label>
        <Form.Control
          type='text'
          name='cardholderName'
          placeholder='Pet Owner Name'
          isInvalid={!!props.validationErrors['cardholderName']}
          value={props.cardholderName}
          onChange={(event) => {
            props.setCardholderName(event.target.value);
          }}
        />
        <Form.Control.Feedback type='invalid'>
          {props.validationErrors['cardholderName']}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='cardholderEmail'>
        <Form.Label>Cardholder Email</Form.Label>
        <Form.Control
          type='text'
          name='cardholderEmail'
          placeholder='email@address.com'
          isInvalid={!!props.validationErrors['cardholderEmail']}
          value={props.cardholderEmail}
          onChange={(event) => {
            props.setCardholderEmail(event.target.value);
          }}
        />
        <Form.Control.Feedback type='invalid'>
          {props.validationErrors['cardholderEmail']}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='phoneNumber'>
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type='text'
          name='phoneNumber'
          placeholder='XXX XXX XXXX'
          isInvalid={!!props.validationErrors['phoneNumber']}
          value={props.phoneNumber}
          onChange={(event) => {
            props.setPhoneNumber(event.target.value);
          }}
        />
        <Form.Control.Feedback type='invalid'>
          {props.validationErrors['phoneNumber']}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='addressLine1'>
        <Form.Label>Address Line 1</Form.Label>
        <Form.Control
          type='text'
          name='addressLine1'
          isInvalid={!!props.validationErrors['addressLine1']}
          value={props.addressLine1}
          onChange={(event) => {
            props.setAddressLine1(event.target.value);
          }}
        />
        <Form.Control.Feedback type='invalid'>
          {props.validationErrors['addressLine1']}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='addressLine1'>
        <Form.Label>Address Line 2</Form.Label>
        <Form.Control
          type='text'
          name='addressLine2'
          placeholder='Apt, suite, etc (optional)'
          value={props.addressLine2}
          onChange={(event) => {
            props.setAddressLine2(event.target.value);
          }}
        />
      </Form.Group>
      <Form.Group controlId='city'>
        <Form.Label>City</Form.Label>
        <Form.Control
          type='text'
          name='city'
          isInvalid={!!props.validationErrors['city']}
          value={props.city}
          onChange={(event) => {
            props.setCity(event.target.value);
          }}
        />
        <Form.Control.Feedback type='invalid'>
          {props.validationErrors['city']}
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col>
          <Form.Group controlId='state'>
            <Form.Label>State</Form.Label>
            <StateSelector
              name='state'
              value={props.state}
              setValue={props.setState}
              isInvalid={!!props.validationErrors['state']}
            ></StateSelector>
            <Form.Control.Feedback type='invalid'>
              {props.validationErrors['state']}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId='zipCode'>
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type='text'
              name='zipCode'
              isInvalid={!!props.validationErrors['zipCode']}
              defaultValue={props.zipCode}
              onChange={(event) => {
                props.setZipCode(event.target.value);
              }}
            />
            <Form.Control.Feedback type='invalid'>
              {props.validationErrors['zipCode']}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentPlanStep;
