import React from 'react';

const ReportsHeader = (props) => {
  return (
    <nav id='ReportsHeader' className='reports-header vitus-app-header navbar'>
      <h1>{props.title}</h1>
    </nav>
  );
};

export default ReportsHeader;
