import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import Refresher from '../Refresher/Refresher';

const SlimTable = ({
  keyField = 'id',
  columns = [],
  data = [],
  noDataMessage = 'No data to display',
  title = '',
  isLoading = false,
  hasContainer = false,
  sortedByMostRecent = true,
  defaultSorted = null,
  refresher = false,
  onRefresh = null,
  refreshMessage = 'Refreshing...',
  updatedMessage = 'Last Updated',
  showLastUpdated = refresher,
  flex = '0 1 auto',
  ...props
}) => {
  function enableSortingByMostRecent() {
    if (sortedByMostRecent) {
      return [{ dataField: 'time', order: 'desc' }];
    }

    return null;
  }

  return (
    <div
      className={`
                table-responsive-md slim-table 
                ${hasContainer && 'shadow-sm bg-white rounded m-3 p-2'}
            `}
      style={{ flex: flex }}
    >
      {(title || refresher) && (
        <div className='d-flex flex-wrap align-items-center p-2'>
          {title && <h1 className='mr-2'>{title}</h1>}
          {refresher && (
            <Refresher
              onRefresh={onRefresh}
              refreshMessage={refreshMessage}
              lastUpdateMessage={updatedMessage}
              isRefreshing={isLoading}
              showLastUpdated={showLastUpdated}
            />
          )}
        </div>
      )}
      {isLoading && !data.length ? (
        <Loading />
      ) : (
        <BootstrapTable
          key={keyField}
          keyField={keyField}
          columns={columns}
          data={data}
          noDataIndication={noDataMessage}
          bordered={false}
          defaultSorted={
            defaultSorted ? defaultSorted : enableSortingByMostRecent()
          }
          {...props}
        />
      )}
    </div>
  );
};

SlimTable.propTypes = {
  keyField: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  noDataMessage: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  hasContainer: PropTypes.bool,
  sortedByMostRecent: PropTypes.bool,
  defaultSort: PropTypes.arrayOf(PropTypes.object),
  refresher: PropTypes.bool,
  onRefresh: PropTypes.func,
  refreshMessage: PropTypes.string,
  updatedMessage: PropTypes.string,
  showLastUpdated: PropTypes.bool,
};

export default SlimTable;
