import React from 'react';
import PropTypes from 'prop-types';
import Env from 'Env';
import LogRocket from 'logrocket';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as navigationActions from './../../utils/store/actions/navigationActions';
import SideNav from './SideBar/SideBar';
import Practices from './Content/Practices/Practices';
import Reports from './Content/Reports/Reports';
import Transactions from './Content/Transactions/Transactions';
import NoPermissions from './../Common/NoPermissions';
import PrivateRoute from './PrivateRoute';
import Staff from './Content/Staff/Staff';
import Stripe from './Content/StripeRedirect/Stripe';
import Transaction from './Content/TransactionDetail/Transaction';
import PracticeReports from './Content/PracticeReports/PracticeReports';
import DailyTransactions from './Content/DailyTransactions/DailyTransactions';
import Customers from './Content/Customers/Customers';
import PageViewLogger from 'components/Common/GAPageView/GAPageView';
import ContactSupport from './Content/ContactSupport/ContactSupport';
import * as Sentry from '@sentry/react';
import * as mixpanel from 'mixpanel-browser';
import AddTransaction from './Content/Transactions/Admin/AddTransaction';

function configureThirdPartyUserTracking(currentUser, loggedPractice) {
  const loggingUser = {
    id: currentUser.id,
    practiceId: currentUser['custom:PracticeId'],
    username: currentUser.username,
    userRole: currentUser['custom:UserRole'],
  };

  if (Env.logRocketEnabled) {
    LogRocket.identify(currentUser.username, loggingUser);
  }

  if (Env.sentryEnabled) {
    Sentry.setUser(loggingUser);
  }

  const trackingUser = {
    userId: currentUser.id,
    username: currentUser.username,
    practiceId: currentUser['custom:PracticeId'],
    corePracticeId: loggedPractice.coreId,
    practiceName: loggedPractice.name,
    isRoleTabletUser: currentUser.roles?.tabletUser,
    isRolePracticeStaff: currentUser.roles?.practiceStaff,
    isRolePracticeAdmin: currentUser.roles?.practiceAdmin,
    isRoleSupportAgent: currentUser.roles?.supportAgent,
    isRoleTeamAdministrator: currentUser.roles?.teamAdministrator,
    practiceIsExclusive: loggedPractice.vitusPayExclusive,
    practiceHasTextToPay: loggedPractice.paymentLinkEnabled,
    practiceHasInstallments: loggedPractice.installments,
  };

  if (Env.appCuesTrackingEnabled && window.Appcues) {
    window.Appcues.identify(trackingUser);
  }

  if (Env.mixpanelEnabled) {
    mixpanel.identify(trackingUser.userId);
    mixpanel.people.set(trackingUser);
  }
}

function AppRoutes({
  currentUser,
  currentPath,
  setNavigationPath,
  loggedPractice,
}) {
  const { path } = useRouteMatch();
  const isTeamAdminOrPracticeStaffOrAdmin =
    currentUser.roles.practiceStaff ||
    currentUser.roles.practiceAdmin ||
    currentUser.roles.teamAdministrator;
  const isPracticeStaffOrAdmin =
    currentUser.roles.practiceStaff || currentUser.roles.practiceAdmin;

  const routes = [
    {
      path: `${path}/practices/:practiceId/transactions/new`,
      condition: currentUser.roles.teamAdministrator,
      Component: AddTransaction,
      redirectTo: '/app',
      name: 'add-transaction',
      hideFromNavigation: true,
      exact: true,
    },
    {
      path: `${path}/practices/:practiceId/transactions`,
      condition: currentUser.roles.teamAdministrator,
      Component: Transactions,
      redirectTo: '/app',
      name: 'view-transactions',
      hideFromNavigation: true,
      exact: true,
    },
    {
      path: `${path}/practices/:practiceId/users`,
      condition: currentUser.roles.teamAdministrator,
      Component: Staff,
      redirectTo: '/app',
      name: 'view-users',
      hideFromNavigation: true,
      exact: true,
    },
    {
      path: `${path}/practices/`,
      condition: currentUser.roles.teamAdministrator,
      Component: Practices,
      redirectTo: '/app',
      name: 'practices',
      icon: 'fa-paw',
    },
    {
      path: `${path}/transactions/:transactionId`,
      exact: true,
      condition: isPracticeStaffOrAdmin,
      Component: Transaction,
      redirectTo: '/app',
      name: 'transaction',
      hideFromNavigation: true,
    },
    {
      path: `${path}/daily-transactions`,
      condition: isPracticeStaffOrAdmin,
      Component: DailyTransactions,
      redirectTo: '/app',
      name: 'daily-transactions',
      displayAs: 'Today',
      icon: 'fa-calendar-alt',
    },
    {
      path: `${path}/transactions`,
      condition: isPracticeStaffOrAdmin,
      Component: Transactions,
      redirectTo: '/app',
      name: 'transactions',
      icon: 'fa-credit-card',
    },
    {
      path: `${path}/users`,
      condition: isPracticeStaffOrAdmin,
      Component: Staff,
      redirectTo: '/app',
      name: 'users',
      displayAs: 'users',
      icon: 'fa-user-friends',
    },
    {
      path: `${path}/customers`,
      condition: isPracticeStaffOrAdmin,
      Component: Customers,
      redirectTo: '/app',
      name: 'customers',
      displayAs: 'customers',
      hideFromNavigation: !loggedPractice.payWithSavedCards,
      icon: 'fa-address-book',
    },
    {
      path: `${path}/practice-reports`,
      condition: isPracticeStaffOrAdmin,
      Component: PracticeReports,
      redirectTo: '/app',
      name: 'practice-reports',
      displayAs: 'Reports',
      icon: 'fa-folder',
    },
    {
      path: `${path}/reports`,
      condition: currentUser.roles.teamAdministrator,
      Component: Reports,
      redirectTo: '/app',
      name: 'reports',
      icon: 'fa-chart-bar',
    },
    {
      path: `${path}/stripe`,
      condition: isPracticeStaffOrAdmin,
      Component: Stripe,
      redirectTo: '/app',
      name: 'stripe',
      hideFromNavigation: true,
    },
    {
      path: `${path}/contact-support`,
      condition: isTeamAdminOrPracticeStaffOrAdmin,
      Component: ContactSupport,
      redirectTo: '/app',
      name: 'contact-support',
      displayAs: 'Contact Support',
      icon: 'fa-phone-alt',
    },
  ];

  const switchRoutes = (userScope, path) => {
    const usePathname =
      currentPath[0] === '/' && currentPath.indexOf('app/') > 0;
    const pathname = currentPath;
    setNavigationPath('/');
    if (userScope.roles.teamAdministrator) {
      return usePathname ? (
        <Redirect to={pathname} />
      ) : (
        <Redirect to={`${path}/practices`} />
      );
    } else if (userScope.roles.practiceStaff || userScope.roles.practiceAdmin) {
      return usePathname ? (
        <Redirect to={pathname} />
      ) : (
        <Redirect to={`${path}/daily-transactions`} />
      );
    } else {
      return <Redirect to={`${path}/no-permissions`} />;
    }
  };

  configureThirdPartyUserTracking(currentUser, loggedPractice);

  return (
    <Router>
      <PageViewLogger />
      <SideNav routeList={routes} />
      <Switch>
        <Route exact path={path}>
          {switchRoutes(currentUser, path)}
        </Route>
        {routes.map((route) => {
          return (
            <PrivateRoute
              key={route.path}
              path={route.path}
              Component={route.Component}
              condition={route.condition}
              redirectTo={route.redirectTo}
            />
          );
        })}
        <Route path={`${path}/no-permissions`}>
          <NoPermissions currentUser={currentUser} />
        </Route>
      </Switch>
      <ToastContainer autoClose={3000} hideProgressBar />
    </Router>
  );
}

AppRoutes.propTypes = {
  currentUser: PropTypes.object,
  setNavigationPath: PropTypes.func.isRequired,
  currentPath: PropTypes.string,
  loggedPractice: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer,
    currentPath: state.currentPath,
    loggedPractice: state.loggedPractice,
  };
}

const mapDispatchToProps = {
  ...navigationActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
