import React from 'react';
import { Form } from 'react-bootstrap';

const serviceTypes = ['Product', 'Service', 'Product & Service'];

const ServiceTypeSelect = (props) => {
  const { serviceType, setServiceType, validationError } = props;

  return (
    <Form.Group controlId='staffMember'>
      <Form.Label>Service Type</Form.Label>
      <Form.Control
        as='select'
        value={serviceType}
        name='serviceType'
        isInvalid={!!validationError}
        onChange={(event) => {
          setServiceType(event.target.value);
        }}
      >
        <option>Choose a service type</option>
        {serviceTypes.map((option, index) => {
          return (
            <option key={index} value={option.id}>
              {option}
            </option>
          );
        })}
      </Form.Control>
      <Form.Control.Feedback type='invalid'>
        {validationError}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default ServiceTypeSelect;
