import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const TransactionRefundConfirm = ({
  onCancel,
  onConfirm,
  transaction,
  refundValue,
  show,
  isSaving,
  onRefundReasonChange,
}) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Body>
        <div className='pt-4 pb-4 pl-2 pr-2'>
          <h3 className='text-center'>
            {/* TODO: Text2Pay refunds */}
            {transaction.isInstallments
              ? 'Confirm Refund'
              : 'Confirm Refund Amount?'}
          </h3>
          {
            // TODO: Text2Pay refunds
            transaction.isInstallments ? (
              <div className='text-center'>
                <h3 className='mt-4 mb-4'>
                  All charges completed up to date will be refunded and
                  installment plan will be cancelled.
                </h3>
              </div>
            ) : (
              <>
                <h3 className='text-center mt-4 mb-4'>
                  $ {Number.parseFloat(refundValue).toFixed(2)}
                </h3>
                <div className='form-group'>
                  <label>Reason for refund:</label>
                  <textarea
                    name='custom_refund_reason'
                    className='form-control'
                    rows='3'
                    onChange={onRefundReasonChange}
                    defaultValue={transaction.custom_refund_reason}
                  ></textarea>
                  <small className='form-text text-muted'>Optional</small>
                </div>
              </>
            )
          }
        </div>
        <div className='pt-2 pb-4 pl-2 pr-2 text-center'>
          <button
            className='btn btn-outline-secondary mr-2'
            onClick={onCancel}
            disabled={isSaving}
          >
            CANCEL
          </button>
          <button
            className='btn btn-primary ml-2'
            onClick={onConfirm}
            disabled={isSaving}
          >
            {isSaving ? 'Processing...' : 'Confirm'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

TransactionRefundConfirm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default TransactionRefundConfirm;
