import React from 'react';
import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Spinner,
  Button,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';

import { standardFormatPhoneNumber } from 'utils/helpers/phoneNumberFormat';

import styles from './DeleteCustomerModal.module.scss';

function DeleteCustomerModal(props) {
  const { show, customer, isLoading, onDelete, onCancel, onExited } = props;
  const firstAddress = customer?.addresses[0] ?? {};

  return (
    <Modal show={show} onHide={onCancel} onExited={onExited} centered>
      <ModalHeader>
        <ModalTitle>Are you sure you want to delete this customer?</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className={styles.centerSpinner}>
            {' '}
            <Spinner animation='border' variant='secondary' />{' '}
          </div>
        ) : (
          <Container>
            <Row>
              <Col>
                <b>Name:</b>
              </Col>
              <Col>
                {customer?.first_name} {customer?.last_name}
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Phone:</b>
              </Col>
              <Col>{standardFormatPhoneNumber(customer?.phone)}</Col>
            </Row>

            {/* Hiding addresses until further notice */}
            <Row hidden>
              <Col>
                <b>Address:</b>
              </Col>
              <Col>{firstAddress.address_1}</Col>
            </Row>
            {/* Hiding addresses until further notice */}
            <Row hidden>
              <Col />
              <Col>
                {firstAddress.city} {firstAddress.state}, {firstAddress.zip}
              </Col>
            </Row>
          </Container>
        )}
      </ModalBody>
      <ModalFooter>
        <div className={styles.buttonContainer}>
          <Button
            variant='outline-secondary'
            onClick={onCancel}
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            variant='primary'
            onClick={() => onDelete(customer.customer_id)}
            disabled={isLoading}
          >
            DELETE
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteCustomerModal;
