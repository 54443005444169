// Modules
import React from 'react';

// Styles
import styles from './PracticeListHeading.module.scss';

const names = {
  id: 'vituspay id',
  coreId: 'vitusvet id',
  name: 'name',
  email: 'email',
  phone: 'phone',
  active: 'status',
};

function getIconClass(name, sortOptions, activeSort) {
  switch (true) {
    case name === 'name' && activeSort === sortOptions.nameAsc:
      return 'fa-caret-up';

    case name === 'name' && activeSort === sortOptions.nameDesc:
      return 'fa-caret-down';

    case name === 'email' && activeSort === sortOptions.emailAsc:
      return 'fa-caret-up';

    case name === 'email' && activeSort === sortOptions.emailDesc:
      return 'fa-caret-down';

    case name === 'active' && activeSort === sortOptions.statusActive:
      return 'fa-caret-up';

    case name === 'active' && activeSort === sortOptions.statusDisabled:
      return 'fa-caret-down';

    default:
      return 'fa-caret-right';
  }
}

function getHeadingJSXElement(args) {
  const { name, sortOptions, activeSort, handlers } = args;

  const hasHandler = typeof handlers[name] === 'function';

  if (hasHandler) {
    return (
      <p className={styles.clickable} key={name} onClick={handlers[name]}>
        {names[name]}
        <i
          className={`fas ${getIconClass(name, sortOptions, activeSort)} ml-1`}
        ></i>
      </p>
    );
  } else {
    return <p key={name}>{names[name]}</p>;
  }
}

/**
 * ==================
 * Exported component
 * ==================
 */
function PracticeListHeading(props) {
  const {
    basicDetails,
    sortOptions,
    activeSort,
    handleSortByName,
    handleSortByEmail,
    handleSortByStatus,
  } = props;

  return (
    <div className={styles.headings}>
      {basicDetails.map((name) =>
        getHeadingJSXElement({
          name,
          sortOptions,
          activeSort,
          handlers: {
            name: handleSortByName,
            email: handleSortByEmail,
            active: handleSortByStatus,
          },
        })
      )}
      <span hidden>
        this is just here to take up space in the grid. Please do not delete
      </span>
    </div>
  );
}

export default PracticeListHeading;
