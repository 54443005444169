// Modules
import ReactGA from 'react-ga';
import { currencyFormat } from 'utils/helpers/currencyFormat';
import { capitalize } from 'utils/helpers/capitalize';

// Utils
import { receiptTypes } from '@vitusvet/react-library';

const gaCategory = 'VP Portal Payments';

const gaActions = {
  dropdown: 'New Payment Dropdown',
  paymentSuccessful: 'Payment Successful',
  textToPayStep1: 'Payment Information, Text to Pay',
  textToPayStep2: 'Text to Pay',
  textToPayStep3Sent: 'Text to Pay Request Sent',
  textToPayStep3Failed: 'Text to Pay Request Failed',
  emailToStep1: 'Payment Information, Email to Pay',
  emailToStep2: 'Email to Pay',
  emailToStep3Sent: 'Email to Pay Request Sent',
  emailToStep3Failed: 'Email to Pay Request Failed',
  payInFullStep1: 'Payment Information: Pay by Card',
  payInFullStep2: 'Pay by Card',
  payInFullStep3Failed: 'Payment Failed',
  payInFullStep3Successful: 'Payment Successful',
  payInFullStep3Completed: 'Payment Completed',
  paymentPlanStep1: 'Payment Information: Pay with Payment Plan',
  paymentPlanStep2: 'Pay with Payment Plan',
  paymentPlanStep3: 'SplitIt Webview',
  paymentPlanCreated: 'Payment Plan Created',
  textToPay: 'Text to Pay',
  email: 'Email to Pay',
};

const gaLabels = {
  cancel: 'Cancel',
  next: 'Next',
  startOver: 'Start Over',
  dropdown: 'Dropdown Selected',
  textToPay: 'Text To Pay',
  emailToPay: 'Email To Pay',
  payByCard: 'Pay by Card',
  payWithPaymentPlan: 'Pay with Payment Plan',
};

function getLabelByReceiptType(receiptType = '') {
  switch (receiptType) {
    case receiptTypes.email:
      return 'Email Receipt';

    case receiptTypes.textMessage:
      return 'Text Receipt';

    case receiptTypes.none:
      return 'No Receipt';

    default:
      return '';
  }
}

export const receiptClickGAEvent = (receiptType) => {
  triggerGAEvent(
    gaCategory,
    gaActions.paymentSuccessful,
    getLabelByReceiptType(receiptType)
  );
};

//Dropdown

export function dropdownGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.dropdown, gaLabels.dropdown);
}

export function dropdownTextToPayGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.dropdown, gaLabels.textToPay);
}

export function dropdownPayInFullGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.dropdown, gaLabels.payByCard);
}

export function dropdownInstallmentsGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.dropdown, gaLabels.payWithPaymentPlan);
}

//Text To Pay

export function textToPayCancelGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.textToPayStep2, gaLabels.cancel);
}

export function textToPayNextGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.textToPayStep1, gaLabels.next);
}

export function textToPayBackGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.textToPayStep2,
    `Back $${currencyFormat(totalAmount * 100)}`
  );
}

export function textToPayTryAgainGAEventHandler(
  sendTextRequestStatus,
  totalAmount
) {
  let currGAAction = sendTextRequestStatus
    ? gaActions.textToPayStep3Sent
    : gaActions.textToPayStep3Failed;
  triggerGAEvent(
    gaCategory,
    currGAAction,
    `Try Again $${currencyFormat(totalAmount * 100)}`
  );
}

export function textToPayFinishedGAEventHandler(
  sendTextRequestStatus,
  totalAmount
) {
  let currGAAction = sendTextRequestStatus
    ? gaActions.textToPayStep3Sent
    : gaActions.textToPayStep3Failed;
  triggerGAEvent(
    gaCategory,
    currGAAction,
    `Finished $${currencyFormat(totalAmount * 100)}`
  );
}

export function paymentResponseGAEventHandler(currentPaymentState) {
  triggerGAEvent(
    gaCategory,
    `Payment ${capitalize(currentPaymentState)}`,
    `${capitalize(currentPaymentState)}`
  );
}

export function sendTextGAEventHandler(success) {
  let currGAAction = success
    ? 'Text to Pay Request Sent'
    : 'Text to Pay Request Failed';
  let currGALabel = success ? 'Sent' : 'Failed';
  triggerGAEvent(gaCategory, currGAAction, currGALabel);
}

export function sendTextToPayRequestGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.textToPay,
    `Send Request $${currencyFormat(totalAmount * 100)}`
  );
}

//Email To Pay

export function emailToPayCancelGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.emailToPayStep2, gaLabels.cancel);
}

export function emailToPayNextGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.emailToPayStep1, gaLabels.next);
}

export function emailToPayBackGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.emailToPayStep2,
    `Back $${currencyFormat(totalAmount * 100)}`
  );
}

export function emailToPayTryAgainGAEventHandler(
  sendEmailRequestStatus,
  totalAmount
) {
  let currGAAction = sendEmailRequestStatus
    ? gaActions.emailToPayStep3Sent
    : gaActions.emailToPayStep3Failed;
  triggerGAEvent(
    gaCategory,
    currGAAction,
    `Try Again $${currencyFormat(totalAmount * 100)}`
  );
}

export function emailToPayFinishedGAEventHandler(
  sendEmailRequestStatus,
  totalAmount
) {
  let currGAAction = sendEmailRequestStatus
    ? gaActions.emailToPayStep3Sent
    : gaActions.emailToPayStep3Failed;
  triggerGAEvent(
    gaCategory,
    currGAAction,
    `Finished $${currencyFormat(totalAmount * 100)}`
  );
}

export function sendEmailGAEventHandler(success) {
  let currGAAction = success
    ? 'Email to Pay Request Sent'
    : 'Email to Pay Request Failed';
  let currGALabel = success ? 'Sent' : 'Failed';
  triggerGAEvent(gaCategory, currGAAction, currGALabel);
}

export function sendEmailToPayRequestGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.emailToPay,
    `Send Request $${currencyFormat(totalAmount * 100)}`
  );
}

//Pay In Full

export function payInFullCancelGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.payInFullStep1, gaLabels.cancel);
}

export function payInFullBackGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.payInFullStep1,
    `Back $${currencyFormat(totalAmount * 100)}`
  );
}

export function payInFullPayNowGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.payInFullStep2,
    `Pay Now $${currencyFormat(totalAmount * 100)}`
  );
}

export function payInFullNextGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.payInFullStep1, gaLabels.next);
}

export function payInFullTryAgainGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.payInFullStep3Failed,
    `Try Again $${currencyFormat(totalAmount * 100)}`
  );
}

export function payInFullFinishedGAEventHandler(
  currentPaymentState,
  totalAmount
) {
  triggerGAEvent(
    gaCategory,
    `Payment ${capitalize(currentPaymentState)}`,
    `Finished $${currencyFormat(totalAmount * 100)}`
  );
}

export function receiptButtonSentGAHandler(currentPaymentState, chosenButton) {
  triggerGAEvent(
    gaCategory,
    `Payment ${capitalize(currentPaymentState)}`,
    `${chosenButton} Sent`
  );
}

//Payment Plans

export function paymentPlanNextStep1GAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.paymentPlanStep1, gaLabels.next);
}

export function paymentPlanNextStep2GAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.paymentPlanStep2,
    `Next $${currencyFormat(totalAmount * 100)}`
  );
}

export function paymentPlanNextStartOverGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.paymentPlanStep3, gaLabels.startOver);
}

export function paymentPlanNextStartOverYesGAEventHandler() {
  triggerGAEvent(
    gaCategory,
    gaActions.paymentPlanStep3,
    `${gaLabels.startOver} Yes`
  );
}

export function paymentPlanNextStartOverNoGAEventHandler() {
  triggerGAEvent(
    gaCategory,
    gaActions.paymentPlanStep3,
    `${gaLabels.startOver} No`
  );
}

export function paymentPlanCancelGAEventHandler() {
  triggerGAEvent(gaCategory, gaActions.paymentPlanStep1, gaLabels.cancel);
}

export function paymentPlanFinishedGAEventHandler(totalAmount) {
  triggerGAEvent(
    gaCategory,
    gaActions.paymentPlanCreated,
    `Finished $${currencyFormat(totalAmount * 100)}`
  );
}

//Generic GA functions

export function setGADimensions(practiceName, practiceId) {
  ReactGA.set({ dimension1: practiceName, dimension2: practiceId });
}

function triggerGAEvent(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}
