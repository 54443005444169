import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const IdShortener = ({ id, styles = {} }) => {
  const displayId = id ? id.split('-')[0] : '';
  return (
    <OverlayTrigger
      className='id-shortener'
      trigger='click'
      key={id}
      placement='bottom'
      overlay={
        <Popover id={`popover-${id}`}>
          <Popover.Content>
            <b>{id}</b>
          </Popover.Content>
        </Popover>
      }
    >
      <span data-value={id} style={{ cursor: 'pointer', ...styles }}>
        {displayId}
      </span>
    </OverlayTrigger>
  );
};

IdShortener.propTypes = {
  id: PropTypes.string,
};

export default IdShortener;
