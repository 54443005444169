import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import LogRocket from 'logrocket';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './utils/store/storeConfig';
import { Provider } from 'react-redux';
import Env from 'Env';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as mixpanel from 'mixpanel-browser';

if (Env.logRocketEnabled) {
  LogRocket.init(Env.logRocketAppId);
}

if (Env.sentryEnabled) {
  Sentry.init({
    dsn: Env.sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
    environment: Env.environment,
  });
}

if (Env.mixpanelEnabled) {
  mixpanel.init(Env.mixpanelToken);
}

ReactGA.initialize(Env.googleAnalyticsId);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
