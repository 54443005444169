import React from 'react';
import Env from 'Env';
import PropTypes from 'prop-types';

import useToggle from 'utils/hooks/useToggle';
import { getPhoneDigitsOnly } from 'utils/helpers/phoneNumberValidate';
import transactionStatuses from 'constants/TransactionStatus';

import TransactionInfoItem from './TransactionInfoItem';
import TransactionRefundHistory from 'components/Common/TransactionRefundHistory/TransactionRefundHistory';
import TransactionVoidHistory from 'components/Common/TransactionVoidHistory/TransactionVoidHistory';
import TransactionSanityCheck from 'components/Common/SanityChecks/TransactionSanityCheck';
import TransactionMessageHistoryModal from 'components/Layout/Content/TransactionDetail/TransactionMessageHistoryModal';
import TransactionPaymentAttemptsModal from 'components/Layout/Content/TransactionDetail/TransactionPaymentAttemptsModal';
import ResendPaymentRequestModal from './ResendPaymentRequestModal';
import PaymentMethod from 'constants/PaymentMethod';
import TransactionOrigin from 'constants/TransactionOrigin';

const TransactionInfo = ({
  transaction,
  onCancelLink,
  isSaving,
  onResendPaymentLink,
  resendingTransaction,
}) => {
  const [showMessageHistoryModal, toggleMessageHistoryModal] = useToggle();
  const [showPaymentAttemptsModal, togglePaymentAttemptsModal] = useToggle();
  const [showResendModal, toggleShowResendModal] = useToggle();

  const paymentLinkCompletedDate = transaction?.paymentLinkCompletedDate ?? '';
  const isVpPaymentLink =
    transaction.isPaymentLink &&
    transaction.origin !== TransactionOrigin.Pharma;

  /**
   * @param {string} to email or phone
   */
  const handleResendPaymentRequest = (to) => {
    const args = {
      transactionId: transaction.transactionId,
    };

    if (transaction.charge.method === PaymentMethod.EmailLink) {
      args.petOwnerEmail = to;
    } else {
      args.petOwnerPhone = `+1${getPhoneDigitsOnly(to)}`;
    }

    onResendPaymentLink(args, toggleShowResendModal);
  };

  return (
    <div className='row info'>
      <div className='col-md-9 col-sm-12'>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.cleanInvoiceId ?? '-'}
            type='string'
            title='DESCRIPTION'
            colStyle='col-md-3'
            hasPopover={true}
          />
          <TransactionInfoItem
            data={transaction.transactionId}
            type='transactionId'
            title='TRANSACTION'
            colStyle='col-md-3'
          />
          <TransactionInfoItem
            data={transaction.time}
            type='date'
            title='CREATED DATE'
            colStyle='col-md-3'
          />
          {transaction.isPaymentLink &&
            (paymentLinkCompletedDate === '' ? (
              <TransactionInfoItem
                data={'-'}
                type='string'
                title='COMPLETED DATE'
                colStyle='col-md-3'
              />
            ) : (
              <TransactionInfoItem
                data={paymentLinkCompletedDate}
                type='date'
                title='COMPLETED DATE'
                colStyle='col-md-3'
              />
            ))}
          {Env.sanityChecksEnabled && (
            <TransactionSanityCheck transaction={transaction} />
          )}
        </div>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.cleanPet_parent_email ?? '-'}
            type='string'
            title='EMAIL'
            colStyle='col-md-3'
          />
          <TransactionInfoItem
            data={
              transaction.pet_parent_card_digits
                ? `*${transaction.pet_parent_card_digits}`
                : '-'
            }
            type='string'
            title='CARD #'
            colStyle='col-md-3'
          />
          <TransactionInfoItem
            data={
              transaction.pet_parent_card_type
                ? transaction.pet_parent_card_type
                : '-'
            }
            type='string'
            title='TYPE'
            colStyle='col-md-3'
          />
          <TransactionInfoItem
            data={transaction.staff_member ? transaction.staff_member : 'NA'}
            type='string'
            title='STAFF MEMBER'
            colStyle='col-md-3'
          />
        </div>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.cleanPet_parent_name ?? '-'}
            type='string'
            title='PET OWNER NAME'
            colStyle='col-md-3'
          />
          <TransactionInfoItem
            data={transaction.cleanPet_parent_phone ?? '-'}
            type='string'
            title='PET OWNER PHONE NUMBER'
            colStyle='col-md-3'
            classes={transaction.hasPet_parent_phone ? '' : 'd-none'}
          />
          {transaction.originalTransactionId && (
            <TransactionInfoItem
              data={transaction.originalTransactionId}
              type='transactionId'
              title='ORIGINAL TRANSACTION'
              colStyle='col-md-3'
            />
          )}
          <TransactionInfoItem
            data={
              transaction.method === PaymentMethod.TextLink &&
              (transaction.messageHistory?.fromPhone ?? '-')
            }
            type='string'
            title='PRACTICE TEXT NUMBER'
            colStyle='col-md-3'
            classes={
              transaction.method === PaymentMethod.TextLink &&
              transaction.messageHistory?.messages?.length > 0
                ? ''
                : 'd-none'
            }
          />
        </div>
        {transaction.isInstallments && (
          <>
            <hr style={{ borderTop: '1px solid #ff8f32' }} />
            <b>Monthly Payment Details</b>
            <div className='mt-3 row'>
              <TransactionInfoItem
                data={transaction.charge?.card?.holderFullName ?? 'NA'}
                type='string'
                title='CARD HOLDER NAME'
                colStyle='col-md-4'
              />
              <TransactionInfoItem
                data={transaction.charge?.id ?? ''}
                type='string'
                title='Monthly Payments Plan Number'
                colStyle='col-md-4'
              />
            </div>
          </>
        )}
        <div
          className={`row ${
            transaction.refunds && transaction.refunds.length > 0
              ? 'mt-5'
              : 'mt-3'
          }`}
        >
          {(transaction.isMerchant || transaction.isPaymentLink) &&
            transaction.refunds &&
            transaction.refunds.length > 0 && (
              <div className='col-md-12'>
                <TransactionRefundHistory transaction={transaction} />
              </div>
            )}
        </div>
        {transaction.status === transactionStatuses.voided && (
          <div className='mt-5 row'>
            <div className='col-md-12'>
              <TransactionVoidHistory transaction={transaction} />
            </div>
          </div>
        )}
      </div>
      <div className='col-md-3 col-sm-12'>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.type}
            boldStyled={true}
            title='TRANSACTION TYPE'
            type='string'
            colStyle='col-md-12'
          />
        </div>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.method}
            boldStyled={true}
            title='METHOD'
            type='string'
            colStyle='col-md-12'
          />
        </div>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.transaction_status ?? 'NA'}
            boldStyled={true}
            title='STATUS'
            type='string'
            colStyle='col-md-12'
          />
        </div>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.amount}
            boldStyled={true}
            title={
              transaction.isInstallments ? 'ORIGINAL AMOUNT' : 'AMOUNT CHARGED'
            }
            type='currency'
            colStyle='col-md-12'
          />
        </div>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={transaction.fee_amount}
            boldStyled={true}
            title='FEES'
            type='currency'
            colStyle='col-md-12'
          />
        </div>
        <div className='mt-3 row'>
          {transaction.status === transactionStatuses.voided ? (
            <TransactionInfoItem
              data={transaction.amount}
              boldStyled={true}
              title='AMOUNT VOIDED'
              type='currency'
              colStyle='col-md-12'
              formatStripe={
                transaction.amount
                  ? transaction.amount % 1 === 0
                    ? true
                    : false
                  : false
              }
            />
          ) : (
            <TransactionInfoItem
              data={transaction.amountRefunded ? transaction.amountRefunded : 0}
              boldStyled={true}
              title='AMOUNT REFUNDED'
              type='currency'
              colStyle='col-md-12'
              formatStripe={
                transaction.amountRefunded
                  ? transaction.amountRefunded % 1 === 0
                    ? true
                    : false
                  : false
              }
            />
          )}
        </div>
        <div className='mt-3 row'>
          <TransactionInfoItem
            data={
              transaction.net && (transaction.net > 0 || transaction.isRefund)
                ? transaction.net
                : 0
            }
            boldStyled={true}
            title='NET'
            type='currency'
            colStyle='col-md-12'
          />
        </div>
        {transaction.isPaymentLink &&
          transaction.messageHistory?.messages?.length > 0 && (
            <>
              <div className='px-3 mt-3 row'>
                <button
                  style={{ width: '100%' }}
                  className='btn btn-outline-primary'
                  onClick={toggleMessageHistoryModal}
                >
                  {'MESSAGE HISTORY'}
                </button>
              </div>
              <TransactionMessageHistoryModal
                show={showMessageHistoryModal}
                onClose={toggleMessageHistoryModal}
                messageHistory={transaction.messageHistory}
              />
            </>
          )}
        {transaction.messageHistory?.failures?.length > 0 && (
          <>
            <div className='px-3 mt-3 row'>
              <button
                style={{ width: '100%' }}
                className='btn btn-outline-primary'
                onClick={togglePaymentAttemptsModal}
              >
                {'PAYMENT ATTEMPTS'}
              </button>
            </div>
            <TransactionPaymentAttemptsModal
              show={showPaymentAttemptsModal}
              onClose={togglePaymentAttemptsModal}
              paymentAttempts={transaction.messageHistory.failures}
            />
          </>
        )}
        {isVpPaymentLink &&
          (transaction.status === transactionStatuses.linkSent ||
            transaction.status === transactionStatuses.sendingLink ||
            transaction.status === transactionStatuses.expired) && (
            <div className='px-3 mt-3 row'>
              <button
                style={{ width: '100%' }}
                className='btn btn-primary'
                onClick={onCancelLink}
                disabled={isSaving}
              >
                {isSaving ? 'Canceling...' : 'CANCEL REQUEST'}
              </button>
            </div>
          )}
        {isVpPaymentLink &&
          (transaction.status === transactionStatuses.linkSent ||
            transaction.status === transactionStatuses.sendingLink ||
            transaction.status === transactionStatuses.errorSendingLink ||
            transaction.status === transactionStatuses.expired) && (
            <>
              <div className='px-3 mt-3 row'>
                <button
                  style={{ width: '100%' }}
                  className='btn btn-primary'
                  onClick={toggleShowResendModal}
                >
                  RESEND REQUEST
                </button>
              </div>
              <ResendPaymentRequestModal
                show={showResendModal}
                toggleModal={toggleShowResendModal}
                resendRequest={handleResendPaymentRequest}
                processing={resendingTransaction}
                paymentMethod={transaction.charge.method}
              />
            </>
          )}
      </div>
    </div>
  );
};

TransactionInfo.propTypes = {
  transaction: PropTypes.object.isRequired,
  onCancelLink: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default TransactionInfo;
