import {
  handleResponse,
  handleError,
  axiosAuthInstance as axiosAuthServices,
} from './index';

export function getPrivacyPolicy() {
  return axiosAuthServices
    .get('/services/privacy_policy', {})
    .then(handleResponse)
    .catch(handleError);
}
