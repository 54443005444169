import { getFirstName, getLastName } from 'utils/helpers/names';
import { createNewCustomer, updatePaymentMethod } from './customerApi';
import {
  axiosRectangleInstance,
  axiosRectanglePublicInstance,
  handleResponse,
  handleError,
} from './index';
import { v4 as uuid } from 'uuid';

export async function cancelPaymentLink(transactionId) {
  const response = await axiosRectangleInstance
    .post('/payment-link/cancel', {
      transactionId,
    })
    .catch(handleError);

  return handleResponse(response);
}

export async function createDraftTransaction(reqBody) {
  const response = await axiosRectangleInstance
    .post('/draft', reqBody)
    .catch(handleError);

  return handleResponse(response);
}

export async function createPaymentLink(reqBody) {
  const response = await axiosRectangleInstance
    .post('/payment-link', reqBody)
    .catch(handleError);

  return handleResponse(response);
}

export async function makePayment(reqBody) {
  const response = await axiosRectanglePublicInstance
    .post('/payment', reqBody)
    .catch(handleError);

  return handleResponse(response);
}

export async function makeWalletPayment(reqBody) {
  const response = await axiosRectanglePublicInstance
    .post('/wallet/payment', reqBody)
    .catch(handleError);

  return handleResponse(response);
}

export async function processRefund(transactId, refundAmount, refundReason) {
  const body = {
    transactionId: transactId,
    amount: parseFloat(refundAmount),
    reason: refundReason,
  };
  const response = await axiosRectangleInstance
    .post('/refund', body)
    .catch(handleError);

  return handleResponse(response);
}

export async function resendPaymentLink(reqBody) {
  const response = await axiosRectangleInstance
    .post('/payment-link/resend', reqBody)
    .catch(handleError);

  return handleResponse(response);
}

export async function processVoid(transactId, voidReason) {
  const body = {
    transactionId: transactId,
    reason: voidReason,
  };
  const response = await axiosRectangleInstance
    .post('/void', body)
    .catch(handleError);

  return handleResponse(response);
}

export async function createWallet(name, token, customerId, practiceId) {
  const body = {
    name: name,
    token: token,
    customerId: customerId,
    practiceId,
  };
  const response = await axiosRectangleInstance
    .post('/wallet', body)
    .catch(handleError);

  return handleResponse(response);
}

export async function updateWallet(name, token, customerId, practiceId) {
  const body = {
    name: name,
    token: token,
    practiceId,
  };
  const response = await axiosRectangleInstance
    .put('/wallet/' + customerId, body)
    .catch(handleError);

  return handleResponse(response);
}

export async function deactivateWallet(customerId, practiceId) {
  try {
    const body = {
      practiceId,
    };
    const response = await axiosRectangleInstance.post(
      '/wallet/' + customerId + '/deactivate',
      body
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function saveCardRectangle(
  token,
  petOwnerName,
  customerId,
  paymentMethodId,
  phoneNumber,
  practiceId
) {
  if (customerId != null) {
    const walletResponse = await updateWallet(
      petOwnerName,
      token,
      customerId,
      practiceId
    );
    if (!walletResponse.walletId) throw new Error('Wallet Id Missing');
    const paymentMethodData = {
      full_name: petOwnerName,
      payment_method_token: walletResponse.walletId,
      fingerprint: '',
      card_type: walletResponse.cardType,
      last_four: walletResponse.lastFour,
      expiration_month: walletResponse.expirationDate.slice(0, 2),
      expiration_year: walletResponse.expirationDate.slice(-2),
    };
    await updatePaymentMethod(paymentMethodData, customerId, paymentMethodId);
    return customerId;
  } else {
    const newCustomerId = uuid();
    const walletResponse = await createWallet(
      petOwnerName,
      token,
      newCustomerId,
      practiceId
    );
    if (!walletResponse.walletId) throw new Error('Wallet Id Missing');
    const insertRecord = {
      customerData: {
        customer_id: newCustomerId,
        first_name: getFirstName(petOwnerName),
        last_name: getLastName(petOwnerName),
        phone: phoneNumber,
      },
      paymentMethodData: {
        full_name: petOwnerName,
        payment_method_token: walletResponse.walletId,
        fingerprint: '',
        card_type: walletResponse.cardType,
        last_four: walletResponse.lastFour,
        expiration_month: walletResponse.expirationDate.slice(0, 2),
        expiration_year: walletResponse.expirationDate.slice(-2),
      },
    };
    await createNewCustomer(insertRecord);
    return newCustomerId;
  }
}

export async function makePaymentTerminal(reqBody) {
  const response = await axiosRectangleInstance
    .post('/terminal/payment', reqBody)
    .catch(handleError);

  return handleResponse(response);
}
