import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isEqual } from 'lodash';

import DailyTransactionsHeader from './DailyTransactionsHeader';
import * as transactionActions from 'utils/store/actions/transactionsActions';
import dataFilter from 'utils/helpers/dataFilter';
import DailyTransactionsList from './DailyTransactionsList';
import { Switch, Route } from 'react-router-dom';
import Transaction from '../TransactionDetail/Transaction';

export class DailyTransactions extends Component {
  state = {
    isLoading: false,
    filteredTransactions: [],
    searchValue: '',
  };

  onSearch = (value) => {
    const results = dataFilter(
      Object.values(this.props.dailyTransactions),
      value
    );
    this.setState({ filteredTransactions: results, searchValue: value });
  };

  fetchTransactions = () => {
    this.setState({ isLoading: true }, () => {
      this.props
        .getDailyTransactions(moment())
        .then(() =>
          this.setState({
            isLoading: false,
            filteredTransactions: dataFilter(
              Object.values(this.props.dailyTransactions),
              this.state.searchValue
            ),
          })
        )
        .catch(() => {
          this.setState({ isLoading: false });
          toast.error('Unable to get daily transactions');
        });
    });
  };

  componentDidMount() {
    this.fetchTransactions();
  }

  componentDidUpdate(prevProps) {
    const { mostRecentTransactionFromWebSocket } = this.props;

    const shouldUpdateTransactions = !isEqual(
      prevProps.mostRecentTransactionFromWebSocket,
      mostRecentTransactionFromWebSocket
    );

    if (shouldUpdateTransactions) {
      this.fetchTransactions();
    }
  }

  render() {
    return (
      <div id='DailyTransactions' className='daily-transactions content'>
        <DailyTransactionsHeader title={'Today'} onSearch={this.onSearch} />
        <Switch>
          <Route
            path='/app/daily-transactions'
            exact
            render={() => (
              <DailyTransactionsList
                transactions={this.state.filteredTransactions}
                isLoading={this.state.isLoading}
                onRefresh={this.fetchTransactions}
              />
            )}
          />
          <Route
            path='/app/daily-transactions/:transactionId'
            exact
            render={() => {
              return (
                <Transaction refreshDailyTransaction={this.fetchTransactions} />
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dailyTransactions: state.dailyTransactions,
  mostRecentTransactionFromWebSocket: state.mostRecentTransactionFromWebSocket,
});

export default connect(mapStateToProps, { ...transactionActions })(
  DailyTransactions
);
