import initialState from './initialState';
import { StoreActions } from '../actions';

const loggedPracticeReducer = (state = initialState.loggedPractice, action) => {
  switch (action.type) {
    case StoreActions.GET_LOGGED_PRACTICE_INFO:
      return action.practiceInfo;
    case StoreActions.SET_PRACTICE_PAYMENT_SUCCESS:
      return state;
    case StoreActions.LOGOUT:
      return initialState.loggedPractice;
    default:
      return state;
  }
};

export default loggedPracticeReducer;
