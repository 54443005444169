// https://github.com/PayFabric/APIs/blob/76a57e1e047a4234fe1e5871805c97024fa4531e/Receivables/Sections/Objects/Transaction.md#transaction
export const PayFabricStatuses = Object.freeze({
  Approved: 'Approved',
  AVSFailure: 'AVSFailure',
  Declined: 'Declined',
  Denied: 'Denied',
  Failure: 'Failure',
  MoreInfo: 'More Info',
});

// https://github.com/PayFabric/APIs/blob/b72fc16c93549b0848655774f213683b9395cf3d/PayFabric/Sections/3.1JSONObjects.md#custom-report-additional-fields
export const PayFabricTransactionStates = Object.freeze({
  Settled: 'Settled',
  PendingSettlement: 'Pending Settlement',
  Captured: 'Captured',
  Voided: 'Voided',
  Returned: 'Returned',
  PendingCapture: 'Pending Capture',
});

export const DeviceTypes = {
  CardNotPresent: {
    id: 'defaultDeviceId',
    password: 'payFabricDevicePassword',
    setupId: 'defaultSetupId',
  },
  SavedCard: {
    id: 'savedCardDeviceId',
    password: 'payFabricSavedCardDevicePassword',
    setupId: 'savedCardSetupId',
  },
  Terminal: {
    id: 'terminalDeviceId',
    password: 'payFabricTerminalDevicePassword',
    setupId: 'terminalSetupId',
  },
};
