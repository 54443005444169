import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import TransactionMessage from 'components/Layout/Content/TransactionDetail/TransactionMessage';

const TransactionMessageHistoryModal = ({ show, onClose, messageHistory }) => {
  return (
    <Modal
      id='TransactionMessageHistory'
      size='lg'
      show={show}
      onHide={onClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='text-center'>
        <h2>Message History</h2>
      </Modal.Header>
      <Modal.Body>
        {messageHistory?.messages?.map((m, index) => (
          <TransactionMessage message={m} key={index} />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={() => onClose()}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TransactionMessageHistoryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  messageHistory: PropTypes.object.isRequired,
};

export default TransactionMessageHistoryModal;
