// Modules
import React, { useState } from 'react';
import {
  paymentOptionsRegisteredFields,
  paxDeviceRegisteredFields,
  updateRegisteredFields,
  getValuesForBasicDetails,
} from './practiceViewFunctions';

// Styles
import styles from './PracticeView.module.scss';

// Components
import { Card } from 'react-bootstrap';
import PracticeDataView from './PracticeDataView';
import PracticeEditView from './PracticeEditView';

/**
 * ==================
 * Exported component
 * ==================
 */
function PracticeView(props) {
  const { practice, basicDetails } = props;

  const [editMode, setEditMode] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const [basicDetailsWithVals, setBasicDetailsWithVals] = useState(
    getValuesForBasicDetails(practice, basicDetails)
  );

  const [
    paymentOptionsUpdatedRegisteredFields,
    setPaymentOptionsUpdatedRegisteredFields,
  ] = useState(
    updateRegisteredFields(practice, paymentOptionsRegisteredFields)
  );

  const [
    paxDeviceUpdatedRegisteredFields,
    setPaxDeviceUpdatedRegisteredFields,
  ] = useState(updateRegisteredFields(practice, paxDeviceRegisteredFields));

  const updatePracticeFields = (newPractice) => {
    setBasicDetailsWithVals(
      getValuesForBasicDetails(newPractice, basicDetails)
    );

    setPaymentOptionsUpdatedRegisteredFields(
      updateRegisteredFields(newPractice, paymentOptionsRegisteredFields)
    );

    setPaxDeviceUpdatedRegisteredFields(
      updateRegisteredFields(newPractice, paxDeviceRegisteredFields)
    );
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const toggleMoreDetails = () => {
    setShowMoreDetails((prev) => !prev);
  };

  const updateSuccess = () => {
    toggleEditMode();
    setShowMoreDetails(true);
  };

  return (
    <Card
      className={`mb-2 + ${styles.card} ${
        showMoreDetails || editMode ? styles.expanded : ''
      }`}
    >
      <Card.Body>
        {editMode ? (
          <PracticeEditView
            practice={practice}
            basicDetailsWithVals={basicDetailsWithVals}
            paymentOptionsRegisteredFields={
              paymentOptionsUpdatedRegisteredFields
            }
            paxDeviceRegisteredFields={paxDeviceUpdatedRegisteredFields}
            updatePracticeFields={updatePracticeFields}
            toggleEditMode={toggleEditMode}
            updateSuccess={updateSuccess}
          />
        ) : (
          <PracticeDataView
            practice={practice}
            basicDetailsWithVals={basicDetailsWithVals}
            paymentOptionsRegisteredFields={
              paymentOptionsUpdatedRegisteredFields
            }
            paxDeviceRegisteredFields={paxDeviceUpdatedRegisteredFields}
            toggleEditMode={toggleEditMode}
            showMoreDetails={showMoreDetails}
            toggleMoreDetails={toggleMoreDetails}
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default PracticeView;
