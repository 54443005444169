class UserBehavior {
  static EditModes = {
    Full: 'Full',
    NameAndEmail: 'NameAndEmail',
    None: 'None',
  };

  static editModeFor(actor, user) {
    if (actor.roles.practiceAdmin) {
      if (user.role.protectedAccount) {
        if (actor.id === user.id) {
          return this.EditModes.NameAndEmail;
        } else {
          return this.EditModes.None;
        }
      } else {
        if (!user.role.teamAdministrator && !user.role.supportAgent) {
          return this.EditModes.Full;
        }
      }
    }
    if (actor.roles.teamAdministrator || actor.roles.supportAgent) {
      if (user.role.protectedAccount) {
        return this.EditModes.NameAndEmail;
      } else if (!user.role.teamAdministrator && !user.role.supportAgent) {
        return this.EditModes.Full;
      }
    }
    return this.EditModes.None;
  }

  static canEdit(actor, user) {
    const editMode = this.editModeFor(actor, user);
    switch (editMode) {
      case this.EditModes.Full:
      case this.EditModes.NameAndEmail:
        return true;
      default:
        return false;
    }
  }

  static canDelete(actor, user) {
    if (actor.id === user.id) {
      return false;
    }
    if (
      actor.roles.practiceAdmin &&
      !user.role.protectedAccount &&
      !user.role.teamAdministrator &&
      !user.role.supportAgent
    ) {
      return true;
    }
    return false;
  }

  static canResetPassword(actor, user) {
    if (actor.roles.teamAdministrator || actor.roles.supportAgent) {
      return true;
    }
    if (
      actor.roles.practiceAdmin &&
      !user.role.teamAdministrator &&
      !user.role.supportAgent
    ) {
      return true;
    }
    return false;
  }
}

export default UserBehavior;
