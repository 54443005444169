import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SavePractice = ({
  show,
  handleClose,
  onChange,
  onSave,
  practice,
  isSaving,
  errors,
}) => {
  const [toggleViewPassword, setToggleViewPassword] = useState(false);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <form onSubmit={onSave}>
        <Modal.Header className='text-center'>
          <h2>{practice.id ? 'Edit Practice' : 'Add new Practice'}</h2>
        </Modal.Header>
        <Modal.Body>
          {errors.message && (
            <div className='alert alert-danger mt-1 mb-3'>{errors.message}</div>
          )}
          <div className='form-group row'>
            <div className='col-md-12'>
              <label>Practice name</label>
              <input
                className={`form-control${errors.name ? ' is-invalid' : ''}`}
                type='text'
                name='name'
                placeholder='Enter practice name'
                value={practice.name}
                onChange={onChange}
                required
              />
              <small className='form-text text-muted'>
                This name will be displayed throughout VitusPay mobile app and
                web portal for this practice.
              </small>
              {errors.name && (
                <div className='invalid-feedback'>{errors.name}</div>
              )}
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-6'>
              <label>Username Email</label>
              <input
                className={`form-control${errors.name ? ' is-invalid' : ''}`}
                type='email'
                placeholder='e.g.: email@mydomain.com'
                name='email'
                value={practice.email}
                onChange={onChange}
                required
              />
              <small className='form-text text-muted'>
                Enter a valid email address. It will be used as login username
                across VitusPay systems.
              </small>
              {errors.email && (
                <div className='invalid-feedback'>{errors.email}</div>
              )}
            </div>
            <div className='col-md-6'>
              <label>Receipt Email</label>
              <input
                className={`form-control${errors.name ? ' is-invalid' : ''}`}
                type='email'
                placeholder='e.g.: email@mydomain.com'
                name='contactEmail'
                value={practice.contactEmail}
                onChange={onChange}
              />
              <small className='form-text text-muted'>Optional</small>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12'>
              <label>Practice phone number</label>
              <input
                className={`form-control${errors.phone ? ' is-invalid' : ''}`}
                type='text'
                placeholder='Enter phone number'
                name='phone'
                value={practice.phone}
                onChange={onChange}
                required
              />
              <small className='form-text text-muted'>
                Enter a valid phone number.
              </small>
              {errors.phone && (
                <div className='invalid-feedback'>{errors.phone}</div>
              )}
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-6'>
              <label>Set Practice Password</label>
              <div className='input-group'>
                <input
                  className={`form-control${
                    errors.password ? ' is-invalid' : ''
                  }`}
                  type={toggleViewPassword ? 'text' : 'password'}
                  placeholder='Enter a password'
                  name='password'
                  value={practice.password}
                  onChange={onChange}
                  required
                />
                <div className='input-group-append'>
                  <span
                    className='input-group-text'
                    onClick={() => setToggleViewPassword(!toggleViewPassword)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className='sr-only'>toggle password view</span>
                    <i
                      className='fas fa-eye'
                      aria-hidden='true'
                      style={{ color: toggleViewPassword ? '#ff8f32' : '' }}
                    ></i>
                  </span>
                </div>
              </div>
              <small className='form-text text-muted'>
                Must be at least 8 characters long, and contain at least 1
                lowercase letter.
              </small>
              {errors.password && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  {errors.password}
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <label>Confirm Password</label>
              <input
                className={`form-control${
                  errors.password ? ' is-invalid' : ''
                }`}
                type={toggleViewPassword ? 'text' : 'password'}
                placeholder='Enter a password'
                name='password_confirm'
                value={practice.password_confirm}
                onChange={onChange}
                required
              />
              {errors.password_confirm && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  {errors.password_confirm}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='light' onClick={handleClose}>
            CANCEL
          </Button>
          <Button type='submit' variant='primary' disabled={isSaving}>
            {isSaving ? 'Saving...' : 'SAVE'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

SavePractice.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  practice: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
};

export default SavePractice;
