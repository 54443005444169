import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const SanityCheck = ({ failedSanityTests }) => {
  if (!failedSanityTests || failedSanityTests.length === 0) {
    return null;
  }

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='left'
      overlay={
        <Popover className='popover-basic'>
          <Popover.Content>
            {failedSanityTests.map((failedSanityTest, index) => (
              <div key={index}>{failedSanityTest}</div>
            ))}
          </Popover.Content>
        </Popover>
      }
    >
      <div
        style={{ overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'pointer' }}
        className={'badge badge-danger'}
      >
        !
      </div>
    </OverlayTrigger>
  );
};

SanityCheck.propTypes = {
  failedSanityTests: PropTypes.array,
};

export default SanityCheck;
