import React, { Component } from 'react';
import Routes from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import Env from 'Env';

class App extends Component {
  //This function is used for sending updates to appcues on page changes
  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    const previousLocation = prevProps.location.pathname;

    if (
      Env.appCuesTrackingEnabled &&
      window.Appcues &&
      pathname !== previousLocation
    ) {
      window.Appcues.page();
    }
  }

  render() {
    return (
      <Router>
        <Routes />
      </Router>
    );
  }
}

export default App;
