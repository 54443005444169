import { StoreActions } from '.';
import {
  fetchStaffMembers as fetchStaff,
  addStaffMember,
  editStaffMember,
} from '../../api/staffApi';

function displayStaffMembersSuccess(staffList) {
  return {
    type: StoreActions.GET_STAFF_MEMBERS_SUCCESS,
    staffList: staffList.Items?.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      else return 0;
    }),
  };
}

function addStaffMemberSuccess(newStaffMember) {
  return {
    type: StoreActions.ADD_STAFF_MEMBER_SUCCESS,
    newStaffMember,
  };
}

function editStaffMemberSuccess(updatedStaffMember) {
  return {
    type: StoreActions.UPDATE_STAFF_MEMBER_SUCCESS,
    updatedStaffMember,
  };
}

function deleteStaffMemberSuccess(staffMember) {
  return {
    type: StoreActions.DELETE_STAFF_MEMBER_SUCCESS,
    deletedStaffMember: staffMember,
  };
}

export function setStaffMemberToInactive(staffMember) {
  return {
    type: StoreActions.SET_STAFF_MEMBER_TO_INACTIVE,
    staffMember: { ...staffMember, active: false },
  };
}

export function toggleAddEditStaffMemberAction(value) {
  return {
    type: StoreActions.TOGGLE_ADD_EDIT_STAFF_MEMBER,
    value,
  };
}

export function toggleDeleteStaffMemberAction(value) {
  return {
    type: StoreActions.TOGGLE_DELETE_STAFF_MEMBER,
    value,
  };
}

export function setSelectedStaffMember(staffMember) {
  return {
    type: StoreActions.SET_SELECTED_STAFF_MEMBER,
    staffMember,
  };
}

export function unsetSelectedStaffMember() {
  return {
    type: StoreActions.UNSET_SELECTED_STAFF_MEMBER,
  };
}

export function fetchStaffMembers(practiceId) {
  return function (dispatch) {
    return fetchStaff(practiceId)
      .then((staffList) => {
        dispatch(displayStaffMembersSuccess(staffList));
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function deleteStaffMember(staffMemberId) {
  return function (dispatch) {
    let staffMember = {
      id: staffMemberId,
      active: false,
    };
    return editStaffMember(staffMember)
      .then(() => dispatch(deleteStaffMemberSuccess(staffMember)))
      .catch((err) => {
        throw err;
      });
  };
}

export function saveStaffMember(staffMember, staffType, practiceId = null) {
  return function (dispatch) {
    if (staffMember.id) {
      const payload =
        staffType === 'users'
          ? {
              id: staffMember.id,
              name: staffMember.name,
              lastName: staffMember.lastName,
              email: staffMember.email,
              role: staffMember.role,
              active: staffMember.active,
              enableLogin: staffMember.active,
            }
          : {
              id: staffMember.id,
              name: staffMember.name,
              lastName: staffMember.lastName,
              active: staffMember.active,
            };

      return editStaffMember(payload)
        .then(() => {
          dispatch(editStaffMemberSuccess(staffMember));
          fetchStaff(practiceId)
            .then((staffList) => {
              dispatch(displayStaffMembersSuccess(staffList));
            })
            .catch((err) => {
              throw err;
            });
        })
        .catch((err) => {
          throw err;
        });
    } else {
      const payload =
        staffType === 'users'
          ? {
              name: staffMember.name,
              lastName: staffMember.lastName,
              email: staffMember.email,
              password: staffMember.password,
              role: staffMember.role,
            }
          : {
              name: staffMember.name,
              lastName: staffMember.lastName,
              role: { ...staffMember.role, practiceStaff: true },
            };

      return addStaffMember(payload)
        .then((addedMember) => {
          dispatch(addStaffMemberSuccess(addedMember));
        })
        .catch((err) => {
          throw err;
        });
    }
  };
}
