import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from './../../assets/images/app-logo.png';
import pb from './../../assets/images/poweredBy.jpg';
import * as authActions from './../../utils/store/actions/authActions';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      usernameError: null,
      passwordError: null,
      authErrors: {},
      isLoggingIn: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    const credentials = { username, password };
    const { sendUserCredentials, history, location } = this.props;
    const parsedQueryString = queryString.parse(location.query);

    this.setState({ isLoggingIn: true });

    sendUserCredentials(credentials)
      .then((res) => {
        if (res.ChallengeName) {
          history.push('/change-pass');
        } else if (parsedQueryString.redirectUri) {
          history.push(parsedQueryString.redirectUri);
        } else {
          history.push('/app');
        }
      })
      .catch((err) => {
        let newState = {
          isLoggingIn: false,
          authErrors: err,
          username: '',
          password: '',
        };
        if (err.status === 403) {
          newState.authErrors.message =
            'The user doesn\'t have the "Login to Portal" permission';
        }
        this.setState(newState);
        document.querySelector('input[name="username"]').focus();
      });
  };

  handleChange = (event) => {
    let stateObj = { ...this.state };
    if (event.target.value) {
      stateObj[event.target.name] = event.target.value;
      stateObj[`${event.target.name}Error`] = null;
      this.setState(stateObj);
    } else {
      stateObj[`${event.target.name}Error`] = true;
      stateObj[event.target.name] = '';
      this.setState(stateObj);
    }
  };

  componentDidMount() {
    const { getUserCredentials, history } = this.props;
    if (getUserCredentials()) {
      history.push('/app');
    }
  }

  render() {
    return (
      <div className='Login'>
        <div className='FormContainer'>
          <div className='Logo'>
            <img className='CompanyLogo' src={logo} alt='Company Logo' />
          </div>
          <div className='Welcome'>
            <h1>Welcome</h1>
            <h5>Sign in to continue</h5>
          </div>
          <form className='mt-3' onSubmit={this.handleSubmit}>
            {this.state.authErrors.message && (
              <div className='alert alert-danger' role='alert'>
                {this.state.authErrors.message}
              </div>
            )}
            <div className='form-group'>
              <input
                name='username'
                value={this.state.username}
                type='text'
                className={
                  this.state.usernameError
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                placeholder='email'
                onChange={this.handleChange}
              />
              <div
                className={this.state.usernameError ? 'invalid-feedback' : ''}
              >
                {this.state.usernameError}
              </div>
            </div>
            <div className='form-group'>
              <input
                name='password'
                value={this.state.password}
                type='password'
                className={
                  this.state.passwordError
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                placeholder='password'
                onChange={this.handleChange}
              />
              <div
                className={this.state.passwordError ? 'invalid-feedback' : ''}
              >
                {this.state.passwordError}
              </div>
            </div>
            <div className='form-group'>
              <Link to='/forgot-pass' className='FormOpts'>
                Forgot your Password?
              </Link>
            </div>
            <button
              type='submit'
              className='btn btn-primary btn-lf btn-block mt-4'
              disabled={
                !this.state.username ||
                !this.state.password ||
                this.state.isLoggingIn
              }
            >
              {this.state.isLoggingIn ? 'Logging in...' : 'LOGIN'}
            </button>
          </form>
          <img style={{ display: 'none' }} src={pb} alt='Powered by' />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sendUserCredentials: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer,
  };
}

const mapDispatchToProps = {
  ...authActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
