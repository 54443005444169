import React from 'react';
import { Link } from 'react-router-dom';
import logo from './../../../assets/images/app-logo.png';

const SidebarHeader = () => {
  return (
    <div id='SidebarHeader' className='sidebar-header'>
      <Link to='/app'>
        <img src={logo} alt='Company Logo' />
      </Link>
    </div>
  );
};

export default SidebarHeader;
