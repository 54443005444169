export const currencyFormat = (value) => {
  return !Number.isNaN(+value) && +value !== 0
    ? parseFloat(value / 100).toFixed(2)
    : parseFloat(0).toFixed(2);
};

export default currencyFormat;

export function cents(val, config = {}) {
  const { resultType = 'string' } = config;
  const nums = val
    .toString()
    .replace(/[^\d.]/g, '')
    .split('.');
  let result;

  if (nums.length === 1 || nums[1] === '') {
    result = nums[0] + '00';
  } else if (nums[1].length === 1) {
    result = nums.join('') + '0';
  } else {
    result = nums.join('');
  }

  if (resultType === 'string') return result;

  if (resultType === 'number') return +result;
}
