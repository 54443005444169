// Modules
import React, { useEffect, useState } from 'react';

// Api
import { getCustomers } from 'utils/api/customerApi';

// Utils
import { mapToTypeaheadOptions, useDebounce } from '@vitusvet/react-library';
import { formatPhoneNumber } from 'react-phone-number-input';

// Components
import { Form, InputGroup } from 'react-bootstrap';
import PetOwnerNameTypeahead from './CustomInputFields/PetOwnerNameTypeahead';
import DescriptionInput from './CustomInputFields/DescriptionInput';
import ServiceTypeSelect from './CustomInputFields/ServiceTypeSelect';
import Loading from 'components/Common/Loading';
import PhoneInput from 'react-phone-number-input/input';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import * as staffMemberActions from 'utils/store/actions/staffMemberActions';

// For searching customers
const debounceInterval = 500;
const minSearchChars = 3;
const maximumFetchedCustomers = 20;

//first step
const PaymentInfoStep = (props) => {
  const {
    staffMember,
    setStaffMember,
    setStaffMemberName,
    petOwnerName,
    setPetOwnerName,
    setSelectedPetOwner,
    selectedPetOwner,
    phoneNumber,
    setPhoneNumber,
    isFormLoading,
    setFormLoading,
    validationErrors,
    description,
    setDescription,
    serviceType,
    setServiceType,
    totalAmount,
    setTotalAmount,
    enablePhoneNumber,
  } = props;

  const dispatch = useDispatch();
  const staffMembers = useSelector((state) =>
    state.staff.filter((user) => user.active && user.role.practiceStaff)
  );

  const payWithSavedCardEnabled = useSelector(
    (s) => s.loggedPractice.payWithSavedCards
  );

  const [petOwners, setPetOwners] = useState([]);
  const [fetchingPetOwners, setFetchingPetOwners] = useState(false);

  const debouncedPetOwnerName = useDebounce(petOwnerName, debounceInterval);

  useEffect(() => {
    // disable Typeahead search
    if (!payWithSavedCardEnabled) return;

    const searchName = debouncedPetOwnerName;

    let didCancel = false;
    async function fetchPetOwners() {
      let params = {
        size: maximumFetchedCustomers,
        page: 0,
        search: searchName,
      };

      const { data } = await getCustomers(params);
      const { rows: customers } = data;

      // compose fields for use in typeahead
      const prepCustomers = customers?.map((owner) => {
        const phone = owner.phone ? ` - ${formatPhoneNumber(owner.phone)}` : '';

        return {
          namePhone: `${owner.full_name}${phone}`,
          ...owner,
        };
      });

      if (!didCancel) {
        const options = mapToTypeaheadOptions(
          prepCustomers,
          'namePhone',
          'full_name'
        );
        setPetOwners(options);
        setFetchingPetOwners(false);
      }
    }

    if (searchName.length >= minSearchChars && !selectedPetOwner) {
      fetchPetOwners();
    }

    return () => {
      didCancel = true;
    };
  }, [debouncedPetOwnerName, selectedPetOwner, payWithSavedCardEnabled]);

  useEffect(() => {
    async function getStaffMembers() {
      setFormLoading(true);
      try {
        await staffMemberActions.fetchStaffMembers()(dispatch);
      } catch (err) {
        console.log(err);
      }
      setFormLoading(false);
    }

    getStaffMembers();
  }, [dispatch, setFormLoading]);

  const handlePetOwnerNameChange = (e) => {
    const { value } = e.target;

    // do nothing if does not contain only letters
    if (!/^[a-zA-Z ]+$|^$/.test(value)) return;

    if (value.length >= minSearchChars && payWithSavedCardEnabled) {
      setFetchingPetOwners(true);
    } else {
      setFetchingPetOwners(false);
      setPetOwners([]);
    }

    setPetOwnerName(value);
  };

  const handlePetOwnerSelected = (petOwner) => {
    if (petOwner === null) {
      if (setSelectedPetOwner instanceof Function) setSelectedPetOwner(null);

      if (selectedPetOwner !== null) {
        if (setPhoneNumber instanceof Function) setPhoneNumber('');
      }
      return;
    }

    if (setPetOwnerName instanceof Function) setPetOwnerName(petOwner.value);

    if (setPhoneNumber instanceof Function)
      setPhoneNumber(petOwner.phone ?? '');

    if (setSelectedPetOwner instanceof Function) setSelectedPetOwner(petOwner);
  };

  return isFormLoading ? (
    <Loading></Loading>
  ) : (
    <Form autoComplete='off'>
      <Form.Group controlId='staffMember'>
        <Form.Label>Staff Member</Form.Label>
        <Form.Control
          as='select'
          value={staffMember}
          name='staffMember'
          isInvalid={!!validationErrors['staffMember']}
          onChange={(event) => {
            if (setStaffMemberName) {
              for (const staff of staffMembers) {
                if (event.target.value === staff.id) {
                  setStaffMemberName(staff.name);
                  break;
                }
              }
            }

            setStaffMember(event.target.value);
          }}
        >
          <option>Choose a staff member</option>
          {staffMembers.map((option, index) => {
            return (
              <option
                key={index}
                value={option.id}
              >{`${option.name} ${option.lastName}`}</option>
            );
          })}
        </Form.Control>
        <Form.Control.Feedback type='invalid'>
          {validationErrors['staffMember']}
        </Form.Control.Feedback>
      </Form.Group>
      <PetOwnerNameTypeahead
        petOwnerName={petOwnerName}
        validationError={validationErrors['petOwnerName']}
        // typeahead props:
        isLoading={fetchingPetOwners}
        options={petOwners}
        onChange={handlePetOwnerNameChange}
        selectedItem={selectedPetOwner}
        onItemSelected={handlePetOwnerSelected}
      />
      {/* TODO: enable phone number for all modals */}
      {enablePhoneNumber && payWithSavedCardEnabled && (
        <Form.Group controlId='phoneNumber'>
          <Form.Label>Phone Number</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>+1</InputGroup.Text>
            </InputGroup.Prepend>
            <PhoneInput
              name='phoneNumber'
              type='text' // for bootstrap styling purposes
              country='US'
              placeholder='(xxx) xxx-xxxx'
              className={[
                'form-control',
                !!validationErrors['phoneNumber'] ? 'is-invalid' : '',
              ].join(' ')}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
            <Form.Control.Feedback type='invalid'>
              {validationErrors['phoneNumber']}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      )}
      <DescriptionInput
        description={description}
        setDescription={setDescription}
        validationError={validationErrors['description']}
      />
      {serviceType && (
        <ServiceTypeSelect
          serviceType={serviceType}
          setServiceType={setServiceType}
          validationError={validationErrors['serviceType']}
        />
      )}
      <Form.Group controlId='totalAmount'>
        <Form.Label>Total Amount</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className='fas fa-dollar-sign'></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type='text'
            name='totalAmount'
            placeholder='Amount'
            isInvalid={!!validationErrors['totalAmount']}
            onChange={(event) => {
              const value = event.target.value.replace(/[^0-9.]+/g, ''); //remove all non-numeric characters
              const number = value.split('.');
              if (number.length > 2 || number[1]?.length > 2) {
                //restrict to 2 decimal places
                return;
              }

              setTotalAmount(value);
            }}
            value={totalAmount}
          />
          <Form.Control.Feedback type='invalid'>
            {validationErrors['totalAmount']}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default PaymentInfoStep;
