// Modules
import React, { useEffect, useState } from 'react';

// Styles
import styles from './CompletedPaymentLinkTransactionsToasts.module.scss';

// Components
import { Toast } from 'react-bootstrap';

const requiredFields = ['id', 'description', 'clientName', 'transactionId'];

const hasRequiredFields = (obj) => requiredFields.every((f) => !!(f in obj));

const validPaths = [
  '/app/daily-transactions',
  '/app/practice-reports/transactions-report',
  '/app/transactions',
];

const redirectPaths = {
  '/app/daily-transactions': 'app/daily-transactions',
  '/app/practice-reports': 'app/practice-reports/transactions-report',
  '/app/transactions': 'app/transactions',
};

const redirectToInvoiceDetails = (transactionId) => {
  const currentPath = window.location.pathname;
  const matchedPath = validPaths.find((path) => currentPath.includes(path));
  if (matchedPath) {
    window.location.href = `${window.location.origin}/${redirectPaths[matchedPath]}/${transactionId}`;
  } else {
    window.location.href = `${window.location.origin}/${redirectPaths['/app/daily-transactions']}/${transactionId}`;
  }
};

// It is possible that two or more Text To Pay transactions
// could be completed at the same time
// So, this component allows for multiple toasts to exist simultaneously
function CompletedPaymentLinkTransactionsToasts(props) {
  const { toastData } = props;

  const [activeToasts, setActiveToasts] = useState([]);

  const dataIsNew = () => !activeToasts.find((t) => t.id === toastData.id);

  const handleToastClose = (id) => {
    setActiveToasts((prev) => prev.filter((t) => t.id !== id));
  };

  useEffect(() => {
    const canAddNewToast = hasRequiredFields(toastData) && dataIsNew();

    if (canAddNewToast) {
      setActiveToasts((prev) => [...prev, toastData]);
    }

    // If we wrap dataIsNew in useCallback and add it to the
    // dependency array (to stop the exhaustive deps warning)
    // it would cause the handleToastClose function would stop working
    // eslint-disable-next-line
  }, [toastData]);

  return (
    <div className={styles.toastContainer}>
      {activeToasts.map((t) => (
        <Toast
          key={t.id}
          className={styles.toast}
          onClose={() => handleToastClose(t.id)}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className={`mr-auto ${styles.toastHeading}`}>
              {t.message}
            </strong>
          </Toast.Header>
          <Toast.Body className={styles.toastBody}>
            <div
              className={styles.redirectButton}
              onClick={() => redirectToInvoiceDetails(t.transactionId)}
            >
              <p>
                Description: <strong>{t.description}</strong>
              </p>
              <p>
                Client Name: <strong>{t.clientName}</strong>
              </p>
              <p>
                Total: <strong>${t.amount}</strong>
              </p>
            </div>
          </Toast.Body>
        </Toast>
      ))}
    </div>
  );
}

export default CompletedPaymentLinkTransactionsToasts;
