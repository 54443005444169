import React from 'react';
import { Form, Button } from 'react-bootstrap';
import PetOwnerNameInput from './CustomInputFields/PetOwnerNameInput';
import DescriptionInput from './CustomInputFields/DescriptionInput';
import PhoneNumberInput from './CustomInputFields/PhoneNumberInput';
import PaymentPlanOptionCheckbox from './CustomInputFields/PaymentPlanOptionCheckbox';
import Loading from 'components/Common/Loading';
import { currencyFormat } from 'utils/helpers/currencyFormat';
import { sendTextToPayRequestGAEventHandler } from './googleAnalyticsHandlers';

const TextToPayStep = (props) => {
  const isInstallmentsHidden =
    Number(props.totalAmount) < Number(props.installmentsMinAmount);

  return props.isFormLoading ? (
    <Loading></Loading>
  ) : (
    <Form autoComplete='off'>
      <p className='text-center'>
        Total Amount
        <br />
        {/* helper function works on cent amounts */}
        <strong>${currencyFormat(props.totalAmount * 100)}</strong>
      </p>
      <PetOwnerNameInput
        petOwnerName={props.petOwnerName}
        setPetOwnerName={props.setPetOwnerName}
        validationError={props.validationErrors['petOwnerName']}
      />
      <PhoneNumberInput
        phoneNumber={props.phoneNumber}
        setPhoneNumber={props.setPhoneNumber}
        validationError={props.validationErrors['phoneNumber']}
      />
      <DescriptionInput
        description={props.description}
        setDescription={props.setDescription}
        validationError={props.validationErrors['description']}
      />
      {props.installmentsEnabled && !isInstallmentsHidden && (
        <PaymentPlanOptionCheckbox
          includePaymentPlanOption={props.includePaymentPlanOption}
          setIncludePaymentPlanOption={props.setIncludePaymentPlanOption}
        />
      )}
      <div className='text-center'>
        <Button
          variant='secondary'
          onClick={() => {
            sendTextToPayRequestGAEventHandler(props.totalAmount);
            props.step2Submit();
          }}
          disabled={false}
        >
          Send Text to Pay Request
        </Button>
      </div>
    </Form>
  );
};

export default TextToPayStep;
