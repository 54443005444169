import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as staffMemberActions from './../../../../utils/store/actions/staffMemberActions';

const EditButton = ({
  id,
  setSelectedStaffMember,
  staffMember,
  staffMembers,
  toggleAddEditStaffMemberAction,
}) => {
  const handleClick = () => {
    setSelectedStaffMember(staffMember);
    toggleAddEditStaffMemberAction(true);
  };

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='top'
      overlay={
        <Popover className='popover-basic'>
          <Popover.Content>Edit</Popover.Content>
        </Popover>
      }
    >
      <button className='btn btn-link' onClick={handleClick}>
        <span className='sr-only'>Edit item</span>
        <i className='fas fa-pencil-alt' aria-hidden='true'></i>
      </button>
    </OverlayTrigger>
  );
};

EditButton.propTypes = {
  id: PropTypes.string.isRequired,
  setSelectedStaffMember: PropTypes.func.isRequired,
  staffMember: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired,
  toggleAddEditStaffMemberAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  staffMember: state.staff.find((s) => s.id === ownProps.id),
  staffMembers: state.staff,
  toggleAddEditStaffMember: state.toggleAddEditStaffMember,
});

const mapDispatchToProps = {
  ...staffMemberActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditButton);
