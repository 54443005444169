import React from 'react';
import DateTime from 'utils/helpers/DateTime';
import Currency from 'components/Common/Currency/Currency';
import LinkedValue from 'components/Common/LinkedValue/LinkedValue';
import TransactionStatus from 'components/Common/Status/TransactionStatus';

function emptyValue(cell) {
  return <>{cell ? cell : '—'}</>;
}

function dateTimeFormatter(cell) {
  return <DateTime datetime={cell} format='MM/DD/YY - h:mma' />;
}

function idFormatter(cell) {
  return (
    <LinkedValue
      redirectTo={`/app/daily-transactions/${cell}`}
      value={cell}
      expandable={false}
    />
  );
}

function currencyFormatter(cell) {
  return <Currency amount={cell} defaultToZero={true} />;
}

function statusFormatter(cell) {
  return <TransactionStatus status={cell} />;
}

export const dailyTransactionColumns = [
  {
    dataField: 'cleanInvoiceId',
    text: 'DESCRIPTION',
    formatter: emptyValue,
  },
  {
    dataField: 'transactionId',
    text: 'TRANSACTION',
    formatter: idFormatter,
  },
  {
    dataField: 'time',
    text: 'DATE/TIME',
    formatter: dateTimeFormatter,
  },
  {
    dataField: 'cleanPet_parent_name',
    text: 'CLIENT NAME',
    formatter: emptyValue,
  },
  {
    dataField: 'net',
    text: 'NET',
    formatter: currencyFormatter,
  },
  {
    dataField: 'transaction_status',
    text: 'STATUS',
    formatter: statusFormatter,
  },
  {
    dataField: 'pet_parent_card_type',
    text: 'CARD TYPE',
    sort: true,
    formatter: emptyValue,
  },
  {
    dataField: 'type',
    text: 'TYPE',
    formatter: emptyValue,
  },
  {
    dataField: 'method',
    text: 'METHOD',
    formatter: emptyValue,
  },
];
