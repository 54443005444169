import { StoreActions } from '.';

export function setInstallmentPlans(plans) {
  const allowedPlans = plans.split(',');
  return {
    type: StoreActions.SET_ALLOWED_PLANS,
    allowedPlans,
  };
}

export function setDefaultMinAmount(defaultMinAmount) {
  return {
    type: StoreActions.SET_DEFAULT_MIN_AMMOUNT,
    defaultMinAmount,
  };
}

export function setConfigFormLocalStorage(installmentsConfig) {
  return {
    type: StoreActions.SET_CONFIG_FROM_LOCALSTORAGE,
    installmentsConfig,
  };
}
