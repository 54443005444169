import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Nav, InputGroup, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { getPracticeInfoForAdmin } from 'utils/store/actions/practiceInfoActions';
import { processNewTransaction } from 'utils/store/actions/transactionDetailActions';

function AddTransaction() {
  const dispatch = useDispatch();
  const { practiceId } = useParams();
  const history = useHistory();
  const selectedPractice = useSelector((s) => s.selectedPractice);
  const selectedTransaction = useSelector((s) => s.selectedTransaction);
  const isAdmin = useSelector((st) => st.authReducer.roles.teamAdministrator);

  // form fields
  const [transactionSource, setTransactionSource] = useState('');
  const [transactionDateTime, setTransactionDateTime] = useState('');
  const [petOwnerName, setPetOwnerName] = useState('');
  const [cardType, setCardType] = useState('');
  const [cardLast4, setCardLast4] = useState('');
  const [amount, setAmount] = useState('');

  // form events and return values
  const [validationErrors, setValidationErrors] = useState({});
  const [showTransactionComplete, setShowTransactionComplete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef();

  const validationRules = [
    {
      field: 'amount',
      value: amount,
      validationMessage: 'Enter an Amount',
    },
    {
      field: 'transactionSource',
      value: transactionSource,
      validationMessage: 'Select a Transaction Source',
    },
    {
      field: 'petOwnerName',
      value: petOwnerName,
      validationMessage: 'Enter a Pet Owner Name',
    },
    {
      field: 'cardLast4',
      value: cardLast4,
      validationMessage: 'Enter the last 4 digits of the card',
    },
    {
      field: 'cardType',
      value: cardType,
      validationMessage: 'Select a Card Type',
    },
    {
      field: 'transactionDateTime',
      value: transactionDateTime,
      validationMessage: 'Select a Transaction Date/Time',
    },
  ];

  useEffect(() => {
    function fetchData() {
      dispatch(getPracticeInfoForAdmin(practiceId));
    }
    fetchData();
  }, [dispatch, practiceId]);

  function handleBackButtonClick(event) {
    if (!isAdmin) return;

    event.preventDefault();
    history.push(`/app/practices/${selectedPractice.id}/transactions`);
  }

  function cardLast4KeyPress(event) {
    const value = event.target.value;
    const maxLength = 4;

    if (value.length >= maxLength) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  function saveClick() {
    if (isSaving) return;
    const validationResult = validate(validationRules);

    if (validationResult.length === 0) {
      setIsSaving(true);
      const transaction = {
        transactionSource,
        transactionDateTime: moment(transactionDateTime).format(),
        amount: amount * 100, //convert to cents for the backend
        petOwnerName,
        cardType,
        cardLast4,
        practice: selectedPractice,
        staffMember: { fullName: 'VitusPay Support' },
      };

      dispatch(processNewTransaction(transaction)).then(() => {
        setIsSaving(false);
        setShowTransactionComplete(true);
      });
    }
  }

  function resetClick() {
    setShowTransactionComplete('');
    setTransactionSource('');
    setTransactionDateTime('');
    setPetOwnerName('');
    setCardType('');
    setCardLast4('');
    setAmount('');
    setShowTransactionComplete(false);
  }

  function validateReducer(field, value) {
    switch (field) {
      case 'transactionDateTime':
        // eslint-disable-next-line eqeqeq
        return new Date(value) != 'Invalid Date' && !isNaN(new Date(value));
      case 'amount':
        return value > 0;
      case 'cardLast4':
        return value?.length === 4;
      case 'cardType':
        return ['visa', 'mastercard', 'amex', 'discover'].indexOf(value) > -1;
      case 'petOwnerName':
        return value?.length > 0;
      case 'transactionSource':
        return ['pax', 'payfabric', 'spreedly'].indexOf(value) > -1;
      default:
        return null;
    }
  }

  function validate(rules) {
    const validationResult = rules.reduce((errors, vf) => {
      const error = !validateReducer(vf.field, vf.value);
      if (error) {
        errors[vf.field] = vf.validationMessage;
      }
      return errors;
    }, []);

    setValidationErrors(validationResult);
    return Object.keys(validationResult);
  }

  return (
    <div id='AddTransaction' className='content'>
      <Navbar className='practices-header vitus-app-header' expand='lg'>
        <Navbar.Brand as='a' href='#' onClick={handleBackButtonClick}>
          {isAdmin && <i className='fas fa-chevron-left text-secondary'></i>}
          <small className='ml-2'>
            {selectedPractice.name} - Add Transaction
          </small>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls='app-header-collapsible' />
        <Navbar.Collapse id='app-header-collapsible'>
          <Nav className='mr-auto'></Nav>
        </Navbar.Collapse>
      </Navbar>
      <div class='pl-4 px-4 my-4'>
        <div class='card'>
          <div class='card-body col-md-6'>
            {showTransactionComplete &&
              selectedTransaction?.transactionId != null && (
                <div>
                  <p>
                    Transaction with id {selectedTransaction.transactionId} has
                    been added.
                  </p>
                </div>
              )}
            {!showTransactionComplete && (
              <Form ref={formRef}>
                <Form.Group controlId='transactionSource'>
                  <Form.Label>Transaction Source</Form.Label>
                  <Form.Control
                    as='select'
                    name='transactionSource'
                    isInvalid={!!validationErrors['transactionSource']}
                    defaultValue={transactionSource}
                    onChange={(event) => {
                      setTransactionSource(event.target.value);
                    }}
                  >
                    <option value=''>Select Transaction Source</option>
                    <option value='pax'>PAX</option>
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {validationErrors['transactionSource']}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='transactionDateTime'>
                  <Form.Label>Transaction Date/Time</Form.Label>
                  <Form.Control
                    type='datetime-local'
                    name='transactionDateTime'
                    isInvalid={!!validationErrors['transactionDateTime']}
                    placeholder='Enter Date/Time'
                    defaultValue={transactionDateTime}
                    onChange={(event) => {
                      setTransactionDateTime(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {validationErrors['transactionDateTime']}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='petOwnerName'>
                  <Form.Label>Pet Owner Name</Form.Label>
                  <Form.Control
                    type='text'
                    name='petOwnerName'
                    isInvalid={!!validationErrors['petOwnerName']}
                    placeholder='Pet Owner Name'
                    defaultValue={petOwnerName}
                    onChange={(event) => {
                      setPetOwnerName(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {validationErrors['petOwnerName']}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='cardType'>
                  <Form.Label>Card Type</Form.Label>
                  <Form.Control
                    as='select'
                    name='cardType'
                    isInvalid={!!validationErrors['cardType']}
                    defaultValue={cardType}
                    onChange={(event) => {
                      setCardType(event.target.value);
                    }}
                  >
                    <option value=''>Select Card Type</option>
                    <option value='visa'>Visa</option>
                    <option value='mastercard'>Mastercard</option>
                    <option value='amex'>American Express</option>
                    <option value='discover'>Discover</option>
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {validationErrors['cardType']}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='cardLast4'>
                  <Form.Label>Card Last 4 Digits</Form.Label>
                  <Form.Control
                    type='number'
                    name='cardLast4'
                    isInvalid={!!validationErrors['cardLast4']}
                    onKeyPress={cardLast4KeyPress}
                    pattern='[0-9]{4}'
                    placeholder='Card Last 4 Digits'
                    defaultValue={cardLast4}
                    onChange={(event) => {
                      setCardLast4(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {validationErrors['cardLast4']}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='amount'>
                  <Form.Label>Amount</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type='number'
                      name='amount'
                      isInvalid={!!validationErrors['amount']}
                      placeholder='Amount'
                      defaultValue={amount}
                      onChange={(event) => {
                        setAmount(event.target.value);
                      }}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type='invalid'>
                    {validationErrors['amount']}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            )}
          </div>
          <div class='card-footer'>
            <div class='col-md-6'>
              {showTransactionComplete && (
                <button
                  class='btn btn-primary float-right'
                  onClick={resetClick}
                >
                  Add Another Transaction
                </button>
              )}
              {!showTransactionComplete && !isSaving && (
                <button class='btn btn-primary float-right' onClick={saveClick}>
                  Save
                </button>
              )}
              {!showTransactionComplete && isSaving && (
                <button class='btn float-right'>Saving...</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTransaction;
