import {
  handleResponse,
  handleError,
  axiosSpreedlyPublicInstance,
} from './index';

export function getSpreedlyTransaction(transactionId = '') {
  return axiosSpreedlyPublicInstance
    .get(`/get${transactionId}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getDraftSpreedlyTransaction(transactionId = '') {
  return axiosSpreedlyPublicInstance
    .get(`/draft?transactionId=${transactionId}`)
    .then(handleResponse)
    .catch(handleError);
}

export function postSpreedlyPaymentToken(
  transactionId = '',
  paymentToken = '',
  retain_on_success = false
) {
  return axiosSpreedlyPublicInstance
    .post('/full_payment', {
      transactionId,
      paymentToken,
      retain_on_success,
    })
    .catch(handleError);
}
