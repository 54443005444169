import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';

const StripeHeader = ({ title }) => {
  return (
    <Navbar className='practices-header vitus-app-header' expand='lg'>
      <Navbar.Brand>{title}</Navbar.Brand>
      <Navbar.Toggle aria-controls='app-header-collapsible' />
      <Navbar.Collapse id='app-header-collapsible'>
        <Nav className='mr-auto'></Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

StripeHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default StripeHeader;
