import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import * as authActions from './../../utils/store/actions/authActions';
import logo from './../../assets/images/app-logo.png';

const RecoverPassword = ({
  resetUserPassword,
  passwordPolicyWithValidationMessage,
}) => {
  const [form, setForm] = useState({
    passwordView: false,
    newPassword: '',
    confirmNewPassword: '',
    validations: {},
    token: '',
    isSaving: false,
    credentials: {},
    errors: {},
  });

  const [policies, setPolicies] = useState({
    retrieved: false,
    passwordPolicyMessage: '',
    minimumLength: 0,
  });

  const [completed, setCompleted] = useState({
    isCompleted: false,
    completedMessage: '',
  });

  const history = useHistory();
  const location = useLocation();

  const togglePasswordView = () => {
    setForm({
      ...form,
      ...{ passwordView: !form.passwordView },
    });
  };

  const formIsComplete = () => {
    return (
      form.newPassword.length >= policies.minimumLength &&
      form.newPassword === form.confirmNewPassword
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { credentials, token, newPassword } = form;

    setForm({ ...form, ...{ isSaving: true } });
    resetUserPassword(credentials.username, newPassword, token)
      .then(() => {
        setForm({ ...form, ...{ isSaving: false } });
        history.push('/app');
      })
      .catch((err) => {
        let newState = {
          ...form,
          ...{
            isSaving: false,
            newPassword: '',
            confirmNewPassword: '',
          },
        };
        if (err.status === 403) {
          setCompleted({
            isCompleted: true,
            completedMessage: 'Password change successful',
          });
        } else {
          const errors = {
            message: err.userMessage ? err.userMessage : err.message,
          };
          newState.errors = errors;
        }
        setForm(newState);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value) {
      setForm({
        ...form,
        ...{ [name]: value },
      });
    } else {
      setForm({
        ...form,
        ...{ [name]: '' },
      });
    }
  };

  useEffect(() => {
    if (!location.search) {
      history.push('/');
    } else {
      const tokenObj = queryString.parse(location.search);
      const credentials = jwtDecode(tokenObj.key);
      setForm({
        ...form,
        ...{
          token: tokenObj.key,
          credentials,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!policies.retrieved) {
      getPasswordPolicy();
    }
  });

  const getPasswordPolicy = async () => {
    let result = await passwordPolicyWithValidationMessage();
    setPolicies({
      retrieved: true,
      passwordPolicyMessage: result.Message,
      minimumLength: result.PasswordPolicy.MinimumLength,
    });
  };

  return (
    <div className='Login'>
      <div className='FormContainer'>
        <div className='Logo'>
          <img className='CompanyLogo' src={logo} alt='Company Logo' />
        </div>
        <div className='Welcome'>
          <h1 className='mb-3'>Welcome</h1>
          {!completed.isCompleted && (
            <h5 className='text-justify'>
              Fill in the fields below to reset your password.
            </h5>
          )}
        </div>
        {form.errors.message && (
          <div className='alert alert-danger mt-4' role='alert'>
            {form.errors.message}
          </div>
        )}
        {!completed.isCompleted && (
          <form className='mt-3' onSubmit={handleSubmit}>
            <div className='form-group'>
              <input
                name='username'
                value={form.credentials.username}
                type='text'
                className='form-control mb-3'
                disabled={true}
                placeholder='Username'
                style={{ cursor: 'not-allowed' }}
              />
            </div>
            <div className='form-group'>
              <div className='input-group'>
                <input
                  name='newPassword'
                  value={form.newPassword}
                  type={form.passwordView ? 'text' : 'password'}
                  className={
                    form.validations.newPasswordError
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  placeholder='New Password'
                  onChange={handleChange}
                />
                <div className='input-group-append'>
                  <span
                    className='input-group-text'
                    onClick={togglePasswordView}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className='sr-only'>toggle password view</span>
                    <i
                      className='fas fa-eye'
                      aria-hidden='true'
                      style={{ color: form.passwordView ? '#ff8f32' : '' }}
                    ></i>
                  </span>
                </div>
              </div>
              <small
                className='form-text'
                style={{ color: '#FFF !important', textAlign: 'left' }}
              >
                {policies.passwordPolicyMessage}.
              </small>
              <div
                className={
                  form.validations.newPasswordError ? 'invalid-feedback' : ''
                }
              >
                {form.validations.newPasswordError}
              </div>
            </div>
            <div className='form-group'>
              <input
                name='confirmNewPassword'
                value={form.confirmNewPassword}
                type={form.passwordView ? 'text' : 'password'}
                className={
                  form.confirmNewPasswordError
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                placeholder='Confirm New Password'
                onChange={handleChange}
              />
              <small
                className='form-text'
                style={{
                  color:
                    form.newPassword === form.confirmNewPassword
                      ? '#FFF'
                      : 'red',
                  textAlign: 'left',
                }}
              >
                Passwords must match
              </small>
              <div
                className={
                  form.confirmNewPasswordError ? 'invalid-feedback' : ''
                }
              >
                {form.confirmNewPasswordError}
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-primary btn-lf btn-block mt-4'
              disabled={!formIsComplete() || form.isSaving}
            >
              {form.isSaving ? 'Saving...' : 'SUBMIT'}
            </button>
            <Link className='btn btn-link mt-2' to='/'>
              CANCEL
            </Link>
          </form>
        )}
        {completed.isCompleted && (
          <>
            <div className={`alert alert-success mt-4`} role='alert'>
              <p style={{ wordWrap: 'break-word' }}>
                {completed.completedMessage}
              </p>
            </div>
            <Link className='btn btn-link mt-2' to='/'>
              BACK
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  ...authActions,
};

export default connect(undefined, mapDispatchToProps)(RecoverPassword);
