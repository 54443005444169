import React, { Fragment } from 'react';
import { Popover } from 'react-bootstrap';
import Currency from 'utils/helpers/currency';
import DateTime from 'utils/helpers/DateTime';

export const popover = (refunds) => {
  const popoverStyle = {
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  return (
    <Popover id='popover-basic'>
      <Popover.Title as='h3' className='text-center'>
        Refund History
      </Popover.Title>
      <Popover.Content>
        <div style={popoverStyle}>
          {refunds.map((refund, idx, arr) => (
            <Fragment key={idx}>
              <div className='row' style={{ minWidth: 265 }}>
                <div className='col-4'>
                  <b>Date</b>
                </div>
                <div className='col text-left'>
                  <DateTime
                    datetime={DateTime.normalizeEpoch(refund.created)}
                    format='MM/DD/YYYY - h:mm a'
                  />
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-4'>
                  <b>Amount</b>
                </div>
                <div className='col'>
                  <Currency amount={refund.amount} />
                </div>
              </div>
              {refund.reason && (
                <div className='row mt-1'>
                  <div className='col-4'>
                    <b>Reason</b>
                  </div>
                  <div className='col'>
                    <p>{refund.reason}</p>
                  </div>
                </div>
              )}
              {idx + 1 < arr.length && <hr />}
            </Fragment>
          ))}
        </div>
      </Popover.Content>
    </Popover>
  );
};
