import { combineReducers } from 'redux';
import practiceReducer, { selectedPractice } from './practiceReducer';
import authReducer, {
  challengeReducer,
  forgotPasswordRequest,
  passwordPolicyReducer,
} from './authReducer';
import currentPath from './navigationReducer';
import staff, {
  selectedStaff as selectedStaffMember,
  toggleAddEditStaffMember,
  toggleDeleteStaffMemberConfirmation,
} from './staffMemberReducer';
import loggedPractice from './loggedPracticeReducer';
import {
  transactionsReducer as transactions,
  dailyTransactionsReducer as dailyTransactions,
  spreedlyTransactionInfoReducer as spreedlyTransactionInfo,
  spreedlyPaymentReducer as spreedlyPaymentInfo,
} from './transactionsReducer';
import selectedTransaction from './transactionDetailReducer';
import {
  dailyReportReducer as practiceDailyReport,
  payoutsReportReducer as practicePayoutsReport,
  transactionReportReducer as transactionReport,
} from './reportingReducer';
import { privacyPolicyReducer as privacyPolicy } from './servicesReducer';

import globalInstallmentsConfig from './installmentsConfigReducer';

import mostRecentTransactionFromWebSocket from '../slices/mostRecentTransactionFromWebSocketSlice';

export default combineReducers({
  practiceReducer,
  selectedPractice,
  authReducer,
  challengeReducer,
  forgotPasswordRequest,
  currentPath,
  staff,
  selectedStaffMember,
  loggedPractice,
  transactions,
  dailyTransactions,
  spreedlyTransactionInfo,
  spreedlyPaymentInfo,
  selectedTransaction,
  toggleAddEditStaffMember,
  toggleDeleteStaffMemberConfirmation,
  practiceDailyReport,
  practicePayoutsReport,
  transactionReport,
  globalInstallmentsConfig,
  passwordPolicyReducer,
  privacyPolicy,
  mostRecentTransactionFromWebSocket,
});
