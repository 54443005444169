import React from 'react';
import { Form } from 'react-bootstrap';

// Used in both first and second steps
const PetOwnerNameInput = (props) => {
  const { petOwnerName, setPetOwnerName, validationError } = props;

  return (
    <Form.Group controlId='petOwnerName'>
      <Form.Label>Pet Owner Name</Form.Label>
      <Form.Control
        type='text'
        name='petOwnerName'
        placeholder='Pet Owner Name'
        isInvalid={!!validationError}
        onChange={(event) => {
          const value = event.target.value.replace(/[^a-zA-Z\s]/g, ''); // Remove all non-letter characters
          setPetOwnerName(value);
        }}
        value={petOwnerName}
      />
      <Form.Control.Feedback type='invalid'>
        {validationError}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default PetOwnerNameInput;
