import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import produce from 'immer';
import PracticesHeader from './PracticesHeader';
import PracticeList from './PracticeList';
import SavePractice from './SavePractice';
import { connect } from 'react-redux';
import * as practicesActions from './../../../../utils/store/actions/practicesActions';
import * as practiceInfoActions from './../../../../utils/store/actions/practiceInfoActions';
import { compareArrays } from './../../../../utils/helpers/compareArrays';
import { Practice } from './../../../../models';
import dataFilter from './../../../../utils/helpers/dataFilter';
import Loading from './../../../Common/Loading';
import { passwordIsValid } from 'utils/helpers/passwordValidate';

class Practices extends Component {
  state = {
    title: 'Practices',
    show: false,
    practice: { ...this.props.practice },
    practices: [],
    errors: {},
    isSaving: false,
    isLoading: false,
    search: '',
  };

  handleClose = () =>
    this.setState({
      show: false,
      practice: { ...this.props.practice },
      errors: {},
    });

  handleShow = () => this.setState({ show: true });

  handleSubmit = async (event) => {
    event.preventDefault();

    const sanitizedPractice = this.sanitizeForm();

    const formIsValid = await this.saveFormIsValid(sanitizedPractice);

    if (!formIsValid) return;

    const data = {
      ...sanitizedPractice,
      ...{ phone: Number.parseInt(this.state.practice.phone) },
    };

    if (!data.contactEmail) {
      data.contactEmail = data.email;
    }

    this.setState({ isSaving: true });

    try {
      await this.props.savePractice(data);
      this.setState({ isSaving: false });
      this.handleClose();
      toast.success('Practice successfully saved.');
    } catch (error) {
      const errors = {};

      errors.message = error.userMessage ?? error.message;

      if (
        errors.message?.includes(
          "Value at 'temporaryPassword' failed to satisfy constraint"
        )
      ) {
        errors.message =
          'Password does not conform to policy: Password must be at least 8 characters long, contain at least 1 uppercase letter, contain at least 1 number, contain at least 1 special character';
      }

      this.setState({ errors, isSaving: false });
    }
  };

  onFilterChange = (event) => {
    let { value } = event.target;
    const filter = dataFilter(this.props.practices, value);
    this.setState({ search: value });
    this.setState({ practices: filter });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    let obj = { ...this.state.practice };
    obj[name] = value;
    this.setState({ practice: obj });
  };

  sanitizeForm = () => {
    const { practice } = this.state;
    const sanitizedPractice = produce(practice, (practiceDraft) => {
      practiceDraft.name = practiceDraft.name?.trim();
      practiceDraft.email = practiceDraft.email?.trim();
      practiceDraft.contactEmail = practiceDraft.contactEmail?.trim();
      practiceDraft.phone = practiceDraft.phone?.trim();
      practiceDraft.password = practiceDraft.password?.trim();
      practiceDraft.password_confirm = practiceDraft.password_confirm?.trim();
    });
    this.setState({ practice: sanitizedPractice });
    return sanitizedPractice;
  };

  saveFormIsValid = async (sanitizedPractice) => {
    const { name, email, phone, password, password_confirm } =
      sanitizedPractice;

    const errors = {};

    if (!name) errors.name = 'Name is required';
    if (!email) errors.email = 'Email is required';
    if (!phone) errors.phone = 'Phone is required';
    if (!password) errors.password = 'Password is Required';
    if (!passwordIsValid(password))
      errors.password = 'Password does not meet the requirements';
    if (password !== password_confirm)
      errors.password_confirm = 'Passwords must match';

    this.setState({ errors });

    const hasNoErrors = Object.keys(errors).length === 0;

    return hasNoErrors;
  };

  fetchData = () => {
    const { loadPractices, match, practices } = this.props;
    const params = match.params;
    loadPractices()
      .then(() => {
        this.setState({ isLoading: false });
        if (params.practiceId) {
          this.setState({ search: params.practiceId });
          const filter = dataFilter(practices, params.practiceId);
          this.setState({ practices: filter });
        } else {
          this.setState({ practices });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
        toast.error('Error loading data');
      });
  };

  componentDidMount() {
    const { selectedPractice, unsetSelectedPractice } = this.props;
    if (selectedPractice) {
      unsetSelectedPractice();
    }
    this.setState({ isLoading: true });
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { practices } = this.props;
    if (!compareArrays(prevProps.practices, practices)) {
      this.fetchData();
    }
  }

  render() {
    let { title, practices, show, practice, isSaving, errors, search } =
      this.state;

    return (
      <div id='Practices' className='practices content'>
        <PracticesHeader
          title={title}
          search={search}
          handleShow={this.handleShow}
          onFilterChange={this.onFilterChange}
        />
        {this.state.isLoading && <Loading />}
        {!this.state.isLoading && practices.length > 0 && (
          <PracticeList practices={practices} />
        )}
        <SavePractice
          show={show}
          handleClose={this.handleClose}
          onSave={this.handleSubmit}
          onChange={this.handleChange}
          practice={practice}
          isSaving={isSaving}
          errors={errors}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    practices: state.practiceReducer,
    practice: Practice,
    selectedPractice: state.selectedPractice,
  };
}

const mapDispatchToProps = {
  ...practicesActions,
  ...practiceInfoActions,
};

Practices.propTypes = {
  practices: PropTypes.array.isRequired,
  practice: PropTypes.object.isRequired,
  loadPractices: PropTypes.func.isRequired,
  savePractice: PropTypes.func.isRequired,
  selectedPractice: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Practices));
