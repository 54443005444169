// Modules
import React, { useEffect, useRef, useReducer, useState } from 'react';

// CSS
import styles from './SpreedlyForm.module.scss';

// Utils
import { serializeForm } from 'utils/helpers/formDataHelpers';
import { currencyFormat } from 'utils/helpers/currencyFormat';

// Spreedly Functions
import { addSpreedlyJS } from './spreedlyFunctions';

// Components
import { Button } from 'react-bootstrap';
import ExpirationDateInput from './ExpirationDateInput';

const validInputFieldsInitState = {
  fullName: true,
  month: true,
  year: true,
  expirationDate: true,
  cardNumber: true,
  cvv: true,
  cvvLength: true,
};

const inputBoolsInitState = {
  fullNameHasVal: false,
  fullNameHasFocus: false,
  creditCardHasVal: false,
  creditCardHasFocus: false,
  cvvHasVal: false,
  cvvHasFocus: false,
};

function inputBoolsReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'fullNameVal':
      return { ...state, fullNameHasVal: payload };
    case 'fullNameFocus':
      return { ...state, fullNameHasFocus: payload };
    case 'creditCardVal':
      return { ...state, creditCardHasVal: payload };
    case 'creditCardFocus':
      return { ...state, creditCardHasFocus: payload };
    case 'cvvVal':
      return { ...state, cvvHasVal: payload };
    case 'cvvFocus':
      return { ...state, cvvHasFocus: payload };
    default:
      return state;
  }
}

function inputFieldIsValid(formDataObj, requiredFields) {
  const validFields = {
    fullName: true,
    month: true,
    year: true,
    expirationDate: true,
  };

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  const name = requiredFields.full_name;
  const inputMonthStr = requiredFields.month;
  const inputMonthNum = inputMonthStr * 1;
  const inputYearStr = requiredFields.year;
  const inputYearNum = inputYearStr * 1;

  if (!/([\w]+\s[\w]+.*)/.test(name)) validFields.fullName = false;

  if (!/^\d{4}$/.test(inputYearStr)) validFields.year = false;

  if (year > inputYearNum) validFields.year = false;

  const expirationIsValid =
    inputYearNum > year ? true : inputMonthNum >= month ? true : false;

  if (!expirationIsValid) validFields.expirationDate = false;

  if (!/^\d{2}$/.test(inputMonthStr)) validFields.month = false;

  if (!/[01]/.test(formDataObj.monthOne)) validFields.month = false;

  if (/1/.test(formDataObj.monthOne) && /[3-9]/.test(formDataObj.monthTwo))
    validFields.month = false;

  return validFields;
}

/**
 * ==================
 * Exported Component
 * ==================
 */
function SpreedlyForm(props) {
  const {
    transaction,
    handleSpreedlySubmit,
    handleTokenSuccess,
    handleSpreedlyError,
    showForm,
  } = props;

  const amount = transaction?.charge?.amount;
  const customerName = transaction?.petData?.owner?.name ?? '';

  const [inputBools, dispatchInputBools] = useReducer(
    inputBoolsReducer,
    inputBoolsInitState
  );

  const [fullName, setFullName] = useState(customerName);
  const [inputData, setInputData] = useState(null);
  const [spreedlyValidationObj, setSpreedlyValidationObj] = useState(null);
  const [validInputFields, setValidInputFields] = useState(
    validInputFieldsInitState
  );

  const isMounted = useRef(false);
  const runValidation = useRef(false);

  const handleInput = (e) => {
    const name = e?.target?.name;
    const hasValue = !!e?.target?.value;

    switch (name) {
      case 'full_name':
        setFullName(e.target.value);
        dispatchInputBools({ type: 'fullNameVal', payload: hasValue });
        break;
      case 'spreedly_number':
        dispatchInputBools({
          type: 'creditCardVal',
          payload: hasValue,
        });
        break;
      case 'spreedly_cvv':
        dispatchInputBools({
          type: 'cvvVal',
          payload: hasValue,
        });
        break;
      default:
        break;
    }
  };

  const handleFocus = (e) => {
    const name = e.target.name;
    const hasFocus = e.type === 'focus' ? true : false;

    switch (name) {
      case 'full_name':
        dispatchInputBools({
          type: 'fullNameFocus',
          payload: hasFocus,
        });
        break;
      case 'spreedly_number':
        dispatchInputBools({
          type: 'creditCardFocus',
          payload: hasFocus,
        });
        break;
      case 'spreedly_cvv':
        dispatchInputBools({
          type: 'cvvFocus',
          payload: hasFocus,
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    /**
     *  Example serialized data:
     *  {
     *      "full_name": "Charlie Parker",
     *      "monthOne": "0",
     *      "monthTwo": "2",
     *      "yearOne": "2",
     *      "yearTwo": "1"
     *  }
     */
    const data = serializeForm(form);

    const requiredFields = {
      full_name: data.full_name,
      month: `${data.monthOne}${data.monthTwo}`,
      year: `20${data.yearOne}${data.yearTwo}`,
    };

    const validFields = inputFieldIsValid(data, requiredFields);

    setInputData(requiredFields);

    const { fullName, month, year, expirationDate } = validFields;

    setValidInputFields({
      ...validInputFieldsInitState,
      fullName,
      month,
      year,
      expirationDate,
    });

    window.Spreedly.validate();
  };

  useEffect(() => {
    if (!spreedlyValidationObj) return;

    runValidation.current = true;
  }, [spreedlyValidationObj]);

  useEffect(() => {
    /**
     * By using the ref here we can ensure that
     * addSpreedlyJS only runs once when the component
     * mounts for the first time
     */
    if (!isMounted.current) {
      isMounted.current = true;

      addSpreedlyJS({
        handleSpreedlyValidation: setSpreedlyValidationObj,
        handleSpreedlyError,
        handleTokenSuccess,
        handleInput,
        handleFocus,
      });

      /**
       * Full name gets an initial value from the draft transaction
       * This does not trigger the onChange handler
       * So we must set this boolean to true here to show the legend
       */
      dispatchInputBools({ type: 'fullNameVal', payload: true });
    }
  }, [handleSpreedlyError, handleTokenSuccess]);

  useEffect(() => {
    if (!spreedlyValidationObj || !inputData || !runValidation.current) return;

    runValidation.current = false;

    /**
     * Example validation object from Spreedly:
     *  {
     *     "cardType": "visa",
     *     "validNumber": false,
     *     "numberLength": 4,
     *     "validCvv": true,
     *     "cvvLength": 3,
     *     "luhnValid": false
     *  }
     */
    const { validNumber, validCvv, cvvLength, cardType } =
      spreedlyValidationObj;

    const cvvIsValid =
      (cvvLength === 3 && cardType !== 'american_express') ||
      (cvvLength === 4 && cardType === 'american_express');

    const updatedValidInputFields = {
      ...validInputFields,
      cardNumber: validNumber,
      cvv: validCvv,
      cvvLength: cvvIsValid,
    };

    const isValid = Object.values(updatedValidInputFields).every(
      (bool) => bool
    );

    if (isValid) {
      handleSpreedlySubmit();
      window.Spreedly.tokenizeCreditCard(inputData);
    } else {
      setValidInputFields(updatedValidInputFields);
    }
  }, [
    spreedlyValidationObj,
    inputData,
    handleSpreedlySubmit,
    validInputFields,
  ]);

  return (
    <form
      className={showForm ? styles.form : 'd-none'}
      id='payment-form'
      onSubmit={handleSubmit}
    >
      <div>
        <h1>Pay in full</h1>

        <p className='text-center'>Total Amount</p>

        <h2 className={styles.amount}>
          $<span>{currencyFormat(amount)}</span>
        </h2>

        <label aria-label='full name'>
          <p
            className={
              inputBools.fullNameHasFocus ||
              inputBools.fullNameHasVal ||
              !validInputFields.fullName
                ? styles.legend
                : [styles.legend, 'invisible'].join(' ')
            }
            style={validInputFields.fullName ? {} : { color: '#e85a5a' }}
          >
            {validInputFields.fullName
              ? 'Full name'
              : 'First and last name separated by a space'}
          </p>

          <input
            type='text'
            id='full_name'
            name='full_name'
            className={validInputFields.fullName ? '' : styles.invalid}
            placeholder={inputBools.fullNameHasFocus ? 'John Doe' : 'Full name'}
            value={fullName}
            onChange={handleInput}
            onFocus={handleFocus}
            onBlur={handleFocus}
            required
          />
        </label>

        <label aria-label='credit card number'>
          <p
            className={
              inputBools.creditCardHasFocus ||
              inputBools.creditCardHasVal ||
              !validInputFields.cardNumber
                ? styles.legend
                : [styles.legend, 'invisible'].join(' ')
            }
            style={
              validInputFields.cardNumber
                ? { marginTop: '10px' }
                : { marginTop: '10px', color: '#e85a5a' }
            }
          >
            {validInputFields.cardNumber
              ? 'Card number'
              : 'Invalid card number, please verify'}
          </p>

          <div
            className={
              validInputFields.cardNumber
                ? styles.spreedlyNumber
                : [styles.spreedlyNumber, styles.invalid].join(' ')
            }
            id='spreedly-number'
          >
            <i style={{ fontSize: '20px' }} className='far fa-credit-card'></i>
          </div>
        </label>

        <div className={styles.dateAndCvvWrapper}>
          <ExpirationDateInput validInputFields={validInputFields} />

          <p
            className={
              inputBools.cvvHasFocus ||
              inputBools.cvvHasVal ||
              !validInputFields.cvv ||
              !validInputFields.cvvLength
                ? styles.legend
                : [styles.legend, 'invisible'].join(' ')
            }
            style={
              validInputFields.cvv && validInputFields.cvvLength
                ? {}
                : { color: '#e85a5a' }
            }
          >
            {validInputFields.cvv && validInputFields.cvvLength
              ? 'CVV'
              : 'Invalid CVV, please verify'}
          </p>
          <label
            className={styles.cvvLabel}
            aria-label='credit card cvv/cvc code'
          >
            <div
              className={
                validInputFields.cvv && validInputFields.cvvLength
                  ? styles.spreedlyCVV
                  : [styles.spreedlyCVV, styles.invalid].join(' ')
              }
              id='spreedly-cvv'
            ></div>
          </label>
        </div>
      </div>

      <footer>
        <Button
          id='submit-button'
          className={styles.submitBtn}
          type='submit'
          variant='primary'
          disabled
        >
          PAY NOW
        </Button>
      </footer>
    </form>
  );
}

export default SpreedlyForm;
