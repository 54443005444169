import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({
  message,
  style = {
    minHeight: 200,
    alignItems: 'center',
  },
  messageStyle = {},
}) => {
  return (
    <div className='d-flex justify-content-center' style={style}>
      <div className='spinner-grow text-secondary' role='status'>
        <span className='sr-only'>Loading</span>
      </div>
      {message && (
        <h4 className='text-center m-0' style={messageStyle}>
          {message}
        </h4>
      )}
    </div>
  );
};

Loading.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
  messageStyle: PropTypes.object,
};

export default Loading;
