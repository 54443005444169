import React from 'react';
import UserBehavior from 'behaviors/UserBehavior';
import IdShortener from 'utils/helpers/IdShortener';
import sortCaret from 'utils/helpers/sortCaret';
import EditButton from 'components/Layout/Content/Staff/EditButton';
import DeleteButton from 'components/Layout/Content/Staff/DeleteButton';
import ResetButton from 'components/Layout/Content/Staff/ResetButton';

function idFormatter(cell) {
  return <IdShortener id={cell} />;
}

function nameFormatter(cell, row) {
  return <span>{`${cell} ${row['lastName']}`}</span>;
}

const editDeleteFormatter = (currentUser) => (cell, row) => {
  return (
    <div className='actions'>
      {UserBehavior.canEdit(currentUser, row) && <EditButton id={row.id} />}
      {row.userType === 'users' &&
        UserBehavior.canResetPassword(currentUser, row) && (
          <ResetButton user={row} />
        )}
      {UserBehavior.canDelete(currentUser, row) && <DeleteButton id={row.id} />}
    </div>
  );
};

function isActiveFormatter(cell, row) {
  if (!cell) {
    return <span>Inactive</span>;
  }

  if (row.role.protectedAccount) {
    return (
      <span className='space-nowrap'>
        Protected
        <i className='fas fa-user-shield pl-2 pr-1'></i>
      </span>
    );
  }

  return <span>Active</span>;
}

function roleFormatter(cell) {
  let role = [];
  if (cell.practiceAdmin) {
    role.push('Access to Web Portal');
  }

  if (cell.practiceStaff) {
    role.push('Practice Staff');
  }

  if (cell.supportAgent) {
    role.push('Support Agent');
  }

  if (cell.tabletUser) {
    role.push('Access to Tablet');
  }

  if (cell.teamAdministrator) {
    role.push('Team Administrator');
  }

  return <span>{role.join(', ') || '-'}</span>;
}

function emailFormatter(cell) {
  return cell ?? '-';
}

export const staffColumns = (currentUser) => [
  {
    text: 'ID',
    dataField: 'id',
    formatter: idFormatter,
  },
  {
    text: 'NAME',
    dataField: 'name',
    sort: true,
    sortCaret,
    formatter: nameFormatter,
  },
  {
    text: 'STATUS',
    dataField: 'active',
    formatter: isActiveFormatter,
  },
  {
    text: '',
    dataField: 'editDeleteFn',
    formatter: editDeleteFormatter(currentUser),
  },
];

export const userColumns = (currentUser) => [
  {
    text: 'ID',
    dataField: 'id',
    formatter: idFormatter,
  },
  {
    text: 'NAME',
    dataField: 'name',
    sort: true,
    sortCaret,
    formatter: nameFormatter,
  },
  {
    text: 'EMAIL',
    dataField: 'email',
    sort: true,
    sortCaret,
    formatter: emailFormatter,
  },
  {
    text: 'PERMISSIONS',
    dataField: 'role',
    formatter: roleFormatter,
  },
  {
    text: 'STATUS',
    dataField: 'active',
    formatter: isActiveFormatter,
  },
  {
    text: '',
    dataField: 'editDeleteFn',
    formatter: editDeleteFormatter(currentUser),
  },
];

export const paginationOptions = {
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
  firstPageText: '<<',
  lastPageText: '>>',
  paginationSize: 5,
  alwaysShowAllBtns: true,
};
