const Practice = {
  id: null,
  name: '',
  email: '',
  contactEmail: '',
  phone: '',
  password: '',
  password_confirm: '',
  stripeId: '',
};

const Transaction = {
  id: null,
  datetime: '',
  name: '',
  email: '',
  phone: '',
  card: '', // last 4 digits
  cardType: '',
  amount: 0,
};

const StaffMember = {
  id: null,
  name: '',
  lastName: '',
  active: '',
  practiceId: null,
  email: '',
  password: '',
  password_confirm: '',
  cognitoAccess: null,
  role: {
    practiceAdmin: false,
    practiceStaff: false,
    teamAdministrator: false,
    supportAgent: false,
    tabletUser: false,
  },
};

module.exports = {
  Practice,
  Transaction,
  StaffMember,
};
