import { handleResponse, handleError, axiosSpreedlyInstance } from './index';

export function cancelTransaction(transactionId) {
  return axiosSpreedlyInstance
    .post('/cancel', {
      transactionId,
    })
    .then(handleResponse)
    .catch(handleError);
}
/**
 * args = {
 *  transactionId,
 *  phone,
 *  email
 * }
 */
export function resendLink(args) {
  return axiosSpreedlyInstance
    .post('/resend_text_message', args)
    .then(handleResponse)
    .catch(handleError);
}

export async function validateGateway(login, password) {
  try {
    const res = await axiosSpreedlyInstance.post('/gateway/validate', {
      login,
      password,
    });
    return handleResponse(res);
  } catch (err) {
    return handleError(err);
  }
}

export async function createSpreedlyTransaction(reqData) {
  try {
    const res = await axiosSpreedlyInstance.post('/create', reqData);
    return handleResponse(res);
  } catch (err) {
    return handleError(err);
  }
}

export async function createDraftTransaction(
  amount,
  description,
  name,
  userId
) {
  try {
    const res = await axiosSpreedlyInstance.post('/draft', {
      paymentInformation: {
        amount,
        invoiceId: description,
      },
      petData: {
        owner: {
          name,
        },
      },
      user: {
        id: userId,
      },
    });
    return handleResponse(res);
  } catch (err) {
    return handleError(err);
  }
}

export async function verifySpreedlyToken(
  payment_method_token,
  retain_on_success = false
) {
  return axiosSpreedlyInstance
    .post('/verify', {
      payment_method_token,
      retain_on_success,
    })
    .then(handleResponse)
    .catch(handleError);
}
