import initialState from './initialState';
import { StoreActions } from '../actions';
import cloneDeep from 'lodash/cloneDeep';

const selectedTransaction = (
  state = initialState.selectedTransaction,
  action
) => {
  switch (action.type) {
    case StoreActions.GET_TRANSACTION_DETAIL_SUCCESS:
      return cloneDeep(action.transaction);
    case StoreActions.PROCESS_TRANSACTION_REFUND_SUCCESS:
    case StoreActions.SEND_TRANSACTION_RECEIPT_SUCCESS:
    case StoreActions.PROCESS_NEW_TRANSACTION_SUCCESS:
      return { ...state, ...cloneDeep(action.transaction) };
    case StoreActions.LOGOUT:
      return initialState.selectedTransaction;
    default:
      return state;
  }
};

export default selectedTransaction;
