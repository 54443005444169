import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, FormControl, Form } from 'react-bootstrap';

const DailyTransactionsHeader = ({ title, onSearch }) => {
  return (
    <Navbar className='vitus-app-header' expand='lg'>
      <Navbar.Brand>{title}</Navbar.Brand>
      <Navbar.Toggle aria-controls='app-header-collapsible' />
      <Navbar.Collapse id='app-header-collapsible'>
        <Nav className='mr-auto'></Nav>
        <Form inline>
          <FormControl
            type='search'
            placeholder='Search'
            className='mr-sm-2 ml-2'
            style={{ maxWidth: 350 }}
            onChange={({ target: { value } }) => onSearch(value)}
          />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

DailyTransactionsHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DailyTransactionsHeader;
