import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import Env from 'Env';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

const isDevEnv = process.env.NODE_ENV === 'development';

/**
 * In the future when the entire project is converted to redux toolkit
 * the immutableCheck and serializableCheck should be true
 * At that point we can remove the config object altogether
 * We can also likely remove reduxImmutableStateInvariant
 *
 * See the docs for more info:
 *      https://redux-toolkit.js.org/api/getDefaultMiddleware
 */
const defaultMiddlewareConfig = {
  thunk: true,
  immutableCheck: false,
  serializableCheck: false,
};

const middlewareNames = {
  default: 'default',
  immutable: 'reduxImmutableStateInvariant',
  logRocket: 'logRocket',
  sentry: 'sentry',
};

const middlewareChecks = {
  [middlewareNames.default]: true,
  [middlewareNames.immutable]: true,
  [middlewareNames.logRocket]: Env.logRocketEnabled,
  [middlewareNames.sentry]: Env.sentryEnabled,
};

function middlewareReducer(action) {
  const { type, array } = action;

  switch (type) {
    case middlewareNames.default:
      return [...array, ...getDefaultMiddleware(defaultMiddlewareConfig)];

    case middlewareNames.immutable:
      return [...array, reduxImmutableStateInvariant()];

    case middlewareNames.logRocket:
      return [...array, LogRocket.reduxMiddleware()];

    case middlewareNames.sentry:
      return [...array, createSentryMiddleware(Sentry)];

    default:
      return array;
  }
}

function getMiddleware(args) {
  const { checks } = args;

  const entries = Object.entries(checks);

  const middlewareArray = entries.reduce((acc, curr) => {
    if (curr[1]) return middlewareReducer({ type: curr[0], array: acc });
    return acc;
  }, []);

  return middlewareArray;
}

const middleware = getMiddleware({ checks: middlewareChecks });

const devTools = isDevEnv ? true : false;

/**
 * This function is not necessary for redux toolkit
 * but various test files use it to set an initial state
 * for the redux store
 */
export default function storeConfig(initState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initState,
    middleware,
    devTools,
  });
}

export const store = storeConfig();
