// Modules
import ReactGA from 'react-ga';

// Utils
import { createGAEventHandler, paymentTypes } from '@vitusvet/react-library';

const gaEventHandler = createGAEventHandler(ReactGA, 'react-ga');

const gaCategory = 'Text 2 Pay';

function getLabelByPaymentType(paymentType = '') {
  switch (paymentType) {
    case paymentTypes.full:
      return 'Pay Now';

    case paymentTypes.installments:
      return 'Pay Monthly';

    default:
      return '';
  }
}

function getLabelByInvoiceDetailsState(state = '') {
  switch (true) {
    case state.toLowerCase() === 'expanded':
      return 'Expanded';

    case state.toLowerCase() === 'collapsed':
      return 'Collapsed';

    default:
      return '';
  }
}

export const invoiceDetailsLoadGAEvent = (val) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Details Default',
    label: getLabelByInvoiceDetailsState(val),
  });
};

export const invoiceDetailsClickGAEvent = (val) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Details Changed',
    label: getLabelByInvoiceDetailsState(val),
  });
};

export const paymentMethodButtonsLoadGAEvent = (paymentType) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Payment Type Default',
    label: getLabelByPaymentType(paymentType),
  });
};

export const paymentMethodButtonsClickGAEvent = (paymentType) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Payment Type Changed',
    label: getLabelByPaymentType(paymentType),
  });
};

export const makePaymentButtonClickGAEvent = (paymentType) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Make Payment',
    label: getLabelByPaymentType(paymentType),
  });
};

export const spreedlyCloseGAEvent = () => {
  gaEventHandler({
    category: gaCategory,
    action: 'Cancel',
    label: 'Spreedly',
  });
};

export const paymentSuccessShowReceiptOptionsGAEvent = (() => {
  let invoked = false;
  return () => {
    if (invoked) return;
    invoked = true;
    // label is not required so we're using the ReactGA library directly
    ReactGA.event({
      category: gaCategory,
      action: 'Payment Processed',
    });
  };
})();

export const receiptSubmitGAEvent = (label) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Receipt',
    label,
  });
};

export const orderCompleteLoadGAEventHandler = (total) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Order Complete',
    label: total,
  });
};

export const paymentFailedLoadGAEventHandler = (msg) => {
  gaEventHandler({
    category: gaCategory,
    action: 'Payment Failed',
    label: msg,
  });
};

export const paymentFailedRetryClickGAEventHandler = () => {
  gaEventHandler({
    category: gaCategory,
    action: 'Payment Failed',
    label: 'Try Again',
  });
};

export const splititCanceledGAEventHandler = () => {
  gaEventHandler({
    category: gaCategory,
    action: 'Cancel',
    label: 'SplitIt',
  });
};
