import initialState from './initialState';
import { StoreActions } from '../actions';
import PaymentMethod from '../../../constants/PaymentMethod';
import { cloneDeep } from 'lodash';
import { mapTransaction } from 'utils/helpers/sortAndMapFunctions';

export const transactionsReducer = (
  state = initialState.transactions,
  action
) => {
  switch (action.type) {
    case StoreActions.GET_PRACTICE_TRANSACTIONS_STARTED:
      return { items: {}, isGettingTransactions: true };

    case StoreActions.GET_PRACTICE_TRANSACTIONS_PAGE_SUCCESS:
      var newPageTransactions = action.transactions
        .filter((t) => t.method !== PaymentMethod.Draft)
        .reduce((dict, currentTransaction) => {
          dict[currentTransaction.transactionId] = currentTransaction;
          return dict;
        }, {});
      return { ...state, items: { ...state.items, ...newPageTransactions } };

    case StoreActions.GET_PRACTICE_TRANSACTIONS_SUCCESS:
      return { ...state, isGettingTransactions: false };

    case StoreActions.LOGOUT:
      return initialState.transactions;

    case StoreActions.UPDATE_TRANSACTION:
      const { transaction } = action;
      const isSpreedly = /sr_/.test(transaction.id);
      const id = isSpreedly ? transaction.id : transaction.transactionId;
      const entries = Object.entries(state.items);
      // if we don't use the mapTransaction function the data doesn't
      // display correctly
      const copy = mapTransaction(cloneDeep(transaction));

      // this is not ideal but... it has to do with how transactions are
      // stored in redux (-_-)
      const updatedItems = entries.reduce(
        (acc, entry) => {
          const [key, value] = entry;
          // ensures we don't overwrite the { [id]: copy } added first
          if (key !== id) {
            acc[key] = value;
          }
          return acc;
        },
        // ensures updated transaction is the first in the object
        { [id]: copy }
      );

      return { ...state, items: updatedItems };

    default:
      return state;
  }
};

export const dailyTransactionsReducer = (
  state = initialState.dailyTransactions,
  action
) => {
  switch (action.type) {
    case StoreActions.GET_DAILY_TRANSACTIONS:
      return action.transactions.filter(
        (t) => t.method !== PaymentMethod.Draft
      );
    default:
      return state;
  }
};

export const spreedlyTransactionInfoReducer = (
  state = initialState.spreedlyTransactonInfo,
  action
) => {
  switch (action.type) {
    case StoreActions.GET_SPREEDLY_TRANSACTION:
      return action.payload;
    default:
      return state;
  }
};

export const spreedlyPaymentReducer = (
  state = initialState.spreedlyPaymentInfo,
  action
) => {
  switch (action.type) {
    case StoreActions.POST_SPREEDLY_PAYMENT_INFO:
      return action.payload;
    default:
      return state;
  }
};

export default transactionsReducer;
