import React from 'react';
import CardRadioButton from './CardRadioButton';

import styles from './CardRadioControl.module.scss';

function CardRadioControl(props) {
  const { children, value = 0, onChange } = props;

  return (
    <div className={styles.container}>
      {children.map((child, i) => {
        return (
          <CardRadioButton
            key={i}
            onClick={() => {
              if (onChange instanceof Function) onChange(i);
            }}
            checked={i === value}
          >
            {child}
          </CardRadioButton>
        );
      })}
    </div>
  );
}

export default CardRadioControl;
