// Modules
import React, { useState, useEffect } from 'react';

// Hooks
import useInput from 'utils/hooks/useInput';

// Styles
import styles from './CustomMessageModal.module.scss';

// Components
import { Modal, Form, Button } from 'react-bootstrap';

const characterLimit = 130;

/**
 * ==================
 * Exported component
 * ==================
 */
function CustomMessageModal(props) {
  const {
    showModal = false,
    setShowModal,
    customMessageText,
    setCustomMessageText,
  } = props;

  const [value, setValue, resetValue] = useInput(customMessageText);
  const [maxLengthReached, setMaxLengthReached] = useState(false);

  const handleClose = () => {
    resetValue();
    setShowModal(false);
  };

  const handleConfirm = () => {
    setCustomMessageText(value);
    setShowModal(false);
  };

  useEffect(() => {
    if (value.length >= 130) {
      setMaxLengthReached(true);
    } else {
      setMaxLengthReached(false);
    }
  }, [value]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size='md'
      aria-labelledby={`update-modal-title-text-to-pay-custom-message`}
      centered
    >
      <Modal.Header>
        <Modal.Title id={`update-modal-title-text-to-pay-custom-message`}>
          TEXT TO PAY CUSTOM MESSAGE
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group controlId='exampleForm.ControlTextarea1'>
          <Form.Label>Custom Message</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            value={value}
            onChange={setValue}
            maxLength={`${characterLimit}`}
            className={maxLengthReached ? styles.isInvalid : ''}
          />
          <small
            className={`${styles.infoText} ${
              maxLengthReached ? styles.infoTextInvalid : ''
            }`}
          >
            {maxLengthReached
              ? `You have reached the ${characterLimit} character limit`
              : 'Input the custom message for this practice that will appear at the bottom of the Text To Pay screens.'}
          </small>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer className={'d-flex align-center justify-content-between'}>
        <Button variant='light' onClick={handleClose}>
          CANCEL
        </Button>

        <Button onClick={handleConfirm}>CONFIRM</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomMessageModal;
