import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import Env from 'Env';
import PropTypes from 'prop-types';

const TokenPayContext = createContext();

export const useTokenPay = () => {
  return useContext(TokenPayContext);
};

export const TokenPayProvider = ({ children, enableTokenPay }) => {
  const tokenPay = useRef(null);

  useEffect(() => {
    if (!enableTokenPay) return;

    const loadStyles = () => {
      const style = document.createElement('style');
      style.id = 'customStyles';
      style.textContent = `
          .input-label {
              color: #124474;
              font-family: Montserrat-Medium, sans-serif;
              font-size: 16px;
          }
        `;
      document.head.appendChild(style);

      return () => {
        document.head.removeChild(style);
      };
    };

    const loadScript = () => {
      const script = document.createElement('script');
      script.src = Env.rectangleUrl;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadStyles();
    loadScript();
  }, [enableTokenPay]);

  const initializeTokenPay = useMemo(
    () =>
      (publicKey, isSaveCard = false) => {
        if (!tokenPay.current) {
          tokenPay.current = window.TokenPay(publicKey);
        }

        tokenPay.current.initialize({
          dataElement: 'card',
          errorElement: 'errorMessage',
          amountElement: 'amount',
          useACH: false,
          useStyles: true,
          disableZip: false,
          disableCvv: false,
        });

        if (isSaveCard) {
          const button = document.querySelector(
            '[class^="PaymentRectangle_btnSubmit"]'
          );
          if (button) {
            button.textContent = 'Save Card';
          }
        }
      },
    []
  );

  const contextValue = useMemo(
    () => ({
      tokenPay,
      initializeTokenPay,
    }),
    [initializeTokenPay]
  );

  return (
    <TokenPayContext.Provider value={contextValue}>
      {children}
    </TokenPayContext.Provider>
  );
};

TokenPayContext.propTypes = {
  enabledTokenPay: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
