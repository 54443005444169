import { cloneDeep } from 'lodash';

export function serializeForm(form) {
  const obj = {};

  const formData = new FormData(form);

  const keys = formData.keys();

  for (const key of keys) {
    obj[key] = formData.get(key);
  }

  return obj;
}

export function getformDataWithBooleans(formData) {
  const copy = cloneDeep(formData);

  for (let key in copy) {
    if (copy[key] === 'true') copy[key] = true;
    if (copy[key] === 'false') copy[key] = false;
  }

  return copy;
}
