import React from 'react';
import Env from './../../../../Env';
import { Link } from 'react-router-dom';
import sortCaret from './../../../../utils/helpers/sortCaret';
import {
  expandColumnRenderer,
  expandHeaderColumnRenderer,
} from './../../../../utils/helpers/expandArrow';
import Currency from './../../../../utils/helpers/currency';
import DateTime from './../../../../utils/helpers/DateTime';
import TransactionSanityCheck from './../../../Common/SanityChecks/TransactionSanityCheck';
import DropDownDetail from './Admin/DropDownDetail';

function currencyFormatter(cell) {
  return <Currency amount={cell} />;
}

const linkToDetailFormatter = (isAdmin) => (cell) => {
  if (isAdmin) return cell;

  return (
    <Link className='text-primary' to={`transactions/${cell}`}>
      {cell}
    </Link>
  );
};

function dateTimeFormatter(cell) {
  const timestamp = DateTime.normalizeEpoch(cell);
  return <DateTime datetime={timestamp} format='MM/DD/YY - h:mma' />;
}

function overflowFormatter(cell) {
  return (
    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: 210 }}>
      <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {cell ? cell : '-'}
      </p>
    </div>
  );
}

function emptyValue(cell) {
  return <>{cell ? cell : '—'}</>;
}

function sanityCheckFormatter(cell, row) {
  return <TransactionSanityCheck transaction={row} />;
}

export function columns(isAdmin) {
  const cols = [
    {
      dataField: 'cleanInvoiceId',
      text: 'DESCRIPTION',
      formatter: overflowFormatter,
      sort: false,
      sortCaret,
    },
    {
      dataField: 'transactionId',
      text: 'TRANSACTION',
      formatter: linkToDetailFormatter(isAdmin),
      sort: true,
      sortCaret,
    },
    {
      dataField: 'lastUpdated',
      text: 'DATE/TIME',
      formatter: dateTimeFormatter,
      sort: true,
      sortCaret,
    },
    {
      dataField: 'cleanPet_parent_name',
      text: 'CLIENT NAME',
      sort: false,
      sortCaret,
    },
    {
      dataField: 'net',
      text: 'NET',
      sort: false,
      sortCaret,
      formatter: currencyFormatter,
    },
    {
      dataField: 'transaction_status',
      text: 'STATUS',
      sort: false,
      sortCaret,
    },
    {
      dataField: 'pet_parent_card_type',
      text: 'CARD TYPE',
      sort: false,
      sortCaret,
      formatter: emptyValue,
    },
    {
      dataField: 'type',
      text: 'TYPE',
      sort: false,
      sortCaret,
    },
  ];
  if (Env.sanityChecksEnabled) {
    cols.push({
      dataField: 'id',
      text: '',
      formatter: sanityCheckFormatter,
    });
  }
  return cols;
}

export const defaultSorted = [{ dataField: 'time', order: 'desc' }];

export const TransactionDetail = {
  showExpandColumn: true,
  onlyOneExpanding: true,
  expandColumnPosition: 'right',
  parentClassName: 'parent-expanded',
  className: 'row-expanded',
  expandColumnRenderer,
  expandHeaderColumnRenderer,
};

function transactionViewExpander(row) {
  return <DropDownDetail transaction={row} />;
}

export const TransactionViewExpand = {
  renderer: transactionViewExpander,
  showExpandColumn: true,
  expandByColumnOnly: true,
  onlyOneExpanding: true,
  expandColumnPosition: 'right',
  parentClassName: 'parent-expanded',
  className: 'row-expanded',
  expandColumnRenderer,
  expandHeaderColumnRenderer,
};
