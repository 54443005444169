import React from 'react';
import PropTypes from 'prop-types';

const DropDownItem = ({ data }) => {
  return (
    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{data}</p>
    </div>
  );
};

DropDownItem.propTypes = {
  data: PropTypes.string,
};

export default DropDownItem;
