import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import TransactionsList from './TransactionsList';
import TransactionsHeader from './TransactionsHeader';

import usePaginatedPages from './usePaginatedPages';
import { withRouter } from 'react-router';

import { triggerSortFieldEvent } from './googleAnalyticsTriggers';

const handleError = (err) => {
  toast.error('Could not fetch transactions');

  console.error(err);
};

function Transactions(props) {
  let title = 'Transactions';

  const isAdmin = useSelector((st) => st.authReducer.roles.teamAdministrator);

  const userPractice = useSelector((st) => st.loggedPractice);

  const practiceId = isAdmin
    ? props.match?.params?.practiceId
    : userPractice.id;

  const adminPracticeList = useSelector((st) => st.practiceReducer);
  const adminPractice = adminPracticeList.find(
    (element) => element.id === practiceId
  );

  const practice = isAdmin
    ? { id: practiceId, ...adminPractice }
    : userPractice;

  if (isAdmin && practice.name) {
    title = practice.name + ' - Transactions';
  }

  const [state, dispatch] = usePaginatedPages({
    onError: handleError,
    practiceId: practice.id,
  });

  function logGASortEvent(field) {
    switch (field) {
      case 'time':
        triggerSortFieldEvent('Date', practice.coreId);
        break;
      case 'transactionId':
        triggerSortFieldEvent('Transaction ID', practice.coreId);
        break;
      default:
        break;
    }
  }

  const handleTableChange = (type, { sortField, sortOrder, data }) => {
    console.log({
      sortField,
      sortOrder,
      data,
    });
    if (type === 'sort' && data?.length) {
      if (!isAdmin) logGASortEvent(sortField);
      dispatch.setSortOrder(sortField, sortOrder);
    }
  };

  const handleSearch = (value) => {
    dispatch.setSearch(value);
  };

  const pageButtonRenderer = ({ page }) => {
    let action;

    const handleClick = (e) => {
      e.preventDefault();
      if (action instanceof Function) action();
    };

    let buttonDisplay = page;
    let classNames = ['page-item'];
    if (page === '<') {
      action = () => dispatch.prevPage();
    } else if (page === '>') {
      action = () => dispatch.nextPage();
    } else {
      classNames = ['page-item', 'active'];
      buttonDisplay = state.currentPage;
    }

    // mimics the default pagination buttons
    return (
      <li className={classNames.join(' ')} key={buttonDisplay}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='#' className='page-link' onClick={handleClick}>
          {buttonDisplay}
        </a>
      </li>
    );
  };

  const paginationOptions = {
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    nextPageText: '>',
    lastPageText: '<',
    pageButtonRenderer,
  };

  return (
    <div id='Transactions' className='transactions content'>
      <TransactionsHeader
        isAdmin={isAdmin}
        title={title}
        practiceId={practice.id}
        practiceName={practice.name}
        onSearch={handleSearch}
        coreId={practice.coreId}
      />
      <TransactionsList
        isAdmin={isAdmin}
        transactions={state.transactions || []}
        isLoading={state.isLoading}
        paginationOptions={paginationOptions}
        onTableChange={handleTableChange}
        coreId={practice.coreId}
      />
    </div>
  );
}

export default withRouter(Transactions);
