import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ResetPasswordModal = ({
  show,
  onCloseResetModal,
  user,
  sendRecoveryEmail,
}) => {
  const [showRecoveryBtn, setShowRecoveryBtn] = useState(true);
  const [recoveryConfirmed, setRecoveryConfirmed] = useState(false);
  const [passwordRecoveryMessage, setPasswordRecoveryMessage] = useState(
    'Sending password recovery email...'
  );
  const [emailSent, setEmailSent] = useState(null);

  function resetState() {
    setShowRecoveryBtn(true);
    setRecoveryConfirmed(false);
    setPasswordRecoveryMessage('Sending password recovery email...');
    setEmailSent(null);
  }

  function handleRecoveryRequest() {
    sendRecoveryEmail(user.email)
      .then(() => {
        setPasswordRecoveryMessage(
          <span>
            An email has been sent to <strong>{user.email}</strong> with further
            information on how to reset their password.
          </span>
        );
        setEmailSent(true);
      })
      .catch((err) => {
        setPasswordRecoveryMessage(
          err.userMessage ? err.userMessage : err.message
        );
        setEmailSent(false);
      });
  }

  return (
    <Modal
      id='ResetPasswordModal'
      show={show}
      onHide={() => {
        resetState();
        return onCloseResetModal();
      }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='text-center mt-4 mb-4 row'>
          <div className='col'>
            <h3>Account Recovery</h3>
          </div>
        </div>
        <div className='text-center mt-1 row'>
          <div className='col'>
            <p>
              {user.name} {user.lastName}
            </p>
          </div>
        </div>
        <div className='text-center mt-1 row'>
          <div className='col'>
            <p>{user.email}</p>
          </div>
        </div>
        <div className='text-center row'>
          <div className='form-group col-md-12'>
            {showRecoveryBtn && (
              <div className='alert alert-light mb-1'>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    setShowRecoveryBtn(false);
                    handleRecoveryRequest();
                    setRecoveryConfirmed(true);
                  }}
                >
                  Send Password Reset Email
                </button>
              </div>
            )}
            {recoveryConfirmed && (
              <div
                className={`alert alert-${
                  emailSent ? 'success' : (emailSent ?? 'light') || 'danger'
                } mt-4`}
                role='alert'
              >
                <p style={{ wordWrap: 'break-word' }}>
                  {passwordRecoveryMessage}
                </p>
                {emailSent !== null && (
                  <span
                    className='btn btn-outline-link'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleRecoveryRequest();
                      setEmailSent(null);
                      setPasswordRecoveryMessage(
                        'Sending password recovery email...'
                      );
                    }}
                  >
                    Resend
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='col-md-12 text-center'>
          <button
            className='btn btn-light mb-2'
            onClick={() => {
              resetState();
              return onCloseResetModal();
            }}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseResetModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  sendRecoveryEmail: PropTypes.func.isRequired,
};

export default ResetPasswordModal;
