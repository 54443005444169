import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Navbar, Nav, Form } from 'react-bootstrap';
import PracticeReportsExport from './PracticeReportsExport';
import PracticeReportsPrint from './PracticeReportsPrint';

const PracticeReportsHeader = ({ title, currentReport, location }) => {
  function validExportRoute() {
    const pathname = location.pathname;

    switch (pathname) {
      case '/app/practice-reports':
      case '/app/practice-reports/':
        return true;
      case '/app/practice-reports/daily-report':
      case '/app/practice-reports/daily-report/':
        return true;
      case '/app/practice-reports/transactions-report':
      case '/app/practice-reports/transactions-report/':
        return true;
      default:
        return false;
    }
  }

  return (
    <Fragment>
      <Navbar className='practices-reports-header vitus-app-header' expand='lg'>
        <Navbar.Brand>{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='header-nav-tabs' variant='pills'>
            <Nav.Item>
              <Nav.Link
                className='pl-3 pr-3'
                as={NavLink}
                to='/app/practice-reports/'
                exact
              >
                PAYOUT
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className='pl-3 pr-3'
                as={NavLink}
                to='/app/practice-reports/daily-report'
                exact
              >
                DAILY
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className='pl-3 pr-3'
                as={NavLink}
                to='/app/practice-reports/transactions-report'
              >
                TRANSACTIONS
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Form inline>
            <PracticeReportsPrint />
          </Form>
          <Form inline>
            {validExportRoute() && (
              <PracticeReportsExport currentReport={currentReport} />
            )}
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

PracticeReportsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  currentReport: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(PracticeReportsHeader);
