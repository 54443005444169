// Modules
import React, { useEffect, useRef } from 'react';

// Components
import { Form, InputGroup, Col } from 'react-bootstrap';
import Loading from 'components/Common/Loading';

// helper functions
import { currencyFormat } from 'utils/helpers/currencyFormat';

// Spreedly Functions
import { addSpreedlyJS } from '../SpreedlyEmbed/spreedlyFunctions';

// State
import { useSelector } from 'react-redux';

// CSS
import styles from './SpreedlyFormStep.module.scss';

const SpreedlyFormStep = (props) => {
  const {
    isFormLoading,
    totalAmount,
    validationErrors,
    petOwnerName,
    cardHolderName,
    setCardHolderName,
    currentCardMonth,
    setCurrentCardMonth,
    currentCardYear,
    setCurrentCardYear,
    selectedPaymentMethod,
    handleTokenSuccess,
    handleSpreedlyError,
    inputData,
    validInputFields,
    setValidInputFields,
    handleSubmit,
    spreedlyValidationObj,
    setSpreedlyValidationObj,
    isSaveCardChecked,
    setSaveCardChecked,
    isPetOwnerSelected,
  } = props;

  const payWithSavedCardEnabled = useSelector(
    (s) => s.loggedPractice.payWithSavedCards
  );

  const isPayingWithSelectedCard = useRef(false);
  const isMounted = useRef(false);
  const runValidation = useRef(false);

  useEffect(() => {
    // we do not need to add spreedly if we have a selectedPaymentMethod
    if (selectedPaymentMethod === null) {
      /**
       * By using the ref here we can ensure that
       * addSpreedlyJS only runs once when the component
       * mounts for the first time
       */
      if (!isMounted.current) {
        isMounted.current = true;

        console.log('adding spreedly js');
        addSpreedlyJS({
          handleSpreedlyValidation: setSpreedlyValidationObj,
          handleSpreedlyError,
        });
      }

      return;
    }

    if (!isPayingWithSelectedCard.current) {
      // force not saving the card (necessary as it is selected by default)
      handleTokenSuccess(selectedPaymentMethod.payment_method_token, false);
      isPayingWithSelectedCard.current = true;
    }
  }, [
    handleTokenSuccess,
    selectedPaymentMethod,
    isPayingWithSelectedCard,
    handleSpreedlyError,
    setSpreedlyValidationObj,
  ]);

  useEffect(() => {
    if (!spreedlyValidationObj) return;

    runValidation.current = true;
  }, [spreedlyValidationObj]);

  useEffect(() => {
    if (
      !spreedlyValidationObj ||
      !inputData ||
      !runValidation.current ||
      selectedPaymentMethod
    )
      return;

    runValidation.current = false;

    /**
     * Example validation object from Spreedly:
     *  {
     *     "cardType": "visa",
     *     "validNumber": false,
     *     "numberLength": 4,
     *     "validCvv": true,
     *     "cvvLength": 3,
     *     "luhnValid": false
     *  }
     */
    const { validNumber, validCvv, cvvLength, cardType } =
      spreedlyValidationObj;

    const cvvIsValid =
      (cvvLength === 3 && cardType !== 'american_express') ||
      (cvvLength === 4 && cardType === 'american_express');

    const updatedValidInputFields = {
      ...validInputFields,
      cardNumber: validNumber,
      cvv: validCvv,
      cvvLength: cvvIsValid,
    };

    const isValid = Object.values(updatedValidInputFields).every(
      (bool) => bool
    );

    console.log(isValid);

    if (isValid) {
      window.Spreedly.tokenizeCreditCard(inputData);
    } else {
      setValidInputFields(updatedValidInputFields);
    }
  }, [
    spreedlyValidationObj,
    inputData,
    validInputFields,
    setValidInputFields,
    selectedPaymentMethod,
  ]);

  return isFormLoading ? (
    <Loading></Loading>
  ) : (
    <Form autoComplete='off' onSubmit={handleSubmit}>
      <p className='text-center'>
        Total Amount
        <br />
        {/* helper function works on cent amounts */}
        <strong>${currencyFormat(totalAmount * 100)}</strong>
      </p>
      <Form.Group controlId='cardHolderName'>
        <Form.Label>Card Holder Name</Form.Label>
        <Form.Control
          type='text'
          name='cardHolderName'
          placeholder='Card Holder Name'
          isInvalid={!!validationErrors['cardHolderName']}
          onChange={(event) => {
            const value = event.target.value.replace(/[^a-zA-Z\s]/g, ''); //remove all non-letter characters
            setCardHolderName(value);
          }}
          defaultValue={cardHolderName ? cardHolderName : petOwnerName}
        />
        <p style={{ color: 'red' }}>
          {validInputFields.fullName
            ? ''
            : 'First and last name separated by a space'}
        </p>
      </Form.Group>
      <label aria-label='credit card number'>
        Card number
        <div id='spreedly-number' className={styles.spreedlyNumber}></div>
        <p style={{ color: 'red', marginTop: '-14px' }}>
          {validInputFields.cardNumber
            ? ''
            : 'Invalid card number, please verify'}
        </p>
      </label>
      <Form.Row>
        <Form.Group as={Col} controlId='expirationDate'>
          <Form.Label>Expiration Date</Form.Label>
          <InputGroup>
            <Form.Control
              type='text'
              name='expirationDateMonth'
              placeholder='MM'
              maxLength='2'
              defaultValue={currentCardMonth}
              onChange={(event) => {
                setCurrentCardMonth(event.target.value);
              }}
            />
            <span>
              &nbsp;<strong>/</strong>&nbsp;
            </span>
            <Form.Control
              type='text'
              name='expirationDateYear'
              placeholder='YY'
              maxLength='2'
              defaultValue={currentCardYear}
              onChange={(event) => {
                setCurrentCardYear(event.target.value);
              }}
            />

            <p style={{ color: 'red' }}>
              {validInputFields.expirationDate &&
              validInputFields.month &&
              validInputFields.year
                ? ''
                : 'Invalid expiration date'}
            </p>
          </InputGroup>
        </Form.Group>
        <Col>
          <label aria-label='credit card cvv/cvc code'>
            CVV
            <div id='spreedly-cvv' className={styles.spreedlyCVV}></div>
            <p style={{ color: 'red', marginTop: '-14px' }}>
              {validInputFields.cvv && validInputFields.cvvLength
                ? ''
                : 'Invalid CVV, please verify'}
            </p>
          </label>
        </Col>
      </Form.Row>

      {payWithSavedCardEnabled && (
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            id='saveCardCheckbox'
            type='checkbox'
            checked={isSaveCardChecked}
            onChange={() => setSaveCardChecked((c) => !c)}
          />
          <label className={styles.checkboxLabel} htmlFor='saveCardCheckbox'>
            {isPetOwnerSelected ? 'Overwrite Saved Card' : 'Securely Save Card'}
          </label>
          <i className='fa fa-lock' />
        </div>
      )}
    </Form>
  );
};

export default SpreedlyFormStep;
