//if there is a new other name for credit card type in database must be included here.
import visa from 'assets/images/visa.png';
import mastercard from 'assets/images/mastercard.png';
import discover from 'assets/images/discover.png';
import americanExpress from 'assets/images/americanExpress.png';
import jcb from 'assets/images/jcb.png';
import diners from 'assets/images/diners.png';
import unionpay from 'assets/images/unionpay.png';

export const CreditCardTypes = Object.freeze({
  americanExpress: {
    displayName: 'American Express',
    otherNames: ['american_express', 'americanexpress', 'amex'],
    imageUrl: americanExpress,
  },
  diners: {
    displayName: 'diners',
    otherNames: ['diners'],
    imageUrl: diners,
  },
  discover: {
    displayName: 'Discover',
    otherNames: ['discover'],
    imageUrl: discover,
  },
  jcb: {
    displayName: 'JCB',
    otherNames: ['jcb'],
    imageUrl: jcb,
  },
  mastercard: {
    displayName: 'Mastercard',
    otherNames: ['mc', 'mastercard', 'master'],
    imageUrl: mastercard,
  },
  unionpay: {
    displayName: 'UnionPay',
    otherNames: ['unionpay'],
    imageUrl: unionpay,
  },
  visa: {
    displayName: 'VISA',
    otherNames: ['visa'],
    imageUrl: visa,
  },
});

export const AllKnownCardNames = Object.freeze(
  Object.entries(CreditCardTypes).reduce((acc, [key, value]) => {
    value.otherNames.forEach((name) => (acc[name] = key));
    return acc;
  }, {})
);
