import Env from 'Env';
import Auth from 'utils/auth/index';

export const webSocketConnection = (function () {
  const websocketApiEndpoint = Env.endpoints.websocket;
  const auth = new Auth();
  const {
    getAccessToken,
    renewSession,
    handleAuthentication,
    isAuthenticated,
  } = auth;

  let socket = null;
  let keepAliveTimerId = 0;

  return async () => {
    try {
      if (socket instanceof WebSocket) return socket;

      await initWebSocket();

      socket.addEventListener('open', keepAlive);
      socket.keepAlive = keepAlive;

      return socket;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  async function initWebSocket() {
    await refreshAccessTokenIfExpired();

    socket = new WebSocket(
      `${websocketApiEndpoint}?authorizer=${getAccessToken()}`
    );
  }

  async function refreshAccessTokenIfExpired() {
    const authenticated = isAuthenticated(true);
    const shouldRenewSession =
      typeof authenticated === 'boolean' && !authenticated;

    if (shouldRenewSession) {
      const result = await renewSession();
      handleAuthentication(result);
    }
  }

  function keepAlive() {
    let timeout = 55000;

    if (socket.readyState === socket.OPEN) {
      const msg = {
        service: 'practiceWebsocketConnections',
        action: 'keepAlive',
      };

      socket.send(JSON.stringify(msg));
    } else {
      cancelKeepAlive();
    }

    keepAliveTimerId = setTimeout(keepAlive, timeout);
  }

  function cancelKeepAlive() {
    if (keepAliveTimerId) clearTimeout(keepAliveTimerId);
  }
})();
