import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { unsetSelectedPractice } from 'utils/store/actions/practiceInfoActions';

const TransactionHeader = ({ title }) => {
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    unsetSelectedPractice();
    history.goBack();
  };

  return (
    <Navbar className='practices-header vitus-app-header' expand='lg'>
      <Navbar.Brand as='a' href='#' onClick={handleClick}>
        <i className='fas fa-chevron-left text-secondary'></i>
        <small className='ml-2'>{title}</small>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls='app-header-collapsible' />
      <Navbar.Collapse id='app-header-collapsible'></Navbar.Collapse>
    </Navbar>
  );
};

TransactionHeader.propTypes = {
  title: PropTypes.string,
};

export default TransactionHeader;
