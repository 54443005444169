import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loading from '../Loading';

const Refresher = ({
  isRefreshing = false,
  onRefresh = null,
  refreshMessage = 'Refreshing...',
  lastUpdateMessage = 'Last Updated',
  showLastUpdated = true,
}) => {
  const [lastUpdated, setlastUpdated] = useState(moment().format());
  const [refreshedOn, setRefreshedOn] = useState(moment().format());

  useEffect(() => {
    const interval = setInterval(() => {
      setlastUpdated(moment(refreshedOn).fromNow());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refreshedOn]);

  useEffect(() => {
    setRefreshedOn(moment().format());
    setlastUpdated(moment(refreshedOn).fromNow());
  }, [isRefreshing, refreshedOn]);

  return (
    <span className='vitus-refresher vitus-secondary-text-gray'>
      {isRefreshing ? (
        <span className='d-inline-flex align-items-center'>
          <Loading style={{}} />
          <span className='ml-2'>{refreshMessage}</span>
        </span>
      ) : (
        <span>
          {showLastUpdated && (
            <span>
              {lastUpdateMessage} {lastUpdated}
            </span>
          )}
          {onRefresh && (
            <i
              className='vitus-refresher-icon fas fa-redo ml-1'
              onClick={() => onRefresh()}
            ></i>
          )}
        </span>
      )}
    </span>
  );
};

Refresher.propTypes = {
  isRefreshing: PropTypes.bool,
  onRefresh: PropTypes.func,
  refreshMessage: PropTypes.string,
  lastUpdateMessage: PropTypes.string,
  showLastUpdated: PropTypes.bool,
};

export default Refresher;
