// Modules
import React from 'react';
import ContactSupportHeader from './ContactSupportHeader';

export default function ContactSupport() {
  const contacts = [
    {
      name: 'VitusPay Portal & Text to Pay',
      departments: [
        {
          name: 'VitusPay Support',
          phoneNumber: '1-877-969-3124',
          schedules: ['Monday - Friday\t8:00 AM EST to 5:00 PM EST'],
        },
      ],
    },
    {
      name: 'SplitIt Payment Plans',
      departments: [
        {
          name: 'SplitIt Support',
          phoneNumber: '1-844-775-4848',
          schedules: ['24 hours a day, 7 days a week'],
        },
      ],
    },
    {
      name: 'InStore Portal',
      departments: [
        {
          name: 'PayBright Customer Support',
          phoneNumber: '1-800-327-0093',
          schedules: ['Monday - Friday\t9:00 AM EST to 9:00 PM EST'],
        },
      ],
    },
    {
      name: 'Pax Devices',
      departments: [
        {
          name: 'PayBright Terminal Support',
          phoneNumber: '1-800-327-0093',
          schedules: ['24 hours a day, 7 days a week'],
        },
        {
          name: 'Pax Support',
          phoneNumber: '1-877-859-0099',
          schedules: [
            'Monday - Friday\t9:00 AM EST to 1:00 AM EST',
            'Saturday\t\t9:00 AM EST to 5:00 PM EST',
          ],
        },
        {
          name: 'EVO Merchant Support',
          phoneNumber: '1-866-513-2628',
          schedules: ['24 hours a day, 7 days a week'],
        },
      ],
    },
    {
      name: 'Clover Devices',
      departments: [
        {
          name: 'PayBright Terminal Support',
          phoneNumber: '1-800-327-0093',
          schedules: ['24 hours a day, 7 days a week'],
        },
        {
          name: 'Clover Support ',
          phoneNumber: '1-855-853-8340',
          schedules: ['24 hours a day, 7 days a week'],
        },
      ],
    },
    {
      name: 'Dejavoo Devices',
      departments: [
        {
          name: 'PayBright Terminal Support',
          phoneNumber: '1-800-327-0093',
          schedules: ['24 hours a day, 7 days a week'],
        },
        {
          name: 'Dejavoo Support ',
          phoneNumber: '1-877-358-6797',
          schedules: ['24 hours a day, 7 days a week'],
        },
      ],
    },
    {
      name: 'EVO Payments',
      departments: [
        {
          name: 'EVO Merchant Support',
          phoneNumber: '1-866-513-2628',
          schedules: ['24 hours a day, 7 days a week'],
        },
      ],
    },
  ];

  return (
    <div className='content'>
      <ContactSupportHeader title='Contact Support' />
      <div id='DailyTransactions' className='daily-transactions content'>
        <div className='pr-8 pl-8 my-4'>
          <div className='row mb-3'>
            <h2>I need help with...</h2>
          </div>
          {contacts.map((contact) => (
            <div key={contact.name}>
              <h3 className='mb-3 ml-3'>{contact.name}</h3>
              {contact.departments.map((department) => (
                <div
                  className='row mb-3 ml-5 justify-content-end'
                  key={department.name}
                >
                  <div className='col-md-3'>{department.name}</div>
                  <div className='col-md-2'>{department.phoneNumber}</div>
                  {department.schedules.map((schedule, idx) => (
                    <div className='col-md-7 ofsset-5' key={idx}>
                      <pre
                        style={{
                          fontFamily: 'inherit',
                          fontSize: 'inherit',
                          color: 'inherit',
                        }}
                      >
                        {schedule}
                      </pre>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
