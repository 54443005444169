// Modules
import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

// CSS
import styles from './PracticeReports.module.scss';

// Components
import PracticeReportsHeader from './PracticeReportsHeader';
import PracticeReportsDaily from './PracticeReportsDaily';
import PracticeReportsPayouts from './PracticeReportsPayouts';
import PracticeReportsTransactions from './PracticeReportsTransactions';
import Transaction from '../TransactionDetail/Transaction';

class PracticeReports extends Component {
  state = {
    currentReport: { type: '', data: [] },
  };

  setCurrentReport = (report) => {
    this.setState({ currentReport: report });
  };

  render() {
    const { currentReport } = this.state;

    return (
      <div
        className={`reports content ${styles.practiceReports}`}
        id='PracticeReports'
      >
        <Fragment>
          <PracticeReportsHeader
            title='Reports'
            currentReport={currentReport}
            setCurrentReport={this.setCurrentReport}
          />
          <Switch>
            <Route
              path='/app/practice-reports'
              exact
              render={() => (
                <PracticeReportsPayouts
                  setCurrentReport={this.setCurrentReport}
                />
              )}
            />
            <Route
              path='/app/practice-reports/daily-report'
              exact
              render={() => (
                <PracticeReportsDaily
                  setCurrentReport={this.setCurrentReport}
                />
              )}
            />
            <Route
              path='/app/practice-reports/transactions-report'
              exact
              render={() => (
                <PracticeReportsTransactions
                  setCurrentReport={this.setCurrentReport}
                />
              )}
            />
            <Route
              path='/app/practice-reports/transactions-report/:transactionId'
              exact
              component={Transaction}
            />
          </Switch>
        </Fragment>
      </div>
    );
  }
}

export default PracticeReports;
