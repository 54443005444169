// this file exists to standardize first and last names
// in most of our form fields we have a 'full name' field
// and have to make decisions about how to parse this
// for our purposes we will consider all names before the final
// name the 'first name' and the final name the 'last name'
//
// example:
//   Mary Beth Smith
//    first: Mary Beth
//    last: Smith

// capture entire first name (including middle name if present)
export function getFirstName(fullName) {
  const nameArr = fullName.split(' ');
  if (nameArr.length === 1) return nameArr[0];
  return nameArr.slice(0, -1).join(' ');
}

// capture last word in string as last name
// return '' if no last name found
export function getLastName(fullName) {
  const nameArr = fullName.split(' ');
  if (nameArr.length === 1) return ''; // there is no last name
  return nameArr.slice(-1).join();
}

export function parseNames(fullName) {
  return [getFirstName(fullName), getLastName(fullName)];
}

export function capitalizeName(fullName) {
  return fullName
    .toLowerCase()
    .split(' ')
    .map((name) => {
      return name ? name[0].toUpperCase() + name.slice(1) : '';
    })
    .join(' ');
}

export function isFullName(name) {
  const [firstName, lastName] = parseNames(name);

  return !!(firstName && lastName);
}
