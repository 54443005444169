import React from 'react';
import { Form } from 'react-bootstrap';

// Used in both first and second steps
const DescriptionInput = (props) => {
  const { description, setDescription, validationError } = props;

  return (
    <Form.Group controlId='description'>
      <Form.Label>Description</Form.Label>
      <Form.Control
        type='text'
        name='description'
        placeholder='Invoice ID/Patient Name'
        defaultValue={description}
        isInvalid={!!validationError}
        onChange={(event) => {
          setDescription(event.target.value);
        }}
      />
      <Form.Control.Feedback type='invalid'>
        {validationError}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default DescriptionInput;
