import React from 'react';
import PropTypes from 'prop-types';

const Currency = ({
  amount,
  bolded = false,
  negative = false,
  defaultToZero = false,
}) => {
  function formatCurrency(val) {
    return Number.parseFloat(val / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  if (!parseFloat(amount)) {
    if (defaultToZero) {
      return <span>$ 0.00</span>;
    }

    return (
      <span
        className='vitus-light-gray'
        style={{ letterSpacing: '-2px', fontSize: '16px' }}
      >
        ------
      </span>
    );
  }

  return (
    <span
      className={`text-nowrap
            ${bolded ? 'font-weight-bold' : ''}
        `}
    >
      {negative && '-'} $ {formatCurrency(amount)}
    </span>
  );
};

Currency.propTypes = {
  amount: PropTypes.number,
  bolded: PropTypes.bool,
  negative: PropTypes.bool,
  defaultToZero: PropTypes.bool,
};

export default Currency;
