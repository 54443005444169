import React from 'react';
import { Form, Button } from 'react-bootstrap';
import PetOwnerNameInput from './CustomInputFields/PetOwnerNameInput';
import DescriptionInput from './CustomInputFields/DescriptionInput';
import EmailInput from './CustomInputFields/EmailInput';
import PaymentPlanOptionCheckbox from './CustomInputFields/PaymentPlanOptionCheckbox';
import Loading from 'components/Common/Loading';
import { currencyFormat } from 'utils/helpers/currencyFormat';
import { sendEmailToPayRequestGAEventHandler } from './googleAnalyticsHandlers';

const EmailToPayStep = (props) => {
  const isInstallmentsHidden =
    Number(props.totalAmount) < Number(props.installmentsMinAmount);

  const sendEmailToPayOnClickHandler = () => {
    sendEmailToPayRequestGAEventHandler(props.totalAmount);
    props.step2Submit();
  };

  return props.isFormLoading ? (
    <Loading></Loading>
  ) : (
    <Form autoComplete='off'>
      <p className='text-center'>
        Total Amount
        <br />
        <strong>${currencyFormat(props.totalAmount * 100)}</strong>
      </p>
      <PetOwnerNameInput
        petOwnerName={props.petOwnerName}
        setPetOwnerName={props.setPetOwnerName}
        validationError={props.validationErrors['petOwnerName']}
      />
      <EmailInput
        emailAddress={props.emailAddress}
        setEmailAddress={props.setEmailAddress}
        validationError={props.validationErrors['emailAddress']}
      />
      <DescriptionInput
        description={props.description}
        setDescription={props.setDescription}
        validationError={props.validationErrors['description']}
      />
      {props.installmentsEnabled && !isInstallmentsHidden && (
        <PaymentPlanOptionCheckbox
          includePaymentPlanOption={props.includePaymentPlanOption}
          setIncludePaymentPlanOption={props.setIncludePaymentPlanOption}
        />
      )}
      <div className='text-center'>
        <Button
          variant='secondary'
          onClick={sendEmailToPayOnClickHandler}
          disabled={false}
        >
          Send Email to Pay Request
        </Button>
      </div>
    </Form>
  );
};

export default EmailToPayStep;
