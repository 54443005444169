import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as staffMemberActions from 'utils/store/actions/staffMemberActions';

const DeleteButton = ({
  setSelectedStaffMember,
  toggleDeleteStaffMemberAction,
  setStaffMemberToInactive,
  selectedStaffMember,
}) => {
  const handleClick = () => {
    setSelectedStaffMember(selectedStaffMember);
    setStaffMemberToInactive(selectedStaffMember);
    toggleDeleteStaffMemberAction(true);
  };

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='top'
      overlay={
        <Popover className='popover-basic'>
          <Popover.Content>Remove</Popover.Content>
        </Popover>
      }
    >
      <button className='btn btn-link' onClick={handleClick}>
        <span className='sr-only'>Edit item</span>
        <i className='fas fa-trash-alt' aria-hidden='true'></i>
      </button>
    </OverlayTrigger>
  );
};

DeleteButton.propTypes = {
  id: PropTypes.string.isRequired,
  setSelectedStaffMember: PropTypes.func.isRequired,
  selectedStaffMember: PropTypes.object.isRequired,
  toggleDeleteConfirmation: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  staffMembers: state.staff,
  selectedStaffMember: state.staff.find((s) => s.id === ownProps.id),
  toggleDeleteStaffMemberConfirmation:
    state.toggleDeleteStaffMemberConfirmation,
});

const mapDispatchToProps = {
  ...staffMemberActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteButton);
