import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const WorkingAreaTooltip = ({
  icon = 'fas fa-hammer',
  content = 'This feature is still under development',
  placement = 'top',
}) => {
  return (
    <span className='space-nowrap' style={{ fontSize: 'inherit' }}>
      (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement={placement}
        overlay={
          content ? (
            <Popover className='popover-basic'>
              <Popover.Content>{content}</Popover.Content>
            </Popover>
          ) : null
        }
      >
        <i className={icon} aria-hidden='true'></i>
      </OverlayTrigger>
      )
    </span>
  );
};

WorkingAreaTooltip.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.string,
  placement: PropTypes.string,
};

export default WorkingAreaTooltip;
