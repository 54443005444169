import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlimTable from 'components/Common/SlimTable/SlimTable';
import moment from 'moment';

import * as reportingActions from 'utils/store/actions/reportingActions';
import {
  getSummaryColumns,
  getCardTypeSummaryColumns,
  transactionColumns,
} from './config/transactions-report-config';
import { isInclusivelyAfterDay } from 'react-dates';
import InputField from 'components/Common/InputField/InputField';
import SelectField from 'components/Common/SelectField/SelectField';
import currencyFormat from 'utils/helpers/currencyFormat';
import DateRangePicker from 'components/Common/DateRangePicker/DateRangePicker';
import { CreditCardTypes, AllKnownCardNames } from 'constants/CreditCardTypes';

class PracticeReportsTransactions extends Component {
  state = {
    transactionsByDateRange: [],
    filteredTransactions: [],
    isLoading: false,
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    filters: {
      transactionId: '',
      amount: '',
      staffMember: '',
      cardType: '',
      status: '',
    },
    staffMembers: [],
    statusOptions: [],
    cardTypes: [],
    dateRangeError: false,
  };

  renderCalendarInstructions = () => {
    return (
      <div className='p-1 pr-3'>
        <small className='pl-3'>Instructions</small>
        <ul>
          <li>
            <small>
              By default, the <strong>last 30 days</strong> will be displayed
              until you select a different date range
            </small>
          </li>
          <li>
            <small>
              To change the date range first choose a{' '}
              <strong>start date</strong> followed by an{' '}
              <strong>end date</strong>
            </small>
          </li>
          <li>
            <small>
              Date selection range is limited to <strong>2 months</strong>
            </small>
          </li>
          <li>
            <small>
              To view a <strong>single day</strong>, select the{' '}
              <strong>same date twice</strong>
            </small>
          </li>
        </ul>
      </div>
    );
  };

  aggregateSummary = (transactions) => {
    let aggregationRows = ['full', 'monthly', 'total'].filter((a) => a);
    let aggregation = aggregationRows.map((type) => {
      // TODO: Text2Pay transaction report
      let typeTransactions =
        type === 'full'
          ? transactions.filter((t) => t.isMerchant || t.isPaymentLink)
          : type === 'monthly'
          ? transactions.filter((t) => t.isInstallments)
          : transactions;
      let result = {
        id: type,
        title: {
          type: type,
          count: 0,
        },
        amount: 0,
        refunded: 0,
        fees: 0,
        net: 0,
      };
      typeTransactions.forEach((t) => {
        result.title.count++;
        result.amount += t.amount;
        result.refunded += t.amountRefunded;
        result.fees += t.fee_amount;
        result.net += t.net;
      });

      if (result.id === 'monthly' && result.title.count <= 0) {
        return null;
      }

      return result;
    });

    return aggregation.filter((a) => a !== null);
  };

  aggregateCreditCardSummary = (transactions) => {
    const creditCardSummary = Object.keys(CreditCardTypes).reduce(
      (acc, curr) => {
        acc[curr] = {
          cardType: curr,
          amount: 0,
          refunded: 0,
          totalTransaction: 0,
          totalRefund: 0,
          totalTrx: '',
          id: curr,
        };
        return acc;
      },
      {}
    );

    // To maintain 'other' type always in the last position of the list.
    const creditCardSummaryWithOther = {
      ...creditCardSummary,
      other: {
        cardType: 'other',
        amount: 0,
        refunded: 0,
        totalTransaction: 0,
        totalRefund: 0,
        totalTrx: '',
        id: 'other',
      },
    };

    const creditCardSummaryData = transactions.reduce((acc, transaction) => {
      const cardType = transaction['pet_parent_card_type'].toLowerCase();
      const accKey = AllKnownCardNames[cardType] ?? 'other';
      const { amount, refunded, totalTransaction, totalRefund } = acc[accKey];

      acc[accKey] = {
        ...acc[accKey],
        amount: amount + transaction.amount,
        refunded: refunded + transaction.amountRefunded,
        totalTransaction: totalTransaction + 1,
        totalRefund:
          transaction.amountRefunded > 0 ? totalRefund + 1 : totalRefund,
      };

      return acc;
    }, creditCardSummaryWithOther);

    return Object.values(creditCardSummaryData)
      .map((obj) => ({
        ...obj,
        totalTrx: `${obj.totalTransaction} / (${obj.totalRefund})`,
      }))
      .filter((obj) => obj.totalTransaction > 0);
  };

  validDateRange = (day, focusedInput) => {
    const { startDate } = this.state;

    if (focusedInput === 'startDate') {
      return isInclusivelyAfterDay(day, moment().add(1, 'days'));
    }

    if (focusedInput === 'endDate' && startDate) {
      if (isInclusivelyAfterDay(day, moment().add(1, 'days'))) {
        return true;
      }

      if (isInclusivelyAfterDay(day, startDate.clone().add(62, 'days'))) {
        return true;
      }

      return false;
    }

    return true;
  };

  dateRangeFilter = (transactions, startDate, endDate) => {
    const epochStart = Date.parse(startDate.clone().subtract(12, 'hours'));
    const epochEnd = Date.parse(endDate.clone().add(12, 'hours'));

    return transactions.filter((transaction) => {
      return transaction.time > epochStart && transaction.time < epochEnd;
    });
  };

  applyFilters = (transactions) => {
    const {
      filters: { transactionId, amount, staffMember, status, cardType },
    } = this.state;

    if (transactionId) {
      transactions = transactions.filter((t) =>
        t.transactionId.toLowerCase().includes(transactionId.toLowerCase())
      );
    }

    if (amount) {
      transactions = transactions.filter((t) =>
        currencyFormat(t.amount).match(
          new RegExp(`^${amount.replace('.', '\\.')}.*`, 'i')
        )
      );
    }

    if (staffMember) {
      transactions = transactions.filter((t) => t.staff_member === staffMember);
    }

    if (status) {
      transactions = transactions.filter(
        (t) => t.transaction_status === status
      );
    }

    if (cardType) {
      transactions = transactions.filter((t) => {
        if (CreditCardTypes[cardType]) {
          return CreditCardTypes[cardType].otherNames.includes(
            t.pet_parent_card_type.toLowerCase()
          );
        }

        return t.pet_parent_card_type === cardType;
      });
    }

    return transactions;
  };

  resetFilters = () => {
    this.setState({
      filters: {
        transactionId: '',
        amount: '',
        staffMember: '',
        cardType: '',
        status: '',
      },
    });
  };

  selectOptions = (transactions, valueName) => {
    let status = new Set();

    return transactions
      .filter((t) => {
        if (!status.has(t[valueName])) {
          status.add(t[valueName]);
          return true;
        }

        return false;
      })
      .map((t) => {
        return { value: t[valueName], id: t.transactionId };
      });
  };

  selectCardTypeOptions = (transactions) => {
    const cardTypeOptions = [];
    const status = new Set();
    transactions.forEach((transaction) => {
      const card = transaction['pet_parent_card_type'];
      const cardTypeKey = AllKnownCardNames[card.toLowerCase()] ?? card;

      if (!status.has(cardTypeKey)) {
        status.add(cardTypeKey);

        cardTypeOptions.push({
          value: cardTypeKey,
          label: CreditCardTypes[cardTypeKey]
            ? CreditCardTypes[cardTypeKey].displayName
            : cardTypeKey,
        });
      }
    });

    return cardTypeOptions.sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  onFilterChange = (value, name) => {
    this.setState({ filters: { ...this.state.filters, [name]: value } }, () =>
      this.setState(
        {
          filteredTransactions: this.applyFilters(
            this.state.transactionsByDateRange
          ),
        },
        () =>
          this.props.setCurrentReport({
            type: 'transactions',
            data: this.state.filteredTransactions,
          })
      )
    );
  };

  onClearFilters = () => {
    const { transactionsByDateRange } = this.state;
    const { setCurrentReport } = this.props;

    this.setState(
      {
        filters: {
          transactionId: '',
          amount: '',
          staffMember: '',
          cardType: '',
          status: '',
        },
      },
      () => {
        this.setState(
          {
            filteredTransactions: this.applyFilters(transactionsByDateRange),
          },
          () =>
            setCurrentReport({
              type: 'transactions',
              data: this.state.filteredTransactions,
            })
        );
      }
    );
  };

  fetchTransactionsReport = async () => {
    const { getTransactionReport, setCurrentReport } = this.props;

    const { startDate, endDate } = this.state;
    setCurrentReport({
      type: '',
      data: [],
    });

    if (startDate && endDate) {
      this.setState({
        isLoading: true,
        dateRangeError: false,
        filteredTransactions: [],
      });

      await getTransactionReport(startDate, endDate)
        .then(() => {
          const { transactions } = this.props;

          this.resetFilters();
          const transactionsByDateRange = this.dateRangeFilter(
            transactions,
            startDate,
            endDate
          );
          const filteredTransactions = this.applyFilters(
            transactionsByDateRange
          );

          this.setState(
            {
              isLoading: false,
              filteredTransactions,
              transactionsByDateRange,
              staffMembers: this.selectOptions(
                transactionsByDateRange,
                'staff_member'
              ),
              statusOptions: this.selectOptions(
                transactionsByDateRange,
                'transaction_status'
              ),
              cardTypes: this.selectCardTypeOptions(transactionsByDateRange),
            },
            () =>
              setCurrentReport({
                type: 'transactions',
                data: filteredTransactions,
              })
          );
        })
        .catch(() => {
          this.setState({ isLoading: false });
          toast.error('Unable to get transactions report');
        });
    } else {
      this.setState({ dateRangeError: true });
    }
  };

  async componentDidMount() {
    const { startDate, endDate } = this.state;
    const { setCurrentReport } = this.props;

    setCurrentReport({ type: '', data: [] });

    if (startDate && endDate) {
      await this.fetchTransactionsReport();
    }
  }

  async componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.state;
    const { setCurrentReport, mostRecentTransactionFromWebSocket } = this.props;
    const shouldUpdateTransactions =
      prevProps.mostRecentTransactionFromWebSocket.status !==
        mostRecentTransactionFromWebSocket.status ||
      prevProps.mostRecentTransactionFromWebSocket.id !==
        mostRecentTransactionFromWebSocket.id;
    if (shouldUpdateTransactions) {
      setCurrentReport({ type: '', data: [] });
      if (startDate && endDate) {
        await this.fetchTransactionsReport();
      }
    }
  }

  render() {
    const {
      filteredTransactions,
      isLoading,
      startDate,
      endDate,
      dateRangeError,
    } = this.state;

    const aggregateSummary = this.aggregateSummary(filteredTransactions);
    const shouldHideSummaryFeesColumn = !aggregateSummary
      .map((row) => row.id)
      .includes('monthly');

    const aggregateCreditCardSummary =
      this.aggregateCreditCardSummary(filteredTransactions);

    return (
      <Fragment>
        <div className='p-3 pb-4 m-3 bg-white rounded shadow-sm'>
          <div className='flex-wrap d-flex align-items-center'>
            <DateRangePicker
              label='Date Range'
              startDate={startDate}
              startDateId='transactionsStartDate'
              endDate={endDate}
              endDateId='transactionsEndDate'
              onDatesChange={({
                startDate: start,
                endDate: end,
                focusedInput,
              }) => {
                if (focusedInput === 'startDate') {
                  this.setState({ startDate: start, endDate: null });
                } else {
                  this.setState(
                    { startDate: start, endDate: end },
                    async () => {
                      await this.fetchTransactionsReport();
                    }
                  );
                }
              }}
              minimumNights={0}
              hideKeyboardShortcutsPanel={true}
              isOutsideRange={({ day, focusedInput }) =>
                this.validDateRange(day, focusedInput)
              }
              renderCalendarInfo={() => this.renderCalendarInstructions()}
              disabled={isLoading}
              error={dateRangeError}
              errorMessage={'Please enter a valid start date and end date'}
            />
          </div>
          <hr />
          <div className='flex-wrap gap-2 d-flex'>
            <InputField
              label='Transaction ID'
              name='transactionId'
              placeholder='Find transaction'
              iconClass='fas fa-search'
              value={this.state.filters.transactionId}
              onChange={({ target: { value, name } }) =>
                this.onFilterChange(value.trim(), name)
              }
            />
            <InputField
              label='Amount'
              type='number'
              name='amount'
              placeholder='0.00'
              iconClass='fas fa-dollar-sign'
              inputStyling={{ width: '145px' }}
              value={this.state.filters.amount}
              onChange={({ target: { value, name } }) =>
                this.onFilterChange(value, name)
              }
            />
            <SelectField
              label='Staff Member'
              name='staffMember'
              placeholder='All Members'
              iconClass='fas fa-user'
              value={this.state.filters.staffMember}
              selectStyling={{
                minWidth: '250px',
                maxWidth: '300px',
              }}
              options={this.state.staffMembers}
              onChange={({ target: { value, name } }) =>
                this.onFilterChange(value, name)
              }
            />
            <SelectField
              label='Card Type'
              name='cardType'
              placeholder='All'
              iconClass='fas fa-credit-card'
              value={this.state.filters.cardType}
              selectStyling={{
                minWidth: '75px',
                maxWidth: '150px',
              }}
              options={this.state.cardTypes}
              onChange={({ target: { value, name } }) =>
                this.onFilterChange(value, name)
              }
            />
            <SelectField
              label='Status'
              name='status'
              placeholder='All'
              iconClass='fas fa-check-double'
              value={this.state.filters.status}
              selectStyling={{
                minWidth: '75px',
                maxWidth: '150px',
              }}
              options={this.state.statusOptions}
              onChange={({ target: { value, name } }) =>
                this.onFilterChange(value, name)
              }
            />
            <div className='pl-3 pr-2 d-flex align-items-center'>
              <button
                className='btn btn-secondary'
                onClick={this.onClearFilters}
              >
                Clear Filters
              </button>
            </div>
          </div>
        </div>
        <div className='d-xl-flex'>
          <SlimTable
            keyField='id'
            columns={getSummaryColumns(shouldHideSummaryFeesColumn)}
            data={aggregateSummary}
            title='Summary'
            isLoading={isLoading}
            hasContainer={true}
            refresher={true}
            refreshMessage='Calculating...'
            showLastUpdated={true}
            onRefresh={async () => this.fetchTransactionsReport()}
            flex='4'
          />
          <SlimTable
            keyField='id'
            columns={getCardTypeSummaryColumns}
            data={aggregateCreditCardSummary}
            title='Card Type Summary'
            isLoading={isLoading}
            hasContainer={true}
            refresher={true}
            refreshMessage='Calculating...'
            showLastUpdated={true}
            onRefresh={async () => this.fetchTransactionsReport()}
            flex='3'
          />
        </div>

        <SlimTable
          keyField='id'
          columns={transactionColumns}
          data={filteredTransactions}
          title='Transactions'
          isLoading={isLoading}
          hasContainer={true}
          sortedByMostRecent={true}
          noDataMessage='No transactions to display'
          refresher={true}
          refreshMessage='Loading Transactions...'
          showLastUpdated={true}
          onRefresh={async () => this.fetchTransactionsReport()}
        />
      </Fragment>
    );
  }
}

PracticeReportsTransactions.propTypes = {
  transactions: PropTypes.array.isRequired,
  loggedPractice: PropTypes.object,
};

const mapStateToProps = (state) => ({
  transactions: state.transactionReport.transactions,
  loggedPractice: state.loggedPractice,
  mostRecentTransactionFromWebSocket: state.mostRecentTransactionFromWebSocket,
});

const mapDispatchToProps = {
  ...reportingActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeReportsTransactions);
