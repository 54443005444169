// Modules
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

// API
import {
  createSpreedlyTransaction,
  cancelTransaction,
} from 'utils/api/spreedlyApi';
import { buildSpreedlyPaymentLinkRequest } from 'utils/api/requestBody';
import { createPaymentLink } from 'utils/api/payfabricApi';
import { createPaymentLink as createPaymentLinkRectangle } from 'utils/api/rectangleApi';

// Google Analytics
import {
  emailToPayCancelGAEventHandler,
  emailToPayNextGAEventHandler,
  emailToPayBackGAEventHandler,
  emailToPayTryAgainGAEventHandler,
  emailToPayFinishedGAEventHandler,
} from './googleAnalyticsHandlers';

// Components
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PaymentInfoStep from './PaymentInfoStep';
import EmailToPayStep from './EmailToPayStep';
import SendEmailStep from './SendEmailStep';

// Helpers
import { emailValidate } from '../../../../utils/helpers/emailValidate';
import { cents } from 'utils/helpers/currencyFormat';
import { capitalizeName } from 'utils/helpers/names';

// Constants
import PaymentPlatforms from 'constants/PaymentPlatforms';

const EmailToPayModal = (props) => {
  const {
    showModal,
    setShowModal,
    triggerUpdate,
    installmentsEnabled,
    installmentsMinAmount,
    paymentPlatform,
  } = props;

  const payWithSavedCardEnabled = useSelector(
    (s) => s.loggedPractice.payWithSavedCards
  );

  const [selectedPetOwner, setSelectedPetOwner] = useState(null);
  const [staffMember, setStaffMember] = useState('Choose a staff member');
  const [petOwnerName, setPetOwnerName] = useState('');
  const [description, setDescription] = useState('');
  const [serviceType, setServiceType] = useState('Choose a service type');
  const [totalAmount, setTotalAmount] = useState('');
  const [stepNumber, setStepNumber] = useState(0);
  const [emailAddress, setEmailAddress] = useState('');
  const [includePaymentPlanOption, setIncludePaymentPlanOption] =
    useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const processedTransactionId = useRef(); // Holds the most recently processed transaction for cancellation
  const [sendEmailRequestStatus, setSendTextRequestStatus] = useState(true);

  // Fields used for validation
  const STAFF_MEMBER_DEFINITION = {
    field: 'staffMember',
    value: staffMember,
    validationMessage: 'Select a staff member',
  };
  const TOTAL_AMOUNT_DEFINITION = {
    field: 'totalAmount',
    value: totalAmount,
    validationMessage: 'Enter a non-zero amount',
  };
  const DESCRIPTION_DEFINITION = {
    field: 'description',
    value: description,
    validationMessage: 'Enter a description',
  };
  const SERVICE_TYPE_DEFINITION = {
    field: 'serviceType',
    value: serviceType,
    validationMessage: 'Select a service type',
  };
  const PET_OWNER_NAME_DEFINITION = {
    field: 'petOwnerName',
    value: petOwnerName,
    validationMessage: 'Enter a pet owner name',
  };
  const EMAIL_ADDRESS_DEFINITION = {
    field: 'emailAddress',
    value: emailAddress,
    validationMessage: 'Enter a valid email address',
  };

  const step1Validation = [
    STAFF_MEMBER_DEFINITION,
    TOTAL_AMOUNT_DEFINITION,
    DESCRIPTION_DEFINITION,
    SERVICE_TYPE_DEFINITION,
    PET_OWNER_NAME_DEFINITION,
  ];
  const step2Validation = [
    DESCRIPTION_DEFINITION,
    PET_OWNER_NAME_DEFINITION,
    EMAIL_ADDRESS_DEFINITION,
  ];

  useEffect(() => {
    resetState();
  }, [showModal]);

  const validateReducer = (field, value) => {
    switch (field) {
      case 'totalAmount':
        return value > 0;
      case 'staffMember':
        return value?.length === 36;
      case 'petOwnerName':
        return value?.length > 0;
      case 'description':
        return value?.length > 0;
      case 'serviceType':
        return value?.length !== 21;
      case 'emailAddress':
        return emailValidate(value);
      default:
        return null;
    }
  };

  const sendEmail = async () => {
    try {
      setFormLoading(true);

      const requestData = {
        amount: totalAmount,
        description,
        invoiceId: description,
        petOwnerName: capitalizeName(petOwnerName),
        petOwnerEmail: emailAddress,
        serviceType: serviceType,
        staffMemberId: staffMember,
        communicationType: 'email',
        includePaymentPlanOption,
      };

      let result;
      if (paymentPlatform === PaymentPlatforms.payfabric) {
        const payfabricRequestData = {
          ...requestData,
          amount: cents(requestData.amount, { resultType: 'number' }),
          ...(payWithSavedCardEnabled && {
            customerId: selectedPetOwner?.customer_id || uuid(),
            paymentMethodId: selectedPetOwner?.payment_methods[0].id,
          }),
        };
        result = await createPaymentLink(payfabricRequestData);
      } else if (
        paymentPlatform === PaymentPlatforms.spreedly ||
        paymentPlatform === PaymentPlatforms.stripe // Handle edge case for stripe practices that are running e2p using spreedly
      ) {
        result = await createSpreedlyTransaction(
          buildSpreedlyPaymentLinkRequest(requestData)
        );
      } else if (paymentPlatform === PaymentPlatforms.rectangle) {
        const rectangleRequestData = {
          ...requestData,
          amount: cents(requestData.amount, { resultType: 'number' }),
          ...(payWithSavedCardEnabled && {
            customerId: selectedPetOwner?.customer_id || uuid(),
            paymentMethodId: selectedPetOwner?.payment_methods[0].id,
          }),
        };
        result = await createPaymentLinkRectangle(rectangleRequestData);
      }

      if (result.Attributes?.id) {
        processedTransactionId.current = result.Attributes?.id;
        return true;
      }

      toast.error('Error creating email to pay transaction');
      return false;
    } catch (err) {
      console.info('Error:', err);
      toast.error('Error creating email to pay transaction');
      return false;
    } finally {
      setFormLoading(false);
    }
  };

  const validate = (rules) => {
    const validationResult = rules.reduce((errors, vf) => {
      const error = !validateReducer(vf.field, vf.value);
      if (error) {
        errors[vf.field] = vf.validationMessage;
      }
      return errors;
    }, []);

    setValidationErrors(validationResult);
    return Object.keys(validationResult);
  };

  //performs form validation for the next button
  const step1Submit = () => {
    const validationResult = validate(step1Validation);

    if (validationResult.length === 0) {
      if (Number(totalAmount) < Number(installmentsMinAmount)) {
        setIncludePaymentPlanOption(false);
      }
      setStepNumber(stepNumber + 1);
    }
  };

  //final validation, then sends the text to pay request
  const step2Submit = async () => {
    const validationResult = validate(step2Validation);

    if (validationResult.length === 0) {
      let result = await sendEmail();
      if (result) {
        setSendTextRequestStatus(true);
        setStepNumber(stepNumber + 1);
        triggerUpdate();
      } else {
        setSendTextRequestStatus(false);
      }
    }
  };

  const tryAgain = async () => {
    try {
      setFormLoading(true);
      let response = await cancelTransaction(processedTransactionId.current);
      if (response) {
        triggerUpdate();
        setFormLoading(false);
        setStepNumber(1);
      }
    } catch (err) {
      toast.error('Error cancelling transaction');
    }
  };

  const resetState = () => {
    setStaffMember('Choose a staff member');
    setPetOwnerName('');
    setDescription('');
    setServiceType('Choose a service type');
    setTotalAmount('');
    setStepNumber(0);
    setEmailAddress('');
    setIncludePaymentPlanOption(false);
    setValidationErrors({});
    setSendTextRequestStatus(true);
  };

  const paymentInformationCancelOnClickHandler = () => {
    emailToPayCancelGAEventHandler();
    setShowModal();
    resetState();
  };

  const paymentInformationNextOnClickHandler = () => {
    emailToPayNextGAEventHandler();
    step1Submit();
  };

  const emailToPayBackOnClickHandler = () => {
    emailToPayBackGAEventHandler(totalAmount);
    setStepNumber(stepNumber - 1);
  };

  const paymentCompleteTryAgainOnClickHandler = () => {
    emailToPayTryAgainGAEventHandler(sendEmailRequestStatus, totalAmount);
    tryAgain();
  };

  const paymentCompleteFinishedOnClickHandler = () => {
    emailToPayFinishedGAEventHandler(sendEmailRequestStatus, totalAmount);
    setShowModal();
    resetState();
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      size='md'
      aria-labelledby={`email-to-pay-modal`}
      centered
    >
      {[0, 1].includes(stepNumber) && (
        <Modal.Header>
          <Modal.Title id={`email-to-pay-modal`}>
            {stepNumber === 0 && `Payment Information`}
            {stepNumber === 1 && `Email To Pay`}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {stepNumber === 0 && (
          <PaymentInfoStep
            staffMember={staffMember}
            setStaffMember={setStaffMember}
            petOwnerName={petOwnerName}
            setPetOwnerName={setPetOwnerName}
            description={description}
            setDescription={setDescription}
            serviceType={serviceType}
            setServiceType={setServiceType}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
            isFormLoading={isFormLoading}
            setFormLoading={setFormLoading}
            validationErrors={validationErrors}
            selectedPetOwner={selectedPetOwner}
            setSelectedPetOwner={setSelectedPetOwner}
          />
        )}
        {stepNumber === 1 && (
          <EmailToPayStep
            totalAmount={totalAmount}
            petOwnerName={petOwnerName}
            setPetOwnerName={setPetOwnerName}
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
            description={description}
            setDescription={setDescription}
            includePaymentPlanOption={includePaymentPlanOption}
            setIncludePaymentPlanOption={setIncludePaymentPlanOption}
            installmentsEnabled={installmentsEnabled}
            installmentsMinAmount={installmentsMinAmount}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            isFormLoading={isFormLoading}
            validationErrors={validationErrors}
            step2Submit={step2Submit}
          />
        )}
        {stepNumber === 2 && (
          <SendEmailStep
            success={sendEmailRequestStatus}
            totalAmount={totalAmount}
            petOwnerName={petOwnerName}
            emailAddress={emailAddress}
            description={description}
            isFormLoading={isFormLoading}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {stepNumber === 0 && (
          <Button
            variant='light'
            onClick={paymentInformationCancelOnClickHandler}
          >
            Cancel
          </Button>
        )}
        {stepNumber === 1 && (
          <Button
            variant='light'
            onClick={emailToPayBackOnClickHandler}
            disabled={false}
          >
            Back
          </Button>
        )}
        {stepNumber === 0 && (
          <Button
            variant='secondary'
            onClick={paymentInformationNextOnClickHandler}
            disabled={isFormLoading}
          >
            Next
          </Button>
        )}
        {stepNumber === 2 && (
          <>
            <Button
              variant='light'
              onClick={paymentCompleteTryAgainOnClickHandler}
            >
              Try again
            </Button>
            <Button
              variant='secondary'
              onClick={paymentCompleteFinishedOnClickHandler}
            >
              Finished
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EmailToPayModal;
