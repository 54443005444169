import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export default function PageViewLogger() {
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
  return null;
}
