import React from 'react';

const ToggleOpen = (props) => {
  const { sidebarIsActive, toggleSidebar } = props;
  let classname = sidebarIsActive ? 'toggle-close' : 'toggle-open';
  return (
    <button
      id='ToggleOpen'
      className={`${classname} btn btn-link`}
      onClick={toggleSidebar}
    >
      <i
        className={`fas ${sidebarIsActive ? 'fa-times' : 'fa-bars'}`}
        aria-hidden='true'
      ></i>
    </button>
  );
};

export default ToggleOpen;
