import React from 'react';
import PropTypes from 'prop-types';
import SanityCheck from './SanityCheck';
import TransactionStatus from 'constants/TransactionStatus';

const TransactionSanityCheck = ({ transaction }) => {
  if (!transaction) {
    return null;
  }

  const {
    amount,
    amountRefunded,
    fee_amount: feeAmount,
    isRefund,
    net,
    status,
  } = transaction;
  const failedSanityTests = [];

  if (amount < 0) {
    failedSanityTests.push(`negative amount: ${amount}`);
  }

  if (feeAmount < 0) {
    failedSanityTests.push(`negative fees: ${feeAmount}`);
  }

  if (amountRefunded < 0) {
    failedSanityTests.push(`negative refunds: ${amountRefunded}`);
  }

  if (!isRefund && net < 0) {
    failedSanityTests.push(`negative net: ${net}`);
  }

  const zeroSum =
    amount === 0 && status === TransactionStatus.refunded
      ? amountRefunded + net
      : amount - feeAmount - amountRefunded - net;

  if (zeroSum !== 0 && status !== TransactionStatus.voided) {
    failedSanityTests.push(`zeroSum: ${zeroSum}`);
  }

  return <SanityCheck failedSanityTests={failedSanityTests} />;
};

TransactionSanityCheck.propTypes = {
  transaction: PropTypes.object,
};

export default TransactionSanityCheck;
