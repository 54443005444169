import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import DateTime from 'utils/helpers/DateTime';
import Currency from 'utils/helpers/currency';
import LinkedValue from 'components/Common/LinkedValue/LinkedValue';

const TransactionInfoItem = ({
  data,
  type,
  title,
  boldStyled,
  colStyle,
  formatStripe = true,
  hasPopover = false,
  classes = '',
}) => {
  return (
    <div className={`${colStyle} col-sm-12 ${classes}`}>
      <h6>{title}</h6>
      {type === 'date' && (
        <DateTime datetime={data} format='MM/DD/YY - h:mma' />
      )}
      {type === 'string' && !hasPopover && (
        <div
          style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
          className={classes}
        >
          {boldStyled ? (
            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <b>{data}</b>
            </p>
          ) : (
            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data}
            </p>
          )}
        </div>
      )}
      {type === 'string' && hasPopover && (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement='right'
          overlay={
            <Popover className='popover-basic'>
              <Popover.Content>{data}</Popover.Content>
            </Popover>
          }
        >
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
            className={classes}
          >
            {boldStyled ? (
              <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <b>{data}</b>
              </p>
            ) : (
              <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data}
              </p>
            )}
          </div>
        </OverlayTrigger>
      )}
      {type === 'currency' && (
        <p>
          <Currency amount={data} />
        </p>
      )}
      {type === 'transactionId' && (
        <div
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          className={classes}
        >
          <LinkedValue value={data} expandable={true} copyable={true} />
        </div>
      )}
    </div>
  );
};

TransactionInfoItem.propTypes = {
  data: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  boldStyled: PropTypes.bool,
  colStyle: PropTypes.string.isRequired,
  hasPopover: PropTypes.bool,
  classes: PropTypes.string,
};

export default TransactionInfoItem;
