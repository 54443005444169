import moment from 'moment';
import { StoreActions } from '.';
import TransactionSource from 'constants/TransactionSource';
import {
  getAllPracticeTransactions,
  getTransactionsByDateRange,
} from './../../api/transactionApi';
import { getPracticePayoutsReport as getPayoutsReports } from './../../api/merchantApi';

function generateDailyReport(transactions) {
  const reportData = {};
  const report = [];
  transactions.forEach((transaction) => {
    let transactionDate = moment(transaction.time);
    transactionDate.startOf('day');
    let thisData = (reportData[transactionDate] = reportData[
      transactionDate
    ] ?? {
      date: transactionDate,
      timestamp: transaction.time,
      count: 0,
      amount: 0,
      fees: 0,
      refunds: 0,
      total: 0,
    });
    thisData.count++;
    thisData.amount += +transaction.amount ? +transaction.amount : 0;
    thisData.fees += +transaction.fee_amount ? +transaction.fee_amount : 0;
    thisData.refunds += +transaction.amountRefunded
      ? +transaction.amountRefunded
      : 0;
    thisData.total += +transaction.net ? +transaction.net : 0;
  });
  Object.keys(reportData).forEach((key) => {
    report.push(reportData[key]);
  });
  return report.sort((a, b) => {
    let aValue = a.timestamp ? new Date(a.date) : false;
    let bValue = b.timestamp ? new Date(b.date) : false;
    return bValue - aValue;
  });
}

function getPracticeDailyReportsSuccess(report) {
  return {
    type: StoreActions.GET_PRACTICE_DAILY_REPORTS_SUCCESSS,
    report,
  };
}

function getPracticePayoutsReportsSuccess(report) {
  return {
    type: StoreActions.GET_PRACTICE_PAYOUT_REPORTS_SUCCESS,
    report,
  };
}

function filterPendingTransactions(transactions = []) {
  return transactions.filter((t) => {
    if (
      t.source.toLowerCase() === TransactionSource.Spreedly ||
      t.source.toLowerCase() === TransactionSource.PayFabric ||
      t.source.toLowerCase() === TransactionSource.Rectangle
    ) {
      return (
        t.status?.toLowerCase() === 'succeeded' ||
        t.status?.toLowerCase() === 'refunded'
      );
    }

    return true;
  });
}

export function getPracticeDailyReports() {
  return async function (dispatch) {
    let results = [];
    try {
      await getAllPracticeTransactions(
        (response) =>
          (results = results.concat(filterPendingTransactions(response.Items)))
      );
      const transactions = results;
      const report = generateDailyReport(transactions);
      dispatch(getPracticeDailyReportsSuccess(report));

      return results;
    } catch (err) {
      console.error(err);
    }
  };
}

export function getPracticePayoutsReports() {
  return async function (dispatch) {
    try {
      const report = await getPayoutsReports();
      dispatch(getPracticePayoutsReportsSuccess(report));
    } catch (err) {
      console.error(err);
    }
  };
}

export function getTransactionReport(
  startDate,
  endDate,
  getExpiration = moment
) {
  return async function (dispatch, getState) {
    const firstMonth = moment(startDate).startOf('month');
    const lastMonth = moment(endDate).startOf('month');
    let monthsToQuery = [];
    let monthToAdd = firstMonth;
    const currentRequests = getState().transactionReport?.requests;
    const isForDate = (m) => (r) => {
      return r.date === +m;
    };

    while (monthToAdd <= lastMonth) {
      const currentMonthToAdd = monthToAdd;
      const monthState = currentRequests?.find(isForDate(currentMonthToAdd));

      if (!monthState?.isFetchingData) {
        monthsToQuery.push(monthToAdd);
      }
      monthToAdd = moment(monthToAdd).add(1, 'month');
    }
    dispatch({
      type: StoreActions.GET_TRANSACTION_REPORT_STARTED,
      monthsToQuery: monthsToQuery,
    });
    const rangeStart = moment(firstMonth).startOf('month');
    const rangeEnd = moment(lastMonth).endOf('month');
    const gotted = getTransactionsByDateRange(
      +rangeStart,
      +rangeEnd,
      (response) =>
        dispatch({
          type: StoreActions.GET_TRANSACTION_REPORT_PAGE_SUCCESS,
          pageRecords: filterPendingTransactions(response.Items),
        })
    );

    try {
      await gotted;
      return dispatch({
        type: StoreActions.GET_TRANSACTION_REPORT_SUCCESS,
        monthsToQuery: monthsToQuery,
        expiration: getExpiration(),
      });
    } catch (error) {
      return dispatch({
        type: StoreActions.GET_TRANSACTION_REPORT_FAILED,
        error,
      });
    }
  };
}
