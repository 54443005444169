import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import Loading from 'components/Common/Loading';

import phoneNumberIsValidFormat, {
  getPhoneDigitsOnly,
} from 'utils/helpers/phoneNumberValidate';
import { emailValidate } from 'utils/helpers/emailValidate';
import useToggle from 'utils/hooks/useToggle';
import useInput from 'utils/hooks/useInput';
import { resendTransactionReceipt } from 'utils/api/transactionApi';

/**
 * Because this function is declared outside of the component
 * it does not get declared on every re-render
 * which is a minor performance bump
 */
async function resendReceipt(sendText, sendEmail, transactionId, phone, email) {
  const receiptTypes = {
    text: 'text_message',
    email: 'email',
  };

  const sendStatus = {
    sendingText: sendText,
    sendingEmail: sendEmail,
    textFailed: false,
    emailFailed: false,
    success: true,
    toogleSendingStatus(type) {
      switch (type) {
        case receiptTypes.text:
          this.sendingText = !this.sendingText;
          break;
        case receiptTypes.email:
          this.sendingEmail = !this.sendingEmail;
          break;
        default:
          break;
      }
    },
    setFailed(type) {
      switch (type) {
        case receiptTypes.text:
          this.textFailed = true;
          break;
        case receiptTypes.email:
          this.emailFailed = true;
          break;
        default:
          break;
      }
    },
    sendingComplete() {
      return this.sendingText && this.sendingEmail;
    },
    failed() {
      return this.textFailed && this.emailFailed;
    },
  };

  const attemptSend = async (receiptType, value) => {
    try {
      await resendTransactionReceipt({ transactionId, receiptType, value });
      sendStatus.toogleSendingStatus(receiptType);
    } catch (err) {
      sendStatus.toogleSendingStatus(receiptType);
      sendStatus.setFailed(receiptType);
    }
  };

  if (sendText)
    await attemptSend(receiptTypes.text, `+1${getPhoneDigitsOnly(phone)}`);

  if (sendEmail) await attemptSend(receiptTypes.email, email);

  if (sendStatus.textFailed || sendStatus.emailFailed)
    sendStatus.success = false;

  return sendStatus;
}

function ResendReceiptModal(props) {
  const {
    show,
    savedPhone = '',
    savedEmail = '',
    toggleModal,
    transactionId,
    isInstallments = false,
  } = props;

  const [phone, setPhone] = useInput(savedPhone);
  const [email, setEmail] = useInput(savedEmail);
  const [showForm, toggleShowForm, resetShowForm] = useToggle(
    isInstallments ? false : true
  );
  const [showInvalidPhoneWarning, toggleShowInvalidPhoneWarning] = useToggle();
  const [showInvalidEmailWarning, toggleShowInvalidEmailWarning] = useToggle();
  const [sendingReceipt, toggleSendingReceipt] = useToggle();
  const [textFailed, toggleTextFailed, resetTextFailed] = useToggle();
  const [emailFailed, toggleEmailFailed, resetEmailFailed] = useToggle();
  const [success, toggleSuccess] = useToggle();

  const handleInput = (e) => {
    if (e.target.name === 'phone') {
      setPhone(e);
      if (showInvalidPhoneWarning) toggleShowInvalidPhoneWarning();
    } else if (e.target.name === 'email') {
      setEmail(e);
      if (showInvalidEmailWarning) toggleShowInvalidEmailWarning();
    }
  };

  const handleConfirmButtonClick = async () => {
    const phoneIsValidFormat = phoneNumberIsValidFormat(phone);
    const emailIsValid = emailValidate(email);

    if (phone !== '' && !phoneIsValidFormat) {
      toggleShowInvalidPhoneWarning();
      return;
    }

    if (email !== '' && !emailIsValid) {
      toggleShowInvalidEmailWarning();
      return;
    }

    toggleShowForm(false);
    toggleSendingReceipt(true);

    const status = await resendReceipt(
      phoneIsValidFormat,
      emailIsValid,
      transactionId,
      phone,
      email
    );

    if (status.textFailed) toggleTextFailed();
    if (status.emailFailed) toggleEmailFailed();
    if (status.success) toggleSuccess();

    toggleSendingReceipt(false);
  };

  return (
    <Modal
      id='ResendPaymentRequest'
      size='lg'
      show={show}
      onHide={toggleModal}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      {isInstallments ? (
        <>
          <Modal.Header>
            <Modal.Title>
              Please have the pet owner contact Splitit for this request
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Email: support@splitit.com</p>
            <p>Phone: US: +1 844 775 4848 \ UK: +44 800 086 9112</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn btn-primary' onClick={toggleModal}>
              OK
            </Button>
          </Modal.Footer>
        </>
      ) : null}
      {success ? (
        <>
          <Modal.Body>
            <p>Receipt has been successfully sent</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn btn-primary' onClick={toggleModal}>
              CLOSE
            </Button>
          </Modal.Footer>
        </>
      ) : null}
      {textFailed || emailFailed ? (
        <>
          <Modal.Body>
            {phone !== '' ? (
              textFailed ? (
                <p>
                  <i
                    className='far fa-window-close'
                    style={{ color: '#dc3545', marginRight: '10px' }}
                  ></i>
                  Receipt was not successfully sent to phone number, please try
                  again
                </p>
              ) : (
                <p>
                  <i
                    className='far fa-check-square'
                    style={{ color: '#28a745', marginRight: '10px' }}
                  ></i>
                  Receipt has been successfully sent
                </p>
              )
            ) : null}

            {email !== '' ? (
              emailFailed ? (
                <p>
                  <i
                    className='far fa-window-close'
                    style={{ color: '#dc3545', marginRight: '10px' }}
                  ></i>
                  Receipt was not successfully sent to email, please try again
                </p>
              ) : (
                <p>
                  <i
                    className='far fa-check-square'
                    style={{ color: '#28a745', marginRight: '10px' }}
                  ></i>
                  Receipt has been successfully sent
                </p>
              )
            ) : null}
          </Modal.Body>

          <Modal.Footer>
            <Button variant='light' onClick={toggleModal}>
              CLOSE
            </Button>

            <Button
              className='btn btn-primary'
              onClick={() => {
                resetTextFailed();
                resetEmailFailed();
                resetShowForm();
              }}
            >
              RETRY
            </Button>
          </Modal.Footer>
        </>
      ) : null}
      {sendingReceipt ? (
        <Modal.Body>
          <Loading message='Sending the receipt...' />
        </Modal.Body>
      ) : null}
      {showForm && !isInstallments ? (
        <>
          <Modal.Header>
            <h2 className='text-center' style={{ margin: '0 auto' }}>
              Resend Receipt
            </h2>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='resendTextReceipt'>
              <InputGroup className='mb-3'>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id='phone-icon'
                    style={{
                      background: 'none',
                      color: '#124474',
                      size: '24px',
                      borderRight: 'none',
                    }}
                    className={
                      showInvalidPhoneWarning
                        ? 'input-group-text-invalid'
                        : null
                    }
                  >
                    <i className='fas fa-mobile-alt'></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  type='tel'
                  placeholder='Enter a phone number'
                  name='phone'
                  aria-label='phone'
                  aria-describedby='phone-icon'
                  style={{ borderLeft: 'none' }}
                  value={phone}
                  onChange={handleInput}
                  isInvalid={showInvalidPhoneWarning}
                />
              </InputGroup>

              {showInvalidPhoneWarning && (
                <Form.Text className='text-danger'>
                  The number you have entered is invalid. Please enter a new
                  number.
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId='resendEmailReceipt'>
              <InputGroup className='mb-3'>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id='email-icon'
                    className={
                      showInvalidEmailWarning
                        ? 'input-group-text-invalid'
                        : null
                    }
                    style={{
                      background: 'none',
                      color: '#124474',
                      size: '24px',
                      borderRight: 'none',
                    }}
                  >
                    <i className='far fa-envelope'></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  type='email'
                  placeholder='Enter an email address'
                  name='email'
                  aria-label='email'
                  aria-describedby='email-icon'
                  style={{
                    borderLeft: 'none',
                    borderColor: '#ced4da',
                    backgroundColor: '#fff',
                  }}
                  onChange={handleInput}
                  value={email}
                  isInvalid={showInvalidEmailWarning}
                />
              </InputGroup>

              {showInvalidEmailWarning && (
                <Form.Text className='text-danger'>
                  The email you have entered is invalid. Please enter a new
                  email.
                </Form.Text>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={toggleModal}>
              CLOSE
            </Button>
            <Button
              className={
                showInvalidPhoneWarning || showInvalidEmailWarning
                  ? 'btn'
                  : 'btn btn-primary'
              }
              variant={
                showInvalidPhoneWarning || showInvalidEmailWarning
                  ? 'light'
                  : ''
              }
              onClick={handleConfirmButtonClick}
              disabled={
                (phone === '' && email === '') ||
                showInvalidPhoneWarning ||
                showInvalidEmailWarning
              }
            >
              CONFIRM
            </Button>
          </Modal.Footer>
        </>
      ) : null}
    </Modal>
  );
}

ResendReceiptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  savedPhone: PropTypes.string,
  savedEmail: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
  isInstallments: PropTypes.bool,
};

export default ResendReceiptModal;
