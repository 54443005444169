import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';

const PhoneNumberInput = (props) => {
  const { phoneNumber, setPhoneNumber, validationError } = props;

  return (
    <Form.Group controlId='phoneNumber'>
      <Form.Label>Phone Number</Form.Label>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>+1</InputGroup.Text>
        </InputGroup.Prepend>
        <PhoneInput
          name='phoneNumber'
          type='text'
          country='US'
          placeholder='(xxx) xxx-xxxx'
          className={['form-control', validationError ? 'is-invalid' : ''].join(
            ' '
          )}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        <Form.Control.Feedback type='invalid'>
          {validationError}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export default PhoneNumberInput;
