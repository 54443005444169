// Modules
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux store
import { updateTransaction } from 'utils/store/slices/mostRecentTransactionFromWebSocketSlice';

// Utils
import { webSocketConnection } from 'utils/websocket/webSocketConnection';
import PaymentMethod from 'constants/PaymentMethod';
import TransactionStatus from 'constants/TransactionStatus';
import currencyFormat from 'utils/helpers/currencyFormat';
import Auth from 'utils/auth';

// Image
import logo from 'assets/images/app-logo.png';

// Components
import AppRoutes from './app.routes';
import CompletedPaymentLinkTransactionsToasts from './CompletedPaymentLinkTransactionsToasts';
import { toast } from 'react-toastify';

const channelNames = {
  newPaymentLinkTransactions: 'newPaymentLinkTransactions',
  twowayStatusUpdates: 'twowayStatusUpdates',
  completedTransactions: 'completedTransactions',
  refundTransactions: 'refundTransactions',
  sendGridStatusUpdates: 'sendGridStatusUpdates',
};

const subscriptionRequestObject = {
  service: 'practiceWebsocketConnections',
  action: 'subscribe',
  data: {
    channels: [
      channelNames.newPaymentLinkTransactions,
      channelNames.completedTransactions,
      channelNames.twowayStatusUpdates,
      channelNames.refundTransactions,
      channelNames.sendGridStatusUpdates,
    ],
  },
};

async function displayCompletedPaymentLinkNotification(args) {
  const { transaction, setToastData } = args;
  const { invoiceId, petData, id, transactionId, charge } = transaction;
  const description = invoiceId;
  const clientName = petData?.owner?.name ?? '';
  const amount = currencyFormat(charge?.amount);
  const useNotification = await canUseNotification();
  const auth = new Auth();
  const practiceId = auth.getPracticeId();

  const notificationMessage = {
    [PaymentMethod.TextLink]: 'Text to Pay Transaction Processed',
    [PaymentMethod.EmailLink]: 'Email to Pay Transaction Processed',
  };
  const message = notificationMessage[charge.method];

  console.log(
    'practiceId: ',
    practiceId,
    '\nweb notifications enabled: ',
    useNotification
  );

  if (useNotification) {
    const body = `${clientName} for $${amount}`;
    console.log('web notification body: ', body);
    try {
      new Notification(message, {
        body,
        icon: logo,
      });
    } catch (error) {
      console.error(error);
    }
  } else {
    setToastData({
      description,
      clientName,
      id,
      transactionId,
      amount,
      message,
    });
  }
}

async function canUseNotification() {
  if (!('Notification' in window)) return false;

  const permission = Notification.permission;

  switch (permission) {
    case 'granted':
      return true;

    case 'denied':
      return false;

    case 'default':
      await requestNotificationPermission();
      return canUseNotification();

    default:
      return false;
  }
}

async function requestNotificationPermission() {
  if (
    !('Notification' in window) ||
    Notification.permission === 'denied' ||
    Notification.permission === 'granted'
  )
    return;

  return Notification.requestPermission();
}

const Layout = () => {
  const [toastData, setToastData] = useState({});

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);

  const isCompletedPaymentLinkTransaction = (transaction, channels) => {
    if (!channels.includes(channelNames.completedTransactions)) return;

    const isPaymentLink =
      transaction?.charge?.method === PaymentMethod.TextLink ||
      transaction?.charge?.method === PaymentMethod.EmailLink;
    const isCompleted =
      transaction?.charge?.status === TransactionStatus.succeeded;

    return isPaymentLink && isCompleted;
  };

  const shouldUpdateRedux = (channels) => {
    const expectedChannelNames = [
      channelNames.newPaymentLinkTransactions,
      channelNames.completedTransactions,
      channelNames.twowayStatusUpdates,
      channelNames.refundTransactions,
      channelNames.sendGridStatusUpdates,
    ];

    return channels.some((ch) => expectedChannelNames.includes(ch));
  };

  const listenForWebSocketMessages = useCallback(
    (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        const { channels, data } = parsedData;

        if (isCompletedPaymentLinkTransaction(data, channels)) {
          displayCompletedPaymentLinkNotification({
            transaction: data,
            setToastData,
          });
          dispatch(updateTransaction(data));
        } else if (shouldUpdateRedux(channels)) {
          dispatch(updateTransaction(data));
        }
      } catch (err) {
        console.error(err);
        console.info('event.data:', event.data);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    let socket;

    const initConnection = async () => {
      socket = await webSocketConnection();

      if (socket instanceof WebSocket) {
        socket.addEventListener('open', () => {
          console.log('WebSocket connection established');
          socket.addEventListener('message', listenForWebSocketMessages);
          socket.send(JSON.stringify(subscriptionRequestObject));
        });

        socket.addEventListener('error', (error) => {
          console.log('Could not establish WebSocket connection', error);
          toast.error('Error Fetching Real Time Updates Please Refresh');
        });
      }
    };

    requestNotificationPermission();
    initConnection();

    return async () => {
      await socket;
      if (socket instanceof WebSocket) {
        socket.removeEventListener('message', listenForWebSocketMessages);
      }
    };
  }, [listenForWebSocketMessages]);

  useEffect(() => {
    const zenDeskScript = document.getElementById('ze-snippet');

    const isSupportAgent = auth?.roles?.supportAgent;

    if (zenDeskScript === null && !isSupportAgent) {
      /**
       * Adds VitusPay Zendesk widget for practice staff only
       */
      const script = document.createElement('script');

      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=fb984b02-557d-41c3-9f40-76524de530de';
      script.id = 'ze-snippet';
      script.async = true;

      document.body.appendChild(script);
    } else if (typeof window.zE === 'function' && !isSupportAgent) {
      window.zE('webWidget', 'show');
    }

    return () => {
      if (typeof window.zE === 'function') window.zE('webWidget', 'hide');
    };
  }, [auth]);

  return (
    <>
      <CompletedPaymentLinkTransactionsToasts toastData={toastData} />

      <div id='Layout' className='wrapper'>
        <AppRoutes />
      </div>
    </>
  );
};

export default Layout;
