import { StoreActions } from './index';
import {
  setInstallmentPlans,
  setDefaultMinAmount,
} from './installmentsConfigActions';
import Auth from './../../auth';
import { handleError } from '../../api';

const auth = new Auth();

function storeUserInfo(userCredentials) {
  return {
    type: StoreActions.LOGIN_SUCCESS,
    userCredentials,
  };
}

function removeUserInfo() {
  return {
    type: StoreActions.LOGOUT,
  };
}

function passwordResetSuccess(userCredentials) {
  return {
    type: StoreActions.PASSWORD_RESET_SUCCESS,
    userCredentials,
  };
}

function forgotPasswordRequest(requestObj) {
  return {
    type: StoreActions.FORGOT_PASSWORD_REQUEST,
    requestObj,
  };
}

function resetPasswordSuccess() {
  return {
    type: StoreActions.RESET_PASSWORD_SUCCESS,
  };
}

export function passwordReset(username, newPassword, sessionToken) {
  return function (dispatch) {
    return auth
      .resetPassword(username, newPassword, sessionToken)
      .then((userCredentials) => {
        const parsedCredentials = auth.handleAuthentication(userCredentials);
        dispatch(passwordResetSuccess(parsedCredentials));
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function newPasswordRequired(challengeObj, username) {
  challengeObj.username = username;
  return {
    type: StoreActions.NEW_PASSWORD_REQUIRED,
    challengeObj,
  };
}

export function sendUserCredentials(tryCredentials) {
  return function (dispatch) {
    return auth
      .login(tryCredentials.username, tryCredentials.password)
      .then((userCredentials) => {
        const parsedCredentials = auth.handleAuthentication(userCredentials);
        if (parsedCredentials.ChallengeName) {
          dispatch(newPasswordRequired(parsedCredentials));
          return parsedCredentials;
        } else {
          dispatch(
            setInstallmentPlans(
              userCredentials.globalInstallmentsPlan || '3,4,6'
            )
          );
          dispatch(
            setDefaultMinAmount(userCredentials.installments_min_amount || 0)
          );
          return dispatch(storeUserInfo(parsedCredentials));
        }
      });
  };
}

export function getUserCredentials() {
  return function (dispatch) {
    let userCredentials = auth.isAuthenticated();
    if (userCredentials) {
      return dispatch(storeUserInfo(userCredentials));
    }
    return false;
  };
}

export function signUserOut() {
  return function (dispatch) {
    dispatch(removeUserInfo());
    return auth.logout();
  };
}

export function sendEmailForRecoveryOfPassword(username) {
  return function (dispatch) {
    return auth
      .sendRecoverPasswordEmail(username)
      .then(() => {
        const requestObj = { username };
        dispatch(forgotPasswordRequest(requestObj));
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function resetUserPassword(username, password, token) {
  return function (dispatch) {
    return auth
      .recoverPassword(username, password, token)
      .then(async (userCredentials) => {
        dispatch(resetPasswordSuccess());
        try {
          // recoverPassword is not receiving a challenge response, skipping these steps
          // const challengeObj = await auth.login(username, password);
          // const userCredentials = await auth.resetPassword(username, password, challengeObj.Session);
          const parsedCredentials = auth.handleAuthentication(userCredentials);
          dispatch(
            setInstallmentPlans(userCredentials.globalInstallmentsPlan || '')
          );
          dispatch(
            setDefaultMinAmount(userCredentials.installments_min_amount || 0)
          );
          dispatch(storeUserInfo(parsedCredentials));
          return true;
        } catch (authenticationError) {
          throw authenticationError;
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };
}

export function passwordPolicySuccess(PasswordPolicy) {
  return {
    type: StoreActions.PASSWORD_POLICY,
    PasswordPolicy,
  };
}

export function passwordPolicyWithValidationMessage() {
  return function (dispatch) {
    return auth.passwordPolicy().then((PasswordPolicy) => {
      let policy = PasswordPolicy;
      let passwordPolicyMessage = '';

      policy = policy.data ? policy.data : policy;

      if (policy.PasswordPolicy.MinimumLength) {
        passwordPolicyMessage = `Must be at least ${policy.PasswordPolicy.MinimumLength} characters long`;
      }

      if (policy.PasswordPolicy.RequireUppercase) {
        passwordPolicyMessage =
          passwordPolicyMessage !== ''
            ? `${passwordPolicyMessage}, contain at least 1 uppercase letter`
            : `Must contain at least 1 uppercase letter`;
      }

      if (policy.PasswordPolicy.RequireLowercase) {
        passwordPolicyMessage =
          passwordPolicyMessage !== ''
            ? `${passwordPolicyMessage}, contain at least 1 lowercase letter`
            : `Must contain at least 1 lowercase letter`;
      }

      if (policy.PasswordPolicy.RequireNumbers) {
        passwordPolicyMessage =
          passwordPolicyMessage !== ''
            ? `${passwordPolicyMessage}, contain at least 1 number`
            : `Must contain at least 1 number`;
      }

      if (policy.PasswordPolicy.RequireSymbols) {
        passwordPolicyMessage =
          passwordPolicyMessage !== ''
            ? `${passwordPolicyMessage}, contain at least 1 special character`
            : `Must contain at least 1 special character`;
      }

      let result = {
        PasswordPolicy: policy.PasswordPolicy,
        Message: passwordPolicyMessage,
      };

      dispatch(passwordPolicySuccess(result));

      return result;
    });
  };
}
