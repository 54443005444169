import React from 'react';
import styles from './DevicesList.module.scss';
import { DeviceTypes } from 'constants/Payfabric.js';

const getDeviceName = (deviceTypeId) => {
  switch (deviceTypeId) {
    case DeviceTypes.SavedCard.id:
      return 'Saved Card';

    case DeviceTypes.Terminal.id:
      return 'Terminal';

    default:
      return 'Card Not Present';
  }
};

const DevicesList = (props) => {
  const getConfiguredDevicesList = () => {
    const { payFabricDeviceIds, deletePayfabricDeviceId } = props;
    if (!!payFabricDeviceIds) {
      return Object.entries(payFabricDeviceIds).map((val, i) => {
        if (!!val[1]) {
          return (
            <tr key={i} className={styles.deviceIdItem}>
              <td>{val[1]}</td>
              <td>{getDeviceName(val[0])}</td>
              <td
                className={styles.deviceIdItemAction}
                onClick={() => deletePayfabricDeviceId(val)}
              >
                <i className='fas fa-trash-alt'></i>
              </td>
            </tr>
          );
        }
        return null;
      });
    } else {
      return (
        <tr className={styles.deviceIdItem}>
          <td>No Devices Connected</td>
        </tr>
      );
    }
  };

  return (
    <table className={`${styles.deviceListTable}`}>
      <thead>
        <tr>
          <th scope='col'>CONFIGURED DEVICE(S)</th>
          <th scope='col'>Type</th>
        </tr>
      </thead>
      <tbody className={`${styles.deviceListTableBody}`}>
        {getConfiguredDevicesList()}
      </tbody>
    </table>
  );
};

export default DevicesList;
