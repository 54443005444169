import Loading from 'components/Common/Loading';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const PaymentTerminalStep = (props) => {
  const { isLoading, setSelectedLocationId, selectedLocationId } = props;

  const locations = useSelector((s) => s.loggedPractice.rectangleLocations);

  return (
    <>
      {!isLoading && (
        <Form>
          <Form.Group>
            <Form.Label>Select A Device</Form.Label>
            <Form.Control
              as='select'
              value={selectedLocationId}
              name='location'
              onChange={(event) => {
                for (const location of locations) {
                  if (event.target.value === location.id) {
                    setSelectedLocationId(location.id);
                    break;
                  }
                }
              }}
            >
              <option>Choose a Location</option>
              {locations.map((option, index) => {
                return (
                  <option key={index} value={option.id}>
                    {option.name || option.id}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Form>
      )}
      {isLoading && selectedLocationId != null && (
        <>
          <p>
            Transaction has been sent to device. This screen will refresh when
            the transaction has been completed
          </p>

          <div
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}
          >
            <Loading />
          </div>
        </>
      )}
    </>
  );
};
