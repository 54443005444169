import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function EditCardButton({ onClick }) {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='top'
      overlay={
        <Popover className='popover-basic'>
          <Popover.Content>Replace Saved Card</Popover.Content>
        </Popover>
      }
    >
      <button className='btn btn-link' onClick={onClick}>
        <span className='sr-only'>Replace Saved Card</span>
        <i className='fas fa-credit-card' aria-hidden='true'></i>
      </button>
    </OverlayTrigger>
  );
}

export default EditCardButton;
