import React from 'react';
import SidebarListItem from './SidebarListItem';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as authActions from './../../../utils/store/actions/authActions';
import { connect, useSelector } from 'react-redux';

import { triggerTransactionsTabClickEvent } from './googleAnalyticsTriggers';

const SidebarList = ({
  signUserOut,
  routeList,
  toggleSidebar,
  currentUser,
}) => {
  const history = useHistory();

  const coreId = useSelector((st) => st.loggedPractice.coreId);

  const signOut = (event) => {
    if (event) event.preventDefault();
    signUserOut().then(() => {
      history.push('/');
    });
  };

  const handleRouteClick = (routeName) => {
    switch (routeName) {
      case 'transactions':
        triggerTransactionsTabClickEvent(coreId);
        break;
      default:
        break;
    }
  };
  const isPracticeStaffOrAdmin =
    currentUser.roles.practiceStaff || currentUser.roles.practiceAdmin;

  return (
    <ul id='SidebarList' className='sidebar-list'>
      {routeList.map((item, index) => {
        if (item.condition && !item.hideFromNavigation) {
          return (
            <SidebarListItem
              key={index + 1}
              name={item.name}
              route='/app'
              displayAs={item.displayAs ? item.displayAs : item.name}
              icon={item.icon}
              toggleSidebar={toggleSidebar}
              onRouteClick={handleRouteClick}
            />
          );
        }
        return null;
      })}
      {isPracticeStaffOrAdmin ? (
        <SidebarListItem
          flag='portal'
          name='VITUSVET'
          icon='fa-clipboard-list'
        />
      ) : null}
      <SidebarListItem
        flag='logout'
        name='logout'
        icon='fa-sign-out-alt'
        logoutFn={signOut}
        toggleSidebar={toggleSidebar}
      />
    </ul>
  );
};

SidebarList.propTypes = {
  currentUser: PropTypes.object,
  signUserOut: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer,
  };
}

const mapDispatchToProps = {
  ...authActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarList);
