import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Currency from 'components/Common/Currency/Currency';
import PaymentMethod from 'constants/PaymentMethod';

const CancelLinkConfirm = ({
  onCancel,
  onConfirm,
  show,
  isSaving,
  transaction,
}) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Body>
        <div className='text-center mt-4 mb-4 row'>
          <div className='col'>
            <h3>Are you sure you want to cancel this payment request?</h3>
          </div>
        </div>
        <div className='text-left mt-1 row'>
          <div className='col pl-5'>
            <p>
              <b>Pet Owner Name:</b>
            </p>
          </div>
          <div className='col pr-5'>
            <p>{transaction.petData?.owner?.name}</p>
          </div>
        </div>
        <div className='text-left mt-1 row'>
          <div className='col pl-5'>
            <p>
              <b>Amount:</b>
            </p>
          </div>
          <div className='col pr-5'>
            <p>
              <Currency amount={transaction.amount} />
            </p>
          </div>
        </div>
        {transaction.charge?.method === PaymentMethod.EmailLink ? (
          <div className='text-left mt-1 row'>
            <div className='col pl-5'>
              <p>
                <b>To Email Address:</b>
              </p>
            </div>
            <div className='col pr-5'>
              <p>{transaction.pet_parent_email}</p>
            </div>
          </div>
        ) : (
          <div className='text-left mt-1 row'>
            <div className='col pl-5'>
              <p>
                <b>To Phone Number:</b>
              </p>
            </div>
            <div className='col pr-5'>
              <p>{transaction.petData?.owner?.phone}</p>
            </div>
          </div>
        )}
        <div className='text-left mt-1 row'>
          <div className='col pl-5'>
            <p>
              <b>Description:</b>
            </p>
          </div>
          <div className='col pr-5'>
            <p>{transaction.cleanInvoiceId ?? '-'}</p>
          </div>
        </div>
        <div className='text-center row'>
          <div className='form-group col-md-12'>
            <div className='alert alert-light mb-1'>
              <button
                className='btn btn-primary ml-2'
                onClick={onConfirm}
                disabled={isSaving}
              >
                {isSaving ? 'Processing...' : 'Yes, Cancel'}
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-12 text-center'>
          <button className='btn btn-light mb-2' onClick={onCancel}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

CancelLinkConfirm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default CancelLinkConfirm;
