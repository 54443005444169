import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { unsetSelectedPractice } from 'utils/store/actions/practiceInfoActions';
import { Navbar, Nav, Button, FormControl, Form } from 'react-bootstrap';

const StaffHeader = ({
  title,
  handleShow,
  onFilterChange,
  search,
  setCurrentStaffType,
  staffType,
  currentUser,
}) => {
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    unsetSelectedPractice();
    history.push('/app');
  };

  return (
    <Navbar className='practices-header vitus-app-header' expand='lg'>
      {currentUser.roles.practiceAdmin ? (
        <Navbar.Brand>{title}</Navbar.Brand>
      ) : (
        <Navbar.Brand as='a' href='#' onClick={handleClick}>
          <i className='fas fa-chevron-left text-secondary'></i>
          <small className='ml-2'>{title}</small>
        </Navbar.Brand>
      )}
      <Navbar.Toggle aria-controls='app-header-collapsible' />
      <Navbar.Collapse id='app-header-collapsible'>
        <Nav
          className='header-nav-tabs mr-auto'
          variant='pills'
          defaultActiveKey='#user-accounts'
        >
          {currentUser.roles.practiceAdmin && (
            <>
              <Nav.Item>
                <Nav.Link
                  className='pl-3 pr-3'
                  href='#user-accounts'
                  onClick={() => setCurrentStaffType('users')}
                >
                  USER ACCOUNTS
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className='pl-3 pr-3'
                  href='#staff-members'
                  onClick={() => setCurrentStaffType('staff')}
                >
                  STAFF MEMBERS
                </Nav.Link>
              </Nav.Item>
            </>
          )}
        </Nav>
        <Form inline>
          {currentUser.roles.practiceAdmin && (
            <Button variant='primary' onClick={handleShow}>
              Add {staffType === 'users' ? 'User Account' : 'Staff Member'}
            </Button>
          )}
          <FormControl
            onChange={onFilterChange}
            type='search'
            value={search}
            placeholder='Search'
            className='mr-sm-2'
            style={{ maxWidth: 350 }}
          />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

StaffHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  search: PropTypes.string,
};

export default StaffHeader;
