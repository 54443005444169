import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as authActions from './utils/store/actions/authActions';
import * as navigationActions from './utils/store/actions/navigationActions';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Login from './components/Login/Login';
import ForgotPass from './components/Login/ForgotPass';
import Layout from './components/Layout/Layout';
import OnboardChangePassword from './components/Login/OnboardChangePassword';
import RecoverPassword from './components/Login/RecoverPassword';
import PageViewLogger from 'components/Common/GAPageView/GAPageView';
import PaymentLink from './components/Layout/Content/PaymentLink/PaymentLink';
import SpreedlyEmbed from 'components/Layout/Content/SpreedlyEmbed/SpreedlyEmbed';
import PaymentWidget from 'components/Layout/Content/PaymentWidget/PaymentWidget';

class Routes extends Component {
  state = {
    userCredentials: null,
    currentPath: null,
  };

  componentDidMount() {
    const { currentUser, currentPath, location, setNavigationPath } =
      this.props;

    setNavigationPath(`${location.pathname}${location.search}`);
    this.setState({ currentPath });

    if (authActions.getUserCredentials()) {
      this.setState({ userCredentials: currentUser });
    }
  }

  render() {
    const noAuthPath = `/?redirectUri=${this.props.location.pathname}`;

    return (
      <>
        <PageViewLogger />
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/forgot-pass' component={ForgotPass} />
          <Route exact path='/change-pass' component={OnboardChangePassword} />
          <Route exact path='/recover_password' component={RecoverPassword} />
          <Route path='/pay' component={PaymentLink} />
          <Route path='/pay-embed' component={SpreedlyEmbed} />
          <Route path='/pay-direct' component={PaymentWidget} />
          {/* PRIVATE PARENT ROUTE */}
          <Route path='/app'>
            {this.props.currentUser ? <Layout /> : <Redirect to={noAuthPath} />}
          </Route>
        </Switch>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer,
    currentPath: state.currentPath,
  };
}

const mapDispatchToProps = {
  ...authActions,
  ...navigationActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
