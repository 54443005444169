//Represents the application which initiated the payment request
const TransactionOrigin = {
  Tablet: 'Tablet',
  Portal: 'Portal',
  Pharma: 'Pharma',
  PaxReader: 'Pax Reader',
  CloverReader: 'Clover Reader',
};

export default TransactionOrigin;
