import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker as RangePicker } from 'react-dates';

const DateRangePicker = ({
  showIcon = true,
  startDate = null,
  startDateId = null,
  endDate = null,
  endDateId = null,
  onDatesChange = null,
  label = '',
  error = false,
  required = false,
  errorMessage = '',
  isOutsideRange = null,
  onFocusChange = null,
  ...props
}) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <Fragment>
      <div
        className={`
                vitus-date-range-picker
                ${error ? 'error' : ''}
            `}
      >
        <div className='d-flex align-items-center'>
          {showIcon && <i className='far fa-calendar-alt pr-2'></i>}
          <div className='vitus-date-range-picker-container position-relative'>
            {label && (
              <span className='vitus-date-range-picker-label'>
                {label} {required && '*'}
              </span>
            )}
            <RangePicker
              startDate={startDate}
              startDateId={startDateId}
              endDate={endDate}
              endDateId={endDateId}
              onDatesChange={(e) => onDatesChange({ ...e, focusedInput })}
              focusedInput={focusedInput}
              onFocusChange={(input) => {
                setFocusedInput(input);
                return onFocusChange && onFocusChange(input);
              }}
              isOutsideRange={(day) =>
                isOutsideRange({ day, focusedInput, startDate, endDate })
              }
              {...props}
            />
            <i
              className='calendar-dropdown fas fa-angle-down pl-3 pr-2'
              onClick={() => {
                setFocusedInput('startDate');
                onFocusChange && onFocusChange('startDate');
              }}
            ></i>
          </div>
        </div>
        {error && (
          <small className={`error-message ${showIcon ? 'icon-padding' : ''}`}>
            {errorMessage}
          </small>
        )}
      </div>
    </Fragment>
  );
};

DateRangePicker.propTypes = {
  showIcon: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  startDateId: PropTypes.any.isRequired,
  endDateId: PropTypes.any.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  isOutsideRange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default DateRangePicker;
