// Modules
import React, { useState, useEffect } from 'react';

// Components
import Loading from 'components/Common/Loading';
import { ReceiptButtons } from '@vitusvet/react-library';

// Utils
import { currencyFormat } from 'utils/helpers/currencyFormat';

// Assets
import cardError from '../../../../assets/images/card-error.png';

// Google Analytics
import {
  paymentResponseGAEventHandler,
  receiptButtonSentGAHandler,
  receiptClickGAEvent,
} from './googleAnalyticsHandlers';
import { formatPhoneNumber } from 'react-phone-number-input';

// Contants
import PaymentStates from 'constants/PaymentStates';

const successStates = ['successful', 'completed'];

// final step - succeeded/failed/completed
const PaymentResponseStep = (props) => {
  let {
    isFormLoading,
    totalAmount,
    currentPaymentState,
    errorMessage,
    lastFourDigits,
    setCurrentPaymentState,
    autoReceiptPhoneNumber = null,
    processedTransactionId: { current: transactionId },
    postTransactionReceipt,
    showReceiptOptions,
    setShowReceiptOptions,
    warningMessage,
  } = props;

  const [receiptPhone, setReceiptPhone] = useState('');
  const [receiptEmail, setReceiptEmail] = useState('');

  const [autoReceiptStatus, setAutoReceiptStatus] = useState(
    autoReceiptPhoneNumber ? 'pending' : 'none'
  );

  // logic to automatically send receipt
  useEffect(() => {
    if (
      !successStates.includes(currentPaymentState) ||
      autoReceiptPhoneNumber === null
    )
      return;

    let didCancel = false;
    async function autoSendReceipt() {
      try {
        await postTransactionReceipt(
          transactionId,
          'text_message',
          autoReceiptPhoneNumber
        );
        if (!didCancel) {
          setAutoReceiptStatus('successful');
        }
      } catch (e) {
        setAutoReceiptStatus('failed');
        console.error(e);
      }
    }

    if (autoReceiptStatus === 'pending') {
      autoSendReceipt();
    }

    return () => {
      didCancel = true;
    };
  }, [
    autoReceiptStatus,
    currentPaymentState,
    autoReceiptPhoneNumber,
    postTransactionReceipt,
    transactionId,
  ]);

  useEffect(() => {
    paymentResponseGAEventHandler(currentPaymentState);
  }, [currentPaymentState]);

  const handleButtonClick = async (args) => {
    const { type, value } = args;
    let chosenButton = '';

    await postTransactionReceipt(transactionId, type, value);
    if (type === 'text_message') {
      setReceiptPhone(value);
      chosenButton = 'Text Receipt';
    } else if (type === 'email') {
      setReceiptEmail(value);
      chosenButton = 'Email Receipt';
    } else {
      chosenButton = 'No Receipt';
    }

    receiptButtonSentGAHandler(currentPaymentState, chosenButton);

    setShowReceiptOptions(false);
    setCurrentPaymentState(PaymentStates.Completed);
  };

  const getContextByState = (status) => {
    return status === 'successful' ? 'aditional ' : '';
  };

  return isFormLoading ? (
    <Loading></Loading>
  ) : (
    <div className='text-center'>
      <p>
        <small>Total Amount</small>
        <br />
        {/* helper function works on cent amounts */}
        <strong>${currencyFormat(totalAmount * 100)}</strong>
      </p>
      {currentPaymentState === PaymentStates.Failed && (
        <>
          <img src={cardError} alt='card error' style={{ width: '150px' }} />
          <p>
            <strong>{errorMessage}</strong>
          </p>
        </>
      )}
      {successStates.includes(currentPaymentState) && (
        <SuccessfulAndCompletedResponse
          errorMessage={errorMessage}
          warningMessage={warningMessage}
          autoReceiptStatus={autoReceiptStatus}
          lastFourDigits={lastFourDigits}
          autoReceiptPhoneNumber={autoReceiptPhoneNumber}
        />
      )}
      {currentPaymentState === PaymentStates.Successful && showReceiptOptions && (
        <>
          {autoReceiptStatus === 'successful' && (
            <strong>Would you like to send another copy of the receipt?</strong>
          )}
          {autoReceiptStatus !== 'successful' && (
            <strong>How would you like your receipt?</strong>
          )}
          <ReceiptButtons
            callback={handleButtonClick}
            gaEventHandlers={{ click: receiptClickGAEvent }}
          />
        </>
      )}
      {!showReceiptOptions && receiptEmail && (
        <strong>Receipt has been sent to {receiptEmail}</strong>
      )}
      {!showReceiptOptions && receiptPhone && (
        <strong>Receipt has been sent to {receiptPhone}</strong>
      )}
      {!showReceiptOptions && !receiptEmail && !receiptPhone && (
        <strong>
          No {getContextByState(autoReceiptStatus)}receipt has been sent
        </strong>
      )}
    </div>
  );
};

export default PaymentResponseStep;

const SuccessfulAndCompletedResponse = ({
  errorMessage,
  warningMessage,
  autoReceiptStatus,
  lastFourDigits,
  autoReceiptPhoneNumber,
}) =>
  warningMessage ? (
    <p>
      <small>{errorMessage}</small>
      <br />
      <small>{warningMessage}</small>
    </p>
  ) : (
    <>
      <p className='text-muted'>
        <small>Card number</small>
        <br />
      </p>
      <pre style={{ marginTop: '-15px' }} className='text-muted'>
        XXXX XXXX XXXX XXXX {lastFourDigits}
      </pre>
      <div
        style={{ marginTop: '-15px', marginBlockEnd: '1em' }}
        className='text-muted'
      >
        {autoReceiptStatus === 'pending' && (
          <div className='d-flex flex-column align-items-center'>
            <small>Sending receipt...</small>
            <div className='spinner-grow' />
          </div>
        )}
        {autoReceiptStatus === 'successful' && (
          <small>
            A copy of the receipt has been automatically sent to{' '}
            {formatPhoneNumber(autoReceiptPhoneNumber)}
          </small>
        )}
        {autoReceiptStatus === 'failed' && (
          <small>Receipt could not be automatically sent</small>
        )}
      </div>
    </>
  );
