import React from 'react';
import { OverlayTrigger, Button } from 'react-bootstrap';
import { popover } from './RefundHistoryPopover';

const TransactionRefundHistory = ({ transaction }) => {
  const refunds = transaction.refunds || [];

  return (
    <OverlayTrigger
      trigger='focus'
      placement='right'
      overlay={popover(refunds)}
    >
      <Button variant='outline-secondary'>
        VIEW REFUNDS
        <i className='ml-2 fas fa-eye' aria-hidden='true' />
      </Button>
    </OverlayTrigger>
  );
};

export default TransactionRefundHistory;
