import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as authActions from './../../utils/store/actions/authActions';
import { emailValidate } from '../../utils/helpers/emailValidate';
import logo from './../../assets/images/app-logo.png';

class ForgotPass extends Component {
  state = {
    username: '',
    formIsValid: false,
    formPristine: true,
    usernameError: '',
    isSending: false,
    authErrors: {},
    authFeedback: {},
  };

  handleSubmit = (event) => {
    const { sendEmailForRecoveryOfPassword } = this.props;
    event.preventDefault();
    this.setState({ isSending: true });
    sendEmailForRecoveryOfPassword(this.state.username)
      .then(() => {
        const feedbackObj = {};
        feedbackObj.isSuccess = true;
        feedbackObj.message =
          'An email has been sent with further information. If you do not receive the email click the link below to resend';
        this.setState({
          authFeedback: feedbackObj,
          isSending: false,
          authErrors: {},
        });
      })
      .catch((err) => {
        const errors = {
          message: err.userMessage ? err.userMessage : err.message,
        };
        this.setState({
          authErrors: errors,
          authFeedback: {},
          isSending: false,
          username: '',
        });
        document.querySelector('input[name="username"]').focus();
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    if (value && emailValidate(value)) {
      this.setState({
        formIsValid: true,
        formPristine: false,
        usernameError: '',
        [name]: value,
      });
    } else {
      this.setState({
        formIsValid: false,
        formPristine: false,
        usernameError: 'A valid email is required',
        [name]: value,
      });
    }
  };

  render() {
    const {
      authFeedback,
      authErrors,
      username,
      formIsValid,
      formPristine,
      usernameError,
      isSending,
    } = this.state;

    return (
      <div className='Login'>
        <div className='FormContainer'>
          <div className='Logo'>
            <img className='CompanyLogo' src={logo} alt='Company Logo' />
          </div>
          <div className='Welcome'>
            <h1>Recover Password</h1>
            <h5>
              Type in your account email. You will receive an email with further
              instructions.
            </h5>
          </div>
          {authFeedback.isSuccess && (
            <div className='alert alert-success mt-4' role='alert'>
              <p>{authFeedback.message}</p>
              <div className='btn btn-link' onClick={this.handleSubmit}>
                Resend
              </div>
            </div>
          )}
          {authErrors.message && (
            <div className='alert alert-danger mt-4' role='alert'>
              {authErrors.message}
            </div>
          )}
          <form className='mt-3' onSubmit={this.handleSubmit}>
            <div className='form-group'>
              <input
                name='username'
                value={username}
                type='text'
                className={
                  !formIsValid && !formPristine
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                placeholder='email'
                onChange={this.handleChange}
              />
              <div
                className={
                  !formIsValid && !formPristine ? 'invalid-feedback' : ''
                }
              >
                {usernameError}
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-primary btn-lf btn-block mt-4'
              disabled={!formIsValid || isSending}
            >
              {isSending ? 'Sending email...' : 'SEND'}
            </button>
            <Link className='btn btn-link mt-2' to='/'>
              BACK
            </Link>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPasswordRequest: state.forgotPasswordRequest,
});

const mapDispatchToProps = {
  ...authActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgotPass));
