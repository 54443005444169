import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import logo from './../../assets/images/app-logo.png';
import * as authActions from '../../utils/store/actions/authActions';

class OnboardChangePassword extends Component {
  state = {
    form: {
      newPassword: '',
      confirmNewPassword: '',
    },
    formValidations: {
      newPasswordError: null,
      confirmNewPasswordError: null,
    },
    labels: {
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
    },
    passwordView: false,
    userAttributes: {},
    sessionId: null,
    errors: {},
    isSaving: false,
    completed: false,
    completedMessage: '',
  };

  togglePasswordView = () => {
    this.setState({ passwordView: !this.state.passwordView });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    if (this.formIsComplete()) {
      const { passwordReset, history } = this.props;
      const { form, sessionId, errors } = this.state;
      passwordReset(
        this.props.challenge.ChallengeParameters.USER_ID_FOR_SRP,
        form.newPassword,
        sessionId
      )
        .then(() => {
          this.setState({ isSaving: false });
          history.push('/app');
        })
        .catch((err) => {
          var newState = {
            isSaving: false,
            form: {
              newPassword: '',
              confirmNewPassword: '',
            },
          };
          if (err.status === 403) {
            newState.completed = true;
            newState.completedMessage = 'Password change successful';
          } else {
            newState.errors = {
              ...errors,
              ...{
                message:
                  err.userMessage ??
                  err.error?.message ??
                  'An error occurred while attempting to change password',
              },
            };
          }
          this.setState(newState);
        });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { form, formValidations, labels } = this.state;
    const errorKey = `${[name]}Error`;
    if (value) {
      this.setState({
        form: { ...form, ...{ [name]: value } },
        formValidations: { ...formValidations, ...{ [errorKey]: null } },
      });
    } else {
      this.setState({
        form: { ...form, ...{ [name]: '' } },
        formValidations: {
          ...formValidations,
          ...{ [errorKey]: `${labels[name]} is required` },
        },
      });
    }
  };

  formIsComplete = () => {
    const { newPassword, confirmNewPassword } = this.state.form;
    return (
      newPassword && confirmNewPassword && newPassword === confirmNewPassword
    );
  };

  async componentDidMount() {
    const { challenge, history } = this.props;
    if (challenge) {
      if (this.props.passwordPolicy.Message === '');
      this.props.passwordPolicyWithValidationMessage();
      const userAttributes = JSON.parse(
        challenge.ChallengeParameters.userAttributes
      );
      this.setState({
        userAttributes,
        sessionId: challenge.Session,
      });
    } else {
      history.push('/');
    }
  }

  render() {
    const {
      errors,
      form,
      formValidations,
      isSaving,
      passwordView,
      completed,
      completedMessage,
    } = this.state;

    return (
      <div className='Login'>
        <div className='FormContainer'>
          <div className='Logo'>
            <img className='CompanyLogo' src={logo} alt='Company Logo' />
          </div>
          <div className='Welcome'>
            <h1 className='mb-1'>Welcome</h1>
            {!completed && (
              <h5 className='text-justify'>
                Please change your password to proceed.
              </h5>
            )}
          </div>
          {!completed && (
            <form className='mt-3' onSubmit={this.handleSubmit}>
              {errors.message && (
                <div className='alert alert-danger' role='alert'>
                  {errors.message}
                </div>
              )}
              <div className='form-group'>
                <div className='input-group'>
                  <input
                    name='newPassword'
                    value={form.newPassword}
                    type={passwordView ? 'text' : 'password'}
                    className={
                      formValidations.newPasswordError
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    placeholder='New Password'
                    onChange={this.handleChange}
                  />
                  <div className='input-group-append'>
                    <span
                      className='input-group-text'
                      onClick={this.togglePasswordView}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className='sr-only'>toggle password view</span>
                      <i
                        className='fas fa-eye'
                        aria-hidden='true'
                        style={{ color: passwordView ? '#ff8f32' : '' }}
                      ></i>
                    </span>
                  </div>
                </div>
                <small
                  className='form-text'
                  style={{ color: '#FFF !important', textAlign: 'left' }}
                >
                  {this.props.passwordPolicy.Message}
                </small>
                <div
                  className={
                    formValidations.newPasswordError ? 'invalid-feedback' : ''
                  }
                >
                  {formValidations.newPasswordError}
                </div>
              </div>
              <div className='form-group'>
                <input
                  name='confirmNewPassword'
                  value={form.confirmNewPassword}
                  type={passwordView ? 'text' : 'password'}
                  className={
                    formValidations.confirmNewPasswordError
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  placeholder='Confirm New Password'
                  onChange={this.handleChange}
                />
                <small
                  className='form-text'
                  style={{
                    color:
                      form.newPassword === form.confirmNewPassword
                        ? '#FFF'
                        : 'red',
                    textAlign: 'left',
                  }}
                >
                  Passwords must match
                </small>
                <div
                  className={
                    formValidations.confirmNewPasswordError
                      ? 'invalid-feedback'
                      : ''
                  }
                >
                  {formValidations.confirmNewPasswordError}
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-primary btn-lf btn-block mt-4'
                disabled={!this.formIsComplete() || isSaving}
              >
                {isSaving ? 'Saving...' : 'SUBMIT'}
              </button>
            </form>
          )}
          {completed && (
            <>
              <div className={`alert alert-success mt-4`} role='alert'>
                <p style={{ wordWrap: 'break-word' }}>{completedMessage}</p>
              </div>
              <Link className='btn btn-link mt-2' to='/'>
                BACK
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  challenge: state.challengeReducer,
  passwordPolicy: state.passwordPolicyReducer,
});

const mapDispatchToProps = {
  ...authActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnboardChangePassword));
