export const sortOptions = {
  none: 'NONE',
  nameAsc: 'NAME_ASC',
  nameDesc: 'NAME_DESC',
  emailAsc: 'EMAIL_ASC',
  emailDesc: 'EMAIL_DESC',
  statusActive: 'STATUS_ACTIVE',
  statusDisabled: 'STATUS_DISABLED',
};

export const practiceDisplayActions = {
  updateDisplay: 'UPDATE_DISPLAY',
  first: 'FIRST_PAGE',
  last: 'LAST_PAGE',
  next: 'NEXT_PAGE',
  prev: 'PREV_PAGE',
  pageNumber: 'PAGE_NUMBER',
  sortByName: 'SORT_BY_NAME',
  sortByEmail: 'SORT_BY_EMAIL',
  sortByStatus: 'SORT_BY_STATUS',
};

export function getDisplayData(args) {
  const { practices, start, numPerPage } = args;

  const displayData = practices.filter((p, idx) => {
    const outsideOfRange = idx < start || idx >= start + numPerPage;

    if (outsideOfRange) return false;

    return true;
  });

  return displayData;
}

function getUpdatedVals(args) {
  const { type, practices, start, numPerPage, currentPage } = args;

  let newStart = start;
  let newCurrentPage = currentPage;

  if (type === practiceDisplayActions.next) {
    newStart = start + numPerPage;
    newCurrentPage = currentPage + 1;
  } else if (type === practiceDisplayActions.prev) {
    newStart = start - numPerPage;
    newCurrentPage = currentPage - 1;
  }

  const newDisplayData = getDisplayData({
    practices,
    start: newStart,
    numPerPage,
  });

  return {
    start: newStart,
    currentPage: newCurrentPage,
    displayData: newDisplayData,
  };
}

function sortPractices(args) {
  const { practices, sortBy, order } = args;

  return practices.sort((a, b) => {
    const aSortVal =
      typeof a[sortBy] === 'string' ? a[sortBy].toUpperCase() : a[sortBy];

    const bSortVal =
      typeof a[sortBy] === 'string' ? b[sortBy].toUpperCase() : b[sortBy];

    if (order === 'asc') {
      if (aSortVal < bSortVal) return -1;
      if (aSortVal > bSortVal) return 1;
    } else if (order === 'desc') {
      if (bSortVal < aSortVal) return -1;
      if (bSortVal > aSortVal) return 1;
    }

    return 0;
  });
}

export function practiceDisplayReducer(state, action) {
  const { type, payload } = action;

  const { practices, start, numPerPage, numPages, currentPage, activeSort } =
    state;

  switch (type) {
    case practiceDisplayActions.updateDisplay:
      return {
        ...state,
        practices: payload.practices,
        displayData: getDisplayData({
          practices: payload.practices,
          start: 0,
          numPerPage,
        }),
        numPages: Math.ceil(payload.practices.length / payload.numPerPage),
      };

    case practiceDisplayActions.first:
      if (currentPage === 1) return state;

      return {
        ...state,
        start: 0,
        currentPage: 1,
        displayData: getDisplayData({
          practices,
          start: 0,
          numPerPage,
        }),
      };

    case practiceDisplayActions.last: {
      if (currentPage === numPages) return state;

      const newStart = (numPages - 1) * numPerPage;

      return {
        ...state,
        start: newStart,
        currentPage: numPages,
        displayData: getDisplayData({
          practices,
          start: newStart,
          numPerPage,
        }),
      };
    }

    case practiceDisplayActions.pageNumber: {
      const pageNumber = action.payload * 1;

      const newStart = (pageNumber - 1) * numPerPage;

      return {
        ...state,
        start: newStart,
        currentPage: pageNumber,
        displayData: getDisplayData({
          practices,
          start: newStart,
          numPerPage,
        }),
      };
    }

    case practiceDisplayActions.next:
      if (currentPage === numPages) return state;

      return {
        ...state,
        ...getUpdatedVals({
          type,
          practices,
          start,
          numPerPage,
          currentPage,
        }),
      };

    case practiceDisplayActions.prev:
      if (currentPage === 1) return state;

      return {
        ...state,
        ...getUpdatedVals({
          type,
          practices,
          start,
          numPerPage,
          currentPage,
        }),
      };

    case practiceDisplayActions.sortByName: {
      let sortedPractices;
      let newActiveSort;

      if (activeSort === sortOptions.nameAsc) {
        sortedPractices = sortPractices({
          practices: practices,
          sortBy: 'name',
          order: 'desc',
        });
        newActiveSort = sortOptions.nameDesc;
      } else {
        sortedPractices = sortPractices({
          practices: practices,
          sortBy: 'name',
          order: 'asc',
        });
        newActiveSort = sortOptions.nameAsc;
      }

      const newDisplayData = getDisplayData({
        practices: sortedPractices,
        start,
        numPerPage,
      });

      return {
        ...state,
        practices: sortedPractices,
        activeSort: newActiveSort,
        displayData: newDisplayData,
      };
    }

    case practiceDisplayActions.sortByEmail: {
      let sortedPractices;
      let newActiveSort;

      if (activeSort === sortOptions.emailAsc) {
        sortedPractices = sortPractices({
          practices: practices,
          sortBy: 'email',
          order: 'desc',
        });
        newActiveSort = sortOptions.emailDesc;
      } else {
        sortedPractices = sortPractices({
          practices: practices,
          sortBy: 'email',
          order: 'asc',
        });
        newActiveSort = sortOptions.emailAsc;
      }

      const newDisplayData = getDisplayData({
        practices: sortedPractices,
        start,
        numPerPage,
      });

      return {
        ...state,
        practices: sortedPractices,
        activeSort: newActiveSort,
        displayData: newDisplayData,
      };
    }

    case practiceDisplayActions.sortByStatus: {
      let sortedPractices;
      let newActiveSort;

      if (activeSort === sortOptions.statusActive) {
        sortedPractices = sortPractices({
          practices: practices,
          sortBy: 'active',
          order: 'desc',
        });
        newActiveSort = sortOptions.statusDisabled;
      } else {
        sortedPractices = sortPractices({
          practices: practices,
          sortBy: 'active',
          order: 'asc',
        });
        newActiveSort = sortOptions.statusActive;
      }

      const newDisplayData = getDisplayData({
        practices: sortedPractices,
        start,
        numPerPage,
      });

      return {
        ...state,
        practices: sortedPractices,
        activeSort: newActiveSort,
        displayData: newDisplayData,
      };
    }

    default:
      return state;
  }
}
