import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

// Api
import {
  createNewCustomer,
  updateCustomer,
  updatePaymentMethod,
} from 'utils/api/customerApi';
import { createWalletFormUrl, getCreditCard } from 'utils/api/payfabricApi';
import {
  convertCreditCardToPaymentMethod,
  PayFabricHostedForm,
} from '@vitusvet/react-library';

// Utils
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { parseNames, getFirstName, getLastName } from 'utils/helpers/names';

// Components
import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import CustomerInfo from './CustomerInfo';
import Loading from 'components/Common/Loading';

// Styles
import styles from './CustomerModal.module.scss';

const CUSTOMER_INFO_STEP = 0;
const CARD_INFO_STEP = 1;

const editModes = Object.freeze({
  NEW: 0,
  INFO: 1,
  CARD: 2,
});

const ERROR_SAVING_CUSTOMER =
  'There was a problem saving the customer, please try again';
const ERROR_ADDING_CARD =
  'There was a problem adding the card, please try again';

// overall steps for adding customer:
// 1: click save button
// 2: validate card info with spreedly
// 3: validate other fields (card holder name, expiration)
// 4: tokenize card
// 5: prepare form data for submission
// 6: upload customer data to BE
// note - when editing customer info, these steps do not occur

function CustomerModal(props) {
  const {
    show,
    onHide,
    onAdd,
    onUpdate,
    editMode: currentEditMode = editModes.NEW,
    customerToEdit = null,
    practiceId,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [isLoading, setLoading] = useState(false);
  const [modalStep, setModalStep] = useState(CUSTOMER_INFO_STEP);
  const [formUrl, setFormUrl] = useState('');
  const [isPayFabricLoading, setIsPayFabricLoading] = useState(false);
  const [newCustomerId, setNewCustomerId] = useState();

  useEffect(() => {
    async function getUpdateWalletFormUrl() {
      if (customerToEdit) {
        setIsPayFabricLoading(true);
        const url = await createWalletFormUrl(
          customerToEdit.customer_id,
          practiceId
        );
        setFormUrl(url);
      }
    }

    if (show) {
      switch (currentEditMode) {
        case editModes.NEW:
        case editModes.INFO:
          setModalStep(CUSTOMER_INFO_STEP);
          break;
        case editModes.CARD:
          setModalStep(CARD_INFO_STEP);
          getUpdateWalletFormUrl();
          break;
        default:
          throw new Error(`Unrecognized edit mode ${currentEditMode}`);
      }
    }
  }, [show, currentEditMode, customerToEdit, practiceId]);

  async function handleModalExited() {
    reset();
    setLoading(false);
    setIsPayFabricLoading(false);
    setModalStep(CUSTOMER_INFO_STEP);
  }

  async function handleBackwardClick() {
    if (
      modalStep === CUSTOMER_INFO_STEP ||
      currentEditMode === editModes.CARD
    ) {
      onHide();
    } else {
      setModalStep(CUSTOMER_INFO_STEP);
    }
  }

  function handleForwardClick(e) {
    if (modalStep !== CARD_INFO_STEP) {
      e.preventDefault(); // prevent form from submitting initially
    }

    handleSubmit(onSubmit)(); // use handleSubmit for field validation
  }

  async function onSubmit(formData) {
    if (modalStep === CUSTOMER_INFO_STEP) {
      const [firstName, lastName] = parseNames(formData.customerData.full_name);
      if (currentEditMode === editModes.INFO && customerToEdit) {
        setLoading(true);

        try {
          // prepare update record
          // intentionally excluding 'addressData'
          const updateRecord = { customerData: formData.customerData };
          // leaving this commented out, as it will once again be needed in the future
          // updateRecord.addressData.id = customerToEdit.addresses[0].id;

          updateRecord.customerData.first_name = firstName;
          updateRecord.customerData.last_name = lastName;

          const updatedCustomer = await updateCustomer(
            updateRecord,
            customerToEdit.customer_id
          );

          onUpdate(updatedCustomer.data);
          onHide();
        } catch (e) {
          console.error(e);

          setLoading(false);
          toast.error(ERROR_SAVING_CUSTOMER);
        }
      } else {
        setModalStep(CARD_INFO_STEP);
        setIsPayFabricLoading(true);
        const randomCustomerId = uuid();
        setNewCustomerId(randomCustomerId);

        const url = await createWalletFormUrl(randomCustomerId, practiceId);
        setFormUrl(url);
      }

      return;
    }

    if (currentEditMode === editModes.NEW && modalStep === CARD_INFO_STEP) {
      try {
        setIsPayFabricLoading(true);

        const creditCard = await getCreditCard(newCustomerId, practiceId);
        const paymentMethod = convertCreditCardToPaymentMethod(creditCard);
        const fullName = formData.customerData.full_name;
        const insertRecord = {
          customerData: {
            customer_id: newCustomerId,
            first_name: getFirstName(fullName),
            last_name: getLastName(fullName),
            phone: formData.customerData.phone,
          },
          paymentMethodData: paymentMethod,
        };
        const newCustomer = await createNewCustomer(insertRecord);
        onAdd(newCustomer.data);

        onHide();
      } catch (e) {
        console.error(e);

        setLoading(false);
        setIsPayFabricLoading(false);
        toast.error(ERROR_ADDING_CARD);
      }
    }

    // Step 6 - upload customer data
    try {
      if (currentEditMode === editModes.CARD) {
        setIsPayFabricLoading(true);
        const creditCard = await getCreditCard(
          customerToEdit.customer_id,
          practiceId
        );
        const paymentMethod = convertCreditCardToPaymentMethod(creditCard);
        const newPaymentMethod = await updatePaymentMethod(
          paymentMethod,
          customerToEdit.customer_id,
          customerToEdit.payment_methods[0].id
        );
        onUpdate({
          customer_id: customerToEdit.customer_id,
          ...newPaymentMethod.data,
        });
      }

      onHide();
    } catch (e) {
      console.error(e);

      setLoading(false);
      setIsPayFabricLoading(false);
      toast.error(ERROR_SAVING_CUSTOMER);
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={handleModalExited}
      dialogClassName={`${styles.modalBody} ${
        modalStep === CARD_INFO_STEP && styles.payfabricModal
      }`}
    >
      <ModalHeader>
        <ModalTitle>
          {currentEditMode === editModes.NEW && 'Add New Customer'}
          {currentEditMode === editModes.INFO && 'Edit Customer'}
          {currentEditMode === editModes.CARD && 'Replace Saved Card'}
        </ModalTitle>
      </ModalHeader>
      <form onSubmit={handleForwardClick}>
        <ModalBody
          className={`${styles.modalBody} ${
            modalStep === CARD_INFO_STEP && styles.payfabricModalBody
          }`}
        >
          {isLoading && (
            <div className={styles.loadingSpinner}>
              <Spinner animation='border' variant='secondary' />
            </div>
          )}
          {!isLoading && modalStep === CUSTOMER_INFO_STEP && (
            <CustomerInfo
              register={register}
              errors={errors}
              control={control}
              customerToEdit={customerToEdit}
            />
          )}
          {!isLoading && modalStep === CARD_INFO_STEP && (
            <PayFabricHostedForm
              hostedFormUrl={formUrl}
              handleFormLoaded={() => setIsPayFabricLoading(false)}
              handleCancel={handleBackwardClick}
              handleWalletCreateCompleted={handleForwardClick}
              handleWalletUpdateCompleted={handleForwardClick}
            />
          )}

          {isPayFabricLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
              }}
            >
              <Loading />
            </div>
          )}
        </ModalBody>
        {modalStep !== CARD_INFO_STEP && !isPayFabricLoading && (
          <ModalFooter>
            <Button
              className={styles.button}
              variant='light'
              onClick={handleBackwardClick}
              disabled={isLoading}
            >
              {modalStep === CUSTOMER_INFO_STEP ||
              currentEditMode === editModes.CARD
                ? 'Cancel'
                : 'Back'}
            </Button>
            <Button
              id='submit-button'
              className={styles.button}
              variant='primary'
              type='submit'
              disabled={isLoading}
            >
              {modalStep === CARD_INFO_STEP || customerToEdit ? 'Save' : 'Next'}
            </Button>
          </ModalFooter>
        )}
      </form>
    </Modal>
  );
}

export function NewCustomerModal(props) {
  const { show, onHide, onAdd, practiceId } = props;
  return (
    <CustomerModal
      show={show}
      onHide={onHide}
      onAdd={onAdd}
      editMode={editModes.NEW}
      practiceId={practiceId}
    />
  );
}

export function EditCustomerModal(props) {
  const { show, onHide, onUpdate, customerToEdit, practiceId } = props;
  return (
    <CustomerModal
      show={show}
      onHide={onHide}
      customerToEdit={customerToEdit}
      onUpdate={onUpdate}
      editMode={editModes.INFO}
      practiceId={practiceId}
    />
  );
}

export function EditCardModal(props) {
  const { show, onHide, onUpdate, customerToEdit, practiceId } = props;
  return (
    <CustomerModal
      show={show}
      onHide={onHide}
      customerToEdit={customerToEdit}
      onUpdate={onUpdate}
      editMode={editModes.CARD}
      practiceId={practiceId}
    />
  );
}

export default CustomerModal;
