import { useCallback, useReducer } from 'react';

const initialState = {
  currentStep: 0,
  stepHistory: [],
};

// this exists to keep a history of previous steps
function stepReducer(prevState, action) {
  switch (action.type) {
    case 'SET':
      if (typeof action.payload !== 'number')
        throw new Error('payload must be a number');

      return {
        currentStep: action.payload,
        stepHistory: [prevState.currentStep, ...prevState.stepHistory],
      };
    case 'NEXT':
      return {
        currentStep: prevState.currentStep + 1,
        stepHistory: [prevState.currentStep, ...prevState.stepHistory],
      };
    case 'PREV':
      const [newStep, ...newHistory] = prevState.stepHistory;
      return {
        currentStep: newStep ?? 0,
        stepHistory: newHistory,
      };
    case 'RESET':
      return initialState;
    default:
      throw new Error(`Unrecognized action type: ${action.type}`);
  }
}

function useStepHistory() {
  const [state, dispatch] = useReducer(stepReducer, initialState);

  const setStep = useCallback((s) => dispatch({ type: 'SET', payload: s }), []);
  const nextStep = useCallback(() => dispatch({ type: 'NEXT' }), []);
  const prevStep = useCallback(() => dispatch({ type: 'PREV' }), []);
  const resetSteps = useCallback(() => dispatch({ type: 'RESET' }), []);

  return [
    { currentStep: state.currentStep },
    { setStep, nextStep, prevStep, resetSteps },
  ];
}

export default useStepHistory;
