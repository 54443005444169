import initialState from './initialState';
import { StoreActions } from '../actions';

const installmentsConfigReducer = (
  state = initialState.globalInstallmentsConfig,
  action
) => {
  switch (action.type) {
    case StoreActions.SET_ALLOWED_PLANS:
      return {
        ...state,
        ...{
          allowedPlans: [...action.allowedPlans],
        },
      };
    case StoreActions.SET_DEFAULT_MIN_AMMOUNT:
      return {
        ...state,
        ...{
          defaultMinAmount: action.defaultMinAmount,
        },
      };
    case StoreActions.SET_CONFIG_FROM_LOCALSTORAGE:
      return { ...state, ...action.installmentsConfig };
    case StoreActions.LOGOUT:
      return initialState.globalInstallmentsConfig;
    default:
      return state;
  }
};

export default installmentsConfigReducer;
