import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover, Collapse } from 'react-bootstrap';
import moment from 'moment';

const TransactionMessage = ({ message }) => {
  const [open, setOpen] = useState(false);

  const messageStatusToStatusClass = (status) => {
    switch (status) {
      case 'invalid':
      case 'error':
      case 'undelivered':
      case 'failed':
      case 'bounce':
      case 'dropped':
        return 'statusRed';
      case 'sent':
      case 'delivered':
        return 'statusGreen';
      default:
        return 'statusNormal';
    }
  };
  return (
    <>
      <div className='mb-3 row'>
        <div className='col d-flex flex-column'>
          <div>
            {message.time ? (
              <b>
                [{moment(message.time).format('lll')} to {message.to}]
              </b>
            ) : null}
            :
            {message.status ? (
              <>
                &nbsp;
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={
                    <Popover className='popover-basic'>
                      <Popover.Content>
                        <>
                          {moment(message.statusTime).format('lll')} -{' '}
                          {message.statusMessage}
                        </>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <span
                    className={`${messageStatusToStatusClass(
                      message.status
                    )} text-capitalize`}
                  >
                    {message.status}
                  </span>
                </OverlayTrigger>
              </>
            ) : null}
          </div>
          {message.content ? <div>"{message.content}"</div> : null}
        </div>
        <div
          className='col-md-auto align-self-center'
          onClick={() => setOpen(!open)}
        >
          <i
            className={`fas ${
              open ? 'fa-chevron-up' : 'fa-chevron-down'
            } p-2 clickable`}
          ></i>
        </div>
      </div>
      <Collapse in={open}>
        <div className='pl-4'>
          {message.logEntries.map((l, i) => (
            <p key={i}>
              {moment(l.created).format('lll')}: {l.message}
              {l.twowayStatusMessage ? (
                <>
                  <br />
                  <span className='statusRed'>{l.twowayStatusMessage}</span>
                </>
              ) : null}
            </p>
          ))}
        </div>
      </Collapse>
    </>
  );
};

TransactionMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default TransactionMessage;
