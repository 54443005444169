import React from 'react';
import Export from '../../../Common/Export';
import {
  parseDailyReportDataForExport,
  parsePayoutsReportsForExport,
  parseTransactionsDataForExport,
} from '../../../../utils/helpers/sortAndMapFunctions';

const PracticeReportsExport = ({
  currentReport = {
    type: '',
    data: [],
  },
}) => {
  function parseSelectedReport() {
    const { type, data } = currentReport;

    switch (type) {
      case 'daily':
        return parseDailyReportDataForExport(data);
      case 'payouts':
        return parsePayoutsReportsForExport(data);
      case 'transactions':
        return parseTransactionsDataForExport(data);
      default:
        return [];
    }
  }

  return (
    <div className='px-2 text-right'>
      <Export
        disabled={!currentReport.data.length}
        csvData={parseSelectedReport()}
        fileName={currentReport.type}
      />
    </div>
  );
};

export default PracticeReportsExport;
