import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkedValue = ({
  value,
  redirectTo = null,
  children = null,
  copyable = false,
  expandable = false,
  shortened = false,
  showEllipsis = false,
  underlined = false,
  shortenedCharNum = 10,
}) => {
  const [linkedValue, setLinkedValue] = useState(shortenValue(value));
  const [copied, setCopied] = useState(false);

  function onExpand() {
    setLinkedValue(value);
  }

  function onCollapse() {
    setCopied(false);
    setLinkedValue(shortenValue(value));
  }

  function onCopy(val) {
    setCopied(true);

    let el = document.createElement('textarea');
    el.value = val;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  function shortenValue(val) {
    if (shortened) {
      return val.substring(0, shortenedCharNum);
    }

    return val;
  }

  return (
    <span
      onMouseEnter={onExpand}
      onMouseLeave={onCollapse}
      className={`
                linked-value
                ${redirectTo ? 'linked' : ''}
                ${expandable ? 'expandable' : ''}
                ${showEllipsis ? 'show-ellipsis' : ''}
                ${underlined ? 'underlined' : ''}
            `}
    >
      {redirectTo && <Link to={redirectTo}>{linkedValue || children}</Link>}
      {!redirectTo && (linkedValue || children)}
      {copyable && (
        <span
          className='linked-value-copy-btn vitus-link-blue'
          onClick={() => onCopy(value)}
        >
          {copied ? (
            <span>
              Copied <i className='fa fa-check'></i>
            </span>
          ) : (
            'Copy'
          )}
        </span>
      )}
    </span>
  );
};

LinkedValue.propTypes = {
  value: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
  children: PropTypes.node,
  copyable: PropTypes.bool,
  expandable: PropTypes.bool,
  shortened: PropTypes.bool,
  showEllipsis: PropTypes.bool,
  underlined: PropTypes.bool,
  shortenedCharNum: PropTypes.number,
};

export default LinkedValue;
