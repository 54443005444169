import {
  handleResponse,
  handleError,
  axiosInstallmentsInstance as axiosInstallments,
} from './index';

const Origin = 'Portal';

export async function initiateInstallmentPlan(initiateRequest) {
  try {
    const {
      amount,
      billingAddress,
      email,
      name,
      phone,
      invoiceId,
      staffMember,
      practice,
    } = initiateRequest;
    const { staffMemberId, staffMemberName } = staffMember;
    const { practiceName, practiceId } = practice;
    const basePath = window.location.origin;

    const body = {
      type: 'initiate',
      amount,
      RedirectUrls: {
        Succeeded: basePath + '/success',
        Canceled: basePath + '/canceled',
        Failed: basePath + '/failed',
      },
      BillingAddress: billingAddress,
      ConsumerData: {
        CultureName: 'en-us',
        Email: email,
        FullName: name,
        PhoneNumber: phone,
      },
      PaymentWizardData: {
        IsOpenedInIframe: true,
      },
      PlanData: {
        NumberOfInstallments: 0,
        AutoCapture: true,
        ExtendedParams: {
          invoice_id: invoiceId,
          staff_member: staffMemberName,
          origin: Origin,
        },
        RefOrderNumber: `${practiceName} (${practiceId})`,
      },
      userId: staffMemberId,
    };

    const res = await axiosInstallments.post('/request', body);
    return handleResponse(res);
  } catch (err) {
    return handleError(err);
  }
}

export async function insertInstallmentPlan(installmentPlanNumber) {
  try {
    const body = {
      InstallmentPlanNumber: installmentPlanNumber,
    };

    const res = await axiosInstallments.post('/insert', body);
    return handleResponse(res);
  } catch (err) {
    return handleError(err);
  }
}
