// Modules
import React, { useState, useEffect, useCallback } from 'react';

// Hooks
import useInput from 'utils/hooks/useInput';

// Api
import { getSetupIds } from 'utils/api/payfabricApi';

// Utils
import { validateStripeAccountName } from 'utils/api/merchantApi';
import { validateGateway } from 'utils/api/spreedlyApi';
import generateRandomID from 'utils/helpers/generateRandomID';

// Components
import {
  Modal,
  Form,
  Button,
  OverlayTrigger,
  Popover,
  Collapse,
} from 'react-bootstrap';
import Loading from 'components/Common/Loading';

// Constants
import PaymentPlatforms from 'constants/PaymentPlatforms';
import FetchStatuses from 'constants/FetchStatuses';

import styles from './ModalFormElements.module.scss';
import DevicesList from 'components/Common/DevicesList/DevicesList';
import { DeviceTypes } from 'constants/Payfabric';

function ModalFormElements(props) {
  const {
    accountName,
    addPayFabricDeviceId,
    connectTo,
    currentPayFabricDeviceIds,
    deletePayfabricDeviceId,
    payProcessorUpdateData,
    resetPayFabricDeviceIds,
    setPayProcessorUpdateData,
    setShowModal,
    showModal,
  } = props;

  const [stripeId, setStripeId] = useInput(
    payProcessorUpdateData?.stripeId ?? ''
  );
  const [authNetLogin, setAuthNetLogin] = useInput(
    payProcessorUpdateData?.stripeId ?? ''
  );
  const [authNetPassword, setAuthNetPassword] = useInput(
    payProcessorUpdateData?.stripeId ?? ''
  );

  const [updating, setUpdating] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const [payFabricDeviceId, setPayFabricDeviceId] = useInput();
  const [payFabricDevicePassword, setPayFabricDevicePassword] = useInput();
  const [payFabricSetupId, setPayFabricSetupId] = useInput();
  const [payFabricDeviceType, setPayFabricDeviceType] = useInput();
  const [fetchedSetupIds, setFetchedSetupIds] = useState([]);
  const [setupIdStatus, setSetupIdStatus] = useState(FetchStatuses.idle);
  const [openDeviceForm, setOpenDeviceForm] = useState(false);
  const [deviceAdded, setDeviceAdded] = useState(false);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([
    { title: 'Select', key: '--' },
    { title: 'Card Not Present', key: 'CardNotPresent' },
    { title: 'Saved Card', key: 'SavedCard' },
    { title: 'Terminal', key: 'Terminal' },
  ]);

  const [rectangleMerchantAccountCode, setRectangleMerchantAccountCode] =
    useInput(
      payProcessorUpdateData?.rectangleMerchantAccountCode ??
        props?.rectangleMerchantAccountCode ??
        ''
    );
  const [rectanglePublicKey, setRectanglePublicKey] = useInput(
    props?.rectanglePublicKey ?? ''
  );
  const [rectanglePrivateKey, setRectanglePrivateKey] = useInput();
  const [rectangleWalletSiteId, setRectangleWalletSiteId] = useInput(
    props?.rectangleWalletSiteId
  );
  const [showRectangleEditButton, setShowRectangleEditButton] = useState(
    rectanglePublicKey === props?.rectanglePublicKey
  );
  const [rectangleLocations, setRectangleLocations] = useState(
    props?.rectangleLocations?.length > 0
      ? props.rectangleLocations
      : [{ id: '', name: '' }]
  );

  function resetPayFabricFields() {
    setFetchedSetupIds([]);
    setPayFabricSetupId('');
    setPayFabricDeviceType('');
    setSetupIdStatus(FetchStatuses.idle);
  }

  const fillPayFabricDeviceTypeSelectValues = useCallback(() => {
    setDeviceTypeOptions(
      [
        { title: 'Select', key: '--' },
        !currentPayFabricDeviceIds?.defaultDeviceId && {
          title: 'Card Not Present',
          key: 'CardNotPresent',
        },
        !currentPayFabricDeviceIds?.savedCardDeviceId && {
          title: 'Saved Card',
          key: 'SavedCard',
        },
        !currentPayFabricDeviceIds?.terminalDeviceId && {
          title: 'Terminal',
          key: 'Terminal',
        },
      ].filter(Boolean)
    );
  }, [setDeviceTypeOptions, currentPayFabricDeviceIds]);

  useEffect(() => {
    fillPayFabricDeviceTypeSelectValues();
  }, [fillPayFabricDeviceTypeSelectValues]);

  async function handleFetchSetupIds() {
    if (setupIdStatus === FetchStatuses.pending) return;

    setErrorMessages({});
    resetPayFabricFields();

    if (!payFabricDeviceId || !payFabricDevicePassword) {
      setErrorMessages({
        payFabricErr: 'Please Enter Device ID and Password',
      });
      return;
    }

    try {
      setSetupIdStatus(FetchStatuses.pending);

      const data = await getSetupIds(
        payFabricDeviceId,
        payFabricDevicePassword
      );

      const setupIds = data.map((gateway) => gateway.Name);

      if (setupIds.length === 0) {
        setSetupIdStatus(FetchStatuses.rejected);
        setErrorMessages({
          payFabricErr:
            'No SetupIDs were found for this device, please contact PayFabric for help.',
        });
        return;
      }

      setFetchedSetupIds(setupIds);
      setPayFabricSetupId(setupIds[0]);
      setSetupIdStatus(FetchStatuses.resolved);
    } catch (err) {
      setSetupIdStatus(FetchStatuses.rejected);
      setErrorMessages({
        payFabricErr:
          'Could not validate Device ID and Password, please verify',
      });
    }
  }

  function getSelectDisplayValue() {
    switch (setupIdStatus) {
      case FetchStatuses.idle:
      case FetchStatuses.rejected:
        return 'Select a SetupID for practice';
      case FetchStatuses.pending:
        return 'Loading...';
      case FetchStatuses.resolved:
        return payFabricSetupId;
      default:
        throw new Error(`Unrecognizable status: ${setupIdStatus}`);
    }
  }

  const isDisabled = () => {
    if (connectTo === PaymentPlatforms.stripe) {
      return !stripeId;
    } else if (connectTo === PaymentPlatforms.spreedly) {
      return !authNetLogin || !authNetPassword;
    } else if (connectTo === PaymentPlatforms.payfabric) {
      return (
        !payFabricDeviceId ||
        !payFabricDevicePassword ||
        !payFabricSetupId ||
        payFabricDeviceType === '--' ||
        payFabricDeviceType === ''
      );
    } else if (connectTo === PaymentPlatforms.rectangle) {
      return showRectangleEditButton
        ? !rectangleMerchantAccountCode
        : !rectangleMerchantAccountCode ||
            !rectanglePublicKey ||
            !rectanglePrivateKey;
    }
  };

  const handleExit = (closeModal = false, isCancel = true) => {
    // reset fields upon modal exit to be sure that bad data doesn't get sent to backend
    resetPayFabricFields();
    setStripeId('');
    setAuthNetLogin('');
    setAuthNetPassword('');
    setPayFabricDeviceId('');
    setPayFabricDevicePassword('');
    setErrorMessages({});
    resetPayFabricDeviceIds();

    if (isCancel) {
      setRectangleMerchantAccountCode(
        props?.rectangleMerchantAccountCode ?? ''
      );
      setRectanglePublicKey(props?.rectanglePublicKey ?? '');
      setRectanglePrivateKey(props?.rectanglePrivateKey ?? '');
      setShowRectangleEditButton(!!rectanglePublicKey);
    }

    if (closeModal) {
      setShowModal(false);
    } else {
      setOpenDeviceForm(false);
    }
  };

  const getPayFabricDetails = (deviceConfig, prev) => {
    if (deviceConfig) {
      return prev?.payFabricDevices?.length >= 0
        ? [
            ...prev.payFabricDevices,
            {
              [deviceConfig.id]: payFabricDeviceId,
              [deviceConfig.password]: payFabricDevicePassword,
              [deviceConfig.setupId]: payFabricSetupId,
              deviceType: payFabricDeviceType,
            },
          ]
        : [
            {
              [deviceConfig.id]: payFabricDeviceId,
              [deviceConfig.password]: payFabricDevicePassword,
              [deviceConfig.setupId]: payFabricSetupId,
              deviceType: payFabricDeviceType,
            },
          ];
    } else {
      return [];
    }
  };

  const updateSuccess = (closeModal = false) => {
    setPayProcessorUpdateData((prev) => {
      const deviceConfig =
        DeviceTypes[payFabricDeviceType || prev?.payFabricDeviceType];

      return {
        ...prev,
        stripeId: stripeId ? stripeId : prev?.stripeId ?? '',
        authNetLogin: authNetLogin ? authNetLogin : prev?.authNetLogin ?? '',
        authNetPassword: authNetPassword
          ? authNetPassword
          : prev?.authNetPassword ?? '',
        payFabricDevices: [...getPayFabricDetails(deviceConfig, prev)],
        rectangleMerchantAccountCode: rectangleMerchantAccountCode,
        rectanglePublicKey: rectanglePublicKey,
        rectanglePrivateKey: rectanglePrivateKey,
        rectangleWalletSiteId: rectangleWalletSiteId,
        rectangleLocations: rectangleLocations,
      };
    });

    handleExit(closeModal, false);
  };

  function handlePayFabricConnect() {
    // we can just continue as validation has already been done
    updateSuccess(false);
    setUpdating(false);
    addPayFabricDeviceId(payFabricDeviceId, payFabricDeviceType);
    setDeviceAdded(true);
  }

  const handleSpreedlyConnect = async () => {
    try {
      const res = await validateGateway(authNetLogin, authNetPassword);
      if (res.valid) {
        updateSuccess(true);
      } else {
        setErrorMessages({
          spreedlyErr: 'Could not validate Auth.Net information, please verify',
        });
      }
    } catch (err) {
      console.log(err);
      setErrorMessages({
        spreedlyErr:
          'There was an error trying to validate the account information',
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleStripeConnect = async () => {
    try {
      const data = await validateStripeAccountName(stripeId, accountName);

      if (data?.isValid) {
        updateSuccess(true);
      } else {
        setErrorMessages({
          practiceName:
            'Practice Name does not match the Stripe account name for the provided Stripe ID',
        });
      }
    } catch (err) {
      setErrorMessages({
        stripeId: 'There is no Stripe account matching the provided ID',
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleRectangleConnect = async () => {
    try {
      updateSuccess(true);
    } catch (err) {
      console.log(err);
    } finally {
      setUpdating(false);
    }
  };

  const handleConnect = () => {
    setUpdating(true);

    if (connectTo === PaymentPlatforms.stripe) {
      handleStripeConnect();
    } else if (connectTo === PaymentPlatforms.spreedly) {
      handleSpreedlyConnect();
    } else if (connectTo === PaymentPlatforms.payfabric) {
      handlePayFabricConnect();
    } else if (connectTo === PaymentPlatforms.rectangle) {
      handleRectangleConnect();
    }
  };

  const getOpenDeviceFormIcon = () => {
    return openDeviceForm ? 'fa-caret-down' : 'fa-plus';
  };

  const getSetupIdStatus = () => {
    return setupIdStatus === FetchStatuses.pending ? styles.spin : '';
  };

  const showNewConnectDeviceForm =
    !!currentPayFabricDeviceIds?.defaultDeviceId &&
    !!currentPayFabricDeviceIds?.savedCardDeviceId &&
    !!currentPayFabricDeviceIds?.terminalDeviceId
      ? 'd-none'
      : '';

  const SubmitButton = (_props) =>
    _props.paymentPlatform === PaymentPlatforms.payfabric ? (
      <Button onClick={() => setShowModal(false)}>UPDATE</Button>
    ) : (
      <Button
        onClick={handleConnect}
        disabled={isDisabled()}
      >{`CONNECT TO ${connectTo.toUpperCase()}`}</Button>
    );

  const editRectanglePublicPrivateKey = () => {
    setShowRectangleEditButton(false);
  };

  const handleRectangleLocationIdChange = (index, event) => {
    const newLocations = [...rectangleLocations];
    newLocations[index].id = event.target.value;

    setRectangleLocations(newLocations);
  };

  const handleRectangleLocationNameChange = (index, event) => {
    const newLocations = [...rectangleLocations];
    newLocations[index].name = event.target.value;

    setRectangleLocations(newLocations);
  };

  // Handle adding a new location ID field
  const handleAddRectangleLocation = () => {
    setRectangleLocations([...rectangleLocations, { id: '', name: '' }]);
  };

  // Handle removing a location ID field
  const handleRemoveRectangleLocation = (index) => {
    const newLocations = rectangleLocations.filter((_, i) => i !== index);
    setRectangleLocations(newLocations);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => null}
      size='md'
      aria-labelledby={`update-modal-title-${connectTo}`}
      centered
    >
      <Modal.Header>
        <Modal.Title id={`update-modal-title-${connectTo}`}>
          {`CONNECT TO ${connectTo.toUpperCase()}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {connectTo === PaymentPlatforms.stripe ? (
          <Form.Group controlId='authNetLogin'>
            <Form.Label>Stripe ID</Form.Label>
            <Form.Control
              type='text'
              name='authNetLogin'
              value={stripeId}
              onChange={setStripeId}
              placeholder='Enter connected Stripe ID'
            />
            <Form.Text className='text-muted'>
              Input the ID of the connect account in Stripe. ex.:
              acct_xxxxxxxxxxxxxxxx
            </Form.Text>
          </Form.Group>
        ) : null}

        {connectTo === PaymentPlatforms.spreedly ? (
          <>
            <Form.Group controlId='authNetLogin'>
              <Form.Label>Auth.Net ID</Form.Label>
              <Form.Control
                type='text'
                name='authNetLogin'
                value={authNetLogin}
                onChange={setAuthNetLogin}
                placeholder='Enter Auth.Net ID for practice'
              />
              <Form.Text className='text-muted'>
                Input the Auth.Net ID provided by Paybright for this practice
              </Form.Text>
            </Form.Group>

            <Form.Group controlId='authNetPassword'>
              <Form.Label>Auth.Net Transaction Key</Form.Label>
              <Form.Control
                type='text'
                name='authNetPassword'
                value={authNetPassword}
                onChange={setAuthNetPassword}
                label='Auth.Net Transaction Key'
                placeholder='Enter Auth.Net Transaction Key for practice'
              />
              <Form.Text className='text-muted'>
                Input the Auth.Net Transaction Key the corresponds with the
                Auth.Net ID for this practice
              </Form.Text>
            </Form.Group>
          </>
        ) : null}

        {connectTo === PaymentPlatforms.payfabric ? (
          <>
            <DevicesList
              payFabricDeviceIds={currentPayFabricDeviceIds}
              deletePayfabricDeviceId={deletePayfabricDeviceId}
            />
            <div
              className={`row ${showNewConnectDeviceForm}`}
              type='button'
              onClick={() => setOpenDeviceForm(!openDeviceForm)}
            >
              <p className={`col ${styles.newDeviceFormTitle}`}>
                CONNECT NEW DEVICE
              </p>
              <div type='button' className='mr-3'>
                <i className={`fas ${getOpenDeviceFormIcon()}`}></i>
              </div>
            </div>
            <Collapse in={openDeviceForm}>
              <div className={styles.newDeviceForm}>
                <Form.Group controlId='payFabricDeviceId'>
                  <Form.Label>Device ID</Form.Label>
                  <Form.Control
                    type='text'
                    name='payFabricDeviceId'
                    value={payFabricDeviceId}
                    onChange={(e) => {
                      resetPayFabricFields();
                      setPayFabricDeviceId(e.target.value.trim());
                    }}
                    placeholder='Enter PayFabric Device ID for practice'
                  />
                  <Form.Text className='text-muted'>
                    Enter PayFabric Device ID for practice
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId='payFabricDevicePassword'>
                  <Form.Label>Device Password</Form.Label>
                  <Form.Control
                    type='text'
                    name='payFabricDevicePassword'
                    value={payFabricDevicePassword}
                    onBlur={handleFetchSetupIds}
                    onChange={(e) => {
                      resetPayFabricFields();
                      setPayFabricDevicePassword(e.target.value);
                    }}
                    placeholder='Enter PayFabric Device Password for practice'
                  />
                  <Form.Text className='text-muted'>
                    Enter PayFabric Device Password for practice
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId='payFabricSetupId'>
                  <Form.Label>Setup ID</Form.Label>

                  {/* Refresh Button */}
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement='top'
                    overlay={
                      <Popover className='popover-basic'>
                        <Popover.Content>Find Setup IDs</Popover.Content>
                      </Popover>
                    }
                  >
                    <i
                      className={`fa fa-sync-alt ml-1 ${getSetupIdStatus()} ${
                        styles.refreshButton
                      }`}
                      onClick={handleFetchSetupIds}
                    />
                  </OverlayTrigger>

                  {/* Select */}
                  <div>
                    <select
                      className='form-control'
                      onChange={(e) => setPayFabricSetupId(e.target.value)}
                      value={
                        fetchedSetupIds.length === 0 ? 'none' : payFabricSetupId
                      }
                    >
                      {fetchedSetupIds.length === 0 ? (
                        <option value='none' disabled>
                          {getSelectDisplayValue()}
                        </option>
                      ) : (
                        fetchedSetupIds.map((setupId, i) => (
                          <option key={i} value={setupId}>
                            {setupId}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <Form.Text className='text-muted'>
                    Select refresh to load the latest Setup IDs for practice{' '}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId='payFabricDeviceType'>
                  <Form.Label>Device Type</Form.Label>
                  {deviceTypeOptions.length > 1 && (
                    <div>
                      <select
                        className='form-control'
                        onChange={(e) => setPayFabricDeviceType(e.target.value)}
                        value={payFabricDeviceType}
                      >
                        {deviceTypeOptions.map((val) => {
                          return (
                            <option key={val.key} value={val.key}>
                              {val.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <Form.Text className='text-muted'>
                    Select the type of this new Device ID
                  </Form.Text>
                </Form.Group>

                <Button onClick={handleConnect} disabled={isDisabled()}>
                  CONNECT TO PAYFABRIC
                </Button>
              </div>
            </Collapse>
          </>
        ) : null}

        {connectTo === PaymentPlatforms.rectangle ? (
          <>
            <Form.Group controlId='rectangleMerchantAccountCode'>
              <Form.Label>Rectangle Merchant Account Code</Form.Label>
              <Form.Control
                type='text'
                name='rectangleAccountCode'
                value={rectangleMerchantAccountCode}
                onChange={setRectangleMerchantAccountCode}
                placeholder='Enter Rectangle Merchant Account Code for this practice'
              />
            </Form.Group>
            {showRectangleEditButton && (
              <>
                <Form.Group controlId='rectanglePublicPrivateKey'>
                  <Button
                    variant='outline-secondary'
                    onClick={editRectanglePublicPrivateKey}
                  >
                    Edit Public/Private Key Pair
                  </Button>
                </Form.Group>
              </>
            )}
            {!showRectangleEditButton && (
              <>
                <Form.Group controlId='rectanglePublicKey'>
                  <Form.Label>Public Key</Form.Label>
                  <Form.Control
                    type='text'
                    name='rectanglePublicKey'
                    value={rectanglePublicKey}
                    onChange={setRectanglePublicKey}
                    placeholder='Enter Rectangle Public Key for this practice'
                  />
                </Form.Group>
                <Form.Group controlId='rectanglePrivateKey'>
                  <Form.Label>Private Key</Form.Label>
                  <Form.Control
                    type='text'
                    name='rectanglePrivateKey'
                    value={rectanglePrivateKey}
                    onChange={setRectanglePrivateKey}
                    placeholder='Enter Rectangle Private Key for this practice'
                  />
                </Form.Group>
              </>
            )}

            <Form.Group controlId='rectangleWalletSiteId'>
              <Form.Label>Wallet Site Id</Form.Label>
              <Form.Control
                type='text'
                name='rectangleWalletSiteId'
                value={rectangleWalletSiteId}
                onChange={setRectangleWalletSiteId}
                placeholder='Enter Rectangle Wallet Site Id for this practice'
              />
            </Form.Group>

            <Form.Group controlId='rectangleLocations'>
              <Form.Label>Locations</Form.Label>
              {rectangleLocations.map((location, index) => (
                <div
                  key={index}
                  className='location-id-row'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '10px',
                  }}
                >
                  <Form.Control
                    type='text'
                    placeholder='Enter Location ID'
                    value={location.id}
                    onChange={(e) => handleRectangleLocationIdChange(index, e)}
                    required
                  />
                  <Form.Control
                    type='text'
                    placeholder='Enter Display Name'
                    value={location.name}
                    onChange={(e) =>
                      handleRectangleLocationNameChange(index, e)
                    }
                  />
                  <button
                    type='button'
                    className='btn btn-outline-secondary'
                    onClick={() => handleRemoveRectangleLocation(index)}
                    hidden={rectangleLocations.length === 1} // Disable remove if only one location ID
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type='button'
                onClick={handleAddRectangleLocation}
                className='btn btn-outline-secondary'
              >
                Add Location ID
              </button>
            </Form.Group>
          </>
        ) : null}

        {Object.values(errorMessages).map((msg) => (
          <p key={generateRandomID()} className='invalid-feedback d-block'>
            {msg}
          </p>
        ))}
        {deviceAdded && connectTo === PaymentPlatforms.payfabric && (
          <p className={styles.successFeedback}>
            Successfully added the device, please click Save to finalize your
            changes.
          </p>
        )}
      </Modal.Body>

      <Modal.Footer
        className={`d-flex align-center ${
          updating ? 'justify-content-center' : 'justify-content-between'
        }`}
      >
        {updating ? (
          <Loading
            style={{
              alignItems: 'center',
            }}
            message='Validating...'
          />
        ) : (
          <>
            <Button variant='light' onClick={handleExit}>
              CANCEL
            </Button>
            <SubmitButton paymentPlatform={connectTo} />
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFormElements;
