import React from 'react';

import filledCheck from '../../../assets/images/filled-check.svg';
import hollowCheck from '../../../assets/images/hollow-check.svg';

import styles from './CardRadioButton.module.scss';

function CardRadioButton(props) {
  const { checked = true, children, onClick } = props;

  return (
    <button
      className={
        checked ? styles.selectedContainer : styles.unselectedContainer
      }
      onClick={onClick}
    >
      <div className={styles.checkContainer}>
        {checked ? (
          <img className={styles.check} src={filledCheck} alt='filled-check' />
        ) : (
          <img className={styles.check} src={hollowCheck} alt='hollow-check' />
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </button>
  );
}

export default CardRadioButton;
