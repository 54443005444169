import {
  handleResponse,
  handleError,
  axiosMerchantInstance as axiosMerchant,
} from './index';

export function processRefund(transaction_id, amount, custom_refund_reason) {
  const body = {
    transaction_id,
    amount,
    customRefundReason: custom_refund_reason,
  };
  return axiosMerchant
    .post('/refund', body)
    .then(handleResponse)
    .catch(handleError);
}

export function getPracticePayoutsReport() {
  return axiosMerchant
    .post('/get', { limit: 100, transaction_type: 'po' })
    .then(handleResponse)
    .catch(handleError);
}

export function configurePayment(code) {
  return axiosMerchant
    .post(`/connect`, { stripe_user_code: code })
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Get Account Details From Stripe
 * @param {string} id
 */
export function validateStripeAccountName(id, name) {
  const uriEncodedName = encodeURI(name);

  return axiosMerchant
    .get(`/accounts/validate/${id}?accountName=${uriEncodedName}`)
    .then(handleResponse)
    .catch(handleError);
}
