import initialState from './initialState';
import { StoreActions } from '../actions';

const currentPath = (state = initialState.currentPath, action) => {
  switch (action.type) {
    case StoreActions.SET_REDIRECT_PATH:
      return action.path;
    case StoreActions.LOGOUT:
      return initialState.currentPath;
    default:
      return state;
  }
};

export default currentPath;
