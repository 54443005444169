import initialState from './initialState';
import { StoreActions } from './../actions';

export const staffReducer = (state = initialState.staff, action) => {
  switch (action.type) {
    case StoreActions.GET_STAFF_MEMBERS_SUCCESS:
      return action.staffList;
    case StoreActions.ADD_STAFF_MEMBER_SUCCESS:
      return [...state, { ...action.newStaffMember }];
    case StoreActions.UPDATE_STAFF_MEMBER_SUCCESS:
      return state.map((staffMember) =>
        staffMember.id === action.updatedStaffMember.id
          ? action.updatedStaffMember
          : staffMember
      );
    case StoreActions.DELETE_STAFF_MEMBER_SUCCESS:
      return state.map((staffMember) =>
        staffMember.id === action.deletedStaffMember.id
          ? { ...staffMember, ...action.deletedStaffMember }
          : staffMember
      );
    case StoreActions.LOGOUT:
      return initialState.staff;
    default:
      return state;
  }
};

export const selectedStaff = (
  state = initialState.selectedStaffMember,
  action
) => {
  switch (action.type) {
    case StoreActions.SET_SELECTED_STAFF_MEMBER:
      return action.staffMember;
    case StoreActions.UNSET_SELECTED_STAFF_MEMBER:
      return initialState.selectedStaffMember;
    case StoreActions.SET_STAFF_MEMBER_TO_INACTIVE:
      return { ...state, ...action.staffMember };
    case StoreActions.LOGOUT:
      return initialState.selectedStaffMember;
    default:
      return state;
  }
};

export const toggleAddEditStaffMember = (
  state = initialState.staffMemberToggleAddEdit,
  action
) => {
  switch (action.type) {
    case StoreActions.TOGGLE_ADD_EDIT_STAFF_MEMBER:
      return action.value;
    case StoreActions.LOGOUT:
      return initialState.staffMemberToggleAddEdit;
    default:
      return state;
  }
};

export const toggleDeleteStaffMemberConfirmation = (
  state = initialState.staffMemberToggleDeleteConfirmation,
  action
) => {
  switch (action.type) {
    case StoreActions.TOGGLE_DELETE_STAFF_MEMBER:
      return action.value;
    case StoreActions.LOGOUT:
      return initialState.staffMemberToggleDeleteConfirmation;
    default:
      return state;
  }
};

export default staffReducer;
