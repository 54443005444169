import { useState } from 'react';

export default function useInput(initialVal = '') {
  /**
   * This handles the case that the initial value is null
   *
   * The default value is not used if null is passed as an argument
   * but controlled input elements should never have null as their
   * initial state value
   */
  initialVal = initialVal || '';

  const storedInitialVal = initialVal;

  const [state, setState] = useState(initialVal);

  const input = (arg) => {
    const constructorName = arg.constructor.name;
    const target = arg.target;
    if (
      constructorName === 'SyntheticEvent' ||
      (target && target.hasOwnProperty('value'))
    ) {
      setState(target.value);
    } else if (typeof arg === 'function') {
      setState(arg);
    } else if (typeof arg === 'string') {
      setState(arg);
    }
  };

  const reset = () => {
    setState(storedInitialVal);
  };

  return [state, input, reset, storedInitialVal];
}
