// Modules
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import {
  postSpreedlyPaymentInfo,
  getDraftSpreedlyTransaction,
} from 'utils/store/actions/transactionsActions';

// Components
import SpreedlyForm from './SpreedlyForm';
import Loading from 'components/Common/Loading';
import { Button } from 'react-bootstrap';

// CSS
import styles from './SpreedlyEmbed.module.scss';

const registeredPaths = {
  base: '/pay-embed',
  success: '/pay-embed/success',
  exit: '/pay-embed/exit',
};

function redirect(path) {
  const origin = window.location.origin;
  const params = window.location.search;

  return (window.location.href = origin + path + params);
}

const redirectPaths = {
  base: () => redirect(registeredPaths.base),
  success: () => redirect(registeredPaths.success),
  exit: () => redirect(registeredPaths.exit),
};

const displayStatuses = {
  loading: 'LOADING',
  showForm: 'SHOW_FORM',
  tokenSucces: 'TOKEN_SUCCESS',
  showError: 'SHOW_ERROR',
  success: 'SHOW_SUCCESS',
  showExit: 'SHOW_EXIT',
};

const displayInitState = {
  showForm: false,
  loading: false,
  tokenSuccess: false,
  showError: false,
  showSuccess: false,
  showExit: false,
};

function displayReducer(state, action) {
  const { type } = action;

  switch (type) {
    case displayStatuses.loading:
      return { ...displayInitState, loading: true };

    case displayStatuses.showForm:
      return { ...state, showForm: true, loading: false };

    case displayStatuses.tokenSucces:
      return { ...state, loading: false, tokenSuccess: true };

    case displayStatuses.showExit:
      return { ...displayInitState, showExit: true };

    case displayStatuses.showError:
      return { ...displayInitState, showError: true };

    case displayStatuses.success:
      return { ...displayInitState, showSuccess: true };

    default:
      return state;
  }
}

function showComponentByPath(dispatchDisplay) {
  const path = window.location.pathname;

  switch (path) {
    case registeredPaths.base:
      return dispatchDisplay({ type: displayStatuses.showForm });
    case registeredPaths.success:
      return dispatchDisplay({ type: displayStatuses.success });
    case registeredPaths.exit:
      return dispatchDisplay({ type: displayStatuses.showExit });
    default:
      return dispatchDisplay({ type: displayStatuses.error });
  }
}

// (err: string): string
function getErrorMessage(err) {
  switch (err) {
    case 'cvv does not match':
      return "Your card's security code is incorrect.  Please wait 2 minutes and try again with the correct CVV or try another card.";

    case 'the credit card number is invalid':
      return 'Your card number is incorrect';

    case 'a duplicate transaction has been submitted':
      return 'Your card has been declined. Please try again with different credit card information';

    default:
      return 'Your card has been declined';
  }
}

/**
 * ==================
 * Exported Component
 * ==================
 */
function SpreedlyEmbed() {
  const [display, dispatchDisplay] = useReducer(
    displayReducer,
    displayInitState,
    (state) => ({ ...state, loading: true })
  );

  const [errorMessage, setErrorMessage] = useState(
    'There was an error processing this transaction'
  );

  const transaction = useSelector((state) => state.spreedlyTransactionInfo);

  const dispatch = useDispatch();

  const handleSpreedlySubmit = () => {
    dispatchDisplay({ type: displayStatuses.loading });
  };

  const handleSpreedlyError = (errors) => {
    dispatchDisplay({ type: displayStatuses.showError });
  };

  const handleTokenSuccess = async (token) => {
    dispatchDisplay({ type: displayStatuses.tokenSucces });

    try {
      await postSpreedlyPaymentInfo(transaction.transactionId, token)(dispatch);
      redirectPaths.success();
    } catch (err) {
      dispatchDisplay({ type: displayStatuses.showError });
      const msg = err.error?.toLowerCase() || '';
      const noPeriod =
        msg[msg.length - 1] === '.' ? msg.substring(0, msg.length - 1) : msg;
      setErrorMessage(getErrorMessage(noPeriod));
    }
  };

  const handleExit = () => {
    redirectPaths.exit();
  };

  useEffect(() => {
    const path = window.location.pathname;

    async function getTransaction() {
      const urlParams = new URLSearchParams(window.location.search);

      const id = urlParams.get('transactionId');

      try {
        await getDraftSpreedlyTransaction(id)(dispatch);
      } catch (err) {
        dispatchDisplay({ type: displayStatuses.showError });
      }
    }

    if (path === registeredPaths.base) {
      getTransaction();
    } else {
      showComponentByPath(dispatchDisplay);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!transaction?.charge?.amount) return;
    showComponentByPath(dispatchDisplay);
  }, [transaction]);

  return (
    <div className={styles.container}>
      {display.loading ? <Loading message='Loading...' /> : null}

      {
        // Don't render component until transaction is loaded
        transaction?.charge?.amount ? (
          <SpreedlyForm
            showForm={display.showForm}
            transaction={transaction}
            handleSpreedlySubmit={handleSpreedlySubmit}
            handleSpreedlyError={handleSpreedlyError}
            handleTokenSuccess={handleTokenSuccess}
          />
        ) : null
      }

      {display.tokenSuccess ? (
        <Loading message='Processing transaction...' />
      ) : null}

      {display.showSuccess ? (
        <>
          <h1>Transaction complete!</h1>
          <Loading message='Redirecting...' />
        </>
      ) : null}

      {display.showExit ? <Loading message='Canceling transaction...' /> : null}

      {display.showError ? (
        <div className={styles.errorWrapper}>
          <h1 className={styles.errorHeading}>{errorMessage}</h1>

          <div className={styles.errorButtons}>
            <Button
              type='button'
              variant='primary'
              onClick={redirectPaths.base}
            >
              RETRY
            </Button>
            <Button
              type='button'
              variant='outline-secondary'
              onClick={handleExit}
            >
              CANCEL TRANSACTION
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SpreedlyEmbed;
