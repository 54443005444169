import React from 'react';

import styles from './PaymentMethodDisplay.module.scss';

function PaymentMethodDisplay(props) {
  const { paymentMethod } = props;

  // convert to shorthand year
  // ex: 02/2024 -> 02/24
  const formattedExpiration = paymentMethod.expiration.replace(
    /(\d{2}\/)\d{2}(\d{2})/,
    '$1$2'
  );

  return (
    <div className={styles.container}>
      <div className={styles.name}>{paymentMethod.full_name}</div>
      <div className={styles.cardType}>{paymentMethod.card_type}</div>
      <div className={styles.lastFour}>**** {paymentMethod.last_four}</div>
      <div className={styles.expiration}>EXP {formattedExpiration}</div>
    </div>
  );
}

export default PaymentMethodDisplay;
