import { getPhoneDigitsOnly } from 'utils/helpers/phoneNumberValidate';
import {
  handleError,
  axiosTransactionPublicInstance as axiosTransaction,
  handleResponse,
} from './index';

export function postTransactionReceipt(
  transactionId = '',
  type = '',
  value = ''
) {
  switch (type) {
    case 'email':
      return axiosTransaction
        .post('/send-receipt', {
          transactionId,
          receiptType: 'email',
          email: value,
        })
        .catch(handleError);

    case 'text':
      return axiosTransaction
        .post('/send-receipt', {
          transactionId,
          receiptType: 'text_message',
        })
        .catch(handleError);

    case 'text_message':
      return axiosTransaction
        .post('/send-receipt', {
          transactionId,
          receiptType: 'text_message',
          phone: `+1${getPhoneDigitsOnly(value)}`,
        })
        .catch(handleError);

    default:
      return axiosTransaction
        .post('/send-receipt', {
          transactionId,
          receiptType: 'no_thanks',
        })
        .catch(handleError);
  }
}

export async function getPaymentLinkData(queryParams = '') {
  try {
    const res = await axiosTransaction.get(`/get${queryParams}`);
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
}

export async function createTransactionCustomer(queryParams, customerData) {
  try {
    const res = await axiosTransaction.post(`/customers${queryParams}`, {
      customerData,
    });
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
}

export async function getTransactionPaymentMethod(
  queryParams,
  paymentMethodId
) {
  try {
    const res = await axiosTransaction.get(
      `/payment-methods${queryParams}&paymentMethodId=${paymentMethodId}`
    );
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
}

export async function updateTransactionPaymentMethod(
  queryParams,
  customerId,
  paymentMethod
) {
  try {
    const res = await axiosTransaction.put(`/payment-methods${queryParams}`, {
      customerId,
      paymentMethod,
    });
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
}
