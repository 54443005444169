import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({
  type = 'text',
  placeholder = '',
  label = '',
  iconClass = '',
  inputStyling = {},
  inputClasses = '',
  required = false,
  error = false,
  fluid = false,
  ...props
}) => {
  return (
    <div
      className={`
            vitus-input-field 
            ${error ? 'error' : ''}
        `}
    >
      {iconClass && <i className={`pr-2 ${iconClass}`}></i>}
      <div className={`position-relative ${fluid && 'fluid'}`}>
        {label && (
          <label className='vitus-input-field-label'>
            {label} {required && '*'}
          </label>
        )}
        <input
          className={`form-control ${inputClasses}`}
          style={inputStyling}
          type={type}
          placeholder={placeholder}
          {...props}
        />
      </div>
    </div>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  inputStyling: PropTypes.object,
  inputClasses: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default InputField;
