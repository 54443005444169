import { isEmpty } from 'lodash';
import {
  handleResponse,
  handleError,
  iterateLastEvaluatedKey,
  axiosTransactionInstance as axiosTransaction,
} from './index';
import { mapTransaction } from './../helpers/sortAndMapFunctions';

const lastEvaluatedKeyIterationLimit = 75;

const get = (args) => (lastEvaluatedKey) => {
  let body = { ...args, lastEvaluatedKey };

  if (lastEvaluatedKey) {
    body.lastEvaluatedKey = lastEvaluatedKey;
  }
  return axiosTransaction
    .post('/get', isEmpty(body) ? null : body)
    .then(handleResponse)
    .then((response) => {
      response.Items.map(mapTransaction);
      return response;
    })
    .catch(handleError);
};

/**
 * Get transactions
 */
export function getPracticeTransactions(lastEvaluatedKey) {
  return axiosTransaction
    .post('/get', lastEvaluatedKey ? { lastEvaluatedKey } : {})
    .then(handleResponse)
    .then((response) => {
      response.Items.map(mapTransaction);
      return response;
    })
    .catch(handleError);
}

export function getAllPracticeTransactions(pageCallback) {
  return iterateLastEvaluatedKey(
    getPracticeTransactions,
    lastEvaluatedKeyIterationLimit,
    pageCallback
  );
}

export function getTransactionsByDateRange(startDate, endDate, pageCallback) {
  const args = {
    dates: {
      start: +startDate,
      final: +endDate,
    },
    projectionExpression:
      'id,transactionId,source,status,authNetStatus,practiceId,refunds,charge.amount,charge.applicationFeeAmount,charge.created,charge.net,charge.refunded,charge.user.name,UserId,invoiceId,description,charge.card.brand,charge.card.last4,charge.method,petData.owner,splitItObject,PaymentLinkSentTimestamp',
  };

  return iterateLastEvaluatedKey(
    get(args),
    lastEvaluatedKeyIterationLimit,
    pageCallback
  );
}

export function getPracticeTransactionsByPracticeId(
  practiceId,
  lastEvaluatedKey
) {
  const body = { practiceId };
  if (lastEvaluatedKey) {
    body.lastEvaluatedKey = lastEvaluatedKey;
  }
  console.log(body);
  return axiosTransaction
    .post('/get', body)
    .then(handleResponse)
    .then((response) => {
      response.Items.map(mapTransaction);
      return response;
    })
    .catch(handleResponse);
}

export function getAllPracticeTransactionsByPracticeId(
  practiceId,
  pageCallback
) {
  return iterateLastEvaluatedKey(
    (lastEvaluatedKey) =>
      getPracticeTransactionsByPracticeId(practiceId, lastEvaluatedKey),
    lastEvaluatedKeyIterationLimit,
    pageCallback
  );
}

/**
 *
 * @param {*} id
 * @param {*} charge
 * @param {*} updateRequested
 */
export function readPracticeTransaction(id) {
  const body = { id };

  return axiosTransaction
    .post('/get', body)
    .then(handleResponse)
    .then((response) => {
      return mapTransaction(response);
    })
    .catch(handleError);
}

export function sendTransactionReceipt(emailInfo) {
  return axiosTransaction
    .post('/email-receipt', emailInfo)
    .then(handleResponse)
    .catch(handleError);
}

export function resendTransactionReceipt(receiptParams) {
  return axiosTransaction
    .post('/resend-receipt', receiptParams)
    .then(handleResponse)
    .catch(handleError);
}

export function processRefund(
  transaction_id,
  amount,
  custom_refund_reason,
  email
) {
  const body = {
    transaction_id,
    amount,
    customRefundReason: custom_refund_reason,
  };
  return axiosTransaction
    .post('/refund', body)
    .then(handleResponse)
    .catch(handleError);
}

export function processVoid(transactionId, customVoidReason) {
  const body = {
    transactionId,
    customVoidReason,
  };
  return axiosTransaction
    .post('/void', body)
    .then(handleResponse)
    .catch(handleError);
}

export function getTransactionsPaginated(params) {
  return axiosTransaction
    .get('/paginated', { params })
    .then(handleResponse)
    .then((response) => {
      response.Items.map(mapTransaction);
      return response;
    })
    .catch(handleError);
}

export function createTransaction(body) {
  return axiosTransaction
    .post('', body)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(handleError);
}
