import produce from 'immer';
import moment from 'moment';
import { StoreActions } from '../actions';
import initialState from './initialState';

export const dailyReportReducer = (
  state = initialState.practiceDailyReport,
  action
) => {
  switch (action.type) {
    case StoreActions.GET_PRACTICE_DAILY_REPORTS_SUCCESSS:
      return action.report;
    case StoreActions.LOGOUT:
      return initialState.practiceDailyReport;
    default:
      return state;
  }
};

export const payoutsReportReducer = (
  state = initialState.practicePayoutsReport,
  action
) => {
  switch (action.type) {
    case StoreActions.GET_PRACTICE_PAYOUT_REPORTS_SUCCESS:
      return action.report;
    case StoreActions.LOGOUT:
      return initialState.practicePayoutsReport;
    default:
      return state;
  }
};

export const transactionReportReducer = produce((stateDraft, action) => {
  switch (action.type) {
    case StoreActions.GET_TRANSACTION_REPORT_FAILED:
      break;
    case StoreActions.GET_TRANSACTION_REPORT_PAGE_SUCCESS:
      stateDraft.transactions = stateDraft.transactions.concat(
        action.pageRecords
      );
      break;
    case StoreActions.GET_TRANSACTION_REPORT_STARTED:
      action.monthsToQuery.forEach((m) => {
        addOrReplaceRequest(stateDraft.requests, {
          date: +m,
          isFetchingData: true,
          expiresOn: null,
        });
        stateDraft.transactions = stateDraft.transactions.filter(
          (t) => !moment(m).isSame(moment(t.time), 'month')
        );
      });
      break;
    case StoreActions.GET_TRANSACTION_REPORT_SUCCESS:
      action.monthsToQuery.forEach((m) => {
        addOrReplaceRequest(stateDraft.requests, {
          date: +m,
          isFetchingData: false,
          expiresOn: action.expiration,
        });
      });
      break;
    default:
  }
}, initialState.transactionReport);

function addOrReplaceRequest(requests, newRequest) {
  const index = requests.findIndex((r) => r.date === newRequest.date);

  if (index === -1) {
    requests.push(newRequest);
  } else {
    requests[index] = newRequest;
  }
}
