import React from 'react';

const chevronStyle = { cursor: 'pointer' };

export const expandColumnRenderer = ({ expanded }) => {
  if (expanded) {
    return <i className='fas fa-chevron-up pr-2' style={chevronStyle}></i>;
  } else {
    return <i className='fas fa-chevron-down pr-2' style={chevronStyle}></i>;
  }
};

export const expandHeaderColumnRenderer = ({ anyExpanded }) => {
  if (anyExpanded) {
    return <i className='fas fa-chevron-up'></i>;
  } else {
    return <i className='fas fa-chevron-down'></i>;
  }
};
