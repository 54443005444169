import React from 'react';
import ReportsHeader from './ReportsHeader';
import NoDataToDisplayList from '../../../Common/NoDataToDisplayList';

const Reports = () => {
  const title = 'Reports';
  return (
    <div id='Reports' className='reports content'>
      <ReportsHeader title={title} />
      <div className='pl-4 pr-4'>
        <NoDataToDisplayList />
      </div>
    </div>
  );
};

export default Reports;
