import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../../utils/helpers/currency';
import TransactionStatus from '../../../../constants/TransactionStatus';
import TransactionSource from '../../../../constants/TransactionSource';
import { PayFabricTransactionStates } from '../../../../constants/Payfabric';
import TransactionOrigin from 'constants/TransactionOrigin';

const TransactionInfoFooter = ({
  onSendReceipt,
  selectedTransaction,
  onRefund,
  onVoid,
  refundValue,
  onRefundValueChange,
  isSaving,
  refundFormSubmitted,
}) => {
  return (
    <div className='mt-3 row'>
      {canShowFooter() && (
        <>
          <div className='col-md-9 col-sm-12'>
            <Footer
              selectedTransaction={selectedTransaction}
              onRefund={onRefund}
              onVoid={onVoid}
              refundFormSubmitted={refundFormSubmitted}
              refundValue={refundValue}
              onRefundValueChange={onRefundValueChange}
            />
          </div>
          {canResendReceipt() && (
            <div className='mb-2 text-center col-md-3 col-sm-12 d-flex align-items-end'>
              <ResendReceiptButton
                onSendReceipt={onSendReceipt}
                isSaving={isSaving}
              />
            </div>
          )}
        </>
      )}
    </div>
  );

  function canResendReceipt() {
    return selectedTransaction.origin !== TransactionOrigin.Pharma;
  }

  function canShowFooter() {
    const { transaction_status } = selectedTransaction;
    const isNotProcessedTransaction =
      transaction_status === 'Delivered' ||
      transaction_status === 'Canceled' ||
      transaction_status === 'Pending' ||
      transaction_status === 'Error' ||
      transaction_status === 'Expired';

    // PaymentLink transaction that has not been processed
    // should not show the Footer component and  the receipt button
    return !isNotProcessedTransaction;
  }
};

const Footer = (props) => {
  const { selectedTransaction, onVoid } = props;
  const {
    transactionId,
    authNetStatus,
    isInstallments,
    isMerchant: isNotInstallments,
    isRefund,
    payfabricObject,
    source,
    status,
    amountRefunded,
    amount,
    rectangleObject,
  } = selectedTransaction;

  const isPendingSettlement =
    authNetStatus === 'capturedPendingSettlement' ||
    payfabricObject?.pfStatus ===
      PayFabricTransactionStates.PendingSettlement ||
    (rectangleObject &&
      JSON.parse(rectangleObject).settlementStatus === 'Unsettled');
  const isFullyRefunded = amountRefunded > 0 && amountRefunded === amount;
  const isVoided = status === TransactionStatus.voided;

  const showPaxMessage = source === TransactionSource.Pax && !isRefund;
  const showInstallmentsMessage = isInstallments;
  const showRefundMessage = isFullyRefunded;
  const showVoidMessage = isVoided;

  const canRefund =
    !isPendingSettlement &&
    isNotInstallments &&
    !isFullyRefunded &&
    source !== TransactionSource.Pax &&
    !isRefund;
  const canVoid =
    isPendingSettlement &&
    isNotInstallments &&
    !isVoided &&
    source !== TransactionSource.Pax &&
    !isRefund;

  if (showPaxMessage) {
    return <PaxMessage isRefundedPax={isRefund} />;
  } else if (showInstallmentsMessage) {
    return <InstallmentsMessage />;
  } else if (showRefundMessage) {
    return <FullyRefundMessage transactionId={transactionId} />;
  } else if (showVoidMessage) {
    return <VoidMessage transactionId={transactionId} />;
  } else if (canVoid) {
    return <VoidButton onVoid={onVoid} />;
  } else if (canRefund) {
    return <RefundForm {...props} />;
  } else {
    return null;
  }
};

const FullyRefundMessage = ({ transactionId }) => {
  return (
    <div className='text-center alert alert-info mb-md-0'>
      {`Charge '${transactionId}' has been fully refunded`}
    </div>
  );
};

const PaxMessage = ({ isRefundedPax }) => {
  const paxRefundMessage =
    'To process a refund for this transaction, please use your PAX Device';
  return !isRefundedPax ? (
    <div className='text-center alert alert-info' style={{ marginBottom: '0' }}>
      <span>{paxRefundMessage}</span>
    </div>
  ) : null; // leave blank if transaction is a refund
};

const InstallmentsMessage = () => {
  return (
    <div className='col-md-16 col-sm-16'>
      <div
        className='text-center alert alert-info'
        style={{ marginBottom: '0' }}
      >
        <span>
          For refunds or any other questions about a monthly payment, please
          contact{' '}
          <a
            href='mailto:support@vituspay.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            support@vituspay.com
          </a>
          .
        </span>
      </div>
    </div>
  );
};

const VoidMessage = ({ transactionId }) => {
  return (
    <div className='text-center alert alert-info mb-md-0'>
      {`Charge '${transactionId}' has been voided`}
    </div>
  );
};

const VoidButton = ({ onVoid }) => {
  return (
    <div className='mb-2 d-flex justify-content-md-end justify-content-sm-start '>
      <button className='btn btn-primary' onClick={onVoid}>
        VOID
      </button>
    </div>
  );
};

const RefundForm = ({
  selectedTransaction,
  onRefund,
  refundFormSubmitted,
  refundValue,
  onRefundValueChange,
}) => {
  const maxRefundableAmount =
    selectedTransaction.amount - selectedTransaction.amountRefunded;
  return (
    <form onSubmit={onRefund}>
      <div className='form-row'>
        <div className='col-md-6 col-sm-12'>
          <small
            className='form-text text-muted'
            style={{ marginTop: '2.5rem' }}
          >
            <span>Max refundable amount: </span>
            <span>{<Currency amount={maxRefundableAmount} />}</span>
          </small>
        </div>
        <div className='mb-2 col-md-4 col-sm-12'>
          <label
            style={{
              marginBottom: 0,
              color: '#124474',
              fontSize: 14,
            }}
          >
            Amount
          </label>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className='input-group-text'>$</span>
            </div>
            <input
              type='number'
              name='transactionRefund'
              className='form-control'
              placeholder='0.00'
              disabled={refundFormSubmitted}
              value={refundValue}
              onChange={onRefundValueChange}
              required
            />
          </div>
        </div>
        <div className='mb-2 col-md-2 col-sm-12 d-flex align-items-end'>
          <button type='submit' className='btn btn-primary'>
            REFUND
          </button>
        </div>
      </div>
    </form>
  );
};

const ResendReceiptButton = ({ onSendReceipt, isSaving }) => {
  return (
    <button
      style={{ width: '100%' }}
      className='btn btn-primary'
      onClick={onSendReceipt}
      disabled={isSaving}
    >
      {isSaving ? 'Sending...' : 'RESEND RECEIPT'}
    </button>
  );
};

TransactionInfoFooter.propTypes = {
  onSendReceipt: PropTypes.func.isRequired,
  selectedTransaction: PropTypes.object.isRequired,
  onRefund: PropTypes.func.isRequired,
  onVoid: PropTypes.func,
  refundValue: PropTypes.node,
  onRefundValueChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default TransactionInfoFooter;
