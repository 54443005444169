import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StripeHeader from './StripeHeader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as practiceInfoActions from './../../../../utils/store/actions/practiceInfoActions';
import { getQuery } from './../../../../utils/hooks/useParams';
import { toast } from 'react-toastify';
import Loading from './../../../Common/Loading';
import StripeFeedback from './StripeFeedback';

class Stripe extends Component {
  state = {
    title: 'Stripe Connect',
    errors: {},
    isProcessing: false,
    success: false,
    code: '',
  };

  componentDidMount() {
    const { location, history } = this.props;
    const code = getQuery(location).get('code');

    if (code) {
      this.setState({
        isProcessing: true,
        code: code,
      });
    } else {
      history.push('/app');
    }
  }

  componentDidUpdate(prevProps) {
    const { loggedPractice, setPracticePayment, history } = this.props;
    if (!prevProps.loggedPractice.id && loggedPractice.id) {
      setPracticePayment(this.state.code, loggedPractice.id)
        .then(() => {
          this.setState({
            isProcessing: false,
            success: true,
          });
          toast.success('Your account is now connected to stripe');
          setTimeout(() => history.push('/app'), 3000);
        })
        .catch((err) => {
          toast.error('There was a problem setting up payment with Stripe');
          this.setState({
            errors: {
              message:
                err.userMessage || err.message || 'Unable to connect to Stripe',
            },
            isProcessing: false,
          });
        });
    }
  }

  render() {
    const { isProcessing, errors, success, title } = this.state;

    return (
      <div className='content'>
        <StripeHeader title={title} />
        {isProcessing && (
          <Loading message='Connecting your account to stripe' />
        )}
        {errors.message && <StripeFeedback message={errors.message} />}
        {success && (
          <Loading message='Your are now connected to Stripe. Redirecting...' />
        )}
      </div>
    );
  }
}

Stripe.propTypes = {
  loggedPractice: PropTypes.object.isRequired,
  setPracticePayment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedPractice: state.loggedPractice,
});

const mapDispatchToProps = {
  ...practiceInfoActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Stripe));
