import React from 'react';
import PropTypes from 'prop-types';

const NoDataToDisplayList = ({ message = 'There is no data to display' }) => {
  return (
    <div className='no-data'>
      <div className='vitus-vet-box text-center'>
        <h4>{message}</h4>
      </div>
    </div>
  );
};

NoDataToDisplayList.propTypes = {
  message: PropTypes.node,
};

export default NoDataToDisplayList;
