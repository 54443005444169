import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Currency from 'utils/helpers/currency';

const TransactionVoidConfirm = ({
  onCancel,
  onConfirm,
  transaction,
  voidValue,
  show,
  isSaving,
  onVoidReasonChange,
}) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Body>
        <div className='pt-4 pb-4 pl-2 pr-2'>
          <h3 className='text-center'>Confirm Void?</h3>
          <>
            <h3 className='text-center mt-4 mb-4'>
              <Currency amount={voidValue} />
            </h3>
            <div className='form-group'>
              <label>Reason for void:</label>
              <textarea
                name='custom_void_reason'
                className='form-control'
                rows='3'
                onChange={onVoidReasonChange}
                defaultValue={transaction.custom_void_reason}
              ></textarea>
              <small className='form-text text-muted'>Optional</small>
            </div>
          </>
        </div>
        <div className='pt-2 pb-4 pl-2 pr-2 text-center'>
          <button
            className='btn btn-outline-secondary mr-2'
            onClick={onCancel}
            disabled={isSaving}
          >
            CANCEL
          </button>
          <button
            className='btn btn-primary ml-2'
            onClick={onConfirm}
            disabled={isSaving}
          >
            {isSaving ? 'Processing...' : 'Confirm'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

TransactionVoidConfirm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  voidValue: PropTypes.number,
  show: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default TransactionVoidConfirm;
