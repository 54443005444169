import { receiptTypes, paymentTypes, formTypes } from '@vitusvet/react-library';

export function getDecimalTotalStr(total) {
  return (total / 100).toFixed(2);
}

// (errMsg: string): string
export function getCardErrorMessage(errMsg = '') {
  switch (errMsg) {
    case 'cvv does not match':
      return 'a CVV mismatch.  Please wait 2 minutes and try again with the correct CVV or try another card.';

    case 'the credit card number is invalid':
      return 'your card number being incorrect.';

    case 'a duplicate transaction has been submitted':
      return 'your card being declined.';

    default:
      return 'your card being declined.';
  }
}

export function getFormType(paymentType) {
  switch (paymentType) {
    case paymentTypes.full:
      return formTypes.spreedly;

    case paymentTypes.installments:
      return formTypes.splitit;

    default:
      return '';
  }
}

export function getGALabel(type) {
  switch (type) {
    case receiptTypes.email:
      return 'Email';

    case receiptTypes.textMessage:
      return 'Text';

    case receiptTypes.none:
      return 'No Receipt';

    default:
      return '';
  }
}

export const displayTypes = {
  loading: 'loading',
  finishLoading: 'finishLoading',
  tokenSuccess: 'tokenSuccess',
  showForm: 'showForm',
  hideForm: 'hideForm',
  showReceiptButtons: 'showReceiptButtons',
  sendingReceipt: 'sendingReceipt',
  finished: 'finished',
  showError: 'showError',
  hideError: 'hideError',
  receiptFailed: 'receiptFailed',
  showDataFailed: 'showDataFailed',
  hideDataFailed: 'hideDataFailed',
  alreadyCompleted: 'alreadyCompleted',
  show404Error: 'show404Error',
  splititFailed: 'splititFailed',
  showDBInsertFailed: 'showDBInsertFailed',
  partialData: 'partialData',
  transactionCanceled: 'transactionCanceled',
  transactionExpired: 'transactionExpired',
};

export const displayInitState = {
  loading: true,
  showForm: false,
  showReceiptButtons: false,
  finished: false,
  showError: false,
  receiptFailed: false,
  dataFailed: false,
  alreadyCompleted: false,
  show404: false,
  splititFailed: false,
  dbInsertFailed: false,
  partialData: false,
  transactionCanceled: false,
  transactionExpired: false,
};

export function displayReducer(state, action) {
  const { type } = action;

  switch (type) {
    case displayTypes.loading:
      return displayInitState;

    case displayTypes.finishLoading:
      return { ...state, loading: false };

    case displayTypes.tokenSuccess:
      return displayInitState;

    case displayTypes.hideForm:
      return { ...state, showForm: false };

    case displayTypes.showForm:
      return { ...displayInitState, loading: false, showForm: true };

    case displayTypes.showReceiptButtons:
      return { ...displayInitState, loading: false, showReceiptButtons: true };

    case displayTypes.sendingReceipt:
      return displayInitState;

    case displayTypes.finished:
      return { ...displayInitState, loading: false, finished: true };

    case displayTypes.showError:
      return { ...displayInitState, loading: false, showError: true };

    case displayTypes.hideError:
      return { ...state, showError: false };

    case displayTypes.receiptFailed:
      return { ...displayInitState, loading: false, receiptFailed: true };

    case displayTypes.showDataFailed:
      return { ...displayInitState, loading: false, dataFailed: true };

    case displayTypes.hideDataFailed:
      return { ...state, dataFailed: false };

    case displayTypes.alreadyCompleted:
      return { ...displayInitState, loading: false, alreadyCompleted: true };

    case displayTypes.show404Error:
      return { ...displayInitState, loading: false, show404: true };

    case displayTypes.splititFailed:
      return { ...displayInitState, loading: false, splititFailed: true };

    case displayTypes.showDBInsertFailed:
      return { ...displayInitState, loading: false, dbInsertFailed: true };

    case displayTypes.partialData:
      return { ...displayInitState, loading: false, partialData: true };

    case displayTypes.transactionCanceled:
      return { ...displayInitState, loading: false, transactionCanceled: true };

    case displayTypes.transactionExpired:
      return { ...displayInitState, loading: false, transactionExpired: true };

    default:
      return state;
  }
}
