import React from 'react';
import DateTime from 'utils/helpers/DateTime';
import Currency from 'components/Common/Currency/Currency';
import LinkedValue from 'components/Common/LinkedValue/LinkedValue';
import SortingHeader from 'components/Common/SortingHeader/SortingHeader';
import TransactionStatus from 'components/Common/Status/TransactionStatus';
import { CreditCardTypes } from 'constants/CreditCardTypes';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function summaryTitle(cell) {
  switch (cell.type) {
    case 'full':
      return (
        <span>
          Total for <strong>{cell.count} Full</strong> Transactions
        </span>
      );
    case 'monthly':
      return (
        <span>
          Total for <strong>{cell.count} Monthly</strong> Transactions
        </span>
      );
    case 'text link':
      return (
        <span>
          Total for <strong>{cell.count} Text Link</strong> Transactions
        </span>
      );
    case 'total':
      return <strong>TOTAL</strong>;
    default:
      return '';
  }
}

function emptyValue(cell) {
  return <>{cell ? cell : '—'}</>;
}

function positiveIncome(cell) {
  return <Currency amount={cell} defaultToZero={true} />;
}

function negativeIncome(cell) {
  return <Currency amount={cell} negative={true} />;
}

function positiveIncomeSummary(cell, row) {
  return <Currency amount={cell} bolded={row.id === 'total'} />;
}

function negativeIncomeSummary(cell, row) {
  return <Currency amount={cell} negative={true} bolded={row.id === 'total'} />;
}

function dateTimeFormatter(cell) {
  return <DateTime datetime={cell} format='MM/DD/YY - h:mma' />;
}

function idFormatter(cell) {
  return (
    <LinkedValue
      redirectTo={`/app/practice-reports/transactions-report/${cell}`}
      value={cell}
      copyable={true}
      expandable={true}
    />
  );
}

function statusFormatter(cell) {
  return <TransactionStatus status={cell} />;
}

function creditCardImage(cell) {
  const creditCard = CreditCardTypes[cell];

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='right'
      overlay={
        <Popover className='popover-basic'>
          <Popover.Content className='text-capitalize'>
            {creditCard ? creditCard.displayName : cell}
          </Popover.Content>
        </Popover>
      }
    >
      {creditCard ? (
        <img
          src={creditCard.imageUrl}
          alt={creditCard.displayName}
          width='32px'
          height='20px'
        />
      ) : (
        <i className='fas fa-credit-card h4'></i>
      )}
    </OverlayTrigger>
  );
}

export const transactionColumns = [
  {
    dataField: 'transactionId',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'TRANSACTION'),
    formatter: idFormatter,
  },
  {
    dataField: 'type',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'TYPE'),
    formatter: emptyValue,
  },
  {
    dataField: 'time',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'DATE/TIME'),
    formatter: dateTimeFormatter,
  },
  {
    dataField: 'petData.owner.name',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'CLIENT NAME'),
    formatter: emptyValue,
  },
  {
    dataField: 'staff_member',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'STAFF MEMBER'),
    formatter: emptyValue,
  },
  {
    dataField: 'transaction_status',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'STATUS'),
    formatter: statusFormatter,
  },
  {
    dataField: 'pet_parent_card_type',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'CARD TYPE'),
    formatter: emptyValue,
  },
  {
    dataField: 'amount',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'AMOUNT'),
    formatter: positiveIncome,
  },
  {
    dataField: 'amountRefunded',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'REFUNDED'),
    formatter: negativeIncome,
  },
  {
    dataField: 'fee_amount',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'FEES'),
    formatter: negativeIncome,
    hidden: true, // hiding 'Fees' field due to confusion surrounding their meaning
  },
  {
    dataField: 'net',
    text: '',
    sort: true,
    sortCaret: (order) => SortingHeader(order, 'NET'),
    formatter: positiveIncome,
  },
];

// function for dynamically including 'fees' column
export const getSummaryColumns = (hideFees) => [
  {
    dataField: 'id',
    text: '',
    hidden: true,
  },
  {
    dataField: 'title',
    text: '',
    attrs: { colSpan: 2 },
    headerAttrs: { colSpan: 2 },
    formatter: summaryTitle,
  },
  {
    dataField: 'amount',
    text: 'AMOUNT',
    formatter: positiveIncomeSummary,
  },
  {
    dataField: 'refunded',
    text: 'REFUNDED',
    formatter: negativeIncomeSummary,
  },
  {
    dataField: 'fees',
    text: 'FEES',
    formatter: negativeIncomeSummary,
    hidden: hideFees,
  },
  {
    dataField: 'net',
    text: 'NET',
    formatter: positiveIncomeSummary,
  },
];

export const getCardTypeSummaryColumns = [
  {
    dataField: 'cardType',
    text: '',
    formatter: creditCardImage,
    align: 'center',
  },
  {
    dataField: 'amount',
    text: 'AMOUNT',
    formatter: positiveIncome,
  },
  {
    dataField: 'refunded',
    text: 'REFUNDED',
    formatter: negativeIncome,
  },
  {
    dataField: 'totalTrx',
    text: 'TOTAL TXN',
    formatter: emptyValue,
  },
];
