import { convertCreditCardToPaymentMethod } from '@vitusvet/react-library';
import { handleResponse, handleError, axiosCustomersInstance } from './index';
import { parseNames } from 'utils/helpers/names';
import { getCreditCard } from 'utils/api/payfabricApi';
import {
  createTransactionCustomer,
  updateTransactionPaymentMethod,
} from './transactionPublicApi';

function mapPaymentMethod(paymentMethod) {
  if (paymentMethod) {
    paymentMethod.expiration = `${paymentMethod.expiration_month}/${paymentMethod.expiration_year}`;
  }
}

function mapCustomer(customer) {
  customer.full_name = `${customer.first_name} ${customer.last_name}`;

  if (customer.payment_methods) customer.payment_methods.map(mapPaymentMethod);

  return customer;
}

export async function createNewCustomer(customer) {
  try {
    const response = await axiosCustomersInstance.post('/', customer);
    const { data } = response;
    const mappedCustomer = mapCustomer(data);

    return handleResponse({
      ...response,
      data: mappedCustomer,
    });
  } catch (error) {
    return handleError(error);
  }
}

export async function updateCustomer(customer, customerId) {
  try {
    const response = await axiosCustomersInstance.put(
      `/${customerId}/`,
      customer
    );
    const { data } = response;
    const mappedCustomer = mapCustomer(data);

    return handleResponse({
      ...response,
      data: mappedCustomer,
    });
  } catch (error) {
    return handleError(error);
  }
}

export async function addPaymentMethod(paymentMethod, customerId) {
  try {
    const response = await axiosCustomersInstance.post(
      `/${customerId}/paymentmethod/`,
      { paymentMethodData: paymentMethod }
    );
    const { data } = response;

    const mappedPaymentMethod = data;
    mappedPaymentMethod.expiration = `${data.expiration_month}/${data.expiration_year}`;

    return handleResponse({
      ...response,
      data: mappedPaymentMethod,
    });
  } catch (error) {
    return handleError(error);
  }
}

export async function updatePaymentMethod(
  paymentMethod,
  customerId,
  paymentMethodId
) {
  try {
    const response = await axiosCustomersInstance.put(
      `/${customerId}/paymentmethod/${paymentMethodId}`,
      { paymentMethodData: paymentMethod }
    );
    const { data } = response;
    data.payment_methods.map(mapPaymentMethod);

    return handleResponse({
      ...response,
      data,
    });
  } catch (error) {
    return handleError(error);
  }
}

export function getCustomers(params) {
  return axiosCustomersInstance
    .get('/', { params })
    .then(handleResponse)
    .then((response) => {
      response.data.rows.map(mapCustomer);
      return response;
    })
    .catch(handleError);
}

export async function getCustomer(customerId) {
  try {
    const response = await axiosCustomersInstance.get(`/${customerId}`);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function deleteCustomer(customerId) {
  try {
    const response = await axiosCustomersInstance.delete(customerId);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function upsertCustomerWithNewCreditCard(
  customer,
  customerId,
  practiceId,
  petOwnerPhone,
  petOwnerEmail,
  setCardInformation
) {
  const creditCard = await getCreditCard(customerId, practiceId);
  const { creditCardsInWallet } = creditCard;
  const isOldCustomerWithNewCreditCard = !!(
    customer && creditCardsInWallet > 1
  );
  const isNewCustomerWithNewCreditCard = !customer && creditCardsInWallet;

  if (isOldCustomerWithNewCreditCard || isNewCustomerWithNewCreditCard) {
    const paymentMethod = convertCreditCardToPaymentMethod(creditCard);
    const {
      full_name: fullName,
      card_type: cardType,
      last_four: lastFourDigits,
    } = paymentMethod;

    if (setCardInformation) {
      const { setCardType, setLastFourDigits } = setCardInformation;
      setCardType(cardType);
      setLastFourDigits(lastFourDigits);
    }

    if (isOldCustomerWithNewCreditCard) {
      setCardInformation
        ? await updateTransactionPaymentMethod(
            setCardInformation.queryParams,
            customerId,
            paymentMethod
          )
        : await updatePaymentMethod(
            paymentMethod,
            customerId,
            customer.payment_methods[0].id
          );
    } else {
      await createCustomerWithNewCreditCard(
        customerId,
        fullName,
        petOwnerPhone,
        petOwnerEmail,
        paymentMethod,
        setCardInformation
      );
    }
  }
}

async function createCustomerWithNewCreditCard(
  customerId,
  petOwnerName,
  petOwnerPhone,
  petOwnerEmail,
  paymentMethod,
  setCardInformation
) {
  const [firstName, lastName] = parseNames(petOwnerName);
  const newCustomer = {
    customerData: {
      customer_id: customerId,
      first_name: firstName,
      last_name: lastName,
      ...(petOwnerPhone && { phone: petOwnerPhone }),
      ...(petOwnerEmail && { email: petOwnerEmail }),
    },
    paymentMethodData: paymentMethod,
  };

  setCardInformation
    ? await createTransactionCustomer(
        setCardInformation.queryParams,
        newCustomer
      )
    : await createNewCustomer(newCustomer);
}
