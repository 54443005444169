import React from 'react';

const sortCaret = (order) => {
  switch (order) {
    case 'asc':
      return <i className='fas fa-caret-up ml-1'></i>;
    case 'desc':
      return <i className='fas fa-caret-down ml-1'></i>;
    default:
      return <i className='fas fa-caret-right ml-1'></i>;
  }
};

export default sortCaret;
