// Modules
import React from 'react';

// Utils
import currencyFormat from 'utils/helpers/currencyFormat';

// Components
import CardRadioControl from 'components/Common/CardRadio/CardRadioControl';
import Loading from 'components/Common/Loading';
import PaymentMethodDisplay from './PaymentMethodDisplay';

function SelectCardStep(props) {
  const {
    selectedPaymentMethodIndex = 0,
    setSelectedPaymentMethodIndex,
    paymentMethods = [],
    isFormLoading = false,
  } = props;

  return isFormLoading ? (
    <Loading></Loading>
  ) : (
    <>
      <p className='text-center'>
        Total Amount
        <br />
        {/* helper function works on cent amounts */}
        <strong>${currencyFormat(props.totalAmount * 100)}</strong>
      </p>

      <CardRadioControl
        value={selectedPaymentMethodIndex}
        onChange={(val) => {
          setSelectedPaymentMethodIndex(val);
        }}
      >
        {[
          ...paymentMethods.map((paymentMethod, i) => {
            return (
              <div key={i}>
                <PaymentMethodDisplay paymentMethod={paymentMethod} />
              </div>
            );
          }),
          <div key={paymentMethods.length}>Use a different card</div>,
        ]}
      </CardRadioControl>
    </>
  );
}

export default SelectCardStep;
