import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import TransactionsExport from './TransactionsExport';

function TransactionsHeader(props) {
  const history = useHistory();

  const handleBackButtonClick = (event) => {
    if (!props.isAdmin) return;

    event.preventDefault();
    history.push('/app');
  };

  const handleAddTransactionClick = (event) => {
    if (!props.isAdmin) return;

    event.preventDefault();
    history.push(`/app/practices/${props.practiceId}/transactions/new`);
  };

  return (
    <Navbar className='practices-header vitus-app-header' expand='lg'>
      <Navbar.Brand as='a' href='#' onClick={handleBackButtonClick}>
        {props.isAdmin && (
          <i className='fas fa-chevron-left text-secondary'></i>
        )}
        <small className='ml-2'>{props.title}</small>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='app-header-collapsible' />
      <Navbar.Collapse id='app-header-collapsible'>
        <Nav className='mr-auto'></Nav>
        {props.isAdmin && (
          <button
            class='btn btn-outline-secondary'
            onClick={handleAddTransactionClick}
          >
            Add Transaction
          </button>
        )}
        <TransactionsExport
          practiceName={props.practiceName}
          practiceId={props.practiceId}
          isAdmin={props.isAdmin}
          coreId={props.coreId}
        />
      </Navbar.Collapse>
    </Navbar>
  );
}

TransactionsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default TransactionsHeader;
