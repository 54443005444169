import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  columns,
  paginationOptions,
  defaultSorted,
} from './practice-reports-payouts.config';
import NoDataToDisplayList from './../../../Common/NoDataToDisplayList';
import { toast } from 'react-toastify';
import * as reportingActions from 'utils/store/actions/reportingActions';
import Loading from 'components/Common/Loading';
import compareArrays from 'utils/helpers/compareArrays';

class PracticeReportsPayouts extends Component {
  state = {
    isLoading: false,
  };

  fetchPayoutReports = () => {
    const { getPracticePayoutsReports, setCurrentReport, payouts } = this.props;

    this.setState({ isLoading: true });

    getPracticePayoutsReports()
      .then(() => {
        this.setState({ isLoading: false });
        setCurrentReport({ type: 'payouts', data: payouts });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        toast.error('Unable to get payout reports');
      });
  };

  componentDidMount() {
    const { setCurrentReport } = this.props;

    setCurrentReport({ type: '', data: [] });
    this.fetchPayoutReports();
  }

  componentDidUpdate(prevProps) {
    const { payouts } = this.props;

    if (!compareArrays(prevProps.payouts, payouts)) {
      this.fetchPayoutReports();
    }
  }

  render() {
    const { payouts } = this.props;
    const { isLoading } = this.state;

    return (
      <div
        id='PracticeReportsPayouts'
        className='practice-payouts-reports'
        style={{ padding: '20px 1.5rem' }}
      >
        {payouts.length > 0 || isLoading ? (
          <div className='table-responsive-md'>
            {isLoading ? (
              <Loading />
            ) : (
              <BootstrapTable
                keyField='id'
                data={payouts}
                columns={columns}
                bordered={false}
                classes='vitus-table'
                pagination={paginationFactory(paginationOptions)}
                defaultSorted={defaultSorted}
              />
            )}
          </div>
        ) : (
          <NoDataToDisplayList />
        )}
      </div>
    );
  }
}

PracticeReportsPayouts.propTypes = {};

const mapStateToProps = (state) => ({
  payouts: state.practicePayoutsReport,
});

const mapDispatchToProps = {
  ...reportingActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeReportsPayouts);
