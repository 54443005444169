import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  columns,
  paginationOptions,
  defaultSorted,
} from './practice-reports-daily.config';
import NoDataToDisplayList from './../../../Common/NoDataToDisplayList';
import { toast } from 'react-toastify';
import * as reportingActions from 'utils/store/actions/reportingActions';
import Loading from 'components/Common/Loading';
import compareArrays from 'utils/helpers/compareArrays';
import { isEqual } from 'lodash';

class PracticeReportsDaily extends Component {
  state = {
    isLoading: false,
  };

  fetchDailyReport = () => {
    const { getPracticeDailyReports, setCurrentReport, dailies } = this.props;

    this.setState({ isLoading: true });

    getPracticeDailyReports()
      .then(() => {
        this.setState({ isLoading: false });
        setCurrentReport({ type: 'daily', data: dailies });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        toast.error('Unable to get daily report');
      });
  };

  componentDidMount() {
    const { setCurrentReport } = this.props;

    setCurrentReport({ type: '', data: [] });
    this.fetchDailyReport();
  }

  componentDidUpdate(prevProps) {
    const { dailies, mostRecentTransactionFromWebSocket } = this.props;

    const shouldUpdateTransactions = !isEqual(
      prevProps.mostRecentTransactionFromWebSocket,
      mostRecentTransactionFromWebSocket
    );

    if (
      !compareArrays(prevProps.dailies, dailies) ||
      shouldUpdateTransactions
    ) {
      this.fetchDailyReport();
    }
  }

  render() {
    const { dailies } = this.props;
    const { isLoading } = this.state;

    return (
      <div
        id='PracticeReportsDaily'
        className='practice-daily-reports'
        style={{ padding: '20px 1.5rem' }}
      >
        {dailies.length > 0 || isLoading ? (
          <div className='table-responsive-md'>
            {isLoading ? (
              <Loading />
            ) : (
              <BootstrapTable
                keyField='date'
                data={dailies}
                columns={columns}
                bordered={false}
                classes='vitus-table'
                pagination={paginationFactory(paginationOptions)}
                defaultSorted={defaultSorted}
              />
            )}
          </div>
        ) : (
          <NoDataToDisplayList />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dailies: state.practiceDailyReport,
  mostRecentTransactionFromWebSocket: state.mostRecentTransactionFromWebSocket,
});

const mapDispatchToProps = {
  ...reportingActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeReportsDaily);
