import React from 'react';

const PracticeReportsPrint = () => {
  return (
    <div className='px-2'>
      <button
        className='btn btn-outline-secondary'
        type='button'
        onClick={printReports}
      >
        <span className='mr-2'>Print</span>
        <i className='fas fa-print'></i>
      </button>
    </div>
  );
};

const printReports = () => {
  window.print();
};

export default PracticeReportsPrint;
