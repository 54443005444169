import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';

const TransactionPaymentAttemptsModal = ({
  show,
  onClose,
  paymentAttempts,
}) => {
  const columns = [
    {
      text: 'Time',
      dataField: 'created',
      formatter: (cell) => {
        return moment(cell).format('lll');
      },
    },
    {
      text: 'Details',
      dataField: 'message',
    },
    {
      text: 'Card',
      dataField: 'paymentMethod',
      formatter: cardDetailFormatter,
    },
  ];

  function cardDetailFormatter(cell) {
    if (cell?.cardType && cell?.lastFour) {
      return `${cell?.cardType} *${cell?.lastFour}`;
    }

    return '';
  }

  return (
    <Modal
      id='TransactionPaymentAttempts'
      size='lg'
      show={show}
      onHide={onClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='text-center'>
        <h2>Payment Attempts</h2>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive-md'>
          <BootstrapTable
            keyField='id'
            data={paymentAttempts}
            columns={columns}
            bordered={false}
            classes='vitus-table'
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={() => onClose()}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TransactionPaymentAttemptsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  paymentAttempts: PropTypes.array.isRequired,
};

export default TransactionPaymentAttemptsModal;
