import React, { useState } from 'react';

import exportTransactionsToExcel from '../../../../utils/helpers/exportToExcel';

import {
  getAllPracticeTransactionsByPracticeId,
  getAllPracticeTransactions,
} from 'utils/api/transactionApi';
import { parseTransactionsDataForExport } from '../../../../utils/helpers/sortAndMapFunctions';

import { triggerExportAllEvent } from './googleAnalyticsTriggers';

const TransactionsExport = (props) => {
  const { practiceName, practiceId, coreId } = props;
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    if (!props.isAdmin) triggerExportAllEvent(coreId);

    setLoading(true);

    try {
      let transactions = [];
      const callback = (response) =>
        (transactions = [...transactions, ...response.Items]);
      if (props.isAdmin) {
        await getAllPracticeTransactionsByPracticeId(practiceId, callback);
      } else {
        await getAllPracticeTransactions(callback);
      }

      // Sort the transactions newest to oldest
      // The `time` property of each transaction is an ISO date string
      // This sort was inspired by this SO answer:
      //  https://stackoverflow.com/a/52583894
      transactions.sort((a, b) => -a.time.localeCompare(b.time));
      exportTransactionsToExcel(
        practiceName,
        parseTransactionsDataForExport(Object.values(transactions))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const exportLabel = isLoading ? 'Exporting...' : 'Export All to Excel';

  return (
    <div className='px-2 text-right'>
      <button
        disabled={isLoading}
        className='btn btn-outline-secondary'
        type='button'
        onClick={handleClick}
      >
        <span className='mr-2'>{exportLabel}</span>
        <i className='fas fa-file-excel'></i>
      </button>
    </div>
  );
};

export default TransactionsExport;
