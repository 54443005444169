import React from 'react';
import PropTypes from 'prop-types';
import userDefault from './../../../assets/images/user.svg';

const UserInfo = ({ currentUser, loggedPractice }) => {
  return (
    <div id='UserInfo' className='user-info'>
      {(currentUser.roles.teamAdministrator ||
        currentUser.roles.supportAgent) && (
        <div className='row ml-0 mr-0'>
          <div className='col-5 img'>
            <img src={userDefault} alt='User Default' />
          </div>
          <div className='col-7 info'>
            <span className='user-name'>Admin account</span>
          </div>
        </div>
      )}
      {(currentUser.roles.practiceAdmin || currentUser.roles.practiceStaff) && (
        <div className='row ml-0 mr-0'>
          <div className='col-12 img'>
            <img
              src={
                loggedPractice.picture ? loggedPractice.picture : userDefault
              }
              alt='User Default'
            />
          </div>
          <div className='col-12 pt-2'>
            <div className='user-name text-center'>{loggedPractice.name}</div>
          </div>
        </div>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  currentUser: PropTypes.object,
  loggedPractice: PropTypes.object,
};

export default UserInfo;
