import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ path, condition, Component, redirectTo }) => {
  return (
    <Route path={path}>
      {condition ? <Component /> : <Redirect to={redirectTo} />}
    </Route>
  );
};

PrivateRoute.propTpes = {
  path: PropTypes.string.isRequired,
  condition: PropTypes.bool.isRequired,
  Component: PropTypes.object.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default PrivateRoute;
