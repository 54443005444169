import React from 'react';
import { Navbar, Button, Nav, FormControl, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PracticesHeader = ({ title, handleShow, onFilterChange, search }) => {
  return (
    <Navbar className='practices-header vitus-app-header' expand='lg'>
      <Navbar.Brand>{title}</Navbar.Brand>
      <Navbar.Toggle aria-controls='app-header-collapsible' />
      <Navbar.Collapse id='app-header-collapsible'>
        <Nav className='mr-auto'></Nav>
        <Form inline>
          <Button variant='primary' onClick={handleShow}>
            ADD PRACTICE
          </Button>
          <FormControl
            onChange={onFilterChange}
            type='search'
            value={search}
            placeholder='Search'
            className='mr-sm-2 ml-2'
            style={{ maxWidth: 350 }}
          />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

PracticesHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  search: PropTypes.string,
};

export default PracticesHeader;
