import React from 'react';
import PropTypes from 'prop-types';

// TODO: replace all instances of this component for the new 'Currency'
// component found in the 'Common' folder
const Currency = ({ amount }) => {
  let formattedAmount = Number.parseFloat(amount / 100).toLocaleString(
    'en-US',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );

  return <b>$ {formattedAmount}</b>;
};

Currency.propTypes = {
  amount: PropTypes.node,
  formatStripe: PropTypes.bool,
};

export default Currency;
