import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from './Loading';

const NoPermissions = ({ currentUser }) => {
  return (
    <div className='no-data content pl-4 pr-4'>
      {currentUser ? (
        <div className='vitus-vet-box text-center'>
          <h4>Your permissions have not been set up yet</h4>
          <hr />
          <p>
            We have records of your user account, but your user permissions to
            use this webpage have not been set up.
          </p>
          <p>
            Please contact Vitus Vet support team to have your user account
            configured.
          </p>
        </div>
      ) : (
        <div className='vitus-vet-box text-center'>
          <h4>Logging out...</h4>
          <Loading />
        </div>
      )}
    </div>
  );
};

NoPermissions.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer,
  };
}

export default connect(mapStateToProps)(NoPermissions);
