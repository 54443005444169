import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import phoneNumberIsValidFormat from '../../../../utils/helpers/phoneNumberValidate';
import { emailValidate } from '../../../../utils/helpers/emailValidate';
import PaymentInfoStep from './PaymentInfoStep';
import PaymentPlanStep from './PaymentPlanStep';
import PaymentPlanIframeStep from './PaymentPlanIframeStep';
import { initiateInstallmentPlan } from '../../../../utils/api/installmentApi';
import { toast } from 'react-toastify';
import StartOverConfirm from './StartOverConfirm';
import {
  paymentPlanNextStep1GAEventHandler,
  paymentPlanNextStartOverGAEventHandler,
  paymentPlanNextStartOverNoGAEventHandler,
  paymentPlanNextStartOverYesGAEventHandler,
  paymentPlanNextStep2GAEventHandler,
  paymentPlanCancelGAEventHandler,
} from './googleAnalyticsHandlers';

const PaymentPlanModal = (props) => {
  const {
    showModal,
    setShowModal,
    triggerUpdate,
    installmentsMinAmount,
    practiceName,
    practiceId,
  } = props;

  const [staffMember, setStaffMember] = useState('Choose staff member...');
  const [staffMemberName, setStaffMemberName] = useState('');
  const [petOwnerName, setPetOwnerName] = useState('');
  const [description, setDescription] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [cardholderEmail, setCardholderEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [stepNumber, setStepNumber] = useState(0);
  const [isFormLoading, setFormLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [splitItResult, setSplitItResult] = useState('');
  const [showStartOver, setShowStartOver] = useState(false);

  //fields used for validation
  const STAFF_MEMBER_DEFINITION = {
    field: 'staffMember',
    value: staffMember,
    validationMessage: 'Select a staff member',
  };
  const TOTAL_AMOUNT_DEFINITION = {
    field: 'totalAmount',
    value: totalAmount,
    validationMessage:
      'A minimum amount of $' +
      installmentsMinAmount +
      ' is required for a monthly payment plan',
  };
  const DESCRIPTION_DEFINITION = {
    field: 'description',
    value: description,
    validationMessage: 'Enter a description',
  };
  const PET_OWNER_NAME_DEFINITION = {
    field: 'petOwnerName',
    value: petOwnerName,
    validationMessage: 'Enter a pet owner name',
  };
  const CARD_HOLDER_NAME_DEFINITION = {
    field: 'cardholderName',
    value: cardholderName,
    validationMessage: 'Enter a cardholder name',
  };
  const CARD_HOLDER_EMAIL_DEFINITION = {
    field: 'cardholderEmail',
    value: cardholderEmail,
    validationMessage: 'Enter a cardholder email',
  };
  const PHONE_NUMBER_DEFINITION = {
    field: 'phoneNumber',
    value: phoneNumber,
    validationMessage: 'Enter a 10 digit phone number including area code',
  };
  const ADDRESS_LINE1_DEFINITION = {
    field: 'addressLine1',
    value: addressLine1,
    validationMessage: 'Enter an address',
  };
  const CITY_DEFINITION = {
    field: 'city',
    value: city,
    validationMessage: 'Enter a city',
  };
  const STATE_DEFINITION = {
    field: 'state',
    value: state,
    validationMessage: 'Select a state',
  };
  const ZIP_CODE_DEFINITION = {
    field: 'zipCode',
    value: zipCode,
    validationMessage: 'Enter 5 digit zip code',
  };

  const step1Validation = [
    STAFF_MEMBER_DEFINITION,
    TOTAL_AMOUNT_DEFINITION,
    DESCRIPTION_DEFINITION,
    PET_OWNER_NAME_DEFINITION,
  ];
  const step2Validation = [
    CARD_HOLDER_NAME_DEFINITION,
    CARD_HOLDER_EMAIL_DEFINITION,
    PHONE_NUMBER_DEFINITION,
    ADDRESS_LINE1_DEFINITION,
    CITY_DEFINITION,
    STATE_DEFINITION,
    ZIP_CODE_DEFINITION,
  ];

  useEffect(() => {
    resetState();
  }, [showModal]);

  const validateReducer = (field, value) => {
    switch (field) {
      case 'totalAmount':
        return Number(value) >= installmentsMinAmount;
      case 'zipCode':
        return value?.length === 5;
      case 'phoneNumber':
        return phoneNumberIsValidFormat(value);
      case 'staffMember':
        return value?.length === 36;
      case 'state':
        return value?.length === 2;
      case 'cardholderEmail':
        return emailValidate(value);
      case 'petOwnerName':
      case 'cardholderName':
      case 'description':
      case 'addressLine1':
      case 'city':
        return value?.length > 0;
      default:
        return null;
    }
  };

  const validate = (rules) => {
    const validationResult = rules.reduce((errors, vf) => {
      const error = !validateReducer(vf.field, vf.value);
      if (error) {
        errors[vf.field] = vf.validationMessage;
      }
      return errors;
    }, []);

    setValidationErrors(validationResult);
    return Object.keys(validationResult);
  };

  const next1Click = () => {
    const validationResult = validate(step1Validation);
    if (validationResult.length > 0) return;

    paymentPlanNextStep1GAEventHandler();

    if (cardholderName === '') {
      setCardholderName(petOwnerName);
    }

    setStepNumber(1);
  };

  const next2Click = async () => {
    const validationResult = validate(step2Validation);
    if (validationResult.length > 0) return;

    paymentPlanNextStep2GAEventHandler(totalAmount);

    setStepNumber(2);

    try {
      setFormLoading(true);
      const result = await createInstallmentPlan();
      setCheckoutUrl(result.CheckoutUrl);
    } catch (err) {
      toast.error('Error initializing payment plan');
      setStepNumber(1);
    }
    setFormLoading(false);
  };

  const createInstallmentPlan = async () => {
    const initiateRequest = {
      amount: totalAmount,
      billingAddress: {
        AddressLine: addressLine1,
        AddressLine2: addressLine2,
        City: city,
        Country: 'USA', //Installment plans are limited to the United States
        State: state,
        Zip: zipCode,
      },
      email: cardholderEmail,
      name: cardholderName,
      phone: phoneNumber.replace(/\s+/g, ''),
      invoiceId: description,
      staffMember: {
        staffMemberName: staffMemberName,
        staffMemberId: staffMember,
      },
      practice: {
        practiceName: practiceName,
        practiceId: practiceId,
      },
    };

    return await initiateInstallmentPlan(initiateRequest);
  };

  const startOverClick = () => {
    paymentPlanNextStartOverGAEventHandler();
    setShowStartOver(true);
  };

  const startOverNoClick = () => {
    paymentPlanNextStartOverNoGAEventHandler();
    setShowStartOver(false);
  };

  const startOverYesClick = () => {
    paymentPlanNextStartOverYesGAEventHandler();
    resetState();
    setStepNumber(0);
    setShowStartOver(false);
  };

  const doneClick = () => {
    setShowModal();
  };

  const resetState = () => {
    setStaffMember('Choose staff member...');
    setStaffMemberName('');
    setPetOwnerName('');
    setPhoneNumber('');
    setDescription('');
    setTotalAmount('');
    setCardholderName('');
    setCardholderEmail('');
    setAddressLine1('');
    setAddressLine2('');
    setCity('');
    setState('');
    setZipCode('');
    setSplitItResult('');
    setStepNumber(0);
    setValidationErrors({});
  };

  const steps = {
    0: {
      header: 'Payment Information',
      body: (
        <PaymentInfoStep
          staffMember={staffMember}
          setStaffMember={setStaffMember}
          setStaffMemberName={setStaffMemberName}
          petOwnerName={petOwnerName}
          setPetOwnerName={setPetOwnerName}
          description={description}
          setDescription={setDescription}
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
          isFormLoading={isFormLoading}
          setFormLoading={setFormLoading}
          validationErrors={validationErrors}
        />
      ),
      footer: (
        <>
          <Button
            variant='light'
            onClick={() => {
              paymentPlanCancelGAEventHandler();
              setShowModal();
              resetState();
            }}
          >
            Cancel
          </Button>
          <Button
            variant='secondary'
            onClick={next1Click}
            disabled={isFormLoading}
          >
            Next
          </Button>
        </>
      ),
      size: 'md',
    },
    1: {
      header: 'Pay with Payment Plan',
      body: (
        <PaymentPlanStep
          cardholderName={cardholderName}
          setCardholderName={setCardholderName}
          cardholderEmail={cardholderEmail}
          setCardholderEmail={setCardholderEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          addressLine1={addressLine1}
          setAddressLine1={setAddressLine1}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zipCode={zipCode}
          setZipCode={setZipCode}
          isFormLoading={isFormLoading}
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
          setFormLoading={setFormLoading}
          validationErrors={validationErrors}
        />
      ),
      footer: (
        <>
          <Button
            variant='light'
            onClick={() => {
              setStepNumber(stepNumber - 1);
            }}
            disabled={false}
          >
            Back
          </Button>
          <Button
            variant='secondary'
            onClick={next2Click}
            disabled={isFormLoading}
          >
            Next
          </Button>
        </>
      ),
      size: 'md',
    },
    2: {
      header: 'Pay with Payment Plan',
      body: (
        <PaymentPlanIframeStep
          src={checkoutUrl}
          setStepNumber={setStepNumber}
          stepNumber={stepNumber}
          isFormLoading={isFormLoading}
          setFormLoading={setFormLoading}
          splitItResult={splitItResult}
          setSplitItResult={setSplitItResult}
          triggerUpdate={triggerUpdate}
          totalAmount={totalAmount}
          practiceName={practiceName}
          practiceId={practiceId}
        ></PaymentPlanIframeStep>
      ),
      footer: (
        <>
          {splitItResult === '' || splitItResult === 'failed' ? (
            <Button
              variant='secondary'
              onClick={startOverClick}
              disabled={isFormLoading}
            >
              Start Over
            </Button>
          ) : (
            <Button
              variant='secondary'
              onClick={doneClick}
              disabled={isFormLoading}
            >
              Done
            </Button>
          )}
        </>
      ),
      size: splitItResult === '' ? 'lg' : 'md',
    },
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      size={steps[stepNumber].size}
      aria-labelledby={`payment-plan-modal`}
      centered
    >
      {steps[stepNumber].header && (
        <Modal.Header>
          <Modal.Title id={`payment-plan-modal`}>
            {steps[stepNumber].header}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{steps[stepNumber].body}</Modal.Body>
      <Modal.Footer>{steps[stepNumber].footer}</Modal.Footer>
      <StartOverConfirm
        show={showStartOver}
        noClick={startOverNoClick}
        yesClick={startOverYesClick}
      />
    </Modal>
  );
};

export default PaymentPlanModal;
