// Modules
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

// Api
import { createDraftTransaction } from 'utils/api/spreedlyApi';
import { createNewCustomer, updatePaymentMethod } from 'utils/api/customerApi';

// Components
import { Modal, Button } from 'react-bootstrap';
import PaymentInfoStep from './PaymentInfoStep';
import SpreedlyFormStep from './SpreedlyFormStep';
import PaymentResponseStep from './PaymentResponseStep';
import SelectCardStep from './CardSelect/SelectCardStep';

// Helpers
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { postTransactionReceipt } from '../../../../utils/api/transactionPublicApi';
import {
  payInFullCancelGAEventHandler,
  payInFullBackGAEventHandler,
  payInFullFinishedGAEventHandler,
  payInFullNextGAEventHandler,
  payInFullPayNowGAEventHandler,
  payInFullTryAgainGAEventHandler,
} from './googleAnalyticsHandlers';
import { parseNames, isFullName } from 'utils/helpers/names';
import {
  addSpreedlyErrorHandler,
  addSpreedlySuccessHandler,
  addSpreedlyValidationHandler,
} from '../SpreedlyEmbed/spreedlyFunctions';

// State
import { useDispatch, useSelector } from 'react-redux';
import { postSpreedlyPaymentInfo } from 'utils/store/actions/transactionsActions';
import useStepHistory from 'utils/hooks/useStepHistory';

// Contants
import PaymentStates from 'constants/PaymentStates';

const validInputFieldsInitState = {
  fullName: true,
  month: true,
  year: true,
  expirationDate: true,
  cardNumber: true,
  cvv: true,
  cvvLength: true,
};

// (err: string): string
function getErrorMessage(err) {
  switch (err) {
    case 'cvv does not match':
      return "Your card's security code is incorrect";

    case 'the credit card number is invalid':
      return 'Your card number is incorrect';

    case 'a duplicate transaction has been submitted':
      return 'Your card has been declined. Please try again with different credit card information';

    case 'there was difficulty communicating with the payment system':
      return "We're having trouble confirming the payment status. Please contact support@vituspay.com or 1-877-969-3124 to confirm this transaction";

    default:
      return 'Your card has been declined';
  }
}

function inputFieldIsValid(requiredFields) {
  const validFields = {
    fullName: true,
    month: true,
    year: true,
    expirationDate: true,
  };

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  const name = requiredFields.full_name;
  const inputMonthStr = requiredFields.month;
  const inputMonthNum = inputMonthStr * 1;
  const inputYearStr = requiredFields.year;
  const inputYearNum = inputYearStr * 1;

  if (!/([\w]+\s[\w]+.*)/.test(name)) validFields.fullName = false;

  if (!/^\d{4}$/.test(inputYearStr)) validFields.year = false;
  if (!/^\d{2}$/.test(inputMonthStr)) validFields.month = false;
  if (year > inputYearNum) validFields.year = false;

  const expirateIsValid =
    inputYearNum > year ? true : inputMonthNum >= month ? true : false;

  if (!expirateIsValid) validFields.expirationDate = false;

  if (!/^\d{2}$/.test(inputMonthStr)) validFields.month = false;

  if (inputMonthNum > 12) validFields.month = false;

  return validFields;
}

const FormSteps = Object.freeze({
  PAYMENT_INFO: 0,
  SELECT_CARD: 1,
  SPREEDLY_FORM: 2,
  PAYMENT_RESPONSE: 3,
});

const PayInFullModal = (props) => {
  const { showModal, setShowModal, triggerUpdate } = props;

  const payWithSavedCardEnabled = useSelector(
    (s) => s.loggedPractice.payWithSavedCards
  );

  const [selectedPetOwner, setSelectedPetOwner] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);
  const [staffMember, setStaffMember] = useState('Choose staff member...');
  const [petOwnerName, setPetOwnerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [totalAmount, setTotalAmount] = useState('');

  const [isSaveCardChecked, setSaveCardChecked] = useState(true);

  const [{ currentStep }, { setStep, nextStep, prevStep, resetSteps }] =
    useStepHistory();
  const [isFormLoading, setFormLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const processedTransactionId = useRef(); //holds the most recently processed transaction for cancellation

  //new states
  const [cardHolderName, setCardHolderName] = useState('');
  const [currentPaymentState, setCurrentPaymentState] = useState(
    PaymentStates.Failed
  );
  const [currentCardMonth, setCurrentCardMonth] = useState('');
  const [currentCardYear, setCurrentCardYear] = useState('');
  const [lastFourDigits, setLastFourDigits] = useState('XXXX');
  const [errorMessage, setErrorMessage] = useState(
    'There was an error processing this transaction'
  );
  const [warningMessage, setWarningMessage] = useState('');
  const [spreedlyValidationObj, setSpreedlyValidationObj] = useState(null);
  const [inputData, setInputData] = useState(null);
  const [validInputFields, setValidInputFields] = useState(
    validInputFieldsInitState
  );
  const [showReceiptOptions, setShowReceiptOptions] = useState(true);
  const communicatingErrorMessage =
    'There was difficulty communicating with the payment system.';

  //fields used for validation
  const STAFF_MEMBER_DEFINITION = {
    field: 'staffMember',
    value: staffMember,
    validationMessage: 'Select a staff member',
  };
  const TOTAL_AMOUNT_DEFINITION = {
    field: 'totalAmount',
    value: totalAmount,
    validationMessage: 'Enter a non-zero amount',
  };
  const DESCRIPTION_DEFINITION = {
    field: 'description',
    value: description,
    validationMessage: 'Enter a description',
  };
  const PET_OWNER_NAME_DEFINITION = {
    field: 'petOwnerName',
    value: petOwnerName,
    validationMessage: "Enter a pet owner's full name",
  };
  const PHONE_NUMBER_DEFINITION = {
    field: 'phoneNumber',
    value: phoneNumber,
    validationMessage: 'Enter a valid phone number',
  };

  const step1Validation = [
    STAFF_MEMBER_DEFINITION,
    TOTAL_AMOUNT_DEFINITION,
    DESCRIPTION_DEFINITION,
    PET_OWNER_NAME_DEFINITION,
    PHONE_NUMBER_DEFINITION,
  ];

  const validateReducer = (field, value) => {
    switch (field) {
      case 'totalAmount':
        return value > 0;
      case 'staffMember':
        return value?.length === 36;
      case 'petOwnerName':
        return value?.length > 0 && isFullName(value);
      case 'description':
        return value?.length > 0;
      case 'phoneNumber':
        if (!payWithSavedCardEnabled) return true;
        return value?.length > 0 && isPossiblePhoneNumber(value);
      default:
        return null;
    }
  };

  async function saveCard({
    brand: card_type,
    last4: last_four,
    token: payment_method_token,
    fingerprint,
  }) {
    const paymentMethodData = {
      full_name: cardHolderName,
      expiration_month: currentCardMonth,
      expiration_year: currentCardYear,
      payment_method_token,
      card_type: card_type.toUpperCase(),
      last_four,
      fingerprint,
    };

    try {
      if (!selectedPetOwner) {
        const [firstName, lastName] = parseNames(petOwnerName);

        const customer = {
          customerData: {
            first_name: firstName,
            last_name: lastName,
            phone: phoneNumber,
          },
          paymentMethodData,
        };

        // create customer
        await createNewCustomer(customer);
        return;
      }

      if (selectedPetOwner.payment_methods[0].id) {
        // overwrite existing card on file
        await updatePaymentMethod(
          paymentMethodData,
          selectedPetOwner.customer_id,
          selectedPetOwner.payment_methods[0].id
        );
        return;
      }

      // Here we would add a new card to customer record
      // (currently this is impossible, as each customer is required to have one
      // and only onecard on file)
    } catch (e) {
      console.error(e);
      toast.error('Error saving the card');
    }
  }

  const dispatch = useDispatch();

  const handleSpreedlyError = (errors) => {
    console.error(errors);
  };

  const generateErrorMessage = (err) => {
    const msg = err.error?.toLowerCase() || '';
    const noPeriod =
      msg[msg.length - 1] === '.' ? msg.substring(0, msg.length - 1) : msg;
    setErrorMessage(getErrorMessage(noPeriod));
  };

  const handleTokenSuccess = async (token, saveCardOverride = null) => {
    // the override is so when we manually call handleTokenSuccess (not called by Spreedly)
    // we can specify whether to save the card and do not rely on state
    const shouldSaveCard =
      (saveCardOverride ?? isSaveCardChecked) && payWithSavedCardEnabled;

    const isDraftCreated = await getTransactionId(
      totalAmount,
      description,
      petOwnerName,
      staffMember
    );

    if (isDraftCreated) {
      try {
        let response = await postSpreedlyPaymentInfo(
          processedTransactionId.current,
          token,
          shouldSaveCard // save card in spreedly vault
        )(dispatch);
        if (response.data.success) {
          setLastFourDigits(response.data.data.last4);

          if (shouldSaveCard) await saveCard(response.data.data);

          setCurrentPaymentState(PaymentStates.Successful);
          triggerUpdate();
        } else {
          setCurrentPaymentState(PaymentStates.Failed);
        }

        setStep(FormSteps.PAYMENT_RESPONSE);
        setFormLoading(false);
      } catch (err) {
        if (err.error === communicatingErrorMessage) {
          const errorMessageWithId = `Transaction ID: ${err.userMessage.id}`;
          setWarningMessage(errorMessageWithId);
          setCurrentPaymentState(PaymentStates.Completed);
        } else {
          setCurrentPaymentState(PaymentStates.Failed);
        }

        setStep(FormSteps.PAYMENT_RESPONSE);
        generateErrorMessage(err);
        setFormLoading(false);
      }
    }
  };

  const getTransactionId = async (
    totalAmount,
    description,
    petOwnerName,
    staffMember
  ) => {
    try {
      setFormLoading(true);
      let result = await createDraftTransaction(
        totalAmount,
        description,
        petOwnerName,
        staffMember
      );

      if (result.id) {
        processedTransactionId.current = result.id;
        return true;
      }

      toast.error('Error creating text to pay transaction');
      setFormLoading(false);
      resetSteps();
      return false;
    } catch (err) {
      toast.error('Error creating text to pay transaction');
      setFormLoading(false);
      resetSteps();
      return false;
    }
  };

  const validate = (rules) => {
    const validationResult = rules.reduce((errors, vf) => {
      const error = !validateReducer(vf.field, vf.value);
      if (error) {
        errors[vf.field] = vf.validationMessage;
      }
      return errors;
    }, []);

    setValidationErrors(validationResult);
    return Object.keys(validationResult);
  };

  //performs form validation for the next button
  const paymentInfoSubmit = () => {
    const validationResult = validate(step1Validation);

    if (validationResult.length === 0) {
      const newStep =
        selectedPetOwner !== null && payWithSavedCardEnabled
          ? FormSteps.SELECT_CARD
          : FormSteps.SPREEDLY_FORM;
      setStep(newStep);
      setCardHolderName(petOwnerName);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = {
      full_name: cardHolderName,
      month: currentCardMonth,
      year: `20${currentCardYear}`,
    };

    const validFields = inputFieldIsValid(requiredFields);

    setInputData(requiredFields);

    const { fullName, month, year, expirationDate } = validFields;

    setValidInputFields({
      ...validInputFieldsInitState,
      fullName,
      month,
      year,
      expirationDate,
    });

    // there is no way to updated a handler so we have to reset and re-add them all
    window.Spreedly.removeHandlers();
    addSpreedlyValidationHandler(setSpreedlyValidationObj);
    addSpreedlyErrorHandler(handleSpreedlyError);
    // we need to add this handler here so that handleTokenSuccess has access
    // to the updated controlled state in this component
    addSpreedlySuccessHandler(handleTokenSuccess);

    window.Spreedly.validate();
  };

  const tryAgain = async () => {
    try {
      setFormLoading(true);
      prevStep();
      setFormLoading(false);
    } catch (err) {
      toast.error('Error cancelling transaction');
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      size='md'
      aria-labelledby={`pay-in-full-modal`}
      centered
    >
      {currentStep in Object.values(FormSteps) && (
        <Modal.Header>
          <Modal.Title id={`pay-in-full-modal`}>
            {currentStep === FormSteps.PAYMENT_INFO && `Payment Information`}
            {currentStep === FormSteps.SELECT_CARD && 'Pay with Saved Card'}
            {currentStep === FormSteps.SPREEDLY_FORM && `Pay by Card`}
            {currentStep === FormSteps.PAYMENT_RESPONSE &&
              currentPaymentState === PaymentStates.Failed &&
              `Payment Failed`}
            {currentStep === FormSteps.PAYMENT_RESPONSE &&
              currentPaymentState === PaymentStates.Successful &&
              `Payment Successful`}
            {currentStep === FormSteps.PAYMENT_RESPONSE &&
              currentPaymentState === PaymentStates.Completed &&
              `Payment Completed`}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {currentStep === FormSteps.PAYMENT_INFO && (
          <PaymentInfoStep
            staffMember={staffMember}
            setStaffMember={setStaffMember}
            petOwnerName={petOwnerName}
            setPetOwnerName={setPetOwnerName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            description={description}
            setDescription={setDescription}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
            isFormLoading={isFormLoading}
            setFormLoading={setFormLoading}
            validationErrors={validationErrors}
            selectedPetOwner={selectedPetOwner}
            setSelectedPetOwner={setSelectedPetOwner}
            enablePhoneNumber // this should eventually be enabled by default (for all payment modals)
          />
        )}
        {currentStep === FormSteps.SELECT_CARD && (
          <SelectCardStep
            selectedPaymentMethodIndex={selectedPaymentMethodIndex}
            setSelectedPaymentMethodIndex={setSelectedPaymentMethodIndex}
            paymentMethods={selectedPetOwner?.payment_methods}
            totalAmount={totalAmount}
          />
        )}
        {currentStep === FormSteps.SPREEDLY_FORM && (
          <SpreedlyFormStep
            selectedPaymentMethod={selectedPaymentMethod}
            totalAmount={totalAmount}
            petOwnerName={petOwnerName}
            cardHolderName={cardHolderName}
            setCardHolderName={setCardHolderName}
            isFormLoading={isFormLoading}
            setFormLoading={setFormLoading}
            validationErrors={validationErrors}
            handleSpreedlyError={handleSpreedlyError}
            handleTokenSuccess={handleTokenSuccess}
            handleSubmit={handleSubmit}
            inputData={inputData}
            validInputFields={validInputFields}
            setValidInputFields={setValidInputFields}
            currentCardMonth={currentCardMonth}
            setCurrentCardMonth={setCurrentCardMonth}
            currentCardYear={currentCardYear}
            setCurrentCardYear={setCurrentCardYear}
            spreedlyValidationObj={spreedlyValidationObj}
            setSpreedlyValidationObj={setSpreedlyValidationObj}
            isSaveCardChecked={isSaveCardChecked}
            setSaveCardChecked={setSaveCardChecked}
            isPetOwnerSelected={selectedPetOwner !== null}
          />
        )}
        {currentStep === FormSteps.PAYMENT_RESPONSE && (
          <PaymentResponseStep
            totalAmount={totalAmount}
            isFormLoading={isFormLoading}
            currentPaymentState={currentPaymentState}
            setCurrentPaymentState={setCurrentPaymentState}
            lastFourDigits={lastFourDigits}
            errorMessage={errorMessage}
            warningMessage={warningMessage}
            postTransactionReceipt={postTransactionReceipt}
            processedTransactionId={processedTransactionId}
            showReceiptOptions={showReceiptOptions}
            setShowReceiptOptions={setShowReceiptOptions}
            autoReceiptPhoneNumber={selectedPetOwner ? phoneNumber : null}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {currentStep === FormSteps.PAYMENT_INFO && (
          <Button
            variant='light'
            onClick={() => {
              payInFullCancelGAEventHandler();
              setShowModal();
            }}
            disabled={false}
          >
            Cancel
          </Button>
        )}
        {currentStep === FormSteps.PAYMENT_INFO && (
          <Button
            variant='secondary'
            onClick={() => {
              payInFullNextGAEventHandler();
              paymentInfoSubmit();
            }}
            disabled={isFormLoading}
          >
            Next
          </Button>
        )}
        {currentStep === FormSteps.SELECT_CARD && (
          <Button
            variant='light'
            onClick={() => {
              payInFullBackGAEventHandler(totalAmount);
              prevStep();
            }}
            disabled={false}
          >
            Back
          </Button>
        )}
        {currentStep === FormSteps.SELECT_CARD && (
          <Button
            variant='secondary'
            onClick={() => {
              payInFullNextGAEventHandler();
              if (
                selectedPaymentMethodIndex >=
                selectedPetOwner?.payment_methods?.length
              ) {
                setSelectedPaymentMethod(null);
              } else {
                setFormLoading(true); // smooth transition into SpreedlyFormStep when paying with a saved card
                setSelectedPaymentMethod(
                  selectedPetOwner.payment_methods[selectedPaymentMethodIndex]
                );
              }
              nextStep();
            }}
            disabled={isFormLoading}
          >
            {selectedPaymentMethodIndex <
            selectedPetOwner?.payment_methods?.length
              ? 'Pay Now'
              : 'Next'}
          </Button>
        )}
        {currentStep === FormSteps.SPREEDLY_FORM && (
          <Button
            variant='light'
            onClick={() => {
              payInFullBackGAEventHandler(totalAmount);
              prevStep();
            }}
            disabled={isFormLoading}
          >
            Back
          </Button>
        )}
        {currentStep === FormSteps.SPREEDLY_FORM && (
          <Button
            id='submit-button'
            variant='secondary'
            onClick={(event) => {
              payInFullPayNowGAEventHandler(totalAmount);
              handleSubmit(event);
            }}
            disabled={isFormLoading}
          >
            Pay Now
          </Button>
        )}
        {currentStep === FormSteps.PAYMENT_RESPONSE &&
          currentPaymentState === PaymentStates.Failed && (
            <Button
              variant='light'
              onClick={() => {
                payInFullTryAgainGAEventHandler(totalAmount);
                tryAgain();
              }}
              disabled={isFormLoading}
            >
              Try again
            </Button>
          )}
        {currentStep === FormSteps.PAYMENT_RESPONSE && !showReceiptOptions && (
          <Button
            variant='secondary'
            onClick={() => {
              payInFullFinishedGAEventHandler(currentPaymentState, totalAmount);
              setShowModal();
            }}
            disabled={isFormLoading}
          >
            Finished
          </Button>
        )}
        {warningMessage && (
          <Button
            variant='secondary'
            onClick={() => {
              payInFullFinishedGAEventHandler(currentPaymentState, totalAmount);
              setShowModal();
            }}
            disabled={isFormLoading}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PayInFullModal;
