import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';

const EmailInput = (props) => {
  const { emailAddress, setEmailAddress, validationError } = props;

  return (
    <Form.Group controlId='emailAddress'>
      <Form.Label>Email Address</Form.Label>
      <InputGroup>
        <Form.Control
          type='text'
          name='emailAddress'
          placeholder='email@address.com'
          isInvalid={!!validationError}
          defaultValue={emailAddress}
          onChange={(event) => {
            setEmailAddress(event.target.value);
          }}
        />
        <Form.Control.Feedback type='invalid'>
          {validationError}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export default EmailInput;
