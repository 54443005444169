import React from 'react';

function Label({ text, hasError, errorMessage }) {
  return (
    <div style={{ display: 'flex' }}>
      <div>{text}</div>
      {hasError && (
        <div style={{ paddingLeft: '.2rem', color: 'red' }}>
          {errorMessage || '*'}
        </div>
      )}
    </div>
  );
}

export default Label;
