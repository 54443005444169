import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import { getTransactionsPaginated } from 'utils/api/transactionApi';

import { mapTransaction } from 'utils/helpers/sortAndMapFunctions';

const defaultSizePerPage = 10;

const queryParamsReducer = (prevState, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...prevState,
        isFailure: false,
        isLoading: true,
      };

    case 'FETCH_SUCCESS':
      let updatedPages;
      const currentItems = prevState.cachedPages[prevState.currentPage] || [];
      updatedPages = {
        ...prevState.cachedPages,
        [prevState.currentPage]: currentItems.concat(
          action.payload.transactions
        ),
      };

      return {
        ...prevState,
        cachedPages: updatedPages,
        lastEvaluatedKey: action.payload.lastEvaluatedKey,
        isLoading: false,
      };

    case 'FETCH_FAILURE':
      return {
        ...prevState,
        isFailure: true,
        isLoading: false,
      };

    case 'WEBSOCKET_UPDATE':
      const newTransaction = action.payload;
      const cp = prevState.cachedPages;
      const mostRecentTransactionId = cp[1][0]?.transactionId;
      if (
        newTransaction.transactionId !== mostRecentTransactionId &&
        cp[1].length > 0
      ) {
        const allTransactions = [newTransaction, ...Object.values(cp).flat()];

        let tmpPages = {};
        for (
          let i = 0, p = 1;
          i < allTransactions.length;
          i += defaultSizePerPage, p++
        )
          tmpPages[p] = allTransactions.slice(i, i + defaultSizePerPage);

        return {
          ...prevState,
          cachedPages: tmpPages,
        };
      } else {
        return prevState;
      }

    case 'NEXT_PAGE':
      const lek = prevState.lastEvaluatedKey;
      const nextPage =
        lek === null ? prevState.currentPage : prevState.currentPage + 1;
      return {
        ...prevState,
        currentPage: nextPage,
      };
    case 'PREV_PAGE':
      return {
        ...prevState,
        currentPage: prevState.currentPage - 1 || 1,
      };

    case 'UPDATE_SORT':
      return {
        ...prevState,
        sort: action.payload,
        cachedPages: { 1: [] },
        currentPage: 1,
        lastEvaluatedKey: undefined,
      };

    case 'UPDATE_SEARCH':
      return {
        ...prevState,
        search: action.payload,
        cachedPages: { 1: [] },
        currentPage: 1,
        lastEvaluatedKey: undefined,
      };

    default:
      throw new Error();
  }
};

function usePaginatedPages({ practiceId, onError }) {
  const [state, dispatch] = useReducer(queryParamsReducer, {
    currentPage: 1,
    cachedPages: { 1: [] },
    sort: { field: 'lastUpdated', order: 'desc' },
    search: '',
    lastEvaluatedKey: undefined,
    isLoading: false,
    isFailure: false,
  });

  const mostRecentTransactionFromWebSocket = useSelector(
    (st) => st.mostRecentTransactionFromWebSocket
  );

  useEffect(() => {
    let newTransaction =
      JSON.stringify(mostRecentTransactionFromWebSocket) === '{}'
        ? null
        : mapTransaction(cloneDeep(mostRecentTransactionFromWebSocket));

    if (newTransaction)
      dispatch({ type: 'WEBSOCKET_UPDATE', payload: newTransaction });
  }, [mostRecentTransactionFromWebSocket]);

  useEffect(() => {
    const fetchData = async (limit) => {
      dispatch({ type: 'FETCH_INIT' });

      let dbParams = {
        practiceId: practiceId,
        pageSize: limit || defaultSizePerPage,
        sort: state.sort,
        search: state.search,
        lastEvaluatedKey: state.lastEvaluatedKey || null,
      };

      let newLastEvaluatedKey = null;
      let transactions = [];
      try {
        const result = await getTransactionsPaginated(dbParams);

        newLastEvaluatedKey = result.LastEvaluatedKey || null;
        transactions = result.Items;

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: {
            transactions,
            lastEvaluatedKey: newLastEvaluatedKey,
          },
        });
      } catch (err) {
        onError(err);
        dispatch({ type: 'FETCH_FAILURE', payload: err });
      }
    };

    const itemsOnCurrentPage =
      state.cachedPages[state.currentPage]?.length || 0;
    if (
      itemsOnCurrentPage < defaultSizePerPage &&
      state.lastEvaluatedKey !== null &&
      practiceId
    ) {
      fetchData(defaultSizePerPage - itemsOnCurrentPage);
    }

    // show loading if a practiceId has not yet been supplied
    if (!practiceId) {
      dispatch({ type: 'FETCH_INIT' });
    }
  }, [
    practiceId,
    onError,
    state.cachedPages,
    state.currentPage,
    state.search,
    state.sort,
    state.lastEvaluatedKey,
  ]);

  const nextPage = () => {
    dispatch({ type: 'NEXT_PAGE' });
  };

  const prevPage = () => {
    dispatch({ type: 'PREV_PAGE' });
  };

  const setSortOrder = (field, order) => {
    let sort = { field, order };

    dispatch({ type: 'UPDATE_SORT', payload: sort });
  };

  const setSearch = (search) => {
    if (search === state.search) return;
    dispatch({ type: 'UPDATE_SEARCH', payload: { search } });
  };

  return [
    {
      isLoading: state.isLoading,
      transactions: state.cachedPages[state.currentPage],
      currentPage: state.currentPage,
      err: state.err,
    }, // state
    {
      nextPage,
      prevPage,
      setSortOrder,
      setSearch,
    }, // dispatch
  ];
}

export default usePaginatedPages;
