import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DateTime = ({ datetime, format }) => (
  <span data-value={`${datetime}`}>{moment(datetime).format(format)}</span>
);

DateTime.normalizeEpoch = function (fuzzyEpoch) {
  if (typeof fuzzyEpoch !== 'number') {
    return fuzzyEpoch;
  }
  return fuzzyEpoch > 9999999999 ? fuzzyEpoch : fuzzyEpoch * 1000;
};

DateTime.propTypes = {
  datetime: PropTypes.node,
  format: PropTypes.string.isRequired,
};

export default DateTime;
