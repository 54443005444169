import React from 'react';
import Currency from './../../../../utils/helpers/currency';
import DateTime from './../../../../utils/helpers/DateTime';
import sortCaret from './../../../../utils/helpers/sortCaret';

function currencyFormatter(cell) {
  return <Currency amount={cell} formatStripe={true} />;
}

function dateFormatter(cell) {
  return <DateTime datetime={cell * 1000} format='MM/DD/YYYY - h:mm a' />;
}

export const columns = [
  {
    text: 'ARRIVAL',
    dataField: 'arrival_date',
    formatter: dateFormatter,
    sort: true,
    sortCaret,
  },
  {
    text: 'CREATED',
    dataField: 'created',
    formatter: dateFormatter,
    sort: true,
    sortCaret,
  },
  {
    text: 'AMOUNT',
    dataField: 'amount',
    formatter: currencyFormatter,
    sort: true,
    sortCaret,
  },
  {
    text: 'DESCRIPTION',
    dataField: 'description',
    sort: true,
    sortCaret,
  },
];

export const defaultSorted = [{ dataField: 'arrival_date', order: 'desc' }];

export const paginationOptions = {
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
  firstPageText: '<<',
  lastPageText: '>>',
  paginationSize: 5,
  alwaysShowAllBtns: true,
};
