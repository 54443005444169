import React from 'react';
const { REACT_APP_ENVIRONMENT_LABEL: env } = process.env;

/*
 Specify Feature in the format:
  { name: <string>, description: <string>, enabled: <bool>, envDisable: [<string>] }
*/
const featureList = [
  {
    name: 'Email to Pay',
    description: 'Sending payment using email',
    enabled: true,
    envDisable: [],
  },
];

// Will return null if the feature does not exist
const isFeatureEnabled = (feature) => {
  if (!feature) return null;

  const disableOverriden =
    feature.envDisable && feature.envDisable.includes(env.toUpperCase());
  return feature.enabled && !disableOverriden;
};

export const Feature = ({ name, children }) => {
  const feature = featureList.find((feat) => feat.name === name);
  const enabled = isFeatureEnabled(feature);

  if (enabled) return children;

  if (enabled === null && (env === 'local' || env === 'dev')) {
    console.error(`There is no feature named "${name}`);

    const spanStyle = {
      padding: '1rem',
      margin: '1rem',
      border: '1px black solid',
      backgroundColor: '#ccc',
      display: 'inline-block',
    };

    return (
      <span style={spanStyle}>
        No feature named <b>"{name}"</b>
      </span>
    );
  }

  return null;
};
