import { triggerGAEvent } from '../../../../utils/helpers/googleAnalytics';

const transactionsCategory = 'Transactions Tab';

export function triggerExportAllEvent(coreId) {
  triggerGAEvent(transactionsCategory, 'Export All to Excel', coreId);
}

export function triggerSortFieldEvent(sortField, coreId) {
  triggerGAEvent(transactionsCategory, `${sortField} Filter`, coreId);
}
