import React from 'react';
import PropTypes from 'prop-types';

import exportToExcel from '../../utils/helpers/exportToExcel';

const Export = ({ csvData = [], fileName, disabled = false }) => {
  const handleClick = () => {
    exportToExcel(fileName, csvData);
  };

  return (
    <button
      disabled={disabled}
      className='btn btn-outline-secondary'
      type='button'
      onClick={handleClick}
    >
      <span className='mr-2'>Export to Excel</span>
      <i className='fas fa-file-excel'></i>
    </button>
  );
};

Export.propTypes = {
  csvData: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default Export;
