import { handleResponse, handleError, axiosUserInstance as axios } from '.';
import map from 'utils/api/map';

export function fetchStaffMembers(practiceId) {
  let body = null;
  if (practiceId) {
    body = { practiceId };
  }
  return axios
    .post(`/get`, body)
    .then(handleResponse)
    .then(map.user.toModel)
    .catch(handleError);
}

export function addStaffMember(staffMember) {
  return axios
    .post(`/update`, staffMember)
    .then(handleResponse)
    .then(map.user.toModel)
    .catch(handleError);
}

export function editStaffMember(staffMember) {
  return axios
    .post(`/update`, staffMember)
    .then(handleResponse)
    .then(map.user.toModel)
    .catch(handleError);
}
