import React from 'react';

import { Form } from 'react-bootstrap';
import { Typeahead } from '@vitusvet/react-library';

const PetOwnerNameTypeahead = (initialProps) => {
  const { petOwnerName, validationError, ...props } = initialProps;

  const isInvalid = !!validationError;

  return (
    <Form.Group controlId='petOwnerName'>
      <Form.Label>Pet Owner Name</Form.Label>
      <Typeahead
        async
        placeholder='Pet Owner Name'
        inputClassName={['form-control', isInvalid ? 'is-invalid' : ''].join(
          ' '
        )}
        value={petOwnerName}
        {...props}
      />
      <Form.Control.Feedback
        type='invalid'
        style={{ display: isInvalid ? 'block' : 'none' }} // we have to force this style
      >
        {validationError}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default PetOwnerNameTypeahead;
