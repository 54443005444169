import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const StartOverConfirm = ({ show, noClick, yesClick }) => {
  return (
    <Modal
      id='StartOverConfirm'
      show={show}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='text-center mt-4 mb-4 row'>
          <div className='col'>
            <h3>
              Are you sure you want to cancel this monthly payment plan and
              start over?
            </h3>
          </div>
        </div>
        <div className='mt-4 mb-2 row'>
          <div className='col text-center'>
            <button
              className='btn btn-outline-secondary mr-2'
              onClick={noClick}
            >
              NO
            </button>
            <button
              className='btn btn-secondary ml-2'
              type='button'
              onClick={yesClick}
            >
              YES
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

StartOverConfirm.propTypes = {
  show: PropTypes.bool.isRequired,
  noClick: PropTypes.func.isRequired,
  yesClick: PropTypes.func.isRequired,
};

export default StartOverConfirm;
