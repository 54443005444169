import { StoreActions } from '../actions';
import initialState from './initialState';

export const practiceReducer = (
  state = initialState.practiceReducer,
  action
) => {
  switch (action.type) {
    case StoreActions.DISPLAY_PRACTICE_LIST_SUCCESS:
      return action.practices;
    case StoreActions.SAVE_PRACTICE_SUCCESS:
      return [...state, { ...action.practice }];
    case StoreActions.SET_PRACTICE_PAYMENT_SUCCESS:
      return state;
    case StoreActions.UPDATE_PRACTICE_SUCCESS:
      return state.map((practice) =>
        practice.id === action.practice.id ? action.practice : practice
      );
    case StoreActions.ACTIVATE_PRACTICE_SUCCESS:
      return state;
    case StoreActions.LOGOUT:
      return initialState.practiceReducer;
    default:
      return state;
  }
};

export const selectedPractice = (
  state = initialState.selectedPractice,
  action
) => {
  switch (action.type) {
    case StoreActions.SET_SELECTED_PRACTICE:
      return action.selectedPractice;
    case StoreActions.ALTER_SELECTED_PRACTICE:
      return { ...state, ...action.alteredPractice };
    case StoreActions.UNSET_SELECTED_PRACTICE:
      return initialState.selectedPractice;
    case StoreActions.ACTIVATE_PRACTICE_SUCCESS:
      return state;
    case StoreActions.LOGOUT:
      return initialState.selectedPractice;
    default:
      return state;
  }
};

export default practiceReducer;
