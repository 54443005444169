import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  columns,
  defaultSorted,
  TransactionViewExpand,
} from './TransactionsListConfig';
import Loading from 'components/Common/Loading';

const TransactionsList = (props) => {
  const displayTransactions = props.isLoading ? [] : props.transactions;

  const noData = () => {
    if (props.isLoading) return <Loading />;

    return (
      <div className='no-data text center'>
        <h4>No Transactions</h4>
      </div>
    );
  };

  const disableExpandRow = {
    renderer: () => <></>, // required
    nonExpandable: displayTransactions.map((e) => e?.transactionId),
  };

  return (
    <div className='mt-3 pl-4 pr-4'>
      {
        <BootstrapTable
          keyField='transactionId'
          data={displayTransactions}
          columns={columns(props.isAdmin)}
          bordered={false}
          classes='vitus-table'
          noDataIndication={noData}
          pagination={paginationFactory(props.paginationOptions)}
          remote={{ pagination: false, filter: false, sort: true }}
          onTableChange={props.onTableChange}
          defaultSorted={defaultSorted}
          expandRow={props.isAdmin ? TransactionViewExpand : disableExpandRow}
        />
      }
    </div>
  );
};

TransactionsList.propTypes = {
  transactions: PropTypes.array.isRequired,
};

export default TransactionsList;
