import moment from 'moment';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function exportToExcel(fileName, csvData) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = 'xlsx';
  const currentDateAndTime = moment()
    .format('LLL')
    .replace(/ /g, '-')
    .replace(/,/g, '');

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = {
    Sheets: {
      data: ws,
    },
    SheetNames: ['data'],
  };
  const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([buffer], { type: fileType });
  FileSaver.saveAs(data, `${fileName}_${currentDateAndTime}.${fileExtension}`);
}
