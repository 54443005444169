import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

export function standardFormatPhoneNumber(phoneNumber) {
  if (phoneNumber && phoneNumber.length !== 0) {
    const tel = phoneUtil.parse(phoneNumber);
    return phoneUtil.format(tel, PNF.NATIONAL);
  }
  return '—';
}
