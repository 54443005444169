// Modules
import React, { useReducer, useEffect } from 'react';
import {
  practiceDisplayReducer,
  sortOptions,
  practiceDisplayActions,
} from './practiceListFunctions';

//Utils
import { cloneDeep } from 'lodash';

// Components
import PracticeView from './PracticeView';
import PracticeListHeading from './PracticeListHeading';
import PaginationControl from './PaginationControl';

const basicDetails = ['id', 'coreId', 'name', 'email', 'phone', 'active'];

const practiceDisplayInitState = {
  start: 0,
  numPerPage: 10,
  numPages: 0,
  currentPage: 1,
  practices: [],
  displayData: [],
  activeSort: sortOptions.none,
};

/**
 * ==================
 * Exported component
 * ==================
 */
const PracticeList = (props) => {
  const { practices } = props;

  const [practiceDisplay, dispatchPracticeDisplay] = useReducer(
    practiceDisplayReducer,
    practiceDisplayInitState
  );

  useEffect(() => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.updateDisplay,
      payload: {
        practices: cloneDeep(practices),
        numPerPage: practiceDisplayInitState.numPerPage,
      },
    });
  }, [practices]);

  const handleFirst = () => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.first,
    });
  };

  const handleLast = () => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.last,
    });
  };

  const handlePrev = () => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.prev,
    });
  };

  const handleNext = () => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.next,
    });
  };

  const handlePageNumber = (e) => {
    const pageNumber = e.target?.value;

    e.target.blur();

    dispatchPracticeDisplay({
      type: practiceDisplayActions.pageNumber,
      payload: pageNumber,
    });
  };

  const handleSortByName = () => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.sortByName,
    });
  };

  const handleSortByEmail = () => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.sortByEmail,
    });
  };

  const handleSortByStatus = () => {
    dispatchPracticeDisplay({
      type: practiceDisplayActions.sortByStatus,
    });
  };

  return (
    <section style={{ padding: '0 1rem' }}>
      <PracticeListHeading
        basicDetails={basicDetails}
        sortOptions={sortOptions}
        activeSort={practiceDisplay.activeSort}
        handleSortByName={handleSortByName}
        handleSortByEmail={handleSortByEmail}
        handleSortByStatus={handleSortByStatus}
      />

      {practiceDisplay.displayData.length > 0
        ? practiceDisplay.displayData.map((practice) => {
            return (
              <PracticeView
                key={practice.id}
                practice={practice}
                basicDetails={basicDetails}
              />
            );
          })
        : null}

      <PaginationControl
        paginationData={{
          numPages: practiceDisplay.numPages,
          currentPage: practiceDisplay.currentPage,
        }}
        paginationRange={
          practiceDisplay.numPages >= 5 ? 5 : practiceDisplay.numPages
        }
        handlers={{
          first: handleFirst,
          last: handleLast,
          next: handleNext,
          prev: handlePrev,
          pageNumber: handlePageNumber,
        }}
      />
    </section>
  );
};

export default PracticeList;
