import React, { Component } from 'react';
import Env from 'Env';
import SidebarHeader from './SidebarHeader';
import ToggleOpen from './ToggleOpen';
import UserInfo from './UserInfo';
import SidebarList from './SidebarList';
import Overlay from './Overlay';
import * as practiceInfoActions from './../../../utils/store/actions/practiceInfoActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppVersion from './AppVersion';

class SideBar extends Component {
  state = {
    loggedPracticeInfo: {},
    sidebarIsActive: false,
  };

  toggleSidebar = () => {
    this.setState((state) => ({ sidebarIsActive: !state.sidebarIsActive }));
  };

  fetchData = () => {
    const { getPracticeInfo, currentUser, loggedPractice } = this.props;
    if (currentUser) {
      const practiceId =
        currentUser['custom:PracticeId'] || currentUser['custom:Practice'];
      getPracticeInfo(practiceId).then(() => {
        this.setState({ loggedPracticeInfo: loggedPractice });
      });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { loggedPractice } = this.props;
    if (!prevProps.loggedPractice.stripeId && loggedPractice.stripeId) {
      this.fetchData();
    }
  }

  render() {
    const { loggedPractice, currentUser, routeList } = this.props;

    return (
      <>
        <nav
          id='SideBar'
          className={this.state.sidebarIsActive ? 'sidebar active' : 'sidebar'}
        >
          <div className='sidebar-container mb-5'>
            <ToggleOpen
              sidebarIsActive={this.state.sidebarIsActive}
              toggleSidebar={this.toggleSidebar}
            />
            <SidebarHeader />
            <UserInfo
              loggedPractice={loggedPractice}
              currentUser={currentUser}
            />
            <SidebarList
              currentUser={currentUser}
              routeList={routeList}
              toggleSidebar={this.toggleSidebar}
            />
            {Env.environment !== 'PRODUCTION' && <AppVersion />}
          </div>
        </nav>
        <Overlay
          sidebarIsActive={this.state.sidebarIsActive}
          toggleSidebar={this.toggleSidebar}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedPractice: state.loggedPractice,
  currentUser: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPracticeInfo: (practiceInfo) =>
    dispatch(practiceInfoActions.getPracticeInfo(practiceInfo)),
});

SideBar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  loggedPractice: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
