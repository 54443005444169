// Modules
import React from 'react';

// Google Analytics
import {
  paymentFailedLoadGAEventHandler,
  paymentFailedRetryClickGAEventHandler,
} from './googleAnalyticsHandlers';

// Components
import {
  ErrorDisplay,
  Button,
  SuccessDisplay,
  ContactPracticeMessage,
} from '@vitusvet/react-library';

function PaymentLinkErrors(props) {
  const {
    display,
    displayTypes,
    dispatchDisplay,
    errorMessage,
    practice,
    handleReceiptCancel,
    handleReceiptRetry,
    receiptSentTo,
  } = props;

  const reloadPage = () => {
    const url = window.location.origin + '/pay' + window.location.search;
    window.location.href = url;
  };

  return (
    <>
      {display?.showError ? (
        <ErrorDisplay
          heading='Payment Failed'
          retryCallback={() => {
            paymentFailedRetryClickGAEventHandler();
            dispatchDisplay({ type: displayTypes?.hideError });
          }}
          gaEventHandlers={{
            load: () => paymentFailedLoadGAEventHandler(errorMessage),
          }}
        >
          <p>{errorMessage}</p>
          <ContactPracticeMessage name={practice.name} phone={practice.phone} />
        </ErrorDisplay>
      ) : null}

      {display?.transactionCanceled ? (
        <ErrorDisplay heading='Payment Failed'>
          <p>
            Could not process your payment because the payment request was
            canceled by your Practice.
          </p>
          <ContactPracticeMessage name={practice.name} phone={practice.phone} />
        </ErrorDisplay>
      ) : null}

      {display.transactionExpired ? (
        <ErrorDisplay heading='Transaction Expired'>
          <p>
            Could not process your payment because the payment request has
            expired.
          </p>
          <ContactPracticeMessage name={practice.name} phone={practice.phone} />
        </ErrorDisplay>
      ) : null}

      {display?.receiptFailed ? (
        <ErrorDisplay
          heading='Error Sending Receipt'
          retryCallback={handleReceiptRetry}
          buttons={[
            <Button
              key='theCancelButton'
              clickHandler={handleReceiptCancel}
              text='Cancel'
              variant='gray'
              extraStyles={{ marginBottom: '2rem', marginTop: '2rem' }}
            />,
          ]}
        >
          <p>Receipt could not be sent to: {receiptSentTo}</p>
        </ErrorDisplay>
      ) : null}

      {display?.dataFailed ? (
        <ErrorDisplay
          heading='Unable to Establish Connection'
          retryCallback={reloadPage}
        >
          <p>
            Sorry we can't load your transaction right now, please select try
            again or contact your practice for assistance
          </p>
        </ErrorDisplay>
      ) : null}

      {display?.alreadyCompleted ? (
        <SuccessDisplay useHeading={false} useHR={false}>
          <p>Your payment has already been successfully completed.</p>
        </SuccessDisplay>
      ) : null}

      {display?.partialData ? (
        <ErrorDisplay heading="Can't Load Transaction">
          <p>Sorry, we can't load your transaction right now.</p>
          <ContactPracticeMessage name={practice.name} phone={practice.phone} />
        </ErrorDisplay>
      ) : null}

      {display?.splititFailed ? (
        <ErrorDisplay heading='Splitit Failed' retryCallback={reloadPage}>
          <p>{errorMessage}</p>
        </ErrorDisplay>
      ) : null}

      {display?.dbInsertFailed ? (
        <ErrorDisplay heading='Could Not Update Database'>
          <p>
            We're sorry, something went wrong trying to update the database.
            Please make a note of the transaction number and installment plan
            number below and contact{' '}
            <a href='mailto:support@vitusvet.com'>support@vitusvet.com</a> for
            assistance.
          </p>

          <p>
            <strong>{errorMessage}</strong>
          </p>
        </ErrorDisplay>
      ) : null}

      {display?.show404 ? (
        <ErrorDisplay heading='Not Found'>
          <p>Sorry, we can't load the page you requested.</p>
        </ErrorDisplay>
      ) : null}
    </>
  );
}

export default PaymentLinkErrors;
