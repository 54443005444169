// Modules
import React from 'react';

// Styles
import styles from './PaginationControl.module.scss';

function getJSXElements(paginationData, numElements, clickFn) {
  const { numPages, currentPage } = paginationData;

  const elements = [];

  let start = 1;

  const diff = Math.floor(numElements / 2);

  if (currentPage > numPages - diff) {
    start = numPages - numElements + 1;
  } else if (currentPage > 3 && numPages > numElements) {
    start = currentPage - diff;
  }

  let curr = start;

  while (elements.length < numElements) {
    const page = (
      <button
        className={curr === currentPage ? styles.currentPage : styles.page}
        value={curr}
        onClick={clickFn}
        key={curr}
      >
        {curr}
      </button>
    );

    curr++;

    elements.push(page);
  }

  return elements;
}

/**
 * ==================
 * Exported component
 * ==================
 */
function PaginationControl(props) {
  const {
    paginationData,
    paginationRange,
    handlers: { first, last, next, prev, pageNumber },
  } = props;

  return (
    <div className={styles.container}>
      <button className={styles.arrow} onClick={first}>
        {'<<'}
      </button>
      <button className={styles.arrow} onClick={prev}>
        {'<'}
      </button>
      {getJSXElements(paginationData, paginationRange, pageNumber)}
      <button className={styles.arrow} onClick={next}>
        {'>'}
      </button>
      <button className={styles.arrow} onClick={last}>
        {'>>'}
      </button>
    </div>
  );
}

export default PaginationControl;
