import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import IdShortener from './../../../../utils/helpers/IdShortener';

const StaffDeleteConfirm = ({
  show,
  onCancelDeleteStaffMember,
  onDeleteStaffMember,
  staffMember,
  staffType,
  isSaving,
}) => {
  return (
    <Modal
      id='StaffDeleteConfirm'
      show={show}
      onHide={onCancelDeleteStaffMember}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='text-center mt-4 mb-4 row'>
          <div className='col'>
            <h3>Are you sure you want to delete this record?</h3>
          </div>
        </div>
        <div className='text-left mt-1 row'>
          <div className='col pl-5'>
            <p>
              <b>
                {staffType === 'users' ? 'User Account' : 'Staff Member'} ID:
              </b>
            </p>
          </div>
          <div className='col pr-5'>
            <p>
              <IdShortener id={staffMember.id} />
            </p>
          </div>
        </div>
        <div className='text-left mt-1 row'>
          <div className='col pl-5'>
            <p>
              <b>
                {staffType === 'users' ? 'User Account' : 'Staff Member'} Name:
              </b>
            </p>
          </div>
          <div className='col pr-5'>
            <p>
              {staffMember.name} {staffMember.lastName}
            </p>
          </div>
        </div>
        <div className='mt-4 mb-2 row'>
          <div className='col text-center'>
            <button
              className='btn btn-outline-secondary mr-2'
              onClick={onCancelDeleteStaffMember}
            >
              CANCEL
            </button>
            <button
              className='btn btn-primary ml-2'
              type='button'
              onClick={onDeleteStaffMember}
              disabled={isSaving}
            >
              {isSaving ? 'Deleting...' : 'DELETE'}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

StaffDeleteConfirm.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancelDeleteStaffMember: PropTypes.func.isRequired,
  onDeleteStaffMember: PropTypes.func.isRequired,
  staffMember: PropTypes.object.isRequired,
  staffType: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default StaffDeleteConfirm;
