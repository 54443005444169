const TransactionSource = Object.freeze({
  Stripe: 'stripe',
  SplitIt: 'splitIt',
  Spreedly: 'spreedly',
  Pax: 'pax',
  PayFabric: 'payfabric',
  Rectangle: 'rectangle',
});

export default TransactionSource;
