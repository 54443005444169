import React, { useState } from 'react';
import DropDownItem from './DropDownItem';
import Currency from './../../../../../utils/helpers/currency';
import TransactionRefundHistory from 'components/Common/TransactionRefundHistory/TransactionRefundHistory';
import TransactionMessageHistoryModal from 'components/Layout/Content/TransactionDetail/TransactionMessageHistoryModal';

const DropDownDetail = ({ transaction }) => {
  const [messageHistoryStatus, setMessageHistoryStatus] = useState(false);

  const closeMessageHistory = () => {
    setMessageHistoryStatus(false);
  };

  const showMessageHistory = () => {
    setMessageHistoryStatus(true);
  };

  return (
    <div className='pt-4 pb-4 ml-0 mr-0 table-details practice-detail info row'>
      <div className='col-md-9 col-sm-12'>
        <div className='mt-3 row'>
          <div className='col-md-3 col-sm-12'>
            <h6>STAFF MEMBER</h6>
            <DropDownItem data={transaction.staff_member} />
          </div>
          <div className='col-md-3 col-sm-12'>
            <h6>EMAIL</h6>
            <DropDownItem
              data={
                transaction.pet_parent_email
                  ? transaction.pet_parent_email
                  : '-'
              }
            />
          </div>
          <div className='col-md-3 col-sm-12'>
            <h6>CARD #</h6>
            <p>
              *
              {transaction.pet_parent_card_digits
                ? transaction.pet_parent_card_digits
                : '-'}
            </p>
          </div>
          <div className='col-md-3 col-sm-12'>
            <h6>TYPE</h6>
            <p>
              {transaction.pet_parent_card_type
                ? transaction.pet_parent_card_type
                : '-'}
            </p>
          </div>
        </div>
        <div className='mt-3 row'>
          <div className='col-md-3 col-sm-12'>
            <h6>PHONE</h6>
            <p>
              {transaction.hasPet_parent_phone
                ? transaction.pet_parent_phone
                : '-'}
            </p>
          </div>
        </div>
        <div className='mt-4 mb-4 row'>
          {transaction.refunds && transaction.refunds.length > 0 ? (
            <div className='col-md-12'>
              <TransactionRefundHistory transaction={transaction} />
            </div>
          ) : null}
        </div>
      </div>
      <div className='col-md-3 col-sm-12'>
        <div className='mt-3 row'>
          <div className='col-md-12 col-sm-12'>
            <h6>FEES</h6>
            <p>
              <Currency amount={transaction.fee_amount} />
            </p>
          </div>
        </div>
        <div className='mt-3 row'>
          <div className='col-md-12 col-sm-12'>
            <h6>AMOUNT REFUNDED</h6>
            <p>
              <Currency
                amount={
                  transaction.amountRefunded ? transaction.amountRefunded : 0
                }
              />
            </p>
          </div>
        </div>
        <div className='mt-3 row'>
          <div className='col-md-12 col-sm-12'>
            <h6>NET</h6>
            <p>
              <Currency
                amount={
                  transaction.net && transaction.net > 0 ? transaction.net : 0
                }
              />
            </p>
          </div>
        </div>

        {transaction.isPaymentLink &&
          transaction.messageHistory?.messages?.length > 0 && (
            <>
              <div className='px-3 mt-3 row'>
                <button
                  style={{ width: '100%' }}
                  className='btn btn-outline-primary'
                  onClick={showMessageHistory}
                >
                  {'MESSAGE HISTORY'}
                </button>
              </div>
              <TransactionMessageHistoryModal
                show={messageHistoryStatus}
                onClose={closeMessageHistory}
                messageHistory={transaction.messageHistory}
              />
            </>
          )}
      </div>
    </div>
  );
};

export default DropDownDetail;
