import React from 'react';
import PropTypes from 'prop-types';
import SanityCheck from './SanityCheck';

const DailyReportRecordSanityCheck = ({ data }) => {
  if (!data) {
    return null;
  }
  var failedSanityTests = [];
  var zeroSum = data.amount - data.fees - data.refunds - data.total;
  if (zeroSum !== 0) {
    failedSanityTests.push(`zeroSum: ${zeroSum}`);
  }

  return <SanityCheck failedSanityTests={failedSanityTests} />;
};

DailyReportRecordSanityCheck.propTypes = {
  transaction: PropTypes.object,
};

export default DailyReportRecordSanityCheck;
