import { StoreActions } from '.';
import * as practiceApi from './../../api/practiceApi';

/**
 * PRIVATE: display practice list after successful request
 * @param {object} practices
 */
function displayParcticeList(practices) {
  return {
    type: StoreActions.DISPLAY_PRACTICE_LIST_SUCCESS,
    practices,
  };
}

/**
 * PRIVATE: Store pratice to redux store after successfull request
 * @param {object} practice
 */
function createPracticeSuccess(practice) {
  return {
    type: StoreActions.SAVE_PRACTICE_SUCCESS,
    practice,
  };
}

/**
 * PRIVATE: Update practice redux store after successfull request
 * @param {object} practice
 */
function updatePracticeSuccess(practice) {
  return {
    type: StoreActions.UPDATE_PRACTICE_SUCCESS,
    practice,
  };
}

/**
 * Request Practice List
 */
export function loadPractices() {
  return function (dispatch) {
    return practiceApi
      .getPractices()
      .then((practices) => {
        dispatch(
          displayParcticeList(
            practices.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              else return 0;
            })
          )
        );
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Save practice request (create and update)
 * @param {object} practice
 */
export function savePractice(practice) {
  return function (dispatch) {
    if (practice.id) {
      return practiceApi
        .updatePractice(practice)
        .then((res) => {
          console.log(res);
          dispatch(updatePracticeSuccess(practice));
        })
        .catch((err) => {
          throw err;
        });
    } else {
      delete practice.id;
      return practiceApi
        .addPractice(practice)
        .then((createdPractice) => {
          practice.id = createdPractice.UserSub;
          dispatch(createPracticeSuccess(practice));
        })
        .catch((error) => {
          throw error;
        });
    }
  };
}
