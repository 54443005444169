import { cloneDeep, omit } from 'lodash';
import { areCaseInsensitiveEqual } from 'utils/helpers/compareStrings';
import mapPaymentLinkLogToModel from 'utils/api/maps/mapPaymentLinkLogToModel';

function mapPracticeToModel(practiceContract) {
  var practiceModel = cloneDeep(practiceContract);
  if (areCaseInsensitiveEqual(practiceModel.coreId, 'Not Assigned')) {
    practiceModel.coreId = null;
  }
  if (areCaseInsensitiveEqual(practiceModel.stripeId, 'Not Assigned')) {
    practiceModel.stripeId = null;
  }
  return practiceModel;
}

function mapUserToModel(userContract) {
  var userModel = cloneDeep(userContract);
  if (areCaseInsensitiveEqual(userModel.email, 'Not Assigned')) {
    userModel.email = null;
  }
  return userModel;
}

const fromMessage = (mapper) => (response) => {
  if (response == null) {
    return null;
  }
  if (Array.isArray(response.Items)) {
    var mappedArrayResponse = cloneDeep(response, omit('Items'));
    mappedArrayResponse.Items = response.Items.map(mapper);
    return mappedArrayResponse;
  }
  return mapper(response);
};

const practice = {
  toModel: fromMessage(mapPracticeToModel),
};

const transactionPaymentLinkLog = {
  toModel: mapPaymentLinkLogToModel,
};

const transaction = {
  paymentLinkLog: transactionPaymentLinkLog,
};

const user = {
  toModel: fromMessage(mapUserToModel),
};

const map = {
  practice,
  transaction,
  user,
};

export default map;
