import React from 'react';
import PropTypes from 'prop-types';
import NoDataToDisplayList from 'components/Common/NoDataToDisplayList';
import {
  userColumns,
  staffColumns,
  paginationOptions,
} from './staff-list.config';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const StaffList = ({ users, userType, currentUser, onResetPassword }) => {
  users = users.map((user) => {
    return { ...user, userType, onResetPassword };
  });

  return (
    <div className='staff-list' id='StaffList' style={{ padding: '0 1.5rem' }}>
      {userType === 'users' && (
        <div className='mt-4'>
          <div className='alert alert-primary' style={{ position: 'static' }}>
            Enter in the names of the employees who will have their own
            individual log-ins to the tablets and/or web portal
          </div>
        </div>
      )}
      {userType === 'staff' && (
        <div className='mt-4'>
          <div className='alert alert-primary' style={{ position: 'static' }}>
            Enter in the names of employees at your practice who will be
            processing transactions through the VitusPay tablet. Their name will
            appear in a drop down after log-in.
            <div>
              <small>
                <strong>NOTE:</strong> this will not give the employee a login
                to the tablet or the web portal.
              </small>
            </div>
          </div>
        </div>
      )}
      {users.length > 0 ? (
        <div className='table-responsive-md'>
          <BootstrapTable
            keyField='id'
            data={users}
            columns={
              userType === 'users'
                ? userColumns(currentUser)
                : staffColumns(currentUser)
            }
            bordered={false}
            classes='vitus-table'
            pagination={paginationFactory(paginationOptions)}
          />
        </div>
      ) : (
        <NoDataToDisplayList />
      )}
    </div>
  );
};

StaffList.propTypes = {
  users: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default StaffList;
