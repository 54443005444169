import React from 'react';
import { Navbar } from 'react-bootstrap';

export default function ContactSupportHeader(props) {
  const { title } = props;

  return (
    <Navbar className='practices-header vitus-app-header' expand='lg'>
      <Navbar.Brand>
        <small className='ml-2'>{title}</small>
      </Navbar.Brand>
    </Navbar>
  );
}
