import React from 'react';
import { Form } from 'react-bootstrap';

const PaymentPlanOptionCheckbox = (props) => {
  const { includePaymentPlanOption, setIncludePaymentPlanOption } = props;

  return (
    <Form.Group controlId='includePaymentPlanOption'>
      <Form.Check
        custom
        name='includePaymentPlanOption'
        checked={includePaymentPlanOption}
        type='checkbox'
        id='include-payment-plan-checkbox'
        label='Include Payment Plan Option'
        onChange={(event) => {
          setIncludePaymentPlanOption(event.target.checked);
        }}
      />
    </Form.Group>
  );
};

export default PaymentPlanOptionCheckbox;
