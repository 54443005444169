import React from 'react';
import Env from './../../../../Env';
import Currency from './../../../../utils/helpers/currency';
import sortCaret from './../../../../utils/helpers/sortCaret';
import DateTime from './../../../../utils/helpers/DateTime';
import DailyReportRecordSanityCheck from './../../../Common/SanityChecks/DailyReportRecordSanityCheck';

function currencyFormatter(cell) {
  return <Currency amount={cell} formatStripe={true} />;
}

function dateFormatter(cell) {
  return <DateTime datetime={cell} format='MM/DD/YY' />;
}

function sanityCheckFormatter(cell, row) {
  return <DailyReportRecordSanityCheck data={row} />;
}

export const columns = [
  {
    text: 'DATE',
    dataField: 'date',
    formatter: dateFormatter,
    sort: true,
    sortCaret,
  },
  {
    text: 'TRANSACTIONS',
    dataField: 'count',
    sort: true,
    sortCaret,
  },
  {
    text: 'PAYMENT AMOUNT',
    dataField: 'amount',
    formatter: currencyFormatter,
    sort: true,
    sortCaret,
  },
  {
    text: 'FEES',
    dataField: 'fees',
    formatter: currencyFormatter,
    sort: true,
    sortCaret,
  },
  {
    text: 'REFUNDED',
    dataField: 'refunds',
    formatter: currencyFormatter,
    sort: true,
    sortCaret,
  },
  {
    text: 'TOTAL',
    dataField: 'total',
    formatter: currencyFormatter,
    sort: true,
    sortCaret,
  },
];

if (Env.sanityChecksEnabled) {
  columns.push({
    text: '',
    dataField: 'sanityCheck',
    formatter: sanityCheckFormatter,
    isDummyField: true,
  });
}

export const defaultSorted = [{ dataField: 'date', order: 'desc' }];

export const paginationOptions = {
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
  firstPageText: '<<',
  lastPageText: '>>',
  paginationSize: 5,
  alwaysShowAllBtns: true,
};
