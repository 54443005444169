export function buildSpreedlyPaymentLinkRequest(payment) {
  const {
    amount,
    description,
    petOwnerName,
    serviceType,
    staffMemberId,
    communicationType,
    petOwnerPhone = '',
    petOwnerEmail = '',
    includePaymentPlanOption = false,
  } = payment;

  return {
    paymentInformation: {
      amount,
      invoiceId: description,
      includePaymentPlanOption,
      serviceType,
    },
    petData: {
      owner: {
        name: petOwnerName,
        phone: petOwnerPhone,
        contactEmail: petOwnerEmail,
      },
    },
    user: {
      id: staffMemberId,
    },
    communicationType,
  };
}
