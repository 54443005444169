import React from 'react';
import { standardFormatPhoneNumber } from 'utils/helpers/phoneNumberFormat';

import DeleteButton from '../DeleteButton';
import EditButton from '../EditButton';
import EditCardButton from '../EditCardButton';

function EmptyValue(cell) {
  return <>{cell ? cell : '—'}</>;
}

export const ButtonFormatter = (buttonFuncs) =>
  function (cell, row) {
    const { onDelete, onEdit, onEditCard } = buttonFuncs;
    return (
      <div className='ms-left'>
        <EditButton onClick={() => onEdit(row)} />
        <EditCardButton onClick={() => onEditCard(row)} />
        <DeleteButton onClick={() => onDelete(row)} />
      </div>
    );
  };

export const customersColumns = (buttonFuncs) => [
  {
    dataField: 'full_name',
    text: 'CUSTOMER NAME',
  },
  {
    dataField: 'addresses[0].address_1',
    text: 'ADDRESS',
    formatter: EmptyValue,
    hidden: true, // Hiding addresses until further notice
  },
  {
    dataField: 'phone',
    text: 'PHONE NUMBER',
    formatter: standardFormatPhoneNumber,
  },
  {
    dataField: 'payment_methods[0].card_type',
    text: 'CARD TYPE',
  },
  {
    dataField: 'payment_methods[0].last_four',
    text: 'LAST FOUR',
    formatter: (cell) => (cell ? '**** '.concat(cell) : ''),
  },
  {
    dataField: 'payment_methods[0].expiration',
    text: 'EXPIRATION',
  },
  {
    dataField: 'buttonFuncs',
    text: 'ACTIONS',
    formatter: ButtonFormatter(buttonFuncs),
  },
];
